import React from 'react';

const TextPrivacyEn = () => {
  return (
    <>
      <b>Article 2 Items of personal information processed and retention period</b>
      ① By default, personal information refers to the information of a person who is alive. Yet,
      when the information is regarding both corporate or organization and a person, it can be
      processed as personal information in a certain situation. Therefore, the information of
      corporate or organization is included in the items of personal information processed to make
      it easy to understand.
      <br />
      <br />
      ② The company processes and retains personal information of its users according to the
      relevant laws or within the retention period agreed by users when the personal information was
      collected.
      <br />
      <table>
        <colgroup>
          <col style={{ width: '15%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
        </colgroup>
        <thead>
          <tr>
            <th colSpan="2">Category</th>
            <th colSpan="2">Processed items</th>
            <th>Period of retention and use</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="2" rowSpan="2">
              Member sign up
            </td>
            <td>E-mail member sign up</td>
            <td>[Required] E-mail address (Login ID), Password</td>
            <td rowSpan="2">Until membership withdrawal</td>
          </tr>
          <tr>
            <td>Simple member sign up (Apple, Facebook, Google)</td>
            <td>[Required] Simple sign up account, e-mail address</td>
          </tr>
          <tr>
            <td colSpan="2">
              Paid service purchase <br />
              (In-app payment)
            </td>
            <td colSpan="2">Receipt number</td>
            <td>Until legally mandatory retention period</td>
          </tr>
          <tr>
            <td colSpan="2">
              When you use the service, <br />
              the following information can be collected
            </td>
            <td colSpan="2">
              Service usage and stoppage record, access logs, cookies, IP connection information,
              device information
            </td>
            <td>Until legally mandatory retention period</td>
          </tr>
          <tr>
            <td rowSpan="4">Member consultation & complaint report, process result notification</td>
            <td>E-mail report</td>
            <td colSpan="2">E-mail address</td>
            <td rowSpan="4">Until legally mandatory retention period</td>
          </tr>
          <tr>
            <td>Fax report</td>
            <td colSpan="2">Fax number</td>
          </tr>
          <tr>
            <td>Phone report</td>
            <td colSpan="2">Cell phone number</td>
          </tr>
          <tr>
            <td>Mail report</td>
            <td colSpan="2">Address</td>
          </tr>
        </tbody>
      </table>
      ③ Personal information items the company retains according to the relevant laws and its
      retention period is as follows.
      <br />
      <br />
      1. The Act on Consumer Protection in Electronic Commerce, etc.
      <br />
      A. Records of agreement or subscription cancellations: Five years
      <br />
      B. Records on payments and provision of currency: Five years
      <br />
      C. Records on consumer complaints or dispute processing: Three years
      <br />
      <br />
      2. Protection of Communications Secrets Act
      <br />
      A. Visit (access) records: Three months
      <br />
      <br />
      ④ “The company”, according to the Personal Information Protection Act, will make the ID and
      personal information of a “member” as dormant if there is no login record or use of services
      for 12 months or longer. In this case, before 30 days prior to the 12 months period, the
      “company” will contact a “member” through e-mail, document, fax, phone, or a similar method to
      notify that which personal information items of a “member” will be separately stored and
      managed and its expiration date. However, if the member is using a service, regardless of the
      expiration date, the ID will become dormant once the service is removed. The personal
      information of a dormant ID will be stored for four years and discarded without delay after
      four years.
      <br />
      <br />
      <a href="/privacy" target="_blank">
        [View full text of Privacy Policy]
      </a>
    </>
  );
};

export default TextPrivacyEn;
