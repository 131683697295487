import React, { useState } from 'react';
import cn from 'classnames';

import Footer from '../../layouts/Footer';
import Header from '../../layouts/Header';
import { Link } from 'react-router-dom';

const Terms = ({ isLogin, user, t }) => {
  const [buttonType, setButtonType] = useState('ko');

  return (
    <>
      <div
        className={cn('wrap', {
          wrapKo: t('languageCode') === 'ko',
          wrapEn: t('languageCode') === 'en',
          wrapId: t('languageCode') === 'id',
          wrapVi: t('languageCode') === 'vi',
        })}
      >
        <Header />

        <section className="sub-contents">
          <div className="sub-inner">
            <article className="privacy-wrap">
              <div className="title-area">
                <h3 className={cn('page-title', 'kor', { active: buttonType === 'ko' })}>
                  Hancom Genie K (한컴 지니케이) 이용약관
                </h3>
                <h3 className={cn('page-title', 'eng', { active: buttonType === 'en' })}>
                  Hancom Genie K Terms of Use
                </h3>
                <div className="lang">
                  <button
                    data-lang="kor"
                    className={cn({ active: buttonType === 'ko' })}
                    onClick={(e) => {
                      e.preventDefault();
                      setButtonType('ko');
                    }}
                  >
                    KOR
                  </button>
                  <button
                    data-lang="eng"
                    className={cn({ active: buttonType === 'en' })}
                    onClick={(e) => {
                      e.preventDefault();
                      setButtonType('en');
                    }}
                  >
                    ENG
                  </button>
                </div>
              </div>
              <div className="privacy-content">
                <div id="kor" className={cn({ active: buttonType === 'ko' })}>
                  <h4>제 1 조 (목적)</h4>
                  <div>
                    <p>
                      본 약관은 회원(본 약관을 동의한 자를 말합니다. 이하 ‘회원’이라고 합니다.)이
                      주식회사 한글과컴퓨터(이하 ‘회사’라 합니다)이 제공하는 Hancom Genie K (한컴
                      지니케이) 서비스 (이하 ‘서비스’라 합니다)를 이용함에 있어 회사와 회원의 권리,
                      의미 및 책임사항을 규정함을 목적으로 합니다.
                    </p>
                  </div>

                  <h4>제 2 조 (정의)</h4>
                  <div>
                    <ul>
                      <li>
                        ① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.1. ‘서비스’라 함은 접속
                        가능한 단말기의 종류와는 상관없이 이용 가능한 회사가 제공하는 온라인상의
                        인터넷 서비스를 의미합니다.
                      </li>
                      <li>
                        2. ‘회원’이라 함은 회사의 서비스에 접속하여 이 약관에 따라 회사와 이용계약을
                        체결하고 회사가 제공하는 서비스를 이용하는 고객을 말합니다.
                      </li>
                      <li>
                        3. ‘비회원’이라 함은 회원으로 가입하지 않고 회사가 제공하는 서비스를
                        이용하는 자를 말합니다.
                      </li>
                      <li>
                        4. ‘이용자’란 서비스에 접속하여 이 약관에 따라 회사가 제공하는 정보 및 기타
                        서비스를 제공받는 회원 및 비회원을 말합니다.
                      </li>
                      <li>
                        5. ‘콘텐츠’라 함은 회원이 서비스를 이용함에 있어 서비스상에 게시한 대화
                        시나리오 및 Text, 음성, 이미지 형태의 디지털 콘텐츠를 의미하며,
                        정보통신망이용촉진 및 정보보호 등에 관한 법률 제2조 제1항 제1호의 규정에
                        의한 정보통신망에서 사용되는 부호.문자.음성.음향.이미지.영상 등으로 표현된
                        자료 또는 정보를 말합니다.
                      </li>
                      <li>
                        6. ‘유료서비스’라 함은 회사가 유료로 제공하는 각종 온라인디지털콘텐츠(각종
                        정보 콘텐츠, 유료 콘텐츠) 및 제반 서비스를 의미합니다.
                      </li>
                      <li>
                        7. ‘정기구독‘라 함은 ‘회원’이 ‘유료서비스’를 이용하기 위해 미리 등록한
                        결제수단을 통하여 월/년 단위로 이용 요금이 정기적으로 결제되고, 이용기간이
                        자동 갱신되는 ‘유료서비스’ 이용방식을 말합니다. ‘정기구독’을 해지하면
                        ‘결제’가 이루어진 ‘유료서비스’의 남은 이용기간 동안은 ‘유료서비스’를 정상
                        이용할 수 있고, 다음 이용기간부터 자동 결제가 이루어지지 않습니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 3 조 (약관의 명시와 개정)</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회사는 이 약관의 내용과 상호 및 대표자의 성명, 영업소 소재지 주소(소비자의
                        불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 전자우편주소, 사업자
                        등록번호, 통신판매업신고번호, 개인정보관리책임자 등을 이용자가 쉽게 알 수
                        있도록 서비스 웹사이트에 게시합니다.
                        <br />
                        회사는 약관의규제에관한법률, 정보통신망이용촉진 및 정보보호등에관한법률(이하
                        ‘정보통신망법’) 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수
                        있습니다.
                      </li>
                      <li>
                        ② 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과
                        함께 제1항의 방식에 따라 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지
                        공지합니다. 다만, 이용자의 권리와 의무에 중대한 영향을 미치는 사항에
                        대하여는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.
                      </li>
                      <li>
                        ③ 회사가 전항에 따라 공지된 적용일자 이후에 이용자가 거부 표시 없이 회사의
                        서비스를 계속 이용하는 경우에는 개정된 약관에 동의하는 것으로 봅니다.
                      </li>
                      <li>
                        ④ 회원이 개정약관의 적용에 동의하지 않는 경우 회원은 이용계약을 해지할 수
                        있습니다.
                      </li>
                      <li>
                        ⑤ 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 관계법령 또는 상관례에
                        따릅니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 4 조 (이용계약 체결)</h4>
                  <div>
                    <ul>
                      <li>
                        ① 이용계약은 회원이 되고자 하는 자(이하 가입신청자)가 약관의 내용에 대하여
                        동의를 한 다음 계정 인증을 함으로써 체결됩니다.
                      </li>
                      <li>
                        ② 회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다.
                        다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에
                        이용계약을 해지 할 수 있습니다.
                        <ul className="one-step">
                          <li>
                            1. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는
                            경우, 단 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 함
                          </li>
                          <li>2. 실명이 아니거나 타인의 명의를 이용한 경우</li>
                          <li>
                            3. 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않는 경우
                          </li>
                          <li>4. 14세 미만 아동이 법정대리인의 동의를 얻지 아니한 경우</li>
                          <li>
                            5. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반
                            사항을 위반하며 신청하는 경우
                          </li>
                        </ul>
                      </li>
                      <li>
                        ③ 제4조 제2항 제1호에 따른 신청에 있어 회사”는 회원의 종류에 따라 전문기관을
                        통한 실명확인 및 본인인증을 요청할 수 있습니다.
                      </li>
                      <li>
                        ④ 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는
                        경우에는 승낙을 유보할 수 있습니다.
                      </li>
                      <li>
                        ⑤ 제4조 제2항 제2호과 제4호에 따라 회원가입신청의 승낙을 하지 아니하거나
                        유보한 경우, 회사는 원칙적으로 이를 가입 신청자에게 알리도록 합니다.
                        <br />
                        이용계약의 성립 시기는 사용자가 계정인증을 진행하여 회원가입의 절차가 완료된
                        시점으로 합니다.
                      </li>
                      <li>
                        ⑥ 회사는 회원에 대해 회사정책에 따라 등급별로 구분하고 이용시간, 이용횟수,
                        이용방식, 이용범위. 서비스 메뉴 등을 세분화하여 이용에 차등을 둘 수
                        있습니다.
                      </li>
                      <li>
                        ⑦ 회사는 회원에 대하여 영화 및 비디오물의진흥에관한법률 및 청소년보호법 등에
                        따른 등급 및 연령 준수를 위해 이용제한이나 등급별 제한을 할 수 있습니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 5 조 (회원정보의 변경)</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회원은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고
                        수정할 수 있습니다.
                      </li>
                      <li>
                        ② 회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을
                        해야 합니다.
                      </li>
                      <li>
                        ③ 제5조 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는
                        책임지지 않습니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 6 조 (개인정보보호 의무)</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회사는 정보통신망법 등 관계 법령이 정하는 바에 따라 회원의 개인정보를
                        보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및
                        회사의 개인정보취급방침이 적용됩니다. 다만, 회사의 공식 사이트 이외의 링크된
                        사이트에서는 회사의 개인정보 취급방침이 적용되지 않습니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 7 조 (회원의 아이디 및 비밀번호의 관리에 대한 의무)</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회원의 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가
                        이용하도록 하여서는 안 됩니다.
                      </li>
                      <li>
                        ② 회사는 회원의 아이디가 개인정보 유출 우려가 있거나, 반사회적 또는
                        미풍양속에 어긋나거나 회사 및 회사의 운영자로 오인한 우려가 있는 경우, 해당
                        아이디의 이용을 제한할 수 있습니다.
                      </li>
                      <li>
                        ③ 회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한
                        경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.
                      </li>
                      <li>
                        ④ 제7조 제3항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한
                        경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지
                        않습니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 8 조 (회원에 대한 통지)</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회사가 회원에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스
                        내 전자우편주소, 전자쪽지 등으로 할 수 있습니다.
                      </li>
                      <li>
                        ② 회사는 회원 전체에 대한 통지의 경우 7일 이상 회사의 서비스에 게시함으로써
                        제1항의 통지에 갈음할 수 있습니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 9 조 (회사의 의무)</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회사는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며,
                        계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.
                      </li>
                      <li>
                        ② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보
                        포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보 취급방침을 공시하고
                        준수합니다.
                      </li>
                      <li>
                        ③ 회사는 서비스 이용과 관련하여 발생하는 이용자의 불만 또는 피해구제요청을
                        적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비합니다.
                      </li>
                      <li>
                        ④ 회사는 서비스 이용과 관련하여 회원으로 부터 제기된 의견이나 불만이
                        정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이 제기한 의견이나
                        불만사항에 대해서는 전자우편 등을 통하여 회원에게 처리 과정 및 결과를
                        전달합니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 10 조 (회원의 의무)</h4>
                  <div>
                    <p>회원은 다음 행위를 하여서는 안 됩니다.</p>
                    <ul>
                      <li>1. 신청 또는 변경 시 허위내용의 등록</li>
                      <li>2. 타인의 정보도용</li>
                      <li>3. 회사가 게시한 정보의 변경</li>
                      <li>
                        4. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
                      </li>
                      <li>5. 회사 및 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
                      <li>6. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
                      <li>
                        7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를
                        서비스에 공개 또는 게시하는 행위
                      </li>
                      <li>8. 회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위</li>
                      <li>9. 기타 불법적이거나 부당한 행위</li>
                    </ul>
                  </div>
                  <h4>제 11 조 (서비스의 제공 등)</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회사는 회원에게 아래와 같은 서비스를 제공합니다.
                        <ul className="one-step">
                          <li>1. 학습 시나리오 콘텐츠 제공</li>
                          <li>2. 사니리오별 퀴즈 문항 및 단어, 문형 제공</li>
                          <li>3. 학습 결과 데시보드를 통한 통계 제공</li>
                          <li>4. 이메일, 문자, 메시지 등을 활용한 정보 알림</li>
                          <li>
                            5. 기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 회원에게
                            제공하는 일체의 서비스
                          </li>
                        </ul>
                      </li>
                      <li>② 서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.</li>
                      <li>
                        ③ 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는
                        운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수
                        있습니다. 이 경우 회사는 제9조 [회원에 대한 통지]에 정한 방법으로 회원에게
                        통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우
                        사후에 통지할 수 있습니다.
                      </li>
                      <li>
                        ④ 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며,
                        정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 12 조 (주문 및 결제)</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회원은 회사가 제공하는 다음 각 호 또는 이와 유사한 절차에 의하여 콘텐츠
                        구매를 신청 합니다.
                        <ul className="one-step">
                          <li>1. 콘텐츠의 검색, 목록 열람 및 구매 대상 선택</li>
                          <li>2. 콘텐츠 상세정보 확인</li>
                          <li>3. 결제금액 확인, 결제수단 및 선택 결제하기 (환불규정 안내)</li>
                          <li>4. 결제금액 재확인 및 결제</li>
                        </ul>
                      </li>
                      <li>
                        ② 회원은 회사에서 정하고 있는 방법으로 유료서비스의 결제가 가능합니다. 단,
                        미성년 회원의 결제는 원칙적으로 법정대리인의 명의 또는 동의 하에 이루어져야
                        하고, 법정대리인은 본인 동의 없이 체결된 자녀(미성년자)의 계약을 취소할 수
                        있습니다. 미성년자가 다른 성년자 등의 주민등록번호를 이용하여 회원가입을
                        하거나 성년자 등의 결제정보를 동의 없이 이용하는 등 회사로 하여금 성년자인
                        것으로 믿게 한 경우에는 법정대리인의 동의가 없더라도 계약을 취소하지
                        못합니다.
                      </li>
                      <li>
                        ③ 1인당 1회 한정으로 제공되는 혜택의 경우, 서로 다른 ID에서 혜택 수령을
                        시도하더라도 결제수단 및 기타 개인식별정보를 이용하여 동일인으로 간주하고
                        혜택 제공을 차단할 수 있습니다.
                      </li>
                      <li>
                        ④ 앱 마켓 인앱 결제의 경우 각 앱 마켓 서비스 이용 약관 정책을 따릅니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 13 조 (환불규정)</h4>
                  <div>
                    <ul>
                      <li>
                        ① 구매를 철회하거나 취소하고 제품을 반품하거나 환불받을 권리에 관한 자세한
                        정보는 각 앱 마켓 서비스 이용 약관 정책을 따릅니다. 각 앱 마켓 환불 정책
                        또는 제공자의 환불 정책에 명시적으로 규정된 경우를 제외하고 모든 구매는
                        취소할 수 없으며 반품, 교환 또는 환불이 허용되지 않습니다.
                      </li>
                      <li>
                        ② 앱 마켓의 인앱 결제 수단 및 해지/환불 이슈 등은 각 앱 마켓 고객센터에
                        문의하시기 바랍니다. 단, 다음 각 호에 해당하는 경우에는 이용자가 환불을
                        요청할 수 없습니다.
                        <ul className="one-step">
                          <li>1. 구매 콘텐츠 열람 시</li>
                          <li>
                            2. 서비스 업데이트를 통한 문제 해결이 가능함에도 회원의 의사로 이를
                            거부하여 서비스를 이용하지 못하는 경우
                          </li>
                          <li>3. 회원의 실수로 해당 서비스를 이용하지 못하는 경우</li>
                          <li>
                            4. 회원이 유료서비스를 선물 받거나, 프로모션 등을 통해 무료/무상으로
                            취득하는 등 회원이 직접 비용을 지불하지 아니하고 이용하는 유료서비스의
                            경우
                          </li>
                        </ul>
                      </li>
                      <li>
                        ③ ‘유료서비스’를 유지하고자 하는 ‘유료회원’은 이용요금의 체납 또는
                        결제수단의 연체가 발생하지 않도록 사전에 조치하여야 합니다.
                      </li>
                      <li>
                        ④ ‘회원’이 전항을 위반하여 ‘유료서비스’의 신청 또는 동의에 따라 월 정기결제
                        중인 ‘유료서비스’를 이용하는 중에 ‘유료서비스’의 이용요금을 체납하는 경우
                        연체가 발생한 날 자동으로 이용권 해지가 될 수 있습니다.
                      </li>
                      <li>
                        ⑤ ‘유료서비스’를 이용 중인 ‘회원’이 회원가입 계약을 해지하는 경우 해당
                        ‘유료서비스’는 즉시 해지되며, 환불할 경우, '회사'는 '회원’의 서비스 이용한
                        일 수, 이용내역, 서비스 또는 상품을 통하여 얻은 이익에 해당하는 금액, 할인
                        받은 금액 등을 차감 후 환불합니다. 다만, ‘회사’는 이 경우 ‘유료서비스’를
                        이용한 ‘회원’의 정보와 이용 내용은 관련 법령이 정하는 경우를 제외하고는 이
                        약관에 따라 처리합니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 14 조 (서비스의 변경)</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회사는 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를
                        변경할 수 있습니다. (유료 서비스 포함)
                      </li>
                      <li>
                        ② 서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유,
                        변경될 서비스의 내용 및 제공일자 등은 그 변경 전에 해당 서비스 초기화면에
                        게시하여야 합니다.
                      </li>
                      <li>
                        ③ 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의
                        필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는
                        한 회원에게 별도의 보상을 하지 않습니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 15 조 (정보의 제공 및 광고의 게재)</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를
                        공지사항이나 전자우편 등의 방법으로 회원에게 제공할 수 있습니다. 다만,
                        회원은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을
                        제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.
                      </li>
                      <li>
                        ② 제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는
                        회원의 사전 동의를 받아서 전송합니다. 다만, 회원의 거래관련 정보 및 고객문의
                        등에 대한 회신에 있어서는 제외됩니다.
                      </li>
                      <li>
                        ③ 회사는 서비스의 운영과 관련하여 서비스 화면, 홈페이지, 전자우편 등에
                        광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 회원은 수신거절을
                        회사에게 할 수 있습니다.
                        <br />
                        <br />
                        이용자(회원, 비회원 포함)는 회사가 제공하는 서비스와 관련하여 게시물 또는
                        기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 16 조 (콘텐츠의 저작권)</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회사가 서비스를 통하여 제공하는 콘텐츠의 저작권 및 기타 지적재산권은
                        회사에 있습니다.
                      </li>
                      <li>
                        ② 이용자는 서비스를 이용함으로써 얻는 정보 중 회사에게 지적재산권이 귀속된
                        정보를 회사의 사전 승낙없이 복제, 판매, 출판, 송신, 배포, 방송 기타 방법에
                        의하여 영리, 비영리 목적으로 이용하거나 제3자에게 이용하게 하여서는
                        안됩니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 17 조 (저작권의 관리)</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회사와 업무협의를 통하여 업로드한 관계사의 콘텐츠가 정보통신망법 및
                        저작권법 등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한
                        절차에 따라 해당 콘텐츠의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는
                        관련법에 따라 해당 콘텐츠를 임의로 삭제할 수 있으며 해당 게시물에 대한 법적
                        문제가 종결된 후 이를 근거로 회사에 신청이 있는 경우에만 상기 임시 삭제된
                        게시물을 다시 게재할 수 있습니다.
                      </li>
                      <li>
                        ② 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한
                        사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라
                        해당 콘텐츠에 대해 임시 조치 등을 취할 수 있습니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 18 조 (권리의 귀속)</h4>
                  <div>
                    <ul>
                      <li>① 서비스에 대한 저작권 및 지적재산권은 회사에 귀속됩니다.</li>
                      <li>
                        ② 회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 계정,
                        아이디, 콘텐츠 등을 이용할 수 있는 이용권만을 부여하며, 회원은 이를 양도,
                        판매, 담보 제공 등의 처분행위를 할 수 없습니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 19 조 (계약해제, 해지 등)</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회원은 언제든지 서비스 내에 메뉴를 통하여 이용계약 해지 신청을 할 수
                        있으며, 회사는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.
                      </li>
                      <li>
                        ② 회원이 계약을 해지할 경우, 관련법 및 개인정보취급방침에 따라 회사가
                        회원정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 모든 데이터는
                        소멸됩니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 20 조 (이용제한 등)</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회사는 회원이 이 약관의 위반하의무를 거나 서비스의 정상적인 운영을 방해한
                        경우, 경고, 일시정지, 영구이용정지 등으로 서비스 이용을 단계적으로 제한할 수
                        있습니다.
                      </li>
                      <li>
                        ② 회사는 전항에도 불구하고, 주민등록법을 위반한 명의도용 및 결제도용,
                        저작권법 및 컴퓨터프로그램보호법을 위반한 불법프로그램의 제공 및 운영방해,
                        정보통신망법을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한
                        초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수
                        있습니다. 본 항에 따른 영구이용정지 시 서비스 이용을 통해 획득한 모든 혜택도
                        함께 소멸되며, 회사”는 이에 대해 별도로 보상하지 않습니다.
                      </li>
                      <li>
                        ③ 회사는 회원이 계속해서 3개월 이상 로그인하지 않은 경우, 회원정보의 보호 및
                        운영의 효율성을 위해 이용을 제한할 수 있습니다.
                        <br />
                        <br />본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는 회사는
                        제8조[회원에 대한 통지]에 따라 통지합니다.
                      </li>
                      <li>
                        ④ 회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을
                        할 수 있습니다. 이 때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시
                        서비스의 이용을 재개합니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 21 조 (책임제한)</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수
                        없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
                      </li>
                      <li>
                        ② 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지
                        않습니다.
                        <br />
                        회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성
                        등의 내용에 관하여는 책임을 지지 않습니다.
                      </li>
                      <li>
                        ③ 회사는 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래 등을
                        한 경우에는 책임이 면제됩니다.
                      </li>
                      <li>
                        ④ 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는
                        한 책임을 지지 않습니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 22 조 (준거법 및 재판관할)</h4>
                  <div>
                    <ul>
                      <li>① 회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.</li>
                      <li>
                        ② 회사와 회원간 발생한 분쟁에 관한 소송은 서울중앙지방법원을 전속관할로
                        합니다.
                      </li>
                      <li>
                        ③ 해외에 주소나 거소가 있는 회원의 경우 회사와 회원간 발생한 분쟁에 관한
                        소송은 대한민국 서울중앙지방법원을 관할법원으로 합니다.
                      </li>
                    </ul>
                  </div>

                  <h4>[부칙]</h4>
                  <h5>제 1 조 (시행일)</h5>
                  <div>
                    <p>본 약관은 2021년 06월 16일자부터 시행하며, 종전의 약관을 대체합니다.</p>
                  </div>
                </div>
                <div id="eng" className={cn({ active: buttonType === 'en' })}>
                  <h4>Article 1 (Purpose)</h4>
                  <div>
                    <p>
                      The purpose of the terms of use is to stipulate the rights, meaning, and
                      obligations of the member (the person who agreed to the terms of use;
                      hereafter "the member") and Hancom Co., Ltd. (hereafter "the company") with
                      respect to using the Hancom Genie K service (hereafter "the service") offered
                      by the company.
                    </p>
                  </div>

                  <h4>Article 2 (Definition)</h4>
                  <div>
                    <ul>
                      <li>
                        ① The definition of terms used in the terms of use is as follows.1.
                        "Service" refers to the online internet services provided by the company
                        which can be used regardless of the types of devices accessible.
                      </li>
                      <li>
                        2. "Member" refers to customers who access the company's service to sign a
                        user agreement with the company in accordance with the terms of use and use
                        the services provided by the company.
                      </li>
                      <li>
                        3. "Non-member" refers to those who use the service provided by the company
                        without registering as a member.
                      </li>
                      <li>
                        4. "User" refers to a member or non-member who accesses the service and
                        receives information or other services provided by the company in accordance
                        with the terms of use.
                      </li>
                      <li>
                        5. "Content" refers to digital content in the form of conversation
                        scenarios, texts, voice, or images that are presented in the service while
                        the member uses the service, and refers to materials or information
                        expressed as symbols, text, voice, sound, images, videos, etc. which are
                        used in the information and communications network in accordance with
                        Article 2 Paragraph 1 Subparagraph 1 of the Act on the Promotion of
                        Information and Communications Network Utilization and Information
                        Protection, Etc.
                      </li>
                      <li>
                        6. "Paid Service" refers to various online digital content (including
                        information content, and paid content) and related services provided by the
                        company at a charge.
                      </li>
                      <li>
                        7. "Regular Subscription" refers to a method of using "paid services" for
                        which a subscription period is renewed automatically and subscription fees
                        are paid regularly every month/year through the pre-registered payment
                        method for a "member" to use the "paid services". If a "regular
                        subscription" is canceled, "paid services" can be used normally for the
                        remaining period of the "paid services" for which payment is completed, and
                        automatic payments will not be charged for the next usage period.
                      </li>
                    </ul>
                  </div>
                  <h4>Article 3 (Announcement and Amendment of Terms of Use)</h4>
                  <div>
                    <ul>
                      <li>
                        ① The company posts the content of the terms of use, company name,
                        representative's name, business address (including the address where
                        consumer complaints are handled), phone number, email address, business
                        registration number, online marketing business report number, personal
                        information manager, etc. on the service website so that it is easily
                        accessible to users.
                        <br />
                        The company may make amendments to the terms of use within a scope which
                        does not violate any relevant laws including the Act on the Regulation of
                        Terms and Conditions and the Act on the Promotion of Information and
                        Communications Network Utilization and Information Protection, Etc.
                        (hereafter "the Information and Communications Network Act").
                      </li>
                      <li>
                        ② If the company amends the terms of use, it will announce the date of
                        effect and reason for the amendments along with the current terms of use
                        from 7 days before the date of effect to the day before the date of effect,
                        in accordance with the method in Paragraph 1. However, matters which may
                        have a significant impact on the rights and obligations of users will be
                        announced at least 30 days in advance.
                      </li>
                      <li>
                        ④ Members may cancel the user agreement if they do not consent to the
                        application of the amended terms.
                      </li>
                      <li>
                        ③ After the date of effect announced by the company in accordance with the
                        previous paragraph, it is deemed that a user agrees to the amended terms of
                        use when the user continues to use the company's service without any
                        expression of refusal.
                      </li>
                      <li>
                        ⑤ Matters or interpretations which are not stipulated in the terms of use
                        shall be determined in accordance with relevant laws or common commercial
                        practices.
                      </li>
                    </ul>
                  </div>
                  <h4>Article 4 (Signing the User Agreement)</h4>
                  <div>
                    <ul>
                      <li>
                        ① The user agreement is completed when a person who wishes to become a
                        member (hereafter "applicant") agrees to the content of the terms of use and
                        verifies their account.
                      </li>
                      <li>
                        ② By default, the company shall approve an applicant's application to use
                        the service. However, the company may not approve or cancel the user
                        agreement in the future for the applications in the following subparagraphs.
                        <ul className="one-step">
                          <li>
                            1. If the applicant has previously lost their membership in the past in
                            accordance with the terms of use, but an exception may be made upon the
                            company's approval for them to re-apply for membership.
                          </li>
                          <li>
                            2. If the applicant does not use a real name or uses someone else's name
                          </li>
                          <li>
                            3. If the applicant uses false information or does not enter required
                            information asked by the company.
                          </li>
                          <li>
                            4. If a minor under 14 years of age does not have a consent from their
                            legal representative
                          </li>
                          <li>
                            5. If approval cannot be given due to user liability or if the applicant
                            violates general regulations
                          </li>
                        </ul>
                      </li>
                      <li>
                        ③ With respect to the applications in accordance with Article 4 Paragraph 2
                        Subparagraph 1, "the company" may request real name verification and
                        identity verification through a specialized institution according to member
                        type.
                      </li>
                      <li>
                        ④ The company may withhold approval if there are not enough service-related
                        facilities or if there are technical or operational issues.
                      </li>
                      <li>
                        ⑤ If the applicant is not approved or if approval is withheld due to Article
                        4 Paragraph 2 Subparagraph 2 or 4, the company shall notify the applicant.
                        <br />
                        The user agreement shall be considered concluded when the user verifies
                        their account and completes the membership registration process.
                      </li>
                      <li>
                        ⑥ The company shall categorize members into different levels according to
                        the company's policies, and differentiate the services by sub-dividing the
                        usage time, frequency of use, usage method, usage range, service menu, and
                        more.
                      </li>
                      <li>
                        ⑦ The company may restrict usage or implement restrictions by user level in
                        order to comply with class or age regulations in accordance with the
                        Promotion of The Motion Pictures and Video Products Act and the Juvenile
                        Protection Act.
                      </li>
                    </ul>
                  </div>
                  <h4>Article 5 (Member Information Change)</h4>
                  <div>
                    <ul>
                      <li>
                        ① Members may view and revise their personal information at any time through
                        the personal information management screen.
                      </li>
                      <li>
                        ② Members can make revisions online if the information they entered when
                        signing up has changed.
                      </li>
                      <li>
                        ③ The company shall not be held responsible for any disadvantages which
                        occur due to members' failure to notify the company of the changes specified
                        in Article 5 Paragraph 2.
                      </li>
                    </ul>
                  </div>

                  <h4>Article 6 (Personal Information Protection Obligations)</h4>
                  <div>
                    <ul>
                      <li>
                        ① The company will make the effort to protect member's personal information
                        as stipulated in the Information and Communications Network Act and other
                        relevant laws. Relevant laws and the company's privacy policy apply to the
                        protection and use of personal information. However, except the company's
                        official website itself, the company's privacy policy does not apply to the
                        websites linked.
                      </li>
                    </ul>
                  </div>

                  <h4>Article 7 (Obligation to Member ID and Password Management)</h4>
                  <div>
                    <ul>
                      <li>
                        ① Members shall be responsible for managing their ID and password, and they
                        shall not allow any third party to use their ID and password.
                      </li>
                      <li>
                        ② The company may prohibit use of an ID in question if a member ID is
                        suspicious of personal information exposure, if it may be antisocial or
                        deviating from social norms, or if it could be misinterpreted as the company
                        or company's administrator.
                      </li>
                      <li>
                        ③ If a member becomes aware that their ID or password has been stolen or
                        that a third party is using this information, the member shall immediately
                        notify the company and follow the company's instructions.
                      </li>
                      <li>
                        ④ If, in cases stipulated in Article 7 Paragraph 3, members fail to notify
                        the company of these facts or if they fail to follow the company's
                        instructions after notifying the company, the company shall not be held
                        responsible for any disadvantages which may occur as a result of member
                        negligence.
                      </li>
                    </ul>
                  </div>

                  <h4>Article 8 (Notifications to Members)</h4>
                  <div>
                    <ul>
                      <li>
                        ① If the company sends a notification to members, it will be sent via email,
                        electronic message, etc. within the service unless otherwise specified in
                        the terms of use.
                      </li>
                      <li>
                        ② If the notification is for all members, the company may substitute the
                        notification from Paragraph 1 with an announcement displayed on the
                        company's service for 7 days or longer.
                      </li>
                    </ul>
                  </div>

                  <h4>Article 9 (The Company's Obligations)</h4>
                  <div>
                    <ul>
                      <li>
                        ① The company shall not engage in activities prohibited by relevant laws and
                        terms of use nor deviate from social norms, and shall make the effort to
                        provide continuous and stable service.
                      </li>
                      <li>
                        ② The company shall secure a security system to protect personal information
                        (including credit information) so that members can safely use the service,
                        and announce and comply with the privacy policy.
                      </li>
                      <li>
                        ③ The company shall prepare staff and systems required to appropriately
                        process user complaints or damage relief requests which may occur with
                        respect to use of service.
                      </li>
                      <li>
                        ④ If the company finds that a comment or complaint raised by a member
                        regarding service use is legitimate, the company must take appropriate
                        actions to resolve it. The company will deliver the processing status and
                        results to a member via email, etc. regarding a comment or complaint that
                        member has raised.
                      </li>
                    </ul>
                  </div>

                  <h4>Article 10 (Member Obligations)</h4>
                  <div>
                    <p>Members are prohibited from the following activities.</p>
                    <ul>
                      <li>1. Registering false information when applying or making changes</li>
                      <li>2. Using another person's information</li>
                      <li>3. Changing information posted by the company</li>
                      <li>
                        4. Transmitting or posting information (computer programs, etc.) not allowed
                        by the company
                      </li>
                      <li>
                        5. Violating the company's or a third party's copyright or intellectual
                        property rights
                      </li>
                      <li>
                        6. Damaging the company's or a third party's reputation or sabotaging an
                        operation
                      </li>
                      <li>
                        7. Disclosing or posting obscenities, violent messages, images, sounds, and
                        other information against public norms
                      </li>
                      <li>
                        8. Using the service for commercial purposes without the company's consent
                      </li>
                      <li>9. Other illegal or inappropriate activities</li>
                    </ul>
                  </div>

                  <h4>Article 11 (Service Provision, Etc.)</h4>
                  <div>
                    <ul>
                      <li>
                        ① The company will provide the following services to its members.
                        <ul className="one-step">
                          <li>1. Lesson scenario content</li>
                          <li>
                            2. Quiz questions, words, and sentence structures for each scenario
                          </li>
                          <li>3. Statistics through the lesson result dashboard</li>
                          <li>
                            4. Notifications of important information via email, text message,
                            direct message, etc.
                          </li>
                          <li>
                            5. All services provided for members through partnerships with other
                            companies or additional services developed by other companies
                          </li>
                        </ul>
                      </li>
                      <li>
                        ② By default, the service will be provided for 24 hours a day, all year
                        around.
                      </li>
                      <li>
                        ③ The company may temporarily suspend services if information and
                        communication facilities, including computers, require maintenance or
                        replacement, if there are any errors, communication interruptions, or other
                        significant operational reasons. In this case, the company shall notify
                        members through the methods stipulated in Article 9 [Notifications to the
                        Member]. However, if there is an unavoidable reason which does not allow the
                        company to announce it in advance, the company may notify members
                        afterwards.
                      </li>
                      <li>
                        ④ The company may carry out routine maintenance if it is necessary to
                        provide the service properly, and the routine maintenance schedule will be
                        announced on the service screen.
                      </li>
                    </ul>
                  </div>

                  <h4>Article 12 (Orders and Payments)</h4>
                  <div>
                    <ul>
                      <li>
                        ① Members can request to purchase content provided by the company according
                        to the following subparagraphs or another similar procedure.
                        <ul className="one-step">
                          <li>1. Search for content, view lists, and select items to purchase</li>
                          <li>2. Check content details</li>
                          <li>
                            3. Check payment amounts, select payment methods, and make payments
                            (refund policy information)
                          </li>
                          <li>4. Re-confirm payment amounts and make payments</li>
                        </ul>
                      </li>
                      <li>
                        ② Members can pay for paid services through the methods specified by the
                        company. However, payments by minor members can be only made under the name
                        or consent of their legal representatives, and the legal representatives may
                        cancel their child's (minor's) purchase agreement made without their
                        consent. Purchase agreements cannot be canceled if a minor signs up as a
                        member by using an adult's resident registration number or uses an adult's
                        payment information without their consent, or otherwise leads the company to
                        believe that the minor is an adult, even when there is no consent from their
                        legal representative.
                      </li>
                      <li>
                        ③ For benefits only given 1 time per person, the payment method and other
                        personal identifying information can be used to identify them as the same
                        person and block the benefits when someone uses two different IDs to claim
                        benefits.
                      </li>
                      <li>
                        ④ In-app payments in the app market shall be subject to follow the terms of
                        use for the respective app market service.
                      </li>
                    </ul>
                  </div>

                  <h4>Article 13 (Refund Policy)</h4>
                  <div>
                    <ul>
                      <li>
                        ① Details regarding the right to withdraw or cancel purchases or return
                        products for refund shall be subject to change according to the terms of use
                        for the respective app market service. Except the cases stipulated in the
                        respective app market's refund policy or provider's refund policy, no
                        purchases can be canceled and there will be no returns, exchanges, or
                        refunds.{' '}
                      </li>
                      <li>
                        ② For in-app payment methods or cancellation/refund issues for app markets,
                        please contact a respective app market's customer service center. However,
                        users cannot request a refund in the event of the following cases.
                        <ul className="one-step">
                          <li>1. If they have already viewed the purchased content</li>
                          <li>
                            2. If the service cannot be used because it hasn't been updated if the
                            member has refused service updates
                          </li>
                          <li>3. If the service cannot be used due to a member's mistakes</li>
                          <li>
                            4. If the paid service is used without direct payment from the member; a
                            paid service given by others as a gift or a bonus service from
                            promotional events
                          </li>
                        </ul>
                      </li>
                      <li>
                        ③ A "paid member" who wishes to maintain "paid service" must act in advance
                        to ensure that there are no unpaid service fees and their payment method is
                        not overdue.
                      </li>
                      <li>
                        ④ When payment for the paid service fee is not made while using the paid
                        service with monthly subscription upon paid service agreement, in violation
                        of the previous article, the subscription may be canceled automatically on
                        the first day the payment is overdue.
                      </li>
                      <li>
                        ⑤ If a "member" using "paid service" cancels their membership agreement, the
                        "paid service" will be immediately canceled. If a refund is requested, the
                        "company" will process a refund after deducting a certain amount obtained by
                        assessing the number of days the service was used, the details of the
                        service used, any amount benefited from the service or product, and any
                        discounted amount. Yet, the "company" will process the information and usage
                        record of the "member" who used the "paid service" in accordance with the
                        terms of use, unless it is stipulated otherwise in relevant laws.
                      </li>
                    </ul>
                  </div>

                  <h4>Article 14 (Changes to the Service)</h4>
                  <div>
                    <ul>
                      <li>
                        ① The company may make changes to all or a portion of the services it offers
                        according to operational or technical needs. (including paid services)
                      </li>
                      <li>
                        ② If there are changes to the service content, usage method, or usage time,
                        the company will post reasons for the change, details of the changed
                        service, date of offer, and more, in advance on the initial screen for the
                        relevant service.
                      </li>
                      <li>
                        ③ The company may revise, suspend, or change all or a portion of the
                        services it offers for free according to the company's policy or operational
                        needs, and it will not compensate members for the changes unless stipulated
                        otherwise in relevant laws.
                      </li>
                    </ul>
                  </div>

                  <h4>Article 15 (Provision of Information and Posting Advertisements)</h4>
                  <div>
                    <ul>
                      <li>
                        ① The company may provide various information deemed as necessary for
                        service use to its members by posting an announcement, sending an email,
                        etc. However, members may refuse to receive emails at any time, except
                        transaction-related information in accordance with relevant laws and
                        responses to customer inquiries.
                      </li>
                      <li>
                        ② To send the information specified in Paragraph 1 via telephone or fax, the
                        company will get members' prior consent for transmission. However,
                        information related to the member's transactions or responses to customer
                        inquiries will be exempt from this consent.
                      </li>
                      <li>
                        ③ The company may post advertisements on the service screen, website, email,
                        etc. for its service operation. Members may refuse to receive emails
                        containing advertisements from the company.
                        <br />
                        Users (including members and non-members) may not take any actions to
                        change, revise, or restrict service-related postings or other information
                        created by the company.
                      </li>
                    </ul>
                  </div>

                  <h4>Article 16 (Content Copyrights)</h4>
                  <div>
                    <ul>
                      <li>
                        ① The company owns the copyrights and intellectual property rights to the
                        content offered through the service.
                      </li>
                      <li>
                        ② Users may not use information obtained while using the service that is
                        under the company's intellectual property rights for commercial or
                        non-commercial purposes or use the information of a third party by copying,
                        selling, publishing, transmitting, distributing, broadcasting, or other
                        methods without the company's prior consent.
                      </li>
                    </ul>
                  </div>

                  <h4>Article 17 (Copyright Management)</h4>
                  <div>
                    <ul>
                      <li>
                        ① When an affiliate's content uploaded through business negotiation with the
                        company contains information which violates the Information and
                        Communications Network Act, the Copyright Act, or other relevant laws, the
                        copyright owner may request the posted content be suspended or deleted
                        according to the procedures in relevant laws. The company may delete the
                        content at its discretion in accordance with relevant laws, and the posting
                        temporarily deleted can be posted only if the legal issues regarding this
                        post are resolved and a posting request is made to the company.
                      </li>
                      <li>
                        ② Even without a request by the copyright owner, as in the previous
                        paragraph, the company may take any temporary actions on the content in
                        question according to relevant laws when there is a reason to believe that
                        there is a breach of rights or when it is against the company's policy or
                        relevant laws.
                      </li>
                    </ul>
                  </div>

                  <h4>Article 18 (Ownership of Rights)</h4>
                  <div>
                    <ul>
                      <li>
                        ① The company shall own the copyrights and intellectual property rights
                        regarding the service.
                      </li>
                      <li>
                        ② The company shall only grant its users the right to use their account, ID,
                        content, etc. according to the terms and conditions for the service
                        established by the company, and members may not transfer, sell, or offer
                        their right to use the service as collateral or partake in any other acts of
                        disposition.
                      </li>
                    </ul>
                  </div>

                  <h4>Article 19 (Agreement Cancellation and Termination)</h4>
                  <div>
                    <ul>
                      <li>
                        ① Members may request to terminate the user agreement through the menu in
                        the service at any time. The company must immediately process their request
                        in accordance with relevant laws.
                      </li>
                      <li>
                        ② If a member terminates the agreement, all of the member's data shall
                        immediately be deleted, except in cases where the company retains member
                        information in accordance with relevant laws and the privacy policy.
                      </li>
                    </ul>
                  </div>

                  <h4>Article 20 (Usage Restrictions, Etc.)</h4>
                  <div>
                    <ul>
                      <li>
                        ① If a member violates their obligations in the terms of use or disrupts
                        normal operation of service, the company may restrict the user from using
                        the service in several phases, starting from a warning or temporary
                        suspension and escalating to a permanent ban or more.
                      </li>
                      <li>
                        ② Despite the previous paragraph, the company may immediately impose a
                        permanent ban if a member violates relevant laws, such as using another
                        person's name or using another person's payment method, which violates the
                        Resident Registration Act; offering illegal programs, which violates the
                        Copyright Act and the Computer Programs Protection Act; disrupting normal
                        operations; conducting illegal communication, which violates the Information
                        and Communications Network Act; hacking and distributing malicious programs;
                        overriding their access rights; etc. If a permanent ban is imposed in
                        accordance with this paragraph, all benefits obtained from the service shall
                        be terminated and "the company" shall not provide any compensation for this
                        forfeiture.
                      </li>
                      <li>
                        ③ If a member does not log in for 3 months or longer, the company may
                        restrict the use of the service in order to protect member information and
                        to increase operational efficiency.
                        <br />
                        The company shall notify its members in accordance with Article 8
                        [Notifications to the Member] when use of service is restricted or if their
                        user agreement has been terminated in accordance with this paragraph.
                      </li>
                      <li>
                        ④ A member may file an objection to the service restriction based on this
                        paragraph according to the procedures established by the company. If the
                        company finds that the member's objection is legitimate, the company will
                        immediately allow the member to use the service.
                      </li>
                    </ul>
                  </div>

                  <h4>Article 21 (Limit on Liabilities)</h4>
                  <div>
                    <ul>
                      <li>
                        ① If the company is unable to offer its service due to a natural disaster or
                        equivalent force majeure, it will be exempt from its obligations to provide
                        regular services.
                      </li>
                      <li>
                        ② The company shall not be held responsible when a member cannot use the
                        service properly due to that member's fault.
                        <br />
                        The company shall not be held responsible for the accuracy and reliability
                        of the information, materials, or content posted to offer its service to
                        members.
                      </li>
                      <li>
                        ③ The company shall be exempt from responsibility when a transaction among
                        members or among members and third parties occurs within the service.
                      </li>
                      <li>
                        ④ The company shall not be held responsible for the use of services offered
                        for free unless otherwise stipulated in relevant laws.
                      </li>
                    </ul>
                  </div>

                  <h4>Article 22 (Governing Laws and Court of Jurisdiction)</h4>
                  <div>
                    <ul>
                      <li>
                        ① The laws of the Republic of Korea shall be the governing law for lawsuits
                        filed between the company and members.
                      </li>
                      <li>
                        ② The Seoul Central District Court shall be the court of jurisdiction over
                        lawsuits regarding disputes between the company and members.
                      </li>
                      <li>
                        ③ If a member resides overseas or their address is overseas, the Seoul
                        Central District Court of the Republic of Korea shall be the court of
                        jurisdiction over lawsuits regarding disputes between the company and the
                        member.
                      </li>
                    </ul>
                  </div>

                  <h4>[Supplementary Provisions]</h4>
                  <h5>Article 1 (Date of Effect)</h5>
                  <p>
                    These terms of use shall be in effect starting from 16/06/2021 and shall replace
                    the previous terms of use.
                  </p>
                </div>
              </div>
            </article>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default Terms;
