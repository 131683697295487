import React, { useEffect, useState } from 'react';

import Lottie from 'react-lottie-player';
import lottieFullBlue from '../../../assets/full_blue.json';
import lottieFullGreen from '../../../assets/full_green.json';
import lottieFullPink from '../../../assets/full_pink.json';
import lottieHalfBlue from '../../../assets/half_blue.json';
import lottieHalfGreen from '../../../assets/half_green.json';
import lottieHalfPink from '../../../assets/half_pink.json';

const SpeakIconStart100 = ({ score, size }) => {
  const [star1Type, setStar1Type] = useState('');
  const [star2Type, setStar2Type] = useState('');
  const [star3Type, setStar3Type] = useState('');

  useEffect(() => {
    if (parseInt(score) >= 1 && parseInt(score) <= 10) setStar1Type('half');
    if (parseInt(score) >= 11) setStar1Type('full');
    if (parseInt(score) >= 21 && parseInt(score) <= 40) setStar2Type('half');
    if (parseInt(score) >= 41) setStar2Type('full');
    if (parseInt(score) >= 61 && parseInt(score) <= 80) setStar3Type('half');
    if (parseInt(score) >= 81) setStar3Type('full');
  }, []);

  return (
    <>
      <div style={{ lineHeight: 0 }}>
        {star1Type === '' && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={parseInt(size) + 1}
            viewBox="0 0 71 72"
          >
            <g id="normal_star" transform="translate(-960 -2345)">
              <rect
                width={size}
                height={parseInt(size) + 1}
                transform="translate(960 2345)"
                fill="rgba(255,255,255,0)"
              />
              <path
                d="M-1353.128,579.714l-11.343-6.808-11.343,6.808a2,2,0,0,1-2.978-2.164l2.962-12.856-9.993-8.7a2,2,0,0,1,1.138-3.5l13.685-1.2,4.66-10.963a2,2,0,0,1,3.681,0l4.673,11,13.309,1.17a2,2,0,0,1,1.138,3.5l-9.642,8.39,3.032,13.162a2.005,2.005,0,0,1-1.951,2.455A1.983,1.983,0,0,1-1353.128,579.714Z"
                transform="translate(2360.181 1821.44)"
                fill="#b1b1b1"
              />
            </g>
          </svg>
        )}
        {star1Type === 'half' && (
          <Lottie
            animationData={lottieHalfBlue}
            className="face"
            speed={1}
            play
            loop={false}
            background="transparent"
            style={{ width: size + 'px', height: parseInt(size) + 1 + 'px' }}
          />
        )}
        {star1Type === 'full' && (
          <Lottie
            animationData={lottieFullBlue}
            className="face"
            speed={1}
            play
            loop={false}
            background="transparent"
            style={{ width: size + 'px', height: parseInt(size) + 1 + 'px' }}
          />
        )}
      </div>

      <div style={{ lineHeight: 0 }}>
        {star2Type === '' && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={parseInt(size) + 1}
            viewBox="0 0 71 72"
          >
            <g id="normal_star" transform="translate(-960 -2345)">
              <rect
                width={size}
                height={parseInt(size) + 1}
                transform="translate(960 2345)"
                fill="rgba(255,255,255,0)"
              />
              <path
                d="M-1353.128,579.714l-11.343-6.808-11.343,6.808a2,2,0,0,1-2.978-2.164l2.962-12.856-9.993-8.7a2,2,0,0,1,1.138-3.5l13.685-1.2,4.66-10.963a2,2,0,0,1,3.681,0l4.673,11,13.309,1.17a2,2,0,0,1,1.138,3.5l-9.642,8.39,3.032,13.162a2.005,2.005,0,0,1-1.951,2.455A1.983,1.983,0,0,1-1353.128,579.714Z"
                transform="translate(2360.181 1821.44)"
                fill="#b1b1b1"
              />
            </g>
          </svg>
        )}
        {star2Type === 'half' && (
          <Lottie
            animationData={lottieHalfGreen}
            className="face"
            speed={1}
            play
            loop={false}
            background="transparent"
            style={{ width: size + 'px', height: parseInt(size) + 1 + 'px' }}
          />
        )}
        {star2Type === 'full' && (
          <Lottie
            animationData={lottieFullGreen}
            className="face"
            speed={1}
            play
            loop={false}
            background="transparent"
            style={{ width: size + 'px', height: parseInt(size) + 1 + 'px' }}
          />
        )}
      </div>

      <div style={{ lineHeight: 0 }}>
        {star3Type === '' && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={parseInt(size) + 1}
            viewBox="0 0 71 72"
          >
            <g id="normal_star" transform="translate(-960 -2345)">
              <rect
                width={size}
                height={parseInt(size) + 1}
                transform="translate(960 2345)"
                fill="rgba(255,255,255,0)"
              />
              <path
                d="M-1353.128,579.714l-11.343-6.808-11.343,6.808a2,2,0,0,1-2.978-2.164l2.962-12.856-9.993-8.7a2,2,0,0,1,1.138-3.5l13.685-1.2,4.66-10.963a2,2,0,0,1,3.681,0l4.673,11,13.309,1.17a2,2,0,0,1,1.138,3.5l-9.642,8.39,3.032,13.162a2.005,2.005,0,0,1-1.951,2.455A1.983,1.983,0,0,1-1353.128,579.714Z"
                transform="translate(2360.181 1821.44)"
                fill="#b1b1b1"
              />
            </g>
          </svg>
        )}
        {star3Type === 'half' && (
          <Lottie
            animationData={lottieHalfPink}
            className="face"
            speed={1}
            play
            loop={false}
            background="transparent"
            style={{ width: size + 'px', height: parseInt(size) + 1 + 'px' }}
          />
        )}
        {star3Type === 'full' && (
          <Lottie
            animationData={lottieFullPink}
            className="face"
            speed={1}
            play
            loop={false}
            background="transparent"
            style={{ width: size + 'px', height: parseInt(size) + 1 + 'px' }}
          />
        )}
      </div>
    </>
  );
};

export default SpeakIconStart100;
