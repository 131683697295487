import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useUserState, useUserDispatch } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import * as Api from '../../library/Api';
import cn from 'classnames';
import moment from 'moment';

import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import MypageLeftNav from './layout/MypageLeftNav';

const MypagePurchase = ({ isLogin, user, t }) => {
  const navigate = useNavigate();

  const [listType, setListType] = useState(1);
  const [paymentList, setPaymentList] = useState([]);

  useEffect(() => {
    if (user) {
      initLoad();
    }
  }, [user]);

  const initLoad = () => {
    (async () => {
      try {
        const mypagePaymentListResponse = await Api.mypagePaymentList(user.esntlId);

        if (mypagePaymentListResponse.data.result.data) {
          setPaymentList(
            mypagePaymentListResponse.data.result.data.map((item, index) => {
              return {
                ...item,
              };
            }),
          );
        }
      } catch (error) {}
    })();
  };

  return (
    <>
      <div
        className={cn('wrap', {
          wrapKo: t('languageCode') === 'ko',
          wrapEn: t('languageCode') === 'en',
          wrapId: t('languageCode') === 'id',
          wrapVi: t('languageCode') === 'vi',
        })}
      >
        <Header />

        <section className="mypage-wrap">
          <h2 className="blind">{t('v1.Mypage.Title')}</h2>
          <div className="inner">
            <MypageLeftNav isLogin={isLogin} user={user} t={t} />
            <article className="mypage-content">
              <h3 className="mypage-title">{t('v1.MypagePurchase.Title')}</h3>
              <div className="sorting">
                <Link to="#" className="active">
                  {t('v1.MypagePurchase.TabMenu1', { n: paymentList.length })}
                </Link>
              </div>
              <div className="list">
                {paymentList.length === 0 && (
                  <div className="none">
                    <p className="purchase">{t('v1.MypagePurchase.NotList')}</p>
                  </div>
                )}
                {paymentList.map((item, index) => {
                  return (
                    <>
                      <div className="item purchase-item">
                        <div
                          className={cn('course', {
                            topik1: item.questionLevel === 1,
                            topik2: item.questionLevel === 2,
                          })}
                        >
                          {item.questionLevel === 1 && 'TOPIK l'}
                          {item.questionLevel === 2 && 'TOPIK ll'}
                        </div>
                        <div className="detail">
                          <div className="date">
                            {t('v1.MypagePurchase.List_LabelDate')} :{' '}
                            {moment(item.creatDt).format('YYYY. MM. DD.')}
                          </div>
                          <strong className="sbj">{t('v1.MypagePurchase.List_Subject')}</strong>
                          <div className="txt">
                            {t('languageCode') === 'ko' && item.paymentDetail}
                            {t('languageCode') === 'en' && item.paymentDetailEn}
                            {t('languageCode') === 'id' && item.paymentDetailIn}
                            {t('languageCode') === 'vi' && item.paymentDetailViet}
                          </div>
                        </div>
                        {item.useYn !== 'Y' && (
                          <span
                            className="status able"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate('/topik/view/' + item.paymentId);
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            {t('v1.MypagePurchase.List_LabelStatus1')}
                          </span>
                        )}
                        {item.useYn === 'Y' && (
                          <span
                            className="status disable"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate('/topik/view/' + item.paymentId);
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            {t('v1.MypagePurchase.List_LabelStatus2')}
                          </span>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </article>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default MypagePurchase;
