import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import cn from 'classnames';

import * as Api from '../../library/Api';

import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import TopikView from './TopikView';
import TopikPayInfo from './layout/TopikPayInfo';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import TopikConfirm from './layout/TopikConfirm';

const TopikMain = ({ isLogin, user, t }) => {
  const navigate = useNavigate();

  const [isVisualCircle1Class, setIsVisualCircle1Class] = useState(false);
  const [isVisualCircle2Class, setIsVisualCircle2Class] = useState(false);
  const [isVisualCircle3Class, setIsVisualCircle3Class] = useState(false);
  const [isVisualCircle4Class, setIsVisualCircle4Class] = useState(false);
  const [isVisualCircle5Class, setIsVisualCircle5Class] = useState(false);
  const [isVisualCircle6Class, setIsVisualCircle6Class] = useState(false);

  const [listType, setListType] = useState('');
  const [listFree, setListFree] = useState(false);

  const [totalData, setTotalData] = useState({
    level: 0,
    lcScore: 0,
    rcScore: 0,
  });
  const [topikList, setTopikList] = useState([]);
  const [topikFinishList, setTopikFinishList] = useState([]);
  const [openTopikData, setOpenTopikData] = useState({
    isOpen: false,
    data: {},
  });
  const [isTopikPayInfo, setIsTopikPayInfo] = useState(false);

  useEffect(() => {
    if (isLogin === 0) {
      //alert('로그인 후 이용가능합니다.');
      navigate('/member/login');
    }
  }, [isLogin]);

  useEffect(() => {
    if (isLogin === 1) {
      setTimeout(() => {
        setIsVisualCircle1Class(true);
      }, 150);
      setTimeout(() => {
        setIsVisualCircle2Class(true);
      }, 300);
      setTimeout(() => {
        setIsVisualCircle3Class(true);
      }, 450);
      setTimeout(() => {
        setIsVisualCircle4Class(true);
      }, 600);
      setTimeout(() => {
        setIsVisualCircle5Class(true);
      }, 750);
      setTimeout(() => {
        setIsVisualCircle6Class(true);
      }, 900);

      (async () => {
        try {
          const topikListResponse = await Api.topikList({ esntlId: user.esntlId });
          console.log('topikListResponse', topikListResponse);
          if (topikListResponse.data.result.data.reward)
            setTopikList(topikListResponse.data.result.data.reward);

          //const topikListResponse2 = await Api.topikList({ esntlId: user.esntlId, type: 'pay' });
          //console.log('topikListResponse2', topikListResponse2);
          //if (topikListResponse.data.result.data.reward)
          //setTopikList(topikListResponse.data.result.data.reward);

          _loadTotalData();

          const topikRecordListResponse = await Api.topikRecordList({ esntlId: user.esntlId });
          console.log('topikRecordListResponse', topikRecordListResponse);
          if (topikRecordListResponse.data.result.data.complete)
            setTopikFinishList(topikRecordListResponse.data.result.data.complete);
        } catch (error) {}
      })();
    }

    return () => {};
  }, [isLogin]);

  const _loadTotalData = () => {
    if (isLogin === 1) {
      (async () => {
        try {
          const topikMyRecordResponse = await Api.topikMyRecord({ esntlId: user.esntlId });
          if (topikMyRecordResponse.data.result.data) {
            setTotalData({
              ...totalData,
              level: topikMyRecordResponse.data.result.data.level,
              lcScore: Math.floor(topikMyRecordResponse.data.result.data.record[0].lcScore * 2),
              rcScore: Math.floor(topikMyRecordResponse.data.result.data.record[0].rcScore * 2),
            });
          }
        } catch (error) {}
      })();
    }
  };

  const modalTopikViewOpen = (topikItem) => {
    navigate('/topik/view/' + topikItem.rwdKind);
    /*
    // 페이지 이동으로 변경
    setOpenTopikData({
      isOpen: true,
      data: topikItem,
    });
    document.body.style.overflow = 'hidden';
    */
  };

  const modalTopikViewClose = () => {
    setOpenTopikData({
      isOpen: false,
      data: {},
    });
    document.body.style.overflow = 'unset';

    _loadTotalData();
  };

  const modalTopikPayInfoOpen = () => {
    setIsTopikPayInfo(true);
    document.body.style.overflow = 'hidden';
  };

  const modalTopikPayInfoClose = () => {
    setIsTopikPayInfo(false);
    document.body.style.overflow = 'unset';
  };

  return (
    <>
      {isLogin === 1 && (
        <>
          {openTopikData.isOpen && (
            <div
              className={cn('wrap', {
                wrapKo: t('languageCode') === 'ko',
                wrapEn: t('languageCode') === 'en',
                wrapId: t('languageCode') === 'id',
                wrapVi: t('languageCode') === 'vi',
              })}
            >
              <TopikView
                isLogin={isLogin}
                user={user}
                t={t}
                onClose={modalTopikViewClose}
                topikData={openTopikData.data}
              />
            </div>
          )}
          {!openTopikData.isOpen && (
            <div
              className={cn('wrap', {
                wrapKo: t('languageCode') === 'ko',
                wrapEn: t('languageCode') === 'en',
                wrapId: t('languageCode') === 'id',
                wrapVi: t('languageCode') === 'vi',
              })}
            >
              <Header />
              <section className="sub-contents">
                <article className="visual topik-visual">
                  <div className="inner">
                    <div className="txt-area">
                      <h2>
                        {/* <span>모의고사</span> TOPIK */}
                        {t('v1.Topik.Title')}
                      </h2>
                    </div>
                    <div className="status">
                      <dl className="latest">
                        <dt>{t('TOPIK.Total_score_grade')}</dt>
                        <dd>
                          {t('languageCode') === 'ko' && (
                            <Trans i18nKey="v1.Common.UnitLevel2" values={{ n: totalData.level }}>
                              <span />
                            </Trans>
                          )}
                          {t('languageCode') !== 'ko' && <>{totalData.level}</>}
                        </dd>
                      </dl>
                      <dl className="listen">
                        <dt>{t('TOPIK.Total_score_listening')}</dt>
                        <dd>
                          {t('languageCode') === 'ko' && (
                            <Trans i18nKey="v1.Common.UnitPoint2" values={{ n: totalData.lcScore }}>
                              <span />
                            </Trans>
                          )}
                          {t('languageCode') !== 'ko' && <>{totalData.lcScore}</>}
                        </dd>
                      </dl>
                      <dl className="read">
                        <dt>{t('TOPIK.Total_score_read')}</dt>
                        <dd>
                          {t('languageCode') === 'ko' && (
                            <Trans i18nKey="v1.Common.UnitPoint2" values={{ n: totalData.rcScore }}>
                              <span />
                            </Trans>
                          )}
                          {t('languageCode') !== 'ko' && <>{totalData.rcScore}</>}
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <div className="obj">
                    <div className={cn('circle01', { move: isVisualCircle1Class })}></div>
                    <div className={cn('circle02', { move: isVisualCircle2Class })}></div>
                    <div className={cn('circle03', { move: isVisualCircle3Class })}></div>
                    <div className={cn('circle04', { move: isVisualCircle4Class })}></div>
                    <div className={cn('circle05', { move: isVisualCircle5Class })}></div>
                    <div className={cn('circle06', { move: isVisualCircle6Class })}></div>
                  </div>
                </article>
                <div className="sub-inner">
                  <article className="topik-wrap">
                    <div className="title-tab">
                      <h3 className="page-title active">{t('TOPIK.Tab_TOPIKTest')}</h3>
                      <h3
                        className="page-title"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate('/topik/history');
                        }}
                      >
                        {t('TOPIK.Tab_Test Record')}
                      </h3>
                    </div>
                    <div className="tab-area">
                      <ul className="course-type">
                        <li>
                          <Link
                            to="#"
                            className={cn({ active: listType === '' })}
                            onClick={(e) => {
                              e.preventDefault();
                              setListType('');
                            }}
                          >
                            {t('TOPIK.Category_Overall')}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            className={cn({ active: listType === 'TOPIK l' })}
                            onClick={(e) => {
                              e.preventDefault();
                              setListType('TOPIK l');
                            }}
                          >
                            TOPIK l
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            className={cn({ active: listType === 'TOPIK ll' })}
                            onClick={(e) => {
                              e.preventDefault();
                              setListType('TOPIK ll');
                            }}
                          >
                            TOPIK ll
                          </Link>
                        </li>
                      </ul>
                      <Link
                        to="#"
                        className="btn-purchase"
                        onClick={(e) => {
                          e.preventDefault();
                          modalTopikPayInfoOpen();
                        }}
                      >
                        {t('TOPIK.btn_Pay_Product')}
                      </Link>
                    </div>
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        id="view"
                        onChange={(e) => {
                          setListFree(e.target.checked);
                        }}
                        disabled={topikList.length === 0}
                      />
                      <label htmlFor="view">{t('TOPIK.Check_Filtering')}</label>
                    </div>
                    <div className="list">
                      {topikList.map((topikItem, topikKey) => {
                        if (listType === 'TOPIK l' && topikItem.questionLevel !== 1) return '';
                        if (listType === 'TOPIK ll' && topikItem.questionLevel !== 2) return '';

                        if (listFree) {
                          if (topikItem.payYn !== 'F' && topikItem.payYn !== 'Y') {
                            return '';
                          }
                        }

                        return (
                          <div className="item" key={'topikList' + topikItem.rwdKind}>
                            <Link
                              to="#"
                              onClick={(e) => {
                                e.preventDefault();

                                if (topikItem.payYn === 'Y' || topikItem.payYn === 'F') {
                                  if (topikItem.useYn !== 'Y') {
                                    modalTopikViewOpen(topikItem);
                                  } else {
                                    confirmAlert({
                                      customUI: ({ onClose }) => {
                                        return (
                                          <TopikConfirm
                                            t={t}
                                            content={
                                              t('TOPIK.Alert_TOPIK_Re_Title') +
                                              '<br />' +
                                              t('TOPIK.Alert_TOPIK_Re_info')
                                            }
                                            onClose={onClose}
                                            onConfirm={() => {
                                              modalTopikViewOpen(topikItem);
                                            }}
                                          />
                                        );
                                      },
                                    });
                                  }
                                } else {
                                  modalTopikPayInfoOpen();
                                }
                              }}
                            >
                              <div className="tag">
                                <span
                                  className={cn({
                                    course01: topikItem.questionLevel === 1,
                                    course02: topikItem.questionLevel === 2,
                                  })}
                                >
                                  {topikItem.questionLevel === 1 && 'TOPIK l'}
                                  {topikItem.questionLevel === 2 && 'TOPIK ll'}
                                </span>
                                <span
                                  className={cn({
                                    free: topikItem.payAt === 'N',
                                    payment: topikItem.payAt === 'Y',
                                  })}
                                >
                                  {topikItem.payAt === 'N' &&
                                    t('Learning_home.Learning_Topik_Badge1')}
                                  {topikItem.payAt === 'Y' &&
                                    t('Learning_home.Learning_Topik_Badge2')}
                                </span>

                                {topikItem.viewYn === 'N' && <span className="new">N</span>}
                                {topikFinishList.map((item) => {
                                  if (topikItem.rwdKind === item.rwdKind && item.finishYn === 'Y')
                                    return <span className="complete">완료</span>;
                                })}
                              </div>
                              <div className="info">
                                <strong className="title">
                                  {t('languageCode') === 'ko' && topikItem.rwdDesc}
                                  {t('languageCode') === 'en' && topikItem.rwdDescEn}
                                  {t('languageCode') === 'id' && topikItem.rwdDescIn}
                                  {t('languageCode') === 'vi' && topikItem.rwdDescViet}
                                </strong>
                                <div className="option">
                                  <Trans
                                    i18nKey="Learning_home.Learning_Topik_Time"
                                    values={{ n: topikItem.topikTime }}
                                  />
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </div>

                    {topikList.length === 0 && (
                      <>
                        <div className="clear">
                          <p>
                            <Trans i18nKey="TOPIK.TOPIK_None_Content" />
                          </p>
                        </div>
                      </>
                    )}
                  </article>
                </div>

                {isTopikPayInfo && <TopikPayInfo t={t} onClose={modalTopikPayInfoClose} />}
              </section>
              <Footer />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TopikMain;
