import React from 'react';

const TextPrivacyKo = () => {
  return (
    <>
      <b>제 2조 개인정보의 처리 항목 및 보유기간</b>
      ① 원칙적으로 개인정보는 살아있는 개인에 관한 정보를 말합니다. 다만 법인·단체에 관한 정보이면서
      동시에 개인에 관한 정보인 경우, 개별 상황에 따라 개인정보로 처리될 수도 있기 때문에,
      법인·단체의 정보도 개인정보처리 항목에 포함하여 이해를 돕고자 합니다.
      <br />
      <br />
      ② 회사는 법령에 따른 개인정보 보유기간 또는 이용자로부터 개인정보를 수집 시에 동의받은
      개인정보 보유기간 내에서 개인정보를 처리하고 보유합니다.
      <br />
      <table>
        <colgroup>
          <col style={{ width: '15%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
        </colgroup>
        <thead>
          <tr>
            <th colSpan="2">구분</th>
            <th colSpan="2">처리 항목</th>
            <th>보유 및 이용 기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="2" rowSpan="2">
              회원가입
            </td>
            <td>이메일 회원가입</td>
            <td>[필수] 이메일 주소 (로그인 ID), 비밀번호</td>
            <td rowSpan="2">회원 탈퇴 시까지</td>
          </tr>
          <tr>
            <td>간편 회원가입 (애플, 페이스북, 구글)</td>
            <td>[필수] 간편가입 계정, 이메일 주소</td>
          </tr>
          <tr>
            <td colSpan="2">
              유료 서비스 구매
              <br />
              (인앱(in-app) 결제)
            </td>
            <td colSpan="2">영수증 번호</td>
            <td>법정 의무 보유기간까지</td>
          </tr>
          <tr>
            <td colSpan="2">
              서비스 이용 시 <br />
              수집될 수 있는 정보
            </td>
            <td colSpan="2">
              서비스 이용 및 중지 기록, 접속 로그, 쿠키, 접속 IP 정보, 단말기 정보
            </td>
            <td>법정 의무 보유기간까지</td>
          </tr>
          <tr>
            <td rowSpan="4">회원 상담∙불만 접수, 처리결과 통보</td>
            <td>이메일 접수</td>
            <td colSpan="2">이메일 주소</td>
            <td rowSpan="4">법정 의무 보유기간까지</td>
          </tr>
          <tr>
            <td>팩스 접수</td>
            <td colSpan="2">팩스 번호</td>
          </tr>
          <tr>
            <td>전화 접수</td>
            <td colSpan="2">휴대 전화번호</td>
          </tr>
          <tr>
            <td>우편 접수</td>
            <td colSpan="2">주소</td>
          </tr>
        </tbody>
      </table>
      ③ 관계법령에 의거하여 회사에서 보존하는 개인정보 항목 및 기간은 다음과 같습니다.
      <br />
      <br />
      1. 전자상거래 등에서의 소비자 보호에 관한 법률
      <br />
      가. 계약 또는 청약철회 등에 관한 기록: 5년
      <br />
      나. 대금 결제 및 재화 등의 공급에 관한 기록: 5년
      <br />
      다. 소비자의 불만 또는 분쟁처리에 관한 기록: 3년
      <br />
      <br />
      2. 통신비밀보호법
      <br />
      라. 방문(접속) 기록: 3개월
      <br />
      <br />
      ④ “회사”는 개인정보보호법에 따라 12개월 이상 로그인하지 않은 경우나, 서비스 이용이 없는 경우
      해당 “회원”의 ID 및 개인정보를 휴면 ID로 별도 관리합니다. 이러한 경우 위 12개월의 기간 도래
      30일 전까지 “회사”는 “회원”의 개인정보가 분리되어 저장, 관리되고 있다는 사실과 기간 만료일 및
      해당 개인정보의 항목을 전자우편, 서면, 모사전송, 전화 또는 이와 유사한 방법 중 어느 하나의
      방법으로 “회원”에게 알려드립니다. 단, 서비스를 이용 중인 경우 기간 만료일과 상관없이 서비스
      삭제 시점에 휴면 ID로 전환됩니다. 휴면 ID로 전환된 개인정보는 4년간 보관 후 지체 없이
      파기합니다.
      <br />
      <br />
      <a href="/privacy" target="_blank">
        [개인정보 처리방침 전문 보기]
      </a>
    </>
  );
};

export default TextPrivacyKo;
