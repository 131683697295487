import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';
import dompurify from 'dompurify';

import DatePicker from 'react-datepicker';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import getDay from 'date-fns/getDay';
import startOfWeek from 'date-fns/startOfWeek';
import moment from 'moment';

import * as Api from '../../library/Api';

import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';

import {
  Chart as ChartJS,
  CategoryScale,
  RadialLinearScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Filler,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ReportMain = ({ isLogin, user, t }) => {
  const navigate = useNavigate();

  const [languageCode, setLanguageCode] = useState('');

  const [isVisualCircle1Class, setIsVisualCircle1Class] = useState(false);
  const [isVisualCircle2Class, setIsVisualCircle2Class] = useState(false);
  const [isVisualCircle3Class, setIsVisualCircle3Class] = useState(false);
  const [isVisualCircle4Class, setIsVisualCircle4Class] = useState(false);
  const [isVisualCircle5Class, setIsVisualCircle5Class] = useState(false);
  const [isVisualCircle6Class, setIsVisualCircle6Class] = useState(false);
  const [isVisualCircle7Class, setIsVisualCircle7Class] = useState(false);

  const [aiReview, setAiReview] = useState({
    reviewId: '',
    reviewTextKor: '',
    reviewTextEn: '',
    reviewTextIn: '',
    reviewTextViet: '',
  });

  const [listType, setListType] = useState('week'); // week, month
  const [isDatepicker, setIsDatepicker] = useState(false);
  const [weekStartDate, setWeekStartDate] = useState(startOfWeek(new Date()));
  const [weekEndDate, setWeekEndDate] = useState(addDays(startOfWeek(new Date()), 6));
  const [monthDate, setMonthDate] = useState(new Date());

  const [isShow1, setIsShow1] = useState(false);
  const [isShow2, setIsShow2] = useState(false);
  const [isShow3, setIsShow3] = useState(false);

  const [mynoteData, setMynoteData] = useState({
    courseCnt: 0,
    time: [],
    todayCnt: 0,
    topik: [],

    topikTotalCnt: 0,
    topik1Cnt: 0,
    topik2Cnt: 0,
    timeTotal: 0,
    timeTotalH: 0,
    timeTotalM: 0,
  });

  const [chartOptions, setChartOptions] = useState({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          font: {
            size: 18,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      r: {
        angleLines: {
          //display: false,
        },
        max: 10,
        min: 0,
        ticks: {
          stepSize: 2,
          maxRotation: 0,
          minRotation: 0,
          display: false,
        },
        pointLabels: {
          font: {
            size: 15,
          },
        },
      },
    },
    layout: {
      padding: 0,
    },
  });
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState({
    labels: [
      t('v1.Report.Chart_DataLabel1'),
      t('v1.Report.Chart_DataLabel2'),
      t('v1.Report.Chart_DataLabel3'),
      t('v1.Report.Chart_DataLabel4'),
      t('v1.Report.Chart_DataLabel5'),
    ],
    datasets: [
      {
        label: t('v1.Report.Chart_Label1'),
        data: [],
        backgroundColor: 'rgba(144, 87, 221, 0.2)',
        borderColor: 'rgba(144, 87, 221, 1)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(144, 87, 221, 1)',
        pointBorderWidth: 3,
      },
      {
        label: t('v1.Report.Chart_Label2'),
        data: [],
        backgroundColor: 'rgba(170, 170, 170, 0.5)',
        borderColor: 'rgba(170, 170, 170, 1)',
        borderWidth: 1,
        pointBackgroundColor: 'rgba(170, 170, 170, 1)',
        pointBorderColor: 'rgba(170, 170, 170, 1)',
        pointBorderWidth: 3,
        hoverBackgroundColor: 'rgba(170, 170, 170, 1)',
        hoverBorderWidth: 0,
      },
    ],
  });

  const [chartTimeDefaultTooltipIndex, setChartTimeDefaultTooltipIndex] = useState(-1);
  const chartTimeRef = useRef(null);
  const [chartTimeOptions, setChartTimeOptions] = useState({
    responsive: true,
    maintainAspectRatio: false,
    events: ['click'],
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      showAllTooltips: true,
      tooltip: {
        enabled: true,
        //position: 'average',
        //mode: 'nearest',
        filter: function (tooltipItem) {
          console.log('tooltipItem.datasetIndex', tooltipItem);
          if (tooltipItem.datasetIndex === 1) {
            /*
            chartTimeRef.current.tooltip.setActiveElements([
              {
                datasetIndex: 0,
                index: tooltipItem.dataIndex,
              },
            ]);

            chartTimeRef.current.update();
            */
          }
          return tooltipItem.datasetIndex === 0 ? true : false;
        },
        callbacks: {
          label: (tooltipItem) => {
            let tmpH = 0;
            let tmpM = 0;
            if (tooltipItem.parsed.y <= 60) {
              tmpM = Math.floor(tooltipItem.parsed.y);
            } else {
              tmpH = Math.floor(tooltipItem.parsed.y / 60);
              tmpM = Math.floor(tooltipItem.parsed.y % 60);
            }

            if (tooltipItem.datasetIndex === 1) return false;
            else {
              if (tmpH > 0) {
                return t('v1.Report.ListChart_TooltipH', { h: tmpH, m: tmpM });
              } else {
                return t('v1.Report.ListChart_TooltipM', { m: tmpM });
              }
            }
          },
        },
      },
    },
    scales: {
      y: {
        //max: 6,
        stacked: false,
        //min: 0,
        ticks: {
          stepSize: 10,
          callback: function (value, index, ticks) {
            return value + ' m';
          },
        },
      },
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
    layout: {
      padding: 20,
    },
  });
  const [chartTimeData, setChartTimeData] = useState({
    labels: [
      t('v1.Report.ListChart_Week01'),
      t('v1.Report.ListChart_Week02'),
      t('v1.Report.ListChart_Week03'),
      t('v1.Report.ListChart_Week04'),
      t('v1.Report.ListChart_Week05'),
      t('v1.Report.ListChart_Week06'),
      t('v1.Report.ListChart_Week07'),
    ],
    datasets: [
      {
        data: [],
        backgroundColor: 'rgba(144, 87, 221, 1)',
        borderColor: 'rgba(144, 87, 221, 1)',
      },
    ],
  });

  useEffect(() => {
    // 언어 변경시 차트변경 반영을 위한 변수 반영
    setLanguageCode(t('languageCode'));

    //loadChart();
    //loadList();

    // Chart Label 변경
    setChartData({
      labels: languageChartLabels(chartLabels),
      datasets: [
        {
          ...chartData.datasets[0],
          label: t('v1.Report.Chart_Label1'),
        },
        {
          ...chartData.datasets[1],
          label: t('v1.Report.Chart_Label2'),
        },
      ],
    });

    if (listType === 'week') {
      setChartTimeData({
        ...chartTimeData,
        labels: [
          t('v1.Report.ListChart_Week01'),
          t('v1.Report.ListChart_Week02'),
          t('v1.Report.ListChart_Week03'),
          t('v1.Report.ListChart_Week04'),
          t('v1.Report.ListChart_Week05'),
          t('v1.Report.ListChart_Week06'),
          t('v1.Report.ListChart_Week07'),
        ],
      });
    } else if (listType === 'month') {
      chartTimeRef.current.tooltip.setActiveElements([], { x: 0, y: 0 });
      //triggerTooltip(chart);
      loadList();
    }
  }, [t]);

  useEffect(() => {
    if (isLogin === 0) {
      //alert('로그인 후 이용가능합니다.');
      navigate('/member/login');
    }
  }, [isLogin]);

  useEffect(() => {
    if (isLogin === 1) {
      setTimeout(() => {
        setIsVisualCircle1Class(true);
      }, 150);
      setTimeout(() => {
        setIsVisualCircle2Class(true);
      }, 300);
      setTimeout(() => {
        setIsVisualCircle3Class(true);
      }, 450);
      setTimeout(() => {
        setIsVisualCircle4Class(true);
      }, 600);
      setTimeout(() => {
        setIsVisualCircle5Class(true);
      }, 750);
      setTimeout(() => {
        setIsVisualCircle6Class(true);
      }, 900);
      setTimeout(() => {
        setIsVisualCircle7Class(true);
      }, 1050);

      let tmpWeekOfStart = addDays(startOfWeek(new Date()), 1);
      let tmpWeekOfEnd = addDays(tmpWeekOfStart, 6);
      setWeekStartDate(tmpWeekOfStart);
      setWeekEndDate(tmpWeekOfEnd);

      loadChart();
    }

    return () => {};
  }, [isLogin]);

  useEffect(() => {
    if (isLogin === 1) {
      setIsShow1(false);
      setIsShow2(false);
      setIsShow3(false);

      loadList();
    }
  }, [listType, weekStartDate, monthDate]);

  // 언어에 따른 Label 가공
  const languageChartLabels = (oriLabels) => {
    console.log('oriLabels', oriLabels);
    if (oriLabels.length === 0) {
      let labels = [
        [t('v1.Report.Chart_DataLabel1')],
        [t('v1.Report.Chart_DataLabel2')],
        [t('v1.Report.Chart_DataLabel3')],
        [t('v1.Report.Chart_DataLabel4')],
        [t('v1.Report.Chart_DataLabel5')],
      ];
      return labels;
    } else {
      let labels = [
        [t('v1.Report.Chart_DataLabel1'), '  ' + oriLabels[0] + '%'],
        [t('v1.Report.Chart_DataLabel2'), '       ' + oriLabels[1] + '%'],
        [t('v1.Report.Chart_DataLabel3'), '       ' + oriLabels[2] + '%'],
        [t('v1.Report.Chart_DataLabel4'), oriLabels[3] + '%     '],
        [t('v1.Report.Chart_DataLabel5'), oriLabels[4] + '%     '],
      ];
      return labels;
    }
  };

  const loadChart = () => {
    (async () => {
      try {
        const mynoteReviewResponse = await Api.mynoteReview({
          esntlId: user.esntlId,
        });

        if (mynoteReviewResponse.data.result.data) {
          setAiReview(mynoteReviewResponse.data.result.data);
        }

        const mynoteReportResponse = await Api.mynoteReport({
          esntlId: user.esntlId,
        });
        if (mynoteReportResponse.data.result.data) {
          let oriLabels = [];
          let labels = [];
          let myReport = {};
          let otherReport = {};
          if (mynoteReportResponse.data.result.data.my) {
            // '단어 정확도', '문장 유창성', 'TOPIK 읽기', 'TOPIK 듣기', '학습 진도율'
            myReport = {
              ...chartData.datasets[0],
              data: [
                mynoteReportResponse.data.result.data.my.report.pronScore / 10,
                mynoteReportResponse.data.result.data.my.report.regressionScore * 2,
                (mynoteReportResponse.data.result.data.my.report.rcScore / 10) * 2,
                (mynoteReportResponse.data.result.data.my.report.lcScore / 10) * 2,
                mynoteReportResponse.data.result.data.my.progress / 10,
              ],
            };

            oriLabels = [
              [parseInt(myReport.data[0] * 10)],
              [parseInt(myReport.data[1] * 10)],
              [parseInt(myReport.data[2] * 10)],
              [parseInt(myReport.data[3] * 10)],
              [parseInt(myReport.data[4] * 10)],
            ];
          }

          if (mynoteReportResponse.data.result.data.other) {
            otherReport = {
              ...chartData.datasets[1],
              data: [
                mynoteReportResponse.data.result.data.other.report.pronScore / 10,
                mynoteReportResponse.data.result.data.other.report.regressionScore * 2,
                (mynoteReportResponse.data.result.data.other.report.rcScore / 10) * 2,
                (mynoteReportResponse.data.result.data.other.report.lcScore / 10) * 2,
                mynoteReportResponse.data.result.data.other.progress / 10,
              ],
            };
          }

          setChartLabels(oriLabels);
          setChartData({
            labels: languageChartLabels(oriLabels),
            datasets: [{ ...myReport }, { ...otherReport }],
          });
        }
      } catch (error) {}
    })();
  };

  const loadList = () => {
    setChartTimeDefaultTooltipIndex(-1);
    (async () => {
      try {
        let mynoteCntOptions = {};
        if (listType === 'week') {
          mynoteCntOptions = {
            esntlId: user.esntlId,
            //esntlId: 'USRCNFRM_00000000539',
            searchKeyword: 'days',
            startDt: moment(weekStartDate).format('YYYY-MM-DD'),
            endDt: moment(weekEndDate).format('YYYY-MM-DD'),
          };
        } else if (listType === 'month') {
          mynoteCntOptions = {
            esntlId: user.esntlId,
            //esntlId: 'USRCNFRM_00000000539',
            searchKeyword: 'month',
            startDt: moment(monthDate).format('YYYY-MM'),
          };
        }

        const mynoteCntResponse = await Api.mynoteCnt(mynoteCntOptions);
        if (mynoteCntResponse.data.result.data) {
          let tmpData = mynoteCntResponse.data.result.data;
          tmpData.topikTotalCnt = 0;
          tmpData.topik1Cnt = 0;
          tmpData.topik2Cnt = 0;
          tmpData.timeTotal = 0;

          for (let i = 0; i < tmpData.topik.length; i++) {
            if (tmpData.topik[i].questionLevel === 1) {
              tmpData.topik1Cnt += tmpData.topik[i].cnt;
            }
            if (tmpData.topik[i].questionLevel === 2) {
              tmpData.topik2Cnt += tmpData.topik[i].cnt;
            }
            tmpData.topikTotalCnt += tmpData.topik[i].cnt;
          }

          for (let i = 0; i < tmpData.time.length; i++) {
            tmpData.timeTotal += tmpData.time[i].studyTime;
          }

          tmpData.timeTotal = parseInt(tmpData.timeTotal / 60);

          tmpData.timeTotalH = 0;
          tmpData.timeTotalM = 0;
          if (tmpData.timeTotal <= 60) {
            tmpData.timeTotalM = Math.floor(tmpData.timeTotal);
          } else {
            tmpData.timeTotalH = Math.floor(tmpData.timeTotal / 60);
            tmpData.timeTotalM = Math.floor(tmpData.timeTotal % 60);
          }

          setMynoteData(tmpData);

          if (listType === 'week') {
            let tmpWeekData = [0, 0, 0, 0, 0, 0, 0];
            let tmpMaxTime = 60;
            for (let i = 0; i < tmpData.time.length; i++) {
              let tmpDay = getDay(new Date(tmpData.time[i].days));
              tmpDay = tmpDay === 0 ? 6 : tmpDay - 1;

              tmpWeekData[tmpDay] = tmpData.time[i].studyTime / 60;

              tmpMaxTime = tmpMaxTime < tmpWeekData[tmpDay] ? tmpWeekData[tmpDay] : tmpMaxTime;
            }

            let tmpWeekData2 = [];
            for (let i = 0; i < tmpWeekData.length; i++) {
              //tmpMonthData2[i] = tmpMaxTime - tmpWeekData[i];
              tmpWeekData2[i] = tmpMaxTime;
            }

            let tmpWeekBgColor = [
              'rgba(144, 87, 221, 1)',
              'rgba(144, 87, 221, 1)',
              'rgba(144, 87, 221, 1)',
              'rgba(144, 87, 221, 1)',
              'rgba(144, 87, 221, 1)',
              'rgba(144, 87, 221, 1)',
              'rgba(144, 87, 221, 1)',
            ];
            //RGB(144,87,221) #9057dd
            //RGB(124,23,255) #7c17ff
            if (
              moment().isBetween(
                moment(weekStartDate).format('YYYY-MM-DD'),
                moment(weekEndDate).format('YYYY-MM-DD'),
              )
            ) {
              let tmpDay = getDay(new Date());
              tmpDay = tmpDay === 0 ? 6 : tmpDay - 1;
              tmpWeekBgColor[tmpDay] = 'rgba(255, 61, 98, 1)';
              setChartTimeDefaultTooltipIndex(tmpDay);
            }

            let maxY = 60;
            if (tmpMaxTime > 60) {
              maxY = Math.ceil(tmpMaxTime / 10);
              if (maxY % 2 === 1) {
                maxY += 1;
              }
              maxY = maxY * 10;
            }

            setChartTimeOptions({
              ...chartTimeOptions,
              scales: {
                ...chartTimeOptions.scales,
                y: {
                  ...chartTimeOptions.scales.y,
                  max: maxY,
                  ticks: {
                    stepSize: 20,
                    callback: function (value, index, ticks) {
                      return value + ' m';
                    },
                  },
                },
              },
            });

            setChartTimeData({
              labels: [
                t('v1.Report.ListChart_Week01'),
                t('v1.Report.ListChart_Week02'),
                t('v1.Report.ListChart_Week03'),
                t('v1.Report.ListChart_Week04'),
                t('v1.Report.ListChart_Week05'),
                t('v1.Report.ListChart_Week06'),
                t('v1.Report.ListChart_Week07'),
              ],
              datasets: [
                {
                  data: tmpWeekData,
                  backgroundColor: tmpWeekBgColor,
                  borderRadius: 10,
                  maxBarThickness: 20,
                  borderWidth: 0,
                  hoverBackgroundColor: tmpWeekBgColor,
                },
                {
                  data: tmpWeekData2,
                  backgroundColor: 'rgba(235, 235, 235, 1)',
                  borderRadius: 10,
                  maxBarThickness: 20,
                  borderWidth: 0,
                  hoverBackgroundColor: 'rgba(235, 235, 235, 1)',
                },
              ],
            });
          } else if (listType === 'month') {
            let tmpMonthData = [];
            let tmpMonthLabels = [];
            let tmpMonthBgColor = [
              'rgba(144, 87, 221, 1)',
              'rgba(144, 87, 221, 1)',
              'rgba(144, 87, 221, 1)',
              'rgba(144, 87, 221, 1)',
              'rgba(144, 87, 221, 1)',
              'rgba(144, 87, 221, 1)',
            ];
            let tmpMaxTime = 20 * 60;
            let tmpCount = 0;

            /*
            if (tmpData.time.length === 0) {
              for (let i = 1; i <= 12; i++) {
                if (('0' + i).substring(-2) === moment(monthDate).format('MM')) {
                  tmpMonthLabels[0] = t('v1.Report.ListChart_Month' + ('0' + i).substring(-2));
                  setChartTimeDefaultTooltipIndex(0);
                }
              }
            }
            */

            let tmpStartMonth = parseInt(moment(monthDate).format('MM')) + 1;
            let tmpStartCheck = false;
            for (let i = tmpData.time.length - 1; i >= 0; i--) {
              if (tmpData.time[i]) {
                if (tmpStartCheck === false) {
                  tmpStartCheck = true;
                  tmpStartMonth = parseInt(tmpData.time[i].months.substr(-2));
                }

                let tmpMonth = tmpData.time[i].months.substr(-2);
                let tmpMonthStr = '';
                if (tmpMonth === '01') tmpMonthStr = t('v1.Report.ListChart_Month01');
                if (tmpMonth === '02') tmpMonthStr = t('v1.Report.ListChart_Month02');
                if (tmpMonth === '03') tmpMonthStr = t('v1.Report.ListChart_Month03');
                if (tmpMonth === '04') tmpMonthStr = t('v1.Report.ListChart_Month04');
                if (tmpMonth === '05') tmpMonthStr = t('v1.Report.ListChart_Month05');
                if (tmpMonth === '06') tmpMonthStr = t('v1.Report.ListChart_Month06');
                if (tmpMonth === '07') tmpMonthStr = t('v1.Report.ListChart_Month07');
                if (tmpMonth === '08') tmpMonthStr = t('v1.Report.ListChart_Month08');
                if (tmpMonth === '09') tmpMonthStr = t('v1.Report.ListChart_Month09');
                if (tmpMonth === '10') tmpMonthStr = t('v1.Report.ListChart_Month10');
                if (tmpMonth === '11') tmpMonthStr = t('v1.Report.ListChart_Month11');
                if (tmpMonth === '12') tmpMonthStr = t('v1.Report.ListChart_Month12');

                if (tmpData.time[i].months === moment(monthDate).format('YYYY-MM')) {
                }

                tmpMonthLabels[tmpCount] = tmpMonthStr;
                tmpMonthData[tmpCount] = tmpData.time[i].studyTime / 60;

                tmpMaxTime =
                  tmpMaxTime < tmpMonthData[tmpCount] ? tmpMonthData[tmpCount] : tmpMaxTime;
                tmpCount++;
              }
            }

            if (tmpData.time.length < 6) {
              for (let i = 0; i < 6 - tmpData.time.length; i++) {
                let tmpMonth = tmpStartMonth - 1 - i;
                tmpMonth = tmpMonth > 0 ? tmpMonth : 12 + tmpMonth;

                let tmpMonthStr = '';
                if (tmpMonth === 1) tmpMonthStr = t('v1.Report.ListChart_Month01');
                if (tmpMonth === 2) tmpMonthStr = t('v1.Report.ListChart_Month02');
                if (tmpMonth === 3) tmpMonthStr = t('v1.Report.ListChart_Month03');
                if (tmpMonth === 4) tmpMonthStr = t('v1.Report.ListChart_Month04');
                if (tmpMonth === 5) tmpMonthStr = t('v1.Report.ListChart_Month05');
                if (tmpMonth === 6) tmpMonthStr = t('v1.Report.ListChart_Month06');
                if (tmpMonth === 7) tmpMonthStr = t('v1.Report.ListChart_Month07');
                if (tmpMonth === 8) tmpMonthStr = t('v1.Report.ListChart_Month08');
                if (tmpMonth === 9) tmpMonthStr = t('v1.Report.ListChart_Month09');
                if (tmpMonth === 10) tmpMonthStr = t('v1.Report.ListChart_Month10');
                if (tmpMonth === 11) tmpMonthStr = t('v1.Report.ListChart_Month11');
                if (tmpMonth === 12) tmpMonthStr = t('v1.Report.ListChart_Month12');

                tmpMonthLabels.unshift(tmpMonthStr);
                tmpMonthData.unshift(0);
              }
            }

            let tmpMonthData2 = [
              tmpMaxTime,
              tmpMaxTime,
              tmpMaxTime,
              tmpMaxTime,
              tmpMaxTime,
              tmpMaxTime,
            ];

            let chartTimeMax = 20 * 60;
            if (tmpMaxTime > chartTimeMax) {
              chartTimeMax = Math.ceil(tmpMaxTime / 60) * 60;
              let tmpAddMax = Math.floor(chartTimeMax / 60) % 5;
              chartTimeMax = chartTimeMax + (5 - tmpAddMax) * 60;
            }

            tmpMonthBgColor[5] = 'rgba(255, 61, 98, 1)';
            setChartTimeDefaultTooltipIndex(5);

            setChartTimeOptions({
              ...chartTimeOptions,
              scales: {
                ...chartTimeOptions.scales,
                y: {
                  ...chartTimeOptions.scales.y,
                  max: chartTimeMax,
                  ticks: {
                    stepSize: 5 * 60,
                    callback: function (value, index, ticks) {
                      return value / 60 + ' h';
                    },
                  },
                },
              },
            });

            setChartTimeData({
              labels: tmpMonthLabels,
              datasets: [
                {
                  data: tmpMonthData,
                  backgroundColor: tmpMonthBgColor,
                  borderRadius: 10,
                  maxBarThickness: 20,
                  borderWidth: 0,
                  hoverBackgroundColor: tmpMonthBgColor,
                },
                {
                  data: tmpMonthData2,
                  backgroundColor: 'rgba(235, 235, 235, 1)',
                  borderRadius: 10,
                  maxBarThickness: 20,
                  borderWidth: 0,
                  hoverBackgroundColor: 'rgba(235, 235, 235, 1)',
                },
              ],
            });
          }
        }
      } catch (error) {}

      setTimeout(() => {
        triggerTooltip(chartTimeRef.current);
      }, 300);
    })();
  };

  const triggerTooltip = (chart) => {
    const tooltip = chart?.tooltip;
    if (!tooltip) {
      return;
    }

    if (chartTimeDefaultTooltipIndex === -1) {
      tooltip.setActiveElements([], { x: 0, y: 0 });
      chart.update();
    } else {
      tooltip.setActiveElements([
        {
          datasetIndex: 0,
          index: chartTimeDefaultTooltipIndex,
        },
      ]);
      chart.update();
    }
  };

  return (
    <>
      {isLogin === 1 && (
        <div
          className={cn('wrap', {
            wrapKo: t('languageCode') === 'ko',
            wrapEn: t('languageCode') === 'en',
            wrapId: t('languageCode') === 'id',
            wrapVi: t('languageCode') === 'vi',
          })}
        >
          <Header />
          <section className="sub-contents">
            <article className="visual report-visual">
              <div className="inner">
                <div className="txt-area">
                  <h2>{t('v1.Report.Title')}</h2>
                </div>
              </div>
              <div className="obj">
                <div className={cn('circle01', { move: isVisualCircle1Class })}></div>
                <div className={cn('circle02', { move: isVisualCircle2Class })}></div>
                <div className={cn('circle03', { move: isVisualCircle3Class })}></div>
                <div className={cn('circle04', { move: isVisualCircle4Class })}></div>
                <div className={cn('circle05', { move: isVisualCircle5Class })}></div>
                <div className={cn('circle06', { move: isVisualCircle6Class })}></div>
                <div className={cn('circle07', { move: isVisualCircle7Class })}></div>
              </div>
              <div className="wave">
                <div className="wave01"></div>
                <div className="wave02"></div>
                <div className="wave03"></div>
                <div className="wave04"></div>
              </div>
            </article>
            <div className="sub-inner">
              <article className="report-wrap">
                <div className="title-tab">
                  <h3 className="page-title active">{t('v1.Report.SubTitle')}</h3>
                </div>
                <section className="report">
                  {/*
                <h4 className="sub-title">{t('v1.Report.Chart_Title')}</h4>
                 */}
                  <div className="inner">
                    <div className="graph">
                      <Radar data={chartData} options={chartOptions} />
                    </div>
                    <div className="summary">
                      <button type="button" className="btn-list">
                        {t('v1.Report.Chart_BtnAi')}
                      </button>
                      <div className="guide-list">
                        <dl>
                          <dt>{t('v1.Report.Chart_TextAi01')}</dt>
                          <dd
                            dangerouslySetInnerHTML={{
                              __html: dompurify.sanitize(t('v1.Report.Chart_TextAi02')),
                            }}
                          ></dd>
                        </dl>
                        <dl>
                          <dt>{t('v1.Report.Chart_TextAi03')}</dt>
                          <dd
                            dangerouslySetInnerHTML={{
                              __html: dompurify.sanitize(t('v1.Report.Chart_TextAi04')),
                            }}
                          ></dd>
                        </dl>
                      </div>
                      <dl>
                        <dt>{t('v1.Report.Chart_TextAi')}</dt>
                        <dd>
                          {t('languageCode') === 'ko' && aiReview.reviewTextKor}
                          {t('languageCode') === 'en' && aiReview.reviewTextEn}
                          {t('languageCode') === 'id' && aiReview.reviewTextIn}
                          {t('languageCode') === 'vi' && aiReview.reviewTextViet}
                        </dd>
                      </dl>
                      <Link to="/topik/history" className="btn-detail">
                        {t('v1.Report.Chart_BtnTopikView')}
                      </Link>
                    </div>
                  </div>
                </section>
                <section className="amount">
                  <h4 className="sub-title">{t('v1.Report.List_Title')}</h4>
                  <div className="tab-area">
                    <ul className="course-type">
                      <li>
                        <Link
                          to="#"
                          className={cn({ active: listType === 'week' })}
                          onClick={(e) => {
                            e.preventDefault();
                            setListType('week');
                            setIsDatepicker(false);
                          }}
                        >
                          {t('v1.Report.List_Tab1')}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className={cn({ active: listType === 'month' })}
                          onClick={(e) => {
                            e.preventDefault();
                            setListType('month');
                            setIsDatepicker(false);
                          }}
                        >
                          {t('v1.Report.List_Tab2')}
                        </Link>
                      </li>
                    </ul>
                    <div className="term">
                      {listType === 'week' && (
                        <>
                          <button
                            type="button"
                            className="btn-prev"
                            onClick={(e) => {
                              e.preventDefault();
                              let tmpWeekOfStart = addDays(
                                startOfWeek(subDays(weekStartDate, 2)),
                                1,
                              );
                              let tmpWeekOfEnd = addDays(tmpWeekOfStart, 6);
                              setWeekStartDate(tmpWeekOfStart);
                              setWeekEndDate(tmpWeekOfEnd);
                            }}
                          >
                            {t('v1.Report.List_BtnWeekPrev')}
                          </button>
                          <div>
                            <span>
                              {moment(weekStartDate).format('MM. DD.')} ~{' '}
                              {moment(weekEndDate).format('MM. DD.')}
                            </span>
                            <button
                              type="button"
                              className="btn-calendar"
                              onClick={(e) => {
                                e.preventDefault();
                                setIsDatepicker(!isDatepicker);
                              }}
                            >
                              {t('v1.Report.List_BtnCalendar')}
                            </button>
                          </div>
                          <button
                            type="button"
                            className="btn-next"
                            onClick={(e) => {
                              e.preventDefault();
                              let tmpWeekOfStart = addDays(
                                startOfWeek(addDays(weekStartDate, 7)),
                                1,
                              );
                              let tmpWeekOfEnd = addDays(tmpWeekOfStart, 6);
                              setWeekStartDate(tmpWeekOfStart);
                              setWeekEndDate(tmpWeekOfEnd);
                            }}
                          >
                            {t('v1.Report.List_BtnWeekNext')}
                          </button>
                          {isDatepicker && (
                            <>
                              <div className="calendar week">
                                <DatePicker
                                  calendarStartDay={1}
                                  selected={weekStartDate}
                                  onChange={(dates) => {
                                    console.log(dates);
                                    let [start, end] = dates;
                                    let tmpDay = getDay(start);
                                    console.log('asddsads', tmpDay);
                                    let tmpWeekOfStart;
                                    if (tmpDay === 0)
                                      tmpWeekOfStart = addDays(startOfWeek(subDays(start, 1)), 1);
                                    else tmpWeekOfStart = addDays(startOfWeek(start), 1);
                                    let tmpWeekOfEnd = addDays(tmpWeekOfStart, 6);
                                    setWeekStartDate(tmpWeekOfStart);
                                    setWeekEndDate(tmpWeekOfEnd);
                                  }}
                                  startDate={weekStartDate}
                                  endDate={weekEndDate}
                                  /*
                                filterDate={(date) => {
                                  const day = getDay(date);
                                  return day !== 6;
                                }}
                                */
                                  selectsRange
                                  selectsDisabledDaysInRange
                                  inline
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}

                      {listType === 'month' && (
                        <>
                          <button
                            type="button"
                            className="btn-prev"
                            onClick={(e) => {
                              e.preventDefault();
                              setMonthDate(subMonths(monthDate, 1));
                            }}
                          >
                            {t('v1.Report.List_BtnMonthPrev')}
                          </button>
                          <div>
                            <span>{moment(monthDate).format('YYYY. MM.')}</span>
                            <button
                              type="button"
                              className="btn-calendar"
                              onClick={(e) => {
                                e.preventDefault();
                                setIsDatepicker(!isDatepicker);
                              }}
                            >
                              {t('v1.Report.List_BtnCalendar')}
                            </button>
                          </div>
                          <button
                            type="button"
                            className="btn-next"
                            onClick={(e) => {
                              e.preventDefault();
                              setMonthDate(addMonths(monthDate, 1));
                            }}
                          >
                            {t('v1.Report.List_BtnMonthNext')}
                          </button>
                          {isDatepicker && (
                            <>
                              <div className="calendar month">
                                <DatePicker
                                  selected={monthDate}
                                  onChange={(date) => setMonthDate(date)}
                                  dateFormat="MM/yyyy"
                                  showMonthYearPicker
                                  showFullMonthYearPicker
                                  inline
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="type-list">
                    <div className={cn('item', { open: isShow1 })}>
                      <div
                        className="amount-title"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsShow1(!isShow1);
                        }}
                      >
                        <i className="ico-paragraph"></i>
                        <Trans
                          i18nKey="v1.Report.List_Text01"
                          values={{ n: mynoteData.todayCnt + mynoteData.courseCnt }}
                        >
                          <span>
                            <b />
                          </span>
                          <b />
                        </Trans>
                      </div>
                      {isShow1 && (
                        <div className="content">
                          <ul>
                            <li>
                              <span className="sbj">{t('v1.Report.List_Text01_01')}</span>
                              <span className="score">
                                <Trans
                                  i18nKey="v1.Report.List_Number"
                                  values={{ n: mynoteData.courseCnt }}
                                >
                                  <b />
                                </Trans>
                              </span>
                            </li>
                            {/*
                          <li>
                            <span className="sbj">{t('v1.Report.List_Text01_02')}</span>
                            <span className="score">
                              <b>-</b>개
                            </span>
                          </li>
                    */}
                            <li>
                              <span className="sbj">{t('v1.Report.List_Text01_03')}</span>
                              <span className="score">
                                <Trans
                                  i18nKey="v1.Report.List_Number"
                                  values={{ n: mynoteData.todayCnt }}
                                >
                                  <b />
                                </Trans>
                              </span>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>

                    {/*
                  <div className={cn('item', { open: isShow2 })}>
                    <div
                      className="amount-title"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsShow2(!isShow2);
                      }}
                    >
                      <i className="ico-topik"></i>
                      <Trans
                        i18nKey="REPORT.REPORT_ST_N_TOPIK"
                        values={{ n: mynoteData.topikTotalCnt }}
                      >
                        <span>
                          {' '}
                          <b>{' '}</b>
                        </span>
                        <b />
                      </Trans>
                    </div>
                    {isShow2 && (
                      <div className="content" style={{ display: 'block' }}>
                        <ul>
                          <li>
                            <span className="sbj">TOPIK I</span>
                            <span className="score">
                              <Trans
                                i18nKey="REPORT.REPORT_N_PHRASE"
                                values={{ n: mynoteData.topik1Cnt }}
                              >
                                <b />
                              </Trans>
                            </span>
                          </li>
                          <li>
                            <span className="sbj">TOPIK II</span>
                            <span className="score">
                              <Trans
                                i18nKey="REPORT.REPORT_N_PHRASE"
                                values={{ n: mynoteData.topik2Cnt }}
                              >
                                <b />
                              </Trans>
                            </span>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                    */}
                    <div className={cn('item', { open: isShow3 })}>
                      <div
                        className="amount-title"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsShow3(!isShow3);

                          if (isShow3 === false) {
                            const chart = chartTimeRef.current;

                            const tooltip = chart.tooltip;
                            if (tooltip) {
                              tooltip.setActiveElements([], { x: 0, y: 0 });
                              chart.update();
                            }

                            triggerTooltip(chart);
                          }
                        }}
                      >
                        <i className="ico-time"></i>

                        {mynoteData.timeTotalH === 0 && (
                          <Trans
                            i18nKey="v1.Report.List_Text03"
                            values={{ n: mynoteData.timeTotalM }}
                          >
                            <span>
                              <b />
                            </span>
                            <b />
                          </Trans>
                        )}
                        {mynoteData.timeTotalH > 0 && (
                          <Trans
                            i18nKey="v1.Report.List_Text03HM"
                            values={{ h: mynoteData.timeTotalH, m: mynoteData.timeTotalM }}
                          >
                            <span>
                              <b />
                            </span>
                            <b />
                          </Trans>
                        )}
                      </div>
                      <div className="content" style={{ display: isShow3 ? 'block' : 'none' }}>
                        <div className="graph">
                          <Bar
                            ref={chartTimeRef}
                            options={chartTimeOptions}
                            data={chartTimeData}
                            style={{ width: '100%', height: '100%' }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </section>
          <Footer />
        </div>
      )}
    </>
  );
};

export default ReportMain;
