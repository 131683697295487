import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { useUserState, useUserDispatch } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import * as Api from '../../library/Api';
import cn from 'classnames';
import Footer from '../../layouts/Footer';
import Header from '../../layouts/Header';

const PasswordChange = ({ isLogin, user, t }) => {
  const navigate = useNavigate();

  const [passwordStep, setPasswordStep] = useState(1);

  const emailRef = useRef();
  const [email, setEmail] = useState('');

  const [emailToken, setEmailToken] = useState();
  const [emailError, setEmailError] = useState('');

  const [isNumberButtonDisabled, setIsNumberButtonDisabled] = useState(false);

  const emailNumberRef = useRef();

  const [emailNumberError, setEmailNumberError] = useState('');
  const [emailNumberErrorNumber, setEmailNumberErrorNumber] = useState(0);

  const [isNewPassword1View, setIsNewPassword1View] = useState(false);
  const [isNewPassword2View, setIsNewPassword2View] = useState(false);
  const [newPassword1Error, setNewPassword1Error] = useState('');
  const [newPassword2Error, setNewPassword2Error] = useState('');

  const newPassword1Ref = useRef();
  const newPassword2Ref = useRef();

  const [isSuccessNewPassword1, setIsSuccessNewPassword1] = useState(false);
  const [isSuccessNewPassword2, setIsSuccessNewPassword2] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);

  const onEmailSend = (alertMessage = false) => {
    (async () => {
      try {
        let emailValue = emailRef.current ? emailRef.current.value : email;
        const checkDuplicateIdResetResponse = await Api.checkDuplicateIdReset(
          //emailRef.current.value,
          emailValue,
        );
        if (checkDuplicateIdResetResponse.data.result.code === '1') {
          if (checkDuplicateIdResetResponse.data.result.data === 'GOOGLE') {
            setEmailError(101);
          } else if (checkDuplicateIdResetResponse.data.result.data === 'APPLE') {
            setEmailError(102);
          } else if (checkDuplicateIdResetResponse.data.result.data === 'FACEBOOK') {
            setEmailError(103);
          } else {
            // 소셜 계정입니다. 소셜로 로그인 해주시기 바랍니다.
            setEmailError(105);
          }

          return;
        }

        if (checkDuplicateIdResetResponse.data.result.code === '2') {
          // 아이디가 존재하지 않습니다.
          setEmailError(104);
          return;
        }

        if (checkDuplicateIdResetResponse.data.result.code === '0') {
          const sendAuthMailResponse = await Api.sendAuthMail({
            //id: emailRef.current.value,
            id: emailValue,
            lang: t('languageApiCode'),
          });
          if (sendAuthMailResponse.data.result.data) {
            setEmail(emailValue);
            setEmailToken(sendAuthMailResponse.data.result.data);
            setPasswordStep(2);
            if (alertMessage) {
              alert(t('v1.MemberPasswordChange.Step2_AlertReSend'));
            }
          } else {
            // 인증 번호 발송 중 오류가 발생하였습니다.
            //setEmailError('Error - Password Change Step1-02');
            setEmailError(105);
          }
        } else {
          // 알 수 없는 오류가 발생하였습니다. 다시 시도해주시기 바랍니다.
          //setEmailError('Error - Password Change Step1-03');
          setEmailError(105);
        }
      } catch (error) {
        // 인증 번호 발송 중 오류가 발생하였습니다.
        //setEmailError('Error - Password Change Step1-04');
        setEmailError(105);
      }
    })();
  };

  const onMailNumberCheck = () => {
    setIsNumberButtonDisabled(true);

    (async () => {
      try {
        const checkAuthNumResponse = await Api.checkAuthNum(
          emailToken,
          emailNumberRef.current.value,
        );
        if (Number(checkAuthNumResponse.data.result.code) === 1) {
          // 만료된 인증번호입니다.
          setEmailNumberError(201);
          setIsNumberButtonDisabled(false);
        } else if (Number(checkAuthNumResponse.data.result.code) === 2) {
          // 인증번호 {{n}}/10회 입력 오류
          setEmailNumberError(202);
          setEmailNumberErrorNumber(checkAuthNumResponse.data.result.data);
          setIsNumberButtonDisabled(false);
        } else {
          setPasswordStep(3);
        }
      } catch (apiError) {
        setEmailNumberError(203);
        setIsNumberButtonDisabled(false);
      }
    })();
  };

  const onNewPassword1Check = () => {
    setIsSuccessNewPassword1(false);
    setIsSuccessNewPassword2(false);

    if (newPassword1Ref.current.value === '') {
      setNewPassword1Error('');
      return false;
    }

    if (newPassword1Ref.current.value.length < 8) {
      setNewPassword1Error(301);
      return false;
    }

    if (newPassword1Ref.current.value.length > 20) {
      setNewPassword1Error(302);
      return false;
    }

    const passwordRegex = /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#&$%^*+=-_~./]).{8,}$/;
    if (passwordRegex.test(newPassword1Ref.current.value) === false) {
      setNewPassword1Error(304);
      return false;
    }

    if (newPassword2Ref.current.value !== '') {
      onNewPassword2Check();
    }

    setNewPassword1Error('');
    setIsSuccessNewPassword1(true);
  };

  const onNewPassword2Check = () => {
    setIsSuccessNewPassword2(false);

    if (newPassword1Ref.current.value !== newPassword2Ref.current.value) {
      setNewPassword2Error(401);
      return false;
    }
    setNewPassword2Error('');
    setIsSuccessNewPassword2(true);
  };

  const onPasswordChange = () => {
    (async () => {
      try {
        const resetPasswordResponse = await Api.resetPassword(
          emailToken,
          newPassword1Ref.current.value,
        );
        if (Number(resetPasswordResponse.data.result.code) === 2) {
          // "Signup_Guide_Remark27" : "현재 비밀번호와 같은 비밀번호입니다.",
          setNewPassword1Error(303);
          setIsSuccessNewPassword1(false);
        } else if (Number(resetPasswordResponse.data.result.code) !== 0) {
          // "Signup_Guide_Remark30" : "비밀번호 변경에 실패했습니다.",
          setNewPassword1Error(305);
          setIsSuccessNewPassword1(false);
        } else {
          setIsSuccess(true);
        }
      } catch (apiError) {
        // "Signup_Guide_Remark30" : "비밀번호 변경에 실패했습니다.",
        setNewPassword1Error(305);
        setIsSuccessNewPassword1(false);
      }
    })();
  };

  return (
    <div
      className={cn('wrap', {
        wrapKo: t('languageCode') === 'ko',
        wrapEn: t('languageCode') === 'en',
        wrapId: t('languageCode') === 'id',
        wrapVi: t('languageCode') === 'vi',
      })}
    >
      <Header />
      <section className="login-wrap">
        <h2>{t('v1.MemberPasswordChange.Title')}</h2>
        <div className="inner">
          <article className="login-form">
            {(passwordStep === 1 || passwordStep === 2) && (
              <div className="form-section mail">
                <h3>{t('v1.MemberPasswordChange.Step1_Title')}</h3>
                {passwordStep === 1 && (
                  <>
                    <input
                      type="text"
                      ref={emailRef}
                      placeholder={t('v1.MemberPasswordChange.Step1_PlaceholderEmail')}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          onEmailSend();
                        }
                      }}
                    />
                    {emailError !== '' && (
                      <p className="alert" style={{ marginTop: '-10px' }}>
                        ! {emailError === 101 && t('v1.MemberPasswordChange.Step1_Error01')}
                        {emailError === 102 && t('v1.MemberPasswordChange.Step1_Error02')}
                        {emailError === 103 && t('v1.MemberPasswordChange.Step1_Error03')}
                        {emailError === 104 && t('v1.MemberPasswordChange.Step1_Error04')}
                        {emailError === 105 && t('v1.MemberPasswordChange.Step1_Error05')}
                      </p>
                    )}
                    <button
                      type="button"
                      className="btn-login-full"
                      onClick={(e) => {
                        e.preventDefault();
                        onEmailSend();
                      }}
                    >
                      {t('v1.MemberPasswordChange.Step1_BtnNext')}
                    </button>
                  </>
                )}
                {passwordStep === 2 && (
                  <>
                    <div className="auth">
                      <p>{t('v1.MemberPasswordChange.Step2_SubTitle')}</p>
                      <div className="num">
                        <input
                          type="text"
                          placeholder={t('v1.MemberPasswordChange.Step2_PlaceholderNumber')}
                          ref={emailNumberRef}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              onMailNumberCheck();
                            }
                          }}
                        />

                        <button
                          type="submit"
                          className="btn-confirm"
                          disabled={isNumberButtonDisabled}
                          onClick={(e) => {
                            e.preventDefault();
                            onMailNumberCheck();
                          }}
                        >
                          {t('v1.MemberPasswordChange.Step2_BtnNext')}
                        </button>
                      </div>
                      {emailNumberError !== '' && (
                        <p className="alert" style={{ marginTop: '-10px' }}>
                          ! {emailNumberError === 201 && t('v1.MemberPasswordChange.Step2_Error01')}
                          {emailNumberError === 202 &&
                            t('v1.MemberPasswordChange.Step2_Error02', {
                              n: emailNumberErrorNumber,
                            })}
                          {emailNumberError === 203 && t('v1.MemberPasswordChange.Step2_Error03')}
                        </p>
                      )}
                      <Link
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          onEmailSend(true);
                        }}
                      >
                        {t('v1.MemberPasswordChange.Step2_BtnReSend')}
                      </Link>
                    </div>

                    <div className="info">
                      <p>* {t('v1.MemberPasswordChange.Step2_Text01')}</p>
                      <p>
                        <Trans i18nKey="v1.MemberPasswordChange.Step2_Text02">
                          <a href="mailto:support.geniek@hancom.com" />
                        </Trans>
                      </p>
                    </div>
                  </>
                )}
              </div>
            )}
            {passwordStep === 3 && (
              <section className="form-section new-pw">
                <h3>{t('v1.MemberPasswordChange.Step3_Title')}</h3>
                <div className="input pw">
                  <input
                    type={isNewPassword1View ? 'text' : 'password'}
                    placeholder={t('v1.MemberPasswordChange.Step3_PlaceholderNewPassword1')}
                    ref={newPassword1Ref}
                    onChange={onNewPassword1Check}
                  />
                  <button
                    type="button"
                    className={cn('btn', { secret: isNewPassword1View })}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNewPassword1View(!isNewPassword1View);
                    }}
                  >
                    {t('v1.MemberPasswordChange.Step3_BtnPasswordView')}
                  </button>
                </div>
                {isSuccessNewPassword1 && (
                  <p className="able" style={{ marginBottom: '12px' }}>
                    {t('v1.MemberPasswordChange.Step3_SuccessNewPassword1')}
                  </p>
                )}
                {!isSuccessNewPassword1 && newPassword1Error !== '' && (
                  <p className="alert">
                    !{' '}
                    {newPassword1Error === 301 &&
                      t('v1.MemberPasswordChange.Step3_ErrorNewPassword01')}
                    {newPassword1Error === 302 &&
                      t('v1.MemberPasswordChange.Step3_ErrorNewPassword02')}
                    {newPassword1Error === 303 &&
                      t('v1.MemberPasswordChange.Step3_ErrorNewPassword03')}
                    {newPassword1Error === 304 &&
                      t('v1.MemberPasswordChange.Step3_ErrorNewPassword04')}
                    {newPassword1Error === 305 &&
                      t('v1.MemberPasswordChange.Step3_ErrorNewPassword06')}
                  </p>
                )}
                {!isSuccessNewPassword1 && newPassword1Error === '' && (
                  <ul className="guide">
                    <li>- {t('v1.MemberPasswordChange.Step3_TextNewPassword1')}</li>
                    <li>- {t('v1.MemberPasswordChange.Step3_TextNewPassword2')}</li>
                  </ul>
                )}

                <div className="input pw">
                  <input
                    type={isNewPassword2View ? 'text' : 'password'}
                    placeholder={t('v1.MemberPasswordChange.Step3_PlaceholderNewPassword2')}
                    ref={newPassword2Ref}
                    disabled={!isSuccessNewPassword1}
                    onChange={onNewPassword2Check}
                  />
                  <button
                    type="button"
                    className={cn('btn', { secret: isNewPassword2View })}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNewPassword2View(!isNewPassword2View);
                    }}
                  >
                    {t('v1.MemberPasswordChange.Step3_BtnPasswordView')}
                  </button>
                </div>
                {!isSuccessNewPassword2 && newPassword2Error !== '' && (
                  <p className="alert">
                    !{' '}
                    {newPassword2Error === 401 &&
                      t('v1.MemberPasswordChange.Step3_ErrorNewPassword05')}
                  </p>
                )}
                {isSuccessNewPassword2 && (
                  <p className="able" style={{ marginBottom: '12px' }}>
                    {t('v1.MemberPasswordChange.Step3_SuccessNewPassword2')}
                  </p>
                )}

                <button
                  type="button"
                  className="btn-login-full"
                  disabled={!isSuccessNewPassword1 || !isSuccessNewPassword2}
                  onClick={(e) => {
                    e.preventDefault();
                    onPasswordChange();
                  }}
                >
                  {t('v1.MemberPasswordChange.Step3_BtnSubmit')}
                </button>
              </section>
            )}
          </article>
        </div>

        {isSuccess && (
          <div className="modal info-modal" style={{ display: 'block' }}>
            <div className="inner">
              <div className="modal-header">
                <Link to="/" className="btn-close">
                  {t('v1.Common.BtnClose')}
                </Link>
              </div>
              <div className="modal-body">
                <div className="change-pw">
                  <p>{t('v1.MemberPasswordChange.Success_Text')}</p>
                </div>
              </div>
              <div className="btn-area">
                <Link to="/" className="btn-default btn-primary">
                  {t('v1.Common.BtnOk')}
                </Link>
              </div>
            </div>
          </div>
        )}
      </section>
      <Footer />
    </div>
  );
};

export default PasswordChange;
