import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import cn from 'classnames';
import * as Api from '../../library/Api';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import TopikConfirm from './layout/TopikConfirm';

import TopikViewInfo from './layout/TopikViewInfo';
import TopikViewQuestion from './layout/TopikViewQuestion';
import TopikViewSuccess from './layout/TopikViewSuccess';

const TopikView2 = ({ isLogin, user, t }) => {
  const { rwdKind } = useParams();

  const navigate = useNavigate();

  const [topikData, setTopikData] = useState({});

  const [resetCheck, setResetCheck] = useState(0);

  const topikIdRef = useRef('');
  const [resultData, setResultData] = useState({});
  const [questionList, setQuestionList] = useState([]);
  const [questionData, setQuestionData] = useState({
    isSuccess: false,
    infoType: 1, // 0 - 닫기, 1 - 초기Info, 2 - 읽기Info
    totalStep: 0,
    totalLCStep: 0,
    totalRCStep: 0,
    nowStep: 0,
    list: [],
    data: {},
  });

  const [questionTimer, setQuestionTimer] = useState({
    min: 0,
    sec: 0,
  });
  const questionTimerSec = useRef(0);
  const questionTimerInterval = useRef(null);

  const [isTimeOverToast, setIsTimeOverToast] = useState(false);

  useEffect(() => {
    if (isLogin === 1) {
      (async () => {
        try {
          // API 단일 정보 추출이 없어 LIST 호출
          const topikListResponse = await Api.topikList({ esntlId: user.esntlId });
          console.log('topikListResponse', topikListResponse);
          if (topikListResponse.data.result.data.reward) {
            for (let i = 0; i < topikListResponse.data.result.data.reward.length; i++) {
              if (topikListResponse.data.result.data.reward[i].rwdKind === rwdKind) {
                setTopikData(topikListResponse.data.result.data.reward[i]);
                questionTimerSec.current =
                  parseInt(topikListResponse.data.result.data.reward[i].topikTime) * 60;
              }
            }
          }
        } catch (error) {}
      })();
    }

    return () => {
      timerClear();
    };
  }, [isLogin, rwdKind]);

  useEffect(() => {
    setQuestionList([]);
    setQuestionData({
      isSuccess: false,
      infoType: 1,
      totalStep: 0,
      totalLCStep: 0,
      totalRCStep: 0,
      nowStep: 0,
      list: [],
      data: {},
    });

    (async () => {
      try {
        const topikTopikResponse = await Api.topikTopik({
          questionRound: topikData.rwdKind,
        });
        if (topikTopikResponse.data.result.data) {
          let tmpLcCount = 0;
          let tmpRcCount = 0;
          for (let i = 0; i < topikTopikResponse.data.result.data.length; i++) {
            if (topikTopikResponse.data.result.data[i].questionType === 'LC') tmpLcCount++;
            else if (topikTopikResponse.data.result.data[i].questionType === 'RC') tmpRcCount++;
          }

          setQuestionList(topikTopikResponse.data.result.data);
          setQuestionData({
            ...questionData,
            totalStep: topikTopikResponse.data.result.data.length,
            totalLCStep: tmpLcCount,
            totalRCStep: tmpRcCount,
            list: topikTopikResponse.data.result.data,
          });
        }
      } catch (error) {}
    })();
  }, [topikData, resetCheck]);

  const reset = () => {
    setResetCheck(resetCheck + 1);

    questionTimerSec.current = parseInt(topikData.topikTime) * 60;
    topikIdRef.current = '';
    setResultData({});
    setQuestionList([]);
    setQuestionData({
      isSuccess: false,
      infoType: 1,
      totalStep: 0,
      totalLCStep: 0,
      totalRCStep: 0,
      nowStep: 0,
      list: [],
      data: {},
    });
    setQuestionTimer({ min: 0, sec: 0 });
    setIsTimeOverToast(false);
  };

  const onReset = () => {
    if (topikData.payAt === 'N') {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <TopikConfirm
              t={t}
              content={t('TOPIK.Alert_TOPIK_Re_Title')}
              content2={t('TOPIK.Alert_TOPIK_Re_info')}
              onClose={onClose}
              onConfirm={() => {
                reset();
              }}
            />
          );
        },
      });
    }
  };

  // 문제 풀이 시작
  const onStart = () => {
    let nowStep = questionData.nowStep === 0 ? 1 : questionData.nowStep;

    if (nowStep === 1) {
      // API Start
      (async () => {
        const topikStartResponse = await Api.topikStart({
          esntlId: user.esntlId,
          rwdKind: topikData.rwdKind,
        });

        if (topikStartResponse.data.result.data) {
          topikIdRef.current = topikStartResponse.data.result.data;
        }

        await Api.topikUpdateRewardView({
          esntlId: user.esntlId,
          rwdKind: topikData.rwdKind,
        });
      })();
    }

    // 첫시작이거나, 읽기설명 후 타이머 시작
    if (nowStep === 1 || questionData.infoType === 2) {
      timerStart();
    }

    setQuestionData({
      ...questionData,
      infoType: 0,
      nowStep: nowStep,
      data: questionList[nowStep - 1],
    });
  };

  const onFinish = () => {
    // 페이지 형태로 변경
    timerClear();

    (async () => {
      try {
        await Api.topikFinish({
          esntlId: user.esntlId,
          rwdKind: topikData.rwdKind,
          timeoverYn: 'N',
          topikText: JSON.stringify(questionData.list),
          topikId: topikIdRef.current,
        });

        navigate('/topik/result/' + topikIdRef.current);
      } catch (error) {}
    })();

    //----------------------------

    // 모달 형태 제거..
    /*
    setQuestionData({
      ...questionData,
      isSuccess: true,
    });

    timerClear();

    (async () => {
      try {
        await Api.topikFinish({
          esntlId: user.esntlId,
          rwdKind: topikData.rwdKind,
          timeoverYn: 'N',
          topikText: JSON.stringify(questionData.list),
          topikId: topikIdRef.current,
        });

        const topikResultResponse = await Api.topikResult({
          esntlId: user.esntlId,
          topikId: topikIdRef.current,
        });
        if (topikResultResponse.data.result.data) {
          setResultData(topikResultResponse.data.result.data);
        }
      } catch (error) {}
    })();
    */
  };

  const onCloseCustom = () => {
    // API Stop
    console.log('onCloseCustom topikId', topikIdRef.current);
    if (topikIdRef.current) {
      (async () => {
        await Api.topikStop({
          stopNum: 1,
          topikId: topikIdRef.current,
          esntlId: user.esntlId,
        });
      })();
    }

    //onClose();
    navigate(-1);
  };

  // 타이머 시작
  const timerStart = () => {
    questionTimerInterval.current = setInterval(() => {
      if (questionTimerSec.current > 0) {
        questionTimerSec.current -= 1;
        setQuestionTimer({
          min: parseInt(questionTimerSec.current / 60),
          sec: questionTimerSec.current % 60,
        });
      }

      // 5분, 3분, 1분 시간 임박 알림
      if (
        questionTimerSec.current === 300 ||
        questionTimerSec.current === 180 ||
        questionTimerSec.current === 60
      ) {
        timeOverToastOpen();
      }

      // 시간 초과
      if (questionTimerSec.current === 0) {
        timerClear();

        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <TopikConfirm
                t={t}
                content={t('TOPIK.Alert_TOPIK_TimeOver')}
                isCancelHideBtn={true}
                onClose={onClose}
                onConfirm={() => {
                  onCloseCustom();
                }}
              />
            );
          },
        });
      }
    }, 1000);
  };

  // 타이머 종료
  const timerClear = () => {
    clearInterval(questionTimerInterval.current);
  };

  // 시간 임박 알림
  const timeOverToastOpen = () => {
    setIsTimeOverToast(true);
    setTimeout(() => {
      timeOverToastClose();
    }, 3000);
  };

  const timeOverToastClose = () => {
    setIsTimeOverToast(false);
  };

  // 다음 문제 시작
  const onNextStep = () => {
    if (questionData.nowStep + 1 > questionData.totalStep) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <TopikConfirm
              t={t}
              content={t('TOPIK_Edu.Alert_TOPIK_Submit')}
              onClose={() => {
                onClose();
              }}
              onConfirm={() => {
                onFinish();
              }}
            />
          );
        },
      });
    } else {
      let infoType = 0;

      // 듣기 -> 읽기 에서 전환될때 안내 페이지 호출
      if (questionData.data.questionType !== questionList[questionData.nowStep].questionType) {
        infoType = 2;

        // 타이머 일시정지
        timerClear();
      }

      setQuestionData({
        ...questionData,
        infoType: infoType,
        nowStep: questionData.nowStep + 1,
        data: questionList[questionData.nowStep],
      });
    }
  };

  const onSelectItem = (questionId, exampleSequence) => {
    let tmpQuestionList = questionList;
    for (let i = 0; i < tmpQuestionList.length; i++) {
      if (tmpQuestionList[i].questionId === questionId)
        tmpQuestionList[i].selectedItem = exampleSequence;
    }

    setQuestionData({
      ...questionData,
      list: tmpQuestionList,
    });
  };

  return (
    <>
      <div
        className={cn('wrap', {
          wrapKo: t('languageCode') === 'ko',
          wrapEn: t('languageCode') === 'en',
          wrapId: t('languageCode') === 'id',
          wrapVi: t('languageCode') === 'vi',
        })}
      >
        {!questionData.isSuccess && questionData.infoType > 0 && (
          <>
            <div className="popup-parent-wrap" style={{ width: '100%', overflow: 'auto' }}>
              <TopikViewInfo
                t={t}
                topikData={topikData}
                infoType={questionData.infoType}
                totalMinute={questionTimerSec.current / 60}
                totalCount={questionData.totalStep}
                totalLCCount={questionData.totalLCStep}
                totalRCCount={questionData.totalRCStep}
                onClose={onCloseCustom}
                onStart={onStart}
              />
            </div>
          </>
        )}
        {!questionData.isSuccess && questionData.infoType === 0 && questionData.nowStep > 0 && (
          <>
            <div className="popup-parent-wrap" style={{ width: '100%', overflow: 'auto' }}>
              <TopikViewQuestion
                t={t}
                topikData={topikData}
                questionData={questionData}
                questionTimer={questionTimer}
                onClose={onCloseCustom}
                onNextStep={onNextStep}
                onSelectItem={onSelectItem}
                isTimeOverToast={isTimeOverToast}
                timeOverToastClose={timeOverToastClose}
              />
            </div>
          </>
        )}
        {questionData.isSuccess && questionData.infoType === 0 && (
          <>
            <div className="popup-parent-wrap" style={{ width: '100%', overflow: 'auto' }}>
              <TopikViewSuccess
                t={t}
                topikData={topikData}
                questionList={questionList}
                resultData={resultData}
                onClose={onCloseCustom}
                onReset={onReset}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TopikView2;
