import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

const JoinStep2 = ({ t, completeFormStep }) => {
  const {
    register,
    formState: { isValid, errors },
  } = useFormContext(); // retrieve all hook methods

  return (
    <>
      <fieldset>
        <legend>{t('Member.Member_Step2')}</legend>
        <h3>{t('Member.Member_Step2')}</h3>
        <input
          type="text"
          className={errors.email ? 'error' : undefined}
          placeholder={t('Member.Member_Email_Title')}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              completeFormStep();
            }
          }}
          {...register('email', {
            required: t('v1.MemberJoin.Step2_Error01'),
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t('v1.MemberJoin.Step2_Error02'),
            },
          })}
        />
        {errors.email && <p className="alert">! {errors.email.message}</p>}
        <div className="btn-area">
          <button type="button" className="btn-login btn-full" onClick={completeFormStep}>
            {t('v1.MemberJoin.Step2_BtnEmail')}
          </button>
        </div>

        <p className="clause-info">* {t('v1.MemberJoin.Step2_Text1')}</p>
      </fieldset>
    </>
  );
};

export default JoinStep2;
