import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useUserState, useUserDispatch } from '../../context/UserContext';
import { Trans, useTranslation } from 'react-i18next';
import * as Api from '../../library/Api';
import cn from 'classnames';
import moment from 'moment';

import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import MypageLeftNav from './layout/MypageLeftNav';

const MypageNoti = ({ isLogin, user, t }) => {
  const navigate = useNavigate();

  const [notiList, setNotiList] = useState([]);
  const [notiViewNList, setNotiViewNList] = useState([]);

  const [listType, setListType] = useState(1);
  const [list, setList] = useState([]);

  useEffect(() => {
    if (isLogin === 1) {
      (async () => {
        try {
          const mypageNotiListResponse = await Api.mypageNotiList(user.esntlId);
          if (mypageNotiListResponse.data.result.data) {
            setNotiList(
              mypageNotiListResponse.data.result.data.map((item, index) => {
                return {
                  ...item,
                  isNew: item.viewYN === 'N' ? true : false,
                  isOpen: false,
                };
              }),
            );
          }
        } catch (error) {}
      })();
    } else if (isLogin === 0) {
      //alert('로그인 후 이용가능합니다.');
      navigate('/member/login');
    }
  }, [isLogin]);

  useEffect(() => {
    setList(listType === 1 ? notiList : notiViewNList);
    setNotiViewNList(
      notiList.filter((item, index) => {
        return item.viewYN === 'N';
      }),
    );
  }, [notiList]);

  useEffect(() => {
    if (listType === 1) setList(notiList);
    else setList(notiViewNList);
  }, [listType]);

  const onListView = (no) => {
    setNotiList(
      notiList.map((item) => {
        return item.no === no
          ? { ...item, isNew: false, isOpen: !item.isOpen }
          : { ...item, isOpen: false };
      }),
    );

    (async () => {
      try {
        await Api.mypageNotiViewUpd(user.esntlId, no);
      } catch (error) {}
    })();
  };

  return (
    <>
      {isLogin === 1 && (
        <div
          className={cn('wrap', {
            wrapKo: t('languageCode') === 'ko',
            wrapEn: t('languageCode') === 'en',
            wrapId: t('languageCode') === 'id',
            wrapVi: t('languageCode') === 'vi',
          })}
        >
          <Header />

          <section className="mypage-wrap">
            <h2 className="blind">{t('v1.Mypage.Title')}</h2>
            <div className="inner">
              <MypageLeftNav isLogin={isLogin} user={user} t={t} />
              <article className="mypage-content">
                <h3 className="mypage-title">{t('MyPage.MyPage_Alarm')}</h3>
                <div className="sorting">
                  <Link
                    to="#"
                    className={cn({ active: listType === 1 })}
                    onClick={(e) => {
                      e.preventDefault();
                      setListType(1);
                    }}
                  >
                    {t('MyPage.MyPage_Alarm_Tab1', { n: notiList.length })}
                  </Link>{' '}
                  <Link
                    to="#"
                    className={cn({ active: listType === 2 })}
                    onClick={(e) => {
                      e.preventDefault();
                      setListType(2);
                    }}
                  >
                    {t('MyPage.MyPage_Alarm_Tab2', { n: notiViewNList.length })}
                  </Link>
                </div>
                <div className="list">
                  {list.length === 0 && (
                    <div className="none">
                      <p className="noti">
                        {listType === 2 && t('v1.MypageNotice.List2_Not')}
                        {listType !== 2 && t('MyPage.MyPage_NoAlarm')}
                      </p>
                    </div>
                  )}
                  {list.map((item, index) => {
                    return (
                      <>
                        <div
                          key={'listItem_' + item.no}
                          className={cn('item', 'noti-item', { open: item.isOpen })}
                          onClick={(e) => {
                            e.preventDefault();
                            onListView(item.no);
                          }}
                        >
                          <Link
                            to="#"
                            className="sbj"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <Trans i18nKey="MyPage.MyPage_Alarm_Notice">
                              <span className="cate notice" />
                            </Trans>
                            {/*<span className="cate event">[이벤트]</span>*/}
                            {item.title}
                            {item.isNew && <span className="new">NEW</span>}
                          </Link>
                          <div className="txt">
                            {item.isOpen === false && item.message}
                            {item.isOpen === true &&
                              item.message.split('\n').map((messageItem, messageIndex) => {
                                return (
                                  <>
                                    <span key={'listMessage_' + item.no + '_' + messageIndex}>
                                      {messageItem}
                                      <br />
                                    </span>
                                  </>
                                );
                              })}
                          </div>
                          <div className="date">
                            {item.date && moment(item.date).format('YYYY. MM. DD.')}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                {/*
            <div className="paging">
              <a href="#" className="btn btn-first">
                처음
              </a>
              <a href="#" className="btn btn-prev">
                {t('common.btn_before')}
              </a>
              <a href="#" className="active">
                1
              </a>
              <a href="#">2</a>
              <a href="#">3</a>
              <a href="#">4</a>
              <a href="#">5</a>
              <a href="#" className="btn btn-next">
                다음
              </a>
              <a href="#" className="btn btn-last">
                마지막
              </a>
            </div>
              */}
              </article>
            </div>
          </section>
          <Footer />
        </div>
      )}
    </>
  );
};

export default MypageNoti;
