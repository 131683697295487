import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import cn from 'classnames';

import Lottie from 'react-lottie-player';
import lottieFace01 from '../../../assets/face01.json';
import lottieFace02 from '../../../assets/face02.json';
import lottieFace03 from '../../../assets/face03.json';
import lottieFace04 from '../../../assets/face04.json';
import lottieFace05 from '../../../assets/face05.json';
import { Trans } from 'react-i18next';

const SpeakUnitQuizSuccess = ({
  t,
  onQuizReload,
  onStepChange,
  unitQuizUnitNum,
  unitQuizList,
  answerData,
  courseData,
  courseLevel,
  nextClass,
}) => {
  const [correctPer, setCorrectPer] = useState(0);
  const [correctIcon, setCorrectIcon] = useState(lottieFace05);
  const randTexts = [
    [
      t('common.Random_reaction1'),
      t('common.Random_reaction2'),
      t('common.Random_reaction3'),
      t('common.Random_reaction4'),
      t('common.Random_reaction5'),
    ],
    [
      t('common.Random_reaction6'),
      t('common.Random_reaction7'),
      t('common.Random_reaction8'),
      t('common.Random_reaction9'),
      t('common.Random_reaction10'),
      t('common.Random_reaction11'),
    ],
    [
      t('common.Random_reaction12'),
      t('common.Random_reaction13'),
      t('common.Random_reaction14'),
      t('common.Random_reaction15'),
      t('common.Random_reaction16'),
      t('common.Random_reaction17'),
      t('common.Random_reaction18'),
      t('common.Random_reaction19'),
    ],
    [
      t('common.Random_reaction20'),
      t('common.Random_reaction21'),
      t('common.Random_reaction22'),
      t('common.Random_reaction23'),
      t('common.Random_reaction24'),
    ],
  ];
  const [rendText, setRendText] = useState('');

  useEffect(() => {
    setCorrectPer(0);
    if (answerData.correctCount > 0) {
      let tmpTotalCount = answerData.correctCount + answerData.incorrectCount;
      let tmpCorrectPer = (answerData.correctCount / tmpTotalCount) * 100;
      setCorrectPer(tmpCorrectPer);
    }
  }, [answerData.correctCount, answerData.incorrectCount]);

  useEffect(() => {
    if (correctPer >= 0 && correctPer <= 10) {
      setCorrectIcon(lottieFace05);
    } else if (correctPer >= 11 && correctPer <= 40) {
      setCorrectIcon(lottieFace04);
    } else if (correctPer >= 41 && correctPer <= 80) {
      setCorrectIcon(lottieFace03);
    } else if (correctPer >= 81) {
      setCorrectIcon(lottieFace02);
    }

    if (correctPer === 0) {
      let randIndex = parseInt(Math.random() * randTexts[0].length);
      setRendText(randTexts[0][randIndex]);
    } else if (correctPer >= 1 && correctPer <= 40) {
      let randIndex = parseInt(Math.random() * randTexts[1].length);
      setRendText(randTexts[1][randIndex]);
    } else if (correctPer >= 41 && correctPer <= 70) {
      let randIndex = parseInt(Math.random() * randTexts[2].length);
      setRendText(randTexts[2][randIndex]);
    } else if (correctPer >= 71) {
      let randIndex = parseInt(Math.random() * randTexts[3].length);
      setRendText(randTexts[3][randIndex]);
    }
  }, [correctPer]);

  return (
    <>
      <h3 className="paragraph-title">
        <Trans i18nKey="Speak_Edu.Quiz_Result_Title" values={{ n: unitQuizUnitNum }} />
      </h3>
      <div className="detail-content">
        <div className="quiz-result">
          <div className="total">
            <button
              type="button"
              className="btn-retry"
              onClick={(e) => {
                e.preventDefault();
                onQuizReload();
              }}
            >
              {t('common.Quiz_Again')}
            </button>
            <div className="result-face">
              {correctIcon && (
                <Lottie animationData={correctIcon} className="face" speed={1} play loop={false} />
              )}
            </div>
            <p className="good">&quot;{rendText}&quot;</p>
            <div className="score">
              <dl className="correct">
                <dt>{t('Speak_Edu.Answer')}</dt>

                <dd>{answerData.correctCount}</dd>
              </dl>
              <dl className="incorrect">
                <dt>{t('Speak_Edu.Wrong')}</dt>

                <dd>{answerData.incorrectCount}</dd>
              </dl>
            </div>
          </div>
          <div className="result-detail">
            <ul>
              {unitQuizList &&
                unitQuizList.map((unitQuizItem, unitQuizIndex) => {
                  return (
                    <li
                      className={cn({
                        correct: answerData.selectItems[unitQuizIndex].isCorrect,
                        incorrect: !answerData.selectItems[unitQuizIndex].isCorrect,
                      })}
                      key={'unitQuiz' + unitQuizIndex}
                    >
                      <Link
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          onStepChange(unitQuizIndex);
                        }}
                      >
                        <p>{answerData.selectItems[unitQuizIndex].answerText}</p>
                        {/*
                        <span className="bookmark active">즐겨찾기 등록됨</span>
                         */}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpeakUnitQuizSuccess;
