import React, { createContext, useContext, useReducer } from 'react';

const sessionStorage = window.sessionStorage;
const localStorage = window.localStorage;

const initialState = {
  isLogin: -1,
  user: {
    name: '',
    imageUrl: '',
    viewTrans: false,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN-CHECK':
      let userData = sessionStorage.getItem('loginUserData');
      if (userData) {
        userData = JSON.parse(userData);
        return {
          ...state,
          isLogin: 1,
          user: {
            ...userData,
          },
        };
      } else {
        return {
          ...state,
          isLogin: 0,
          user: null,
        };
      }
    case 'LOGIN':
    case 'MODIFY':
      console.log('action.user', action.user);
      sessionStorage.setItem('loginUserData', JSON.stringify(action.user));
      return {
        ...state,
        isLogin: 1,
        user: {
          ...action.user,
        },
      };
    case 'LOGOUT':
      sessionStorage.removeItem('loginUserData');
      return {
        ...state,
        isLogin: 0,
        user: null,
      };
    default:
      return state;
  }
};

const UserStateContext = createContext(null);
const UserDispatchContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

export const useUserState = () => {
  const state = useContext(UserStateContext);
  if (!state) throw new Error('Cannot find UserProvider');
  return state;
};

export const useUserDispatch = () => {
  const dispatch = useContext(UserDispatchContext);
  if (!dispatch) throw new Error('Cannot find UserProvider');
  return dispatch;
};
