import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Beforeunload, useBeforeunload } from 'react-beforeunload';

import * as Api from '../../library/Api';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import SpeakConfirm from './layout/SpeakConfirm';

import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import SpeakUnitQuiz01 from './layout/SpeakUnitQuiz01';
import SpeakUnitQuiz02 from './layout/SpeakUnitQuiz02';
import SpeakUnitQuiz03 from './layout/SpeakUnitQuiz03';
import SpeakUnitQuiz04 from './layout/SpeakUnitQuiz04';
import SpeakUnitQuiz05 from './layout/SpeakUnitQuiz05';
import SpeakUnitQuizSuccess from './layout/SpeakUnitQuizSuccess';

const SpeakUnitQuiz = ({ isLogin, user, t }) => {
  const navigate = useNavigate();

  const { courseId, classId, unitId } = useParams();

  const [reviewData, setReviewData] = useState({});

  const [courseData, setCourseData] = useState({ courseLevel: '' });
  const [isLevel1, setIsLevel1] = useState(false);
  const [isLevel2, setIsLevel2] = useState(false);
  const [isLevel3, setIsLevel3] = useState(false);

  const [answerData, setAnswerData] = useState({
    isSuccess: false, // 완료여부
    isReload: false, // 다시 풀기
    correctCount: 0, // 정답 수
    incorrectCount: 0, // 오답 수
    selectItems: [], // 선택 정답 정보  { isEnd : false, isCorrect : false or true, answerCheck : '', answerText : '', item : {} }
  });

  const [unitText, setUnitText] = useState({});

  const [unitQuizUnitNum, setUnitQuizUnitNum] = useState(0);
  const [unitQuizStep, setUnitQuizStep] = useState(0);
  const [unitQuizList, setUnitQuizList] = useState([]);
  const [unitQuizData, setUnitQuizData] = useState({ questionNmTra: '' });

  const [nextClass, setNextClass] = useState([]);

  useEffect(() => {
    if (isLogin === 0) {
      //alert('로그인 후 이용가능합니다.');
      navigate('/member/login');
    }
    if (isLogin === 1) {
      reviewLoad();
    }
  }, [isLogin]);

  useEffect(() => {
    //reviewLoad();

    return () => {};
  }, [user]);

  useEffect(() => {
    setIsLevel1(courseData.courseLevel.substr(0, 5) === 'BEGIN' ? true : false);
    setIsLevel2(courseData.courseLevel.substr(0, 5) === 'INTER' ? true : false);
    setIsLevel3(courseData.courseLevel.substr(0, 8) === 'BUSINESS' ? true : false);
  }, [courseData.courseLevel]);

  useEffect(() => {
    console.log('answerData.isReload');
    if (answerData.isReload === true) {
      quizLoad();
    }
    return () => {};
  }, [answerData.isReload]);

  useEffect(() => {
    setUnitQuizData(unitQuizList[unitQuizStep]);
  }, [unitQuizStep, unitQuizList]);

  useEffect(() => {
    let unitIndex = parseInt(unitId.slice(-3));
    setUnitQuizUnitNum(unitIndex);
  }, [unitId]);

  // 정답, 오답 수 가공
  useEffect(() => {
    console.log('answerData.selectItems useEffect');
    let correctCount = 0;
    let incorrectCount = 0;
    for (let i = 0; i < answerData.selectItems.length; i++) {
      if (answerData.selectItems[i].isEnd && answerData.selectItems[i].isCorrect) {
        correctCount++;
      } else if (answerData.selectItems[i].isEnd && !answerData.selectItems[i].isCorrect) {
        incorrectCount++;
      }
    }
    setAnswerData({ ...answerData, correctCount: correctCount, incorrectCount: incorrectCount });
  }, [answerData.selectItems]);

  const reviewLoad = () => {
    //initLoad();

    (async () => {
      try {
        // 코스 List
        const speakCourseDataResponse = await Api.speakCourseList({
          esntlId: user.esntlId,
          courseId: courseId,
        });
        if (
          speakCourseDataResponse.data.result.data &&
          speakCourseDataResponse.data.result.data.length > 0
        )
          setCourseData(speakCourseDataResponse.data.result.data[0]);

        // Unit Text
        const speakUnitTextResponse = await Api.speakUnitText({
          unitId: unitId,
        });
        if (speakUnitTextResponse.data.result.data)
          setUnitText(speakUnitTextResponse.data.result.data);
      } catch (error) {
        console.log('speakUnitQuizReviewResponse2 error', error);
      }

      try {
        const speakUnitQuizReviewResponse = await Api.speakUnitQuizReview({
          esntlId: user.esntlId,
          classId: classId,
        });

        if (speakUnitQuizReviewResponse.headers['content-type'] === 'application/json') {
          if (speakUnitQuizReviewResponse.data.result.data.quizText) {
            //setUnitQuizUnitNum(speakUnitQuizReviewResponse.data.result.data.unitNum);

            console.log('###');
            let quizTextJson = JSON.parse(speakUnitQuizReviewResponse.data.result.data.quizText);
            setUnitQuizList(quizTextJson);
            setUnitQuizStep(quizTextJson.length); // 결과보기

            console.log('quizTextJson', quizTextJson);
            let correctCount = 0;
            let incorrectCount = 0;
            let selectItems = [];
            for (let i = 0; i < quizTextJson.length; i++) {
              if (quizTextJson[i].exampleAnswer === quizTextJson[i].selectedItem) correctCount++;
              else incorrectCount++;

              if (quizTextJson[i].questionNmTra === 'QUESTION_002') {
                let tmpSelectTexts = quizTextJson[i].selectedItem.split(' ');
                let tmpItems = [];

                for (let j = 0; j < tmpSelectTexts.length; j++) {
                  for (let jj = 0; jj < quizTextJson[i].exampleList.length; jj++) {
                    if (quizTextJson[i].exampleList[jj].exampleText === tmpSelectTexts[j]) {
                      tmpItems[j] = quizTextJson[i].exampleList[jj];
                    }
                  }
                }

                selectItems[i] = {
                  isEnd: true,
                  isCorrect: quizTextJson[i].selectedItem === quizTextJson[i].exampleAnswer,
                  answerCheck: quizTextJson[i].selectedItem,
                  answerText: quizTextJson[i].selectedItem,
                  item: tmpItems,
                };
              } else {
                for (let j = 0; j < quizTextJson[i].exampleList.length; j++) {
                  if (
                    quizTextJson[i].exampleList[j].exampleSequence === quizTextJson[i].selectedItem
                  ) {
                    selectItems[i] = {
                      isEnd: true,
                      isCorrect: quizTextJson[i].selectedItem === quizTextJson[i].exampleAnswer,
                      answerCheck: quizTextJson[i].exampleList[j].exampleSequence,
                      answerText: quizTextJson[i].exampleList[j].exampleText,
                      item: quizTextJson[i].exampleList[j],
                    };
                  }
                }
              }
            }
            setAnswerData({
              isSuccess: true, // 완료여부
              isReload: false, // 다시 풀기
              correctCount: correctCount, // 정답 수
              incorrectCount: incorrectCount, // 오답 수
              selectItems: selectItems, // 선택 정답 정보  { isEnd : false, isCorrect : false or true, answerCheck : '', answerText : '', item : {} }
            });
          }
        } else {
          quizLoad();
        }
      } catch (error) {
        quizLoad();
      }

      try {
        // 다음 학습 불러오기
        const speakNextClassListResponse = await Api.speakNextClassList({
          esntlId: user.esntlId,
          classId: classId,
        });
        console.log('speakNextClassListResponse', speakNextClassListResponse);
        if (speakNextClassListResponse.data.result.data) {
          setNextClass(speakNextClassListResponse.data.result.data);
        }
      } catch (error) {}
    })();
  };

  const quizLoad = () => {
    if (isLogin === 1) {
      (async () => {
        try {
          // 학습 기록하기.
          Api.speakUpdateRecentUnit({
            esntlId: user.esntlId,
            courseId: courseId,
            recentUnit: unitId,
          });
        } catch (error) {}

        try {
          const unitQuizListResponse = await Api.speakUnitQuiz({
            classId: classId,
          });
          if (unitQuizListResponse.data.result.data) {
            // 퀴즈 정보 저장
            setUnitQuizList(unitQuizListResponse.data.result.data);

            // 정답 정보 기초값
            setAnswerData({
              ...answerData,
              isReload: false,
              selectItems: unitQuizListResponse.data.result.data.map((item, index) => {
                return {
                  isEnd: false,
                  isCorrect: false,
                  answerCheck: '',
                  answerText: '',
                  item: {},
                };
              }),
            });
          }
        } catch (error) {}
      })();
    }
  };

  // 퀴즈 다시 풀기
  const quizReload = () => {
    setAnswerData({
      isSuccess: false, // 완료여부
      isReload: true, // 다시 풀기
      correctCount: 0, // 정답 수
      incorrectCount: 0, // 오답 수
      selectItems: [], // 선택 정답 정보  { isEnd : false, isCorrect : false or true, answerCheck : '', answerText : '', item : {} }
    });

    //setUnitText({});
    setUnitQuizStep(0);
    setUnitQuizList([]);
    setUnitQuizData({ questionNmTra: '' });
  };

  // 정답 정보 저장
  const answerAdd = (stepIndex, isAnswer, answerItem) => {
    setAnswerData({
      ...answerData,
      selectItems: answerData.selectItems.map((selectItem, selectIndex) => {
        if (selectIndex === stepIndex) {
          if (Array.isArray(answerItem)) {
            let selectText = '';
            for (let i = 0; i < answerItem.length; i++) {
              if (selectText !== '') selectText += ' ';
              selectText += answerItem[i].exampleText;
            }
            return {
              isEnd: true,
              isCorrect: isAnswer,
              answerCheck: selectText,
              answerText: selectText,
              item: answerItem,
            };
          } else {
            return {
              isEnd: true,
              isCorrect: isAnswer,
              answerCheck: answerItem.exampleSequence,
              answerText: answerItem.exampleText,
              item: answerItem,
            };
          }
        } else {
          return selectItem;
        }
      }),
    });
  };

  // 다음 문제
  const nextQuiz = () => {
    if (unitQuizStep + 1 >= unitQuizList.length) quizSuccess();

    setUnitQuizStep(unitQuizStep + 1);
  };

  const prevQuiz = () => {};

  // 특정 퀴즈로 이동
  const stepChange = (step) => {
    setUnitQuizStep(step);
  };

  const quizSuccess = () => {
    if (!answerData.isSuccess) {
      setAnswerData({ ...answerData, isSuccess: true });

      (async () => {
        try {
          const answerQuizList = unitQuizList.map((unizQuizItem, unizQuizIndex) => {
            return {
              ...unizQuizItem,
              selectedItem: answerData.selectItems[unizQuizIndex].answerCheck,
            };
          });

          const speakUnitQuizResultResponse = await Api.speakUnitQuizResult({
            esntlId: user.esntlId,
            classId: classId,
            quizText: JSON.stringify(answerQuizList),
            quizScore: answerData.correctCount,
          });
        } catch (error) {}
      })();
    }
  };

  const backPage = () => {
    if (answerData.isSuccess) {
      navigate('/speak/unit/' + courseId);
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <SpeakConfirm
              t={t}
              content={t('v1.SpeakQuiz.Alert_TextStop1')}
              content2={t('v1.SpeakQuiz.Alert_TextStop2')}
              onClose={onClose}
              onConfirm={() => {
                navigate('/speak/unit/' + courseId);
                //navigate(-1);
              }}
            />
          );
        },
      });
    }
  };

  return (
    <>
      <div
        className={cn('wrap', {
          wrapKo: t('languageCode') === 'ko',
          wrapEn: t('languageCode') === 'en',
          wrapId: t('languageCode') === 'id',
          wrapVi: t('languageCode') === 'vi',
        })}
      >
        <Header />

        <section className="sub-contents">
          <article
            className={cn('expression-area', {
              beginner: isLevel1,
              medium: isLevel2,
              expert: isLevel3,
            })}
          >
            <div className="inner">
              <Link
                to="#"
                className="btn-prev"
                onClick={(e) => {
                  e.preventDefault();
                  backPage();
                }}
              >
                {t('common.btn_before')}
              </Link>
              <h2>
                {t('languageCode') === 'ko' && unitText.unitTextKor}
                {t('languageCode') === 'en' && unitText.unitTextEn}
                {t('languageCode') === 'id' && unitText.unitTextIn}
                {t('languageCode') === 'vi' && unitText.unitTextViet}
              </h2>
            </div>
          </article>
          <div className="sub-inner">
            <article
              className={cn({
                'quiz-detail': unitQuizStep < unitQuizList.length,
                'expression-detail': unitQuizStep >= unitQuizList.length,
                'course-beginner': isLevel1,
                'course-medium': isLevel2,
                'course-expert': isLevel3,
              })}
            >
              {unitQuizStep < unitQuizList.length && (
                <div className="progress">
                  <div
                    className="bar"
                    style={{ width: ((unitQuizStep + 1) / unitQuizList.length) * 100 + '%' }}
                  >
                    <div className="num">
                      <div>
                        <b>{unitQuizStep + 1}</b>/<span>{unitQuizList.length}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/*unitQuizData && unitQuizData.questionNmTra*/}
              {/*unitQuizStep < unitQuizList.length && unitQuizData && unitQuizData.questionNmTra*/}
              {unitQuizStep < unitQuizList.length &&
                unitQuizData &&
                unitQuizData.questionNmTra === 'QUESTION_001' && (
                  <SpeakUnitQuiz01
                    t={t}
                    onNextStep={nextQuiz}
                    onStepChange={stepChange}
                    onAnswerAdd={answerAdd}
                    nowStep={unitQuizStep}
                    totalStep={unitQuizList.length}
                    quizData={unitQuizData}
                    answerData={answerData}
                  />
                )}
              {unitQuizStep < unitQuizList.length &&
                unitQuizData &&
                unitQuizData.questionNmTra === 'QUESTION_002' && (
                  <SpeakUnitQuiz02
                    t={t}
                    onNextStep={nextQuiz}
                    onStepChange={stepChange}
                    onAnswerAdd={answerAdd}
                    nowStep={unitQuizStep}
                    totalStep={unitQuizList.length}
                    quizData={unitQuizData}
                    answerData={answerData}
                  />
                )}
              {unitQuizStep < unitQuizList.length &&
                unitQuizData &&
                unitQuizData.questionNmTra === 'QUESTION_003' && (
                  <SpeakUnitQuiz03
                    t={t}
                    onNextStep={nextQuiz}
                    onStepChange={stepChange}
                    onAnswerAdd={answerAdd}
                    nowStep={unitQuizStep}
                    totalStep={unitQuizList.length}
                    quizData={unitQuizData}
                    answerData={answerData}
                  />
                )}
              {unitQuizStep < unitQuizList.length &&
                unitQuizData &&
                unitQuizData.questionNmTra === 'QUESTION_004' && (
                  <SpeakUnitQuiz04
                    t={t}
                    onNextStep={nextQuiz}
                    onStepChange={stepChange}
                    onAnswerAdd={answerAdd}
                    nowStep={unitQuizStep}
                    totalStep={unitQuizList.length}
                    quizData={unitQuizData}
                    answerData={answerData}
                  />
                )}
              {unitQuizStep < unitQuizList.length &&
                unitQuizData &&
                unitQuizData.questionNmTra === 'QUESTION_005' && (
                  <SpeakUnitQuiz05
                    t={t}
                    onNextStep={nextQuiz}
                    onStepChange={stepChange}
                    onAnswerAdd={answerAdd}
                    nowStep={unitQuizStep}
                    totalStep={unitQuizList.length}
                    quizData={unitQuizData}
                    answerData={answerData}
                  />
                )}
              {unitQuizStep > 0 && unitQuizStep >= unitQuizList.length && (
                <SpeakUnitQuizSuccess
                  t={t}
                  onQuizReload={quizReload}
                  onStepChange={stepChange}
                  unitQuizUnitNum={unitQuizUnitNum}
                  unitQuizList={unitQuizList}
                  answerData={answerData}
                  courseData={courseData}
                  courseLevel={{ isLevel1: isLevel1, isLevel2: isLevel2, isLevel3: isLevel3 }}
                  nextClass={nextClass}
                />
              )}
            </article>
            {unitQuizStep > 0 && unitQuizStep >= unitQuizList.length && (
              <article className="more-study">
                <div className="inner">
                  <h3>{t('Speak_Edu.Next_Edu_Title')}</h3>
                  <div className="link">
                    {nextClass.map((nextClassItem, index) => {
                      return (
                        <Link
                          to={
                            '/speak/unitProblem/' +
                            nextClassItem.courseId +
                            '/' +
                            nextClassItem.classId +
                            '/' +
                            nextClassItem.unitId +
                            '/0'
                          }
                        >
                          <span>{t('Speak_Edu.Next_Edu_Category')}</span>
                          <p>
                            {t('languageCode') === 'ko' && nextClassItem.classTextKor}
                            {t('languageCode') === 'en' && nextClassItem.classTextEn}
                            {t('languageCode') === 'id' && nextClassItem.classTextIn}
                            {t('languageCode') === 'vi' && nextClassItem.classTextViet}
                          </p>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </article>
            )}
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default SpeakUnitQuiz;
