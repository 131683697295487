import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import cn from 'classnames';

const SpeakUnitQuiz01 = ({
  t,
  onNextStep,
  onStepChange,
  onAnswerAdd,
  nowStep,
  totalStep,
  quizData,
  answerData,
}) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [isAnswer, setIsAnswer] = useState(false);

  const [selectQuiz, setSelectQuiz] = useState({});
  const [selectQuizIndex, setSelectQuizIndex] = useState(-1);

  useEffect(() => {
    console.log('SpeakUnitQuiz01', 'quizData reload');
    if (!answerData.isSuccess) {
      setIsSubmit(false);
      setIsAnswer(false);
      setSelectQuiz({});
      setSelectQuizIndex(-1);
    }
  }, [quizData]);

  useEffect(() => {
    console.log('SpeakUnitQuiz01', 'nswerData.isSuccess reload');
    if (answerData.isSuccess) {
      setIsSubmit(true);
      setIsAnswer(answerData.selectItems[nowStep].isCorrect);
      setSelectQuiz(answerData.selectItems[nowStep].item);
      setSelectQuizIndex(Number(answerData.selectItems[nowStep].answerCheck));
    }
  }, [answerData.isSuccess, nowStep]);

  const selectQuizSubmit = () => {
    if (selectQuizIndex !== -1) {
      if (!isSubmit) {
        setIsSubmit(true);
        if (selectQuizIndex === Number(quizData.exampleAnswer)) {
          setIsAnswer(true);
          onAnswerAdd(nowStep, true, selectQuiz);
        } else {
          onAnswerAdd(nowStep, false, selectQuiz);
        }
      } else {
        onNextStep();
      }
    }
  };
  return (
    <>
      <div className="detail-content">
        <div className="title-area">
          <h3 className="em">{quizData.questionNm}</h3>
          {t('languageCode') !== 'ko' && <p>{t('v1.SpeakQuiz.Text01')}</p>}
        </div>
        <div className="paragraph">
          <p>{quizData.questionAttributeText}</p>
        </div>
        <div className="quiz-list">
          <ul>
            {quizData.exampleList &&
              quizData.exampleList.map((exampleItem, exampleIndex) => {
                return (
                  <li key={'exampleItem' + exampleIndex}>
                    {/* button class = active, correct, incorrect */}
                    <button
                      type="button"
                      className={cn({
                        active: !isSubmit && selectQuizIndex === exampleIndex,
                        correct: isSubmit && Number(quizData.exampleAnswer) === exampleIndex,
                        incorrect:
                          isSubmit &&
                          selectQuizIndex === exampleIndex &&
                          Number(quizData.exampleAnswer) !== exampleIndex,
                      })}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!isSubmit) {
                          setSelectQuizIndex(exampleIndex);
                          setSelectQuiz(exampleItem);
                        }
                      }}
                    >
                      <span className="num">{exampleIndex + 1}</span>
                      <span className="txt">{exampleItem.exampleText}</span>
                    </button>
                  </li>
                );
              })}
          </ul>
        </div>
        {isSubmit && (
          <div className={cn('result', { correct: isAnswer }, { incorrect: !isAnswer })}>
            <div className="result-correct">
              <span>{t('Speak_Edu.Answer')}</span>
              {answerData.correctCount}개
            </div>
            <div className="result-incorrect">
              <span>{t('Speak_Edu.Wrong')}</span>
              {answerData.incorrectCount}개
            </div>
            <p className="result-txt">{isAnswer ? t('common.Quiz_Ans') : t('common.Quiz_Wrong')}</p>
          </div>
        )}
      </div>
      {!answerData.isSuccess && (
        <div className="btn-area">
          <div className="right">
            <Link
              to="#"
              className={cn('btn-default', { 'btn-next': isSubmit, 'btn-confirm': !isSubmit })}
              onClick={(e) => {
                e.preventDefault();
                selectQuizSubmit();
              }}
            >
              {isSubmit ? t('common.btn_next') : t('common.btn_Check')}
            </Link>
          </div>
        </div>
      )}
      {answerData.isSuccess && (
        <div className="btn-area">
          <div className="left">
            <Link
              to="#"
              className={'btn-default'}
              onClick={(e) => {
                e.preventDefault();
                onStepChange(totalStep);
              }}
            >
              퀴즈 결과
            </Link>
          </div>
          <div className="right">
            {nowStep > 0 && (
              <Link
                to="#"
                className={'btn-default btn-prev'}
                onClick={(e) => {
                  e.preventDefault();
                  onStepChange(nowStep - 1);
                }}
              >
                {t('common.btn_before')}
              </Link>
            )}
            &nbsp;
            <Link
              to="#"
              className={'btn-default btn-next'}
              onClick={(e) => {
                e.preventDefault();
                onStepChange(nowStep + 1);
              }}
            >
              {t('common.btn_next')}
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default SpeakUnitQuiz01;
