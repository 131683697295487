import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import * as Api from '../../library/Api';
import cn from 'classnames';

import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';

const MypagePasswordChange = ({ isLogin, user, t }) => {
  const [isNowPasswordView, setIsNowPasswordView] = useState(false);
  const [isNewPassword1View, setIsNewPassword1View] = useState(false);
  const [isNewPassword2View, setIsNewPassword2View] = useState(false);

  const [nowPasswordError, setNowPasswordError] = useState('');
  const [newPassword1Error, setNewPassword1Error] = useState('');
  const [newPassword2Error, setNewPassword2Error] = useState('');
  const [networkError, setNetworkError] = useState('');

  const nowPasswordRef = useRef();
  const newPassword1Ref = useRef();
  const newPassword2Ref = useRef();

  const [isSuccessNowPassword, setIsSuccessNowPassword] = useState(false);
  const [isSuccessNewPassword1, setIsSuccessNewPassword1] = useState(false);
  const [isSuccessNewPassword2, setIsSuccessNewPassword2] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);

  const onNowPasswordCheck = () => {
    setIsSuccessNowPassword(false);

    if (!nowPasswordRef.current.value) {
      setNowPasswordError(100);
      return false;
    }

    if (nowPasswordRef.current.value.length < 8) {
      setNowPasswordError(101);
      return false;
    }

    if (nowPasswordRef.current.value.length > 20) {
      setNowPasswordError(102);
      return false;
    }

    (async () => {
      try {
        const checkPrePwResponse = await Api.checkPrePw({
          id: user.id,
          password: nowPasswordRef.current.value,
        });
        setIsSuccessNowPassword(true);
      } catch (apiError) {
        setNowPasswordError(103);
        setIsSuccessNowPassword(false);
      }
    })();
  };

  const onNewPassword1Check = () => {
    setIsSuccessNewPassword1(false);
    setIsSuccessNewPassword2(false);

    if (newPassword1Ref.current.value === '') {
      setNewPassword1Error('');
      return false;
    }

    if (newPassword1Ref.current.value.length < 8) {
      setNewPassword1Error(201);
      return false;
    }

    if (newPassword1Ref.current.value.length > 20) {
      setNewPassword1Error(202);
      return false;
    }

    if (nowPasswordRef.current.value === newPassword1Ref.current.value) {
      setNewPassword1Error(203);
      return false;
    }

    const passwordRegex = /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#&$%^*+=-_~./]).{8,}$/;
    if (passwordRegex.test(newPassword1Ref.current.value) === false) {
      setNewPassword1Error(204);
      return false;
    }

    if (newPassword2Ref.current.value !== '') {
      onNewPassword2Check();
    }

    setNewPassword1Error('');
    setIsSuccessNewPassword1(true);
  };

  const onNewPassword2Check = () => {
    setIsSuccessNewPassword2(false);

    if (newPassword1Ref.current.value !== newPassword2Ref.current.value) {
      setNewPassword2Error(301);
      return false;
    }
    setNewPassword2Error('');
    setIsSuccessNewPassword2(true);
  };

  const onPasswordChange = () => {
    if (isSuccessNowPassword && isSuccessNewPassword1 && isSuccessNewPassword2) {
      (async () => {
        try {
          const changePwResponse = await Api.changePw({
            id: user.id,
            password: newPassword1Ref.current.value,
          });
          if (Number(changePwResponse.data.result.code) === 0) {
            setIsSuccess(true);
          } else {
            alert('Error - Password Change 011');
          }
        } catch (apiError) {
          alert('Error - Password Change 012');
        }
      })();
    }

    return false;
  };

  return (
    <>
      <div
        className={cn('wrap', {
          wrapKo: t('languageCode') === 'ko',
          wrapEn: t('languageCode') === 'en',
          wrapId: t('languageCode') === 'id',
          wrapVi: t('languageCode') === 'vi',
        })}
      >
        <Header />

        <section className="login-wrap">
          <h2>{t('v1.MypageAccount.Change_Title')}</h2>
          <div className="inner">
            <article className="login-form">
              <section className="form-section new-pw">
                <h3>{t('v1.MypageAccount.Change_LabelNowPassword')}</h3>
                <div className="input pw">
                  <input
                    type={isNowPasswordView ? 'text' : 'password'}
                    placeholder={t('v1.MypageAccount.Change_PlaceholderNowPassword')}
                    ref={nowPasswordRef}
                    onChange={onNowPasswordCheck}
                  />
                  <button
                    type="button"
                    className={cn('btn', { secret: isNowPasswordView })}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNowPasswordView(!isNowPasswordView);
                    }}
                  >
                    {t('v1.MypageAccount.Change_BtnPasswordView')}
                  </button>
                  {isSuccessNowPassword && (
                    <p className="able">{t('v1.MypageAccount.Change_SuccessNowPassword')}</p>
                  )}
                  {!isSuccessNowPassword && nowPasswordError !== '' && (
                    <p className="alert" style={{ padding: 0 }}>
                      !{' '}
                      {nowPasswordError === 100 && t('v1.MypageAccount.Change_ErrorNowPassword01')}
                      {nowPasswordError === 101 && t('v1.MypageAccount.Change_ErrorNowPassword02')}
                      {nowPasswordError === 102 && t('v1.MypageAccount.Change_ErrorNowPassword03')}
                      {nowPasswordError === 103 && t('v1.MypageAccount.Change_ErrorNowPassword04')}
                    </p>
                  )}
                </div>
              </section>

              <section className="form-section new-pw">
                <h3>{t('v1.MypageAccount.Change_LabelNewPassword')}</h3>
                <div className="input pw">
                  <input
                    type={isNewPassword1View ? 'text' : 'password'}
                    placeholder={t('v1.MypageAccount.Change_PlaceholderNewPassword1')}
                    ref={newPassword1Ref}
                    disabled={!isSuccessNowPassword}
                    onChange={onNewPassword1Check}
                  />
                  <button
                    type="button"
                    className={cn('btn', { secret: isNewPassword1View })}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNewPassword1View(!isNewPassword1View);
                    }}
                  >
                    {t('v1.MypageAccount.Change_BtnPasswordView')}
                  </button>
                </div>
                {isSuccessNewPassword1 && (
                  <p className="able" style={{ marginBottom: '12px' }}>
                    {t('v1.MypageAccount.Change_SuccessNewPassword1')}
                  </p>
                )}
                {!isSuccessNewPassword1 && newPassword1Error !== '' && (
                  <p className="alert">
                    ! {newPassword1Error === 201 && t('v1.MypageAccount.Change_ErrorNewPassword01')}
                    {newPassword1Error === 202 && t('v1.MypageAccount.Change_ErrorNewPassword02')}
                    {newPassword1Error === 203 && t('v1.MypageAccount.Change_ErrorNewPassword03')}
                    {newPassword1Error === 204 && t('v1.MypageAccount.Change_ErrorNewPassword04')}
                  </p>
                )}
                {!isSuccessNewPassword1 && newPassword1Error === '' && (
                  <ul className="guide">
                    <li>- {t('v1.MypageAccount.Change_TextNewPassword1')}</li>
                    <li>- {t('v1.MypageAccount.Change_TextNewPassword2')}</li>
                  </ul>
                )}

                <div className="input pw">
                  <input
                    type={isNewPassword2View ? 'text' : 'password'}
                    placeholder={t('v1.MypageAccount.Change_PlaceholderNewPassword2')}
                    ref={newPassword2Ref}
                    disabled={!isSuccessNowPassword || !isSuccessNewPassword1}
                    onChange={onNewPassword2Check}
                  />
                  <button
                    type="button"
                    className={cn('btn', { secret: isNewPassword2View })}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNewPassword2View(!isNewPassword2View);
                    }}
                  >
                    {t('v1.MypageAccount.Change_BtnPasswordView')}
                  </button>
                </div>
                {!isSuccessNewPassword2 && newPassword2Error !== '' && (
                  <p className="alert">
                    ! {newPassword2Error === 301 && t('v1.MypageAccount.Change_ErrorNewPassword05')}
                  </p>
                )}
                {isSuccessNewPassword2 && (
                  <p className="able" style={{ marginBottom: '12px' }}>
                    {t('v1.MypageAccount.Change_SuccessNewPassword2')}
                  </p>
                )}
                <button
                  type="button"
                  className="btn-login-full"
                  disabled={
                    !isSuccessNowPassword || !isSuccessNewPassword1 || !isSuccessNewPassword2
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    onPasswordChange();
                  }}
                >
                  {t('v1.MypageAccount.Change_BtnSubmit')}
                </button>
              </section>
            </article>
          </div>

          {isSuccess && (
            <div className="modal info-modal" style={{ display: 'block' }}>
              <div className="inner">
                <div className="modal-header">
                  <Link to="/mypage/account" className="btn-close">
                    {t('v1.Common.BtnClose')}
                  </Link>
                </div>
                <div className="modal-body">
                  <div className="change-pw">
                    <p>{t('v1.MypageAccount.ChangeSuccess_Text')}</p>
                  </div>
                </div>
                <div className="btn-area">
                  <Link to="/mypage/account" className="btn-default btn-primary">
                    {t('v1.MypageAccount.ChangeSuccess_Btn')}
                  </Link>
                </div>
              </div>
            </div>
          )}
        </section>

        <Footer />
      </div>
    </>
  );
};

export default MypagePasswordChange;
