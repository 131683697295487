import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cn from 'classnames';

const MypageLeftNav = ({ isLogin, user, t }) => {
  const navigate = useNavigate();
  const [profileImageUrl, setProfileImageUrl] = useState();

  useEffect(() => {
    if (user && isLogin === 1) {
      setProfileImageUrl(user.imageUrl);
    }
  }, [user]);

  useEffect(() => {
    if (isLogin === 0) {
      //alert('로그인 후 이용가능합니다.');
      navigate('/member/login');
    }
  }, [isLogin]);

  return (
    <>
      {isLogin === 1 && (
        <aside className="lnb">
          <div className="profile">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                navigate('/mypage');
              }}
            >
              {t('v1.MypageNav.LinkProfile')}
            </button>
            <div className="detail">
              <div className="pic">
                {profileImageUrl && profileImageUrl !== '' && (
                  <img src={profileImageUrl} alt={user.name} style={{ width: '100%' }} />
                )}

                {(!profileImageUrl || profileImageUrl === '') && (
                  <img src="/assets/images/my.svg" alt="" style={{ width: '100%' }} />
                )}
              </div>
              <div className="info">
                <span className="name">{user && user.name}&nbsp;</span>
                <span
                  className={cn('email', {
                    google: user.mberType === 'GOOGLE',
                    facebook: user.mberType === 'FACEBOOK',
                    apple: user.mberType === 'APPLE',
                  })}
                  style={{ fontSize: '14px' }}
                >
                  {user && user.id}
                </span>
              </div>
            </div>
          </div>

          <nav>
            <h3 className="blind">마이메뉴</h3>
            <ul>
              <li className="noti">
                <Link to="/mypage/noti">{t('v1.MypageNav.LinkNoti')}</Link>
              </li>
              <li className="coupon">
                <Link to="/mypage/coupon">{t('v1.MypageNav.LinkCoupon')}</Link>
              </li>
              <li className="purchase">
                <Link to="/mypage/purchase">{t('v1.MypageNav.LinkPurchase')}</Link>
              </li>
            </ul>
            <ul>
              <li className="setting">
                <Link to="/mypage/account">{t('v1.MypageNav.LinkAccount')}</Link>
              </li>
            </ul>
          </nav>
        </aside>
      )}
    </>
  );
};

export default MypageLeftNav;
