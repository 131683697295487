import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import TextPrivacyKo from './TextPrivacyKo';
import TextPrivacyEn from './TextPrivacyEn';
import TextProfileKo from './TextProfileKo';
import TextProfileEn from './TextProfileEn';
import TextTermsKo from './TextTermsKo';
import TextTermsEn from './TextTermsEn';

const JoinStep1 = ({ t, completeFormStep }) => {
  const navigate = useNavigate();
  const [allCheck, setAllCheck] = useState(false);
  const { watch, register, setValue, getValues } = useFormContext(); // retrieve all hook methods
  useEffect(() => {
    if (allCheck) {
      if (
        !getValues('isTerms1') ||
        !getValues('isTerms2') ||
        !getValues('isTerms3') ||
        !getValues('isTerms4') ||
        !getValues('isTerms5')
      ) {
        setAllCheck(false);
      }
    }
    console.log('watch', getValues('isTerms1'));
  }, [watch()]);

  return (
    <>
      <fieldset>
        <legend>{t('v1.MemberJoin.Step1_Title')}</legend>
        <div className={'row all'}>
          <div className="checkbox">
            <input
              type="checkbox"
              name="allCheck"
              id="allCheck"
              checked={allCheck}
              onChange={(e) => {
                setAllCheck(e.target.checked);
                setValue('isTerms1', e.target.checked);
                setValue('isTerms2', e.target.checked);
                setValue('isTerms3', e.target.checked);
                setValue('isTerms4', e.target.checked);
                setValue('isTerms5', e.target.checked);
              }}
            />
            <label htmlFor="allCheck">{t('v1.MemberJoin.Step1_LabelAllCheck')}</label>
          </div>
        </div>
        <div className="row">
          <div className="checkbox">
            <input
              type="checkbox"
              name="isTerms1"
              id="isTerms1"
              {...register('isTerms1', { required: true })}
            />
            <label htmlFor="isTerms1">{t('v1.MemberJoin.Step1_LabelTerms1')}</label>
          </div>
        </div>
        <div className="row">
          <div className="checkbox">
            <input
              type="checkbox"
              name="isTerms2"
              id="isTerms2"
              {...register('isTerms2', { required: true })}
            />
            <label htmlFor="isTerms2">{t('v1.MemberJoin.Step1_LabelTerms2')}</label>
          </div>
          <div className="clause">
            <div className="inner">
              {t('languageCode') === 'ko' && <TextTermsKo />}
              {t('languageCode') !== 'ko' && <TextTermsEn />}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="checkbox">
            <input
              type="checkbox"
              name="isTerms3"
              id="isTerms3"
              {...register('isTerms3', { required: true })}
            />
            <label htmlFor="isTerms3">{t('v1.MemberJoin.Step1_LabelTerms3')}</label>
          </div>
          <div className="clause">
            <div className="inner">
              {t('languageCode') === 'ko' && <TextPrivacyKo />}
              {t('languageCode') !== 'ko' && <TextPrivacyEn />}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="checkbox">
            <input type="checkbox" name="isTerms4" id="isTerms4" {...register('isTerms4')} />
            <label htmlFor="isTerms4">{t('v1.MemberJoin.Step1_LabelTerms4')}</label>
          </div>
          <div className="clause">
            <div className="inner">
              {t('languageCode') === 'ko' && <TextProfileKo />}
              {t('languageCode') !== 'ko' && <TextProfileEn />}
            </div>
          </div>
        </div>
        {/*
        <div className="row">
          <div className="checkbox">
            <input type="checkbox" name="isTerms5" id="isTerms5" {...register('isTerms5')} />
            <label htmlFor="isTerms5">{t('v1.MemberJoin.Step1_LabelTerms5')}</label>
          </div>
        </div>
         */}
        <p className="clause-info">* {t('v1.MemberJoin.Step1_Text1')}</p>

        <div className="btn-area">
          <button
            className="btn-cancel"
            onClick={(e) => {
              e.preventDefault();
              navigate('/member/login');
            }}
          >
            {t('v1.MemberJoin.Step1_BtnCancel')}
          </button>
          <button type="button" className="btn-login" onClick={completeFormStep}>
            {t('v1.MemberJoin.Step1_BtnOk')}
          </button>
        </div>
      </fieldset>
    </>
  );
};

export default JoinStep1;
