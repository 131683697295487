import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

import cn from 'classnames';

import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Navigation, Pagination, Autoplay } from 'swiper';
import '../assets/swiper-bundle.min.css';

const MainGuest = ({ isLogin, user, t }) => {
  const swiperPrevRef = useRef(null);
  const swiperNextRef = useRef(null);
  const swiperPaginationRef = useRef(null);
  const swiperPaginationTestRef = useRef(null);

  const swiperIndex = useRef(0);

  const swiperIndex1Video = useRef(null);
  const swiperIndex1VideoIsPlay = useRef(false);

  const [phoneSwiperIndex, setPhoneSwiperIndex] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', scroll, { capture: true }); // 스크롤 이벤트 등록
    objMove();
    return () => {
      window.removeEventListener('scroll', scroll); // 스크롤 이벤트 제거
    };
  }, []);

  const scroll = (e) => {
    if (swiperIndex.current === 1) {
      if (e.currentTarget.scrollY < 500) {
        if (swiperIndex1VideoIsPlay.current) {
          swiperIndex1Video.current.play();
          swiperIndex1Video.current.muted = true;
          swiperIndex1VideoIsPlay.current = true;
        }
      } else {
        if (swiperIndex1VideoIsPlay.current) {
          swiperIndex1Video.current.pause();
          swiperIndex1Video.current.muted = true;
          swiperIndex1VideoIsPlay.current = false;
        }
      }
    }
  };

  const objMove = () => {
    const objs = document.querySelectorAll('.swiper-slide-active .obj > div');
    const total = objs.length;
    let num = 1;
    let timer = setInterval(function () {
      if (num > total) {
        clearInterval(timer);
      } else {
        move();
      }
    }, 150);
    function move() {
      document.querySelector(`.swiper-slide-active .obj .obj0${num}`).classList.add('move');
      num++;
    }
  };

  return (
    <div
      className={cn('wrap', {
        wrapKo: t('languageCode') === 'ko',
        wrapEn: t('languageCode') === 'en',
        wrapId: t('languageCode') === 'id',
        wrapVi: t('languageCode') === 'vi',
      })}
    >
      <Header />
      <section className="default-contents">
        <article className="default-visual">
          <div className="visual-slide">
            <Swiper
              className="swiper-wrapper first"
              modules={[Autoplay, Navigation, Pagination, EffectFade]}
              effect="fade"
              loop="true"
              /*
              navigation={
                {
                  //nextEl: swiperNextRef.current,
                  //prevEl: swiperPrevRef.current,
                }
              }
              */
              autoplay={{ delay: 10000 }}
              pagination={{ clickable: true, el: swiperPaginationRef.current }}
              onBeforeInit={(swiper) => {
                //swiper.params.navigation.prevEl = swiperPrevRef.current;
                //swiper.params.navigation.nextEl = swiperNextRef.current;
                swiper.params.pagination.el = swiperPaginationRef.current;
              }}
              onSlideChangeTransitionStart={(swiper) => {
                swiperIndex.current = swiper.realIndex;
                objMove();
                if (swiper.realIndex === 1) {
                  swiperIndex1Video.current.play();
                  swiperIndex1Video.current.muted = true;
                  swiperIndex1VideoIsPlay.current = true;
                } else {
                  swiperIndex1Video.current.pause();
                  swiperIndex1Video.current.muted = true;
                  swiperIndex1VideoIsPlay.current = false;
                }
              }}
            >
              <SwiperSlide className="swiper-slide first-slide">
                <div className="inner">
                  <h2>
                    <Trans i18nKey="Service_home.Service_Visual1_Title">
                      <span />
                    </Trans>
                  </h2>
                  <div className="txt-area">
                    <div>
                      <p>
                        <Trans i18nKey="Service_home.Service_Visual1_Comments1" />
                      </p>
                      <p>
                        <Trans i18nKey="Service_home.Service_Visual1_Comments2" />
                      </p>
                    </div>
                    <p className="result">
                      <span>
                        <Trans i18nKey="Service_home.Service_Visual1_Comments3" />
                      </span>
                    </p>
                  </div>
                  <div className={cn('phone')}>
                    <div className="content">
                      <div className="phone-slide">
                        <Swiper
                          modules={[Autoplay, Navigation, Pagination]}
                          className="swiper-wrapper second"
                          loop="true"
                          autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                          }}
                          onSlideChangeTransitionStart={(swiper) => {
                            /*
                            console.log(
                              'onSlideChangeTransitionStart',
                              swiper.realIndex,
                              swiper.snapIndex,
                              swiper.progress,
                              swiperPaginationTestRef.current,
                              swiper,
                            );
                            */
                            setPhoneSwiperIndex(swiper.realIndex);
                            //swiper.stopAutoplay();
                          }}
                          onRealIndexChange={(swiper) => {
                            console.log('onRealIndexChange', swiper.realIndex);
                          }}
                          onActiveIndexChange={(swiper) => {
                            console.log('onActiveIndexChange', swiper.realIndex);
                          }}
                          pagination={{
                            clickable: false,
                            el: swiperPaginationTestRef.current,
                            bulletClass: 'phone-nav-image',
                            bulletActiveClass: 'active',
                            renderBullet: (index, className) => {
                              return (
                                '<img src="/assets/images/footer_phone0' +
                                (index + 1) +
                                '.jpg" alt="" class="' +
                                className +
                                '"/>'
                              );
                            },
                          }}
                          onBeforeInit={(swiper) => {
                            //swiper.params.navigation.prevEl = swiperPrevRef.current;
                            //swiper.params.navigation.nextEl = swiperNextRef.current;
                            swiper.params.pagination.el = swiperPaginationTestRef.current;
                          }}
                        >
                          <SwiperSlide className="swiper-slide item">
                            {t('languageCode') === 'ko' && (
                              <img src="/assets/images/phone01_kr.jpg" />
                            )}
                            {t('languageCode') === 'en' && (
                              <img src="/assets/images/phone01_en.jpg" />
                            )}
                            {t('languageCode') === 'id' && (
                              <img src="/assets/images/phone01_en.jpg" />
                            )}
                            {t('languageCode') === 'vi' && (
                              <img src="/assets/images/phone01_vn.jpg" />
                            )}
                          </SwiperSlide>
                          <SwiperSlide className="swiper-slide item">
                            {t('languageCode') === 'ko' && (
                              <img src="/assets/images/phone02_kr.jpg" />
                            )}
                            {t('languageCode') === 'en' && (
                              <img src="/assets/images/phone02_en.jpg" />
                            )}
                            {t('languageCode') === 'id' && (
                              <img src="/assets/images/phone02_en.jpg" />
                            )}
                            {t('languageCode') === 'vi' && (
                              <img src="/assets/images/phone02_vn.jpg" />
                            )}
                          </SwiperSlide>
                          <SwiperSlide className="swiper-slide item">
                            {t('languageCode') === 'ko' && (
                              <img src="/assets/images/phone03_kr.jpg" />
                            )}
                            {t('languageCode') === 'en' && (
                              <img src="/assets/images/phone03_en.jpg" />
                            )}
                            {t('languageCode') === 'id' && (
                              <img src="/assets/images/phone03_en.jpg" />
                            )}
                            {t('languageCode') === 'vi' && (
                              <img src="/assets/images/phone03_vn.jpg" />
                            )}
                          </SwiperSlide>
                          <SwiperSlide className="swiper-slide item">
                            {t('languageCode') === 'ko' && (
                              <img src="/assets/images/phone04_kr.jpg" />
                            )}
                            {t('languageCode') === 'en' && (
                              <img src="/assets/images/phone04_en.jpg" />
                            )}
                            {t('languageCode') === 'id' && (
                              <img src="/assets/images/phone04_en.jpg" />
                            )}
                            {t('languageCode') === 'vi' && (
                              <img src="/assets/images/phone04_vn.jpg" />
                            )}
                          </SwiperSlide>
                          <SwiperSlide className="swiper-slide item">
                            {t('languageCode') === 'ko' && (
                              <img src="/assets/images/phone05_kr.jpg" />
                            )}
                            {t('languageCode') === 'en' && (
                              <img src="/assets/images/phone05_en.jpg" />
                            )}
                            {t('languageCode') === 'id' && (
                              <img src="/assets/images/phone05_en.jpg" />
                            )}
                            {t('languageCode') === 'vi' && (
                              <img src="/assets/images/phone05_vn.jpg" style={{ marginTop: -1 }} />
                            )}
                          </SwiperSlide>
                          <div
                            className="swiper-pagination"
                            style={{ bottom: 'initial', top: 405 }}
                            ref={swiperPaginationTestRef}
                          ></div>
                        </Swiper>
                      </div>
                      {/*
                      <div className="phone-nav">
                        <img
                          src="/assets/images/footer_phone01.jpg"
                          alt=""
                          className={cn({ active: phoneSwiperIndex === 0 })}
                        />
                        <img
                          src="/assets/images/footer_phone02.jpg"
                          alt=""
                          className={cn({ active: phoneSwiperIndex === 1 })}
                        />
                        <img
                          src="/assets/images/footer_phone03.jpg"
                          alt=""
                          className={cn({ active: phoneSwiperIndex === 2 })}
                        />
                        <img
                          src="/assets/images/footer_phone04.jpg"
                          alt=""
                          className={cn({ active: phoneSwiperIndex === 3 })}
                        />
                        <img
                          src="/assets/images/footer_phone05.jpg"
                          alt=""
                          className={cn({ active: phoneSwiperIndex === 4 })}
                        />
                      </div>
                            */}
                    </div>
                  </div>
                </div>
                <div className="obj">
                  <div className="obj01"></div>
                  <div className="obj02"></div>
                  <div className="obj03"></div>
                  <div className="obj04"></div>
                  {/* AIKORAPP-1288 이슈로 style 처리 */}
                  <div
                    className="obj05"
                    style={{
                      position: 'relative',
                      margin: '0 auto',
                      top: '-490px',
                      left: '-228px',
                    }}
                  ></div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide second-slide">
                <div className="inner">
                  {/*
                  <p className="txt">
                    <Trans i18nKey="Service_home.Service_Visual2_SubTitle">
                      <span />
                    </Trans>
                  </p>
                        */}
                  <h2>
                    <Trans i18nKey="Service_home.Service_Visual2_Title">
                      <span />
                    </Trans>
                  </h2>
                  {/*
                  <Link to="#" className="btn-begin">
                    <Trans i18nKey="Service_home.Service_Visual2_Button" />
                  </Link>
                   */}
                  <div className="vod">
                    <div className="vod-inner">
                      <video
                        poster={process.env.PUBLIC_URL + '/assets/images/mainGuestVideo.png'}
                        ref={swiperIndex1Video}
                        controls={true}
                        muted={true}
                        autoPlay={true}
                        /*
                        width="720"
                        height="403"
                        */
                      >
                        <source
                          src={process.env.PUBLIC_URL + '/videos/mainGuestVideo.mp4'}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  </div>
                </div>
                <div className="obj">
                  <div className="obj01"></div>
                  <div className="obj02"></div>
                  <div className="obj03"></div>
                  <div className="obj04"></div>
                  {/* AIKORAPP-1288 이슈로 style 처리 */}
                  <div
                    className="obj05"
                    style={{
                      position: 'relative',
                      margin: '0 auto',
                      top: '-255px',
                      left: '-228px',
                    }}
                  ></div>
                </div>
              </SwiperSlide>

              {/*
              <div className="slide-btn">
                <button className="btn-prev" ref={swiperPrevRef}>
                  {t('common.btn_before')}
                </button>
                <button className="btn-next" ref={swiperNextRef}>
                  {t('common.btn_next')}
                </button>
              </div>
                  */}
            </Swiper>
            <div className="pagination" ref={swiperPaginationRef}></div>
          </div>
        </article>
        <article className="most-service">
          <div className="inner">
            <h2 className="article-title">
              <Trans i18nKey="Service_home.Service_MajorServices_Title" />
            </h2>
            <div className="service-list">
              <dl>
                <dt>
                  <Trans i18nKey="Service_home.Service_MajorService_Ment1_Title" />
                </dt>
                <dd>
                  <Trans i18nKey="Service_home.Service_MajorServices_Comments1" />
                </dd>
              </dl>
              <dl>
                <dt>
                  <Trans i18nKey="Service_home.Service_MajorService_Ment2_Title" />
                </dt>
                <dd>
                  <Trans i18nKey="Service_home.Service_MajorServices_Comments2" />
                </dd>
              </dl>
              <dl>
                <dt>
                  <Trans i18nKey="Service_home.Service_MajorService_Ment3_Title" />
                </dt>
                <dd>
                  <Trans i18nKey="Service_home.Service_MajorServices_Comments3" />
                </dd>
              </dl>
            </div>
          </div>
        </article>
        <article className="ai-system">
          <div className="inner">
            <h2 className="article-title">
              <Trans i18nKey="Service_home.Service_LearningSystem_Title" />
            </h2>
            <div className="system-list">
              <dl>
                <dt>
                  <Trans i18nKey="Service_home.Service_LearningSystem_Ment1_Title" />
                </dt>
                <dd>
                  <Trans i18nKey="Service_home.Service_LearningSystem_Comments1" />
                </dd>
              </dl>
              <dl>
                <dt>
                  <Trans i18nKey="Service_home.Service_LearningSystem_Ment2_Title" />
                </dt>
                <dd>
                  <Trans i18nKey="Service_home.Service_LearningSystem_Comments2" />
                </dd>
              </dl>

              <dl>
                <dt>
                  <Trans i18nKey="Service_home.Service_LearningSystem_Ment3_Title" />
                </dt>
                <dd>
                  <Trans i18nKey="Service_home.Service_LearningSystem_Comments3" />
                </dd>
              </dl>
            </div>
          </div>
        </article>
        <article className="my-note">
          <div className="inner">
            <h2 className="article-title">
              <Trans i18nKey="Service_home.Service_My Note_Title" />
            </h2>
            <div className="note-list">
              <dl>
                <dt>
                  <Trans i18nKey="Service_home.Service_My Note_Ment1_Title" />
                </dt>
                <dd>
                  <Trans i18nKey="Service_home.Service_My Note_Comments1" />
                </dd>
              </dl>
              <dl>
                <dt>
                  <Trans i18nKey="Service_home.Service_My Note_Ment2_Title" />
                </dt>
                <dd>
                  <Trans i18nKey="Service_home.Service_My Note_Comments2" />
                </dd>
              </dl>
              <dl>
                <dt>
                  <Trans i18nKey="Service_home.Service_My Note_Ment3_Title" />
                </dt>
                <dd>
                  <Trans i18nKey="Service_home.Service_My Note_Comments3" />
                </dd>
              </dl>
            </div>
          </div>
        </article>
        <article className="app-download">
          <div className="inner">
            <h2 className="article-title">
              <Trans i18nKey="Service_home.Service_Download_Title" />
            </h2>
            <p>
              <Trans i18nKey="Service_home.Service_Download_Comment" />
            </p>
            <div className="btn">
              <a href={t('appGoogleDownloadLink')} className="google" target="_blank">
                Google play
              </a>
              <a href={t('appAppleDownloadLink')} className="apple" target="_blank">
                App Store
              </a>
            </div>
          </div>
        </article>
      </section>
      <Footer />
    </div>
  );
};

export default MainGuest;
