import './assets/scss/ui.scss';
import { useEffect, useLayoutEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { useUserState, useUserDispatch } from './context/UserContext';
import ContextProvider from './ContextProvider';
import i18n from './library/i18n';
import { useTranslation } from 'react-i18next';

import NotFoundPage from './pages/NotFoundPage';

import Main from './pages/Main';
import MainGuest from './pages/MainGuest';
import MainMember from './pages/MainMember';

import Login from './pages/member/Login';
import Join from './pages/member/Join';
import JoinSuccess from './pages/member/JoinSuccess';

import MypageProfile from './pages/mypage/MypageProfile';
import MypageNoti from './pages/mypage/MypageNoti';
import MypageCoupon from './pages/mypage/MypageCoupon';
import MypagePurchase from './pages/mypage/MypagePurchase';
import MypageAccount from './pages/mypage/MypageAccount';

import SpeakMain from './pages/speak/SpeakMain';
import SpeakUnit from './pages/speak/SpeakUnit';
import SpeakUnitProblem from './pages/speak/SpeakUnitProblem';
import SpeakUnitQuiz from './pages/speak/SpeakUnitQuiz';

import TopikMain from './pages/topik/TopikMain';

import LoginGoogle from './pages/sample/LoginGoogle';
import LoginApple from './pages/sample/LoginApple';
import LoginFacebook from './pages/sample/LoginFacebook';
import TestAudioRecord from './pages/sample/TestAudioRecord';

import PasswordChange from './pages/member/PasswordChange';

import Privacy from './pages/etc/Privacy';
import Terms from './pages/etc/Terms';
import ReportMain from './pages/report/ReportMain';
import TopikHistory from './pages/topik/TopikHistory';
import TopikView from './pages/topik/TopikView';
import TopikView2 from './pages/topik/TopikView2';
import TopikResult from './pages/topik/TopikResult';

import AppLanding from './pages/etc/AppLanding';
import MypagePasswordChange from './pages/mypage/MypagePasswordChange';

let currentPath = '';

function App() {
  let sessionStorage = window.sessionStorage;
  let localStorage = window.localStorage;

  const { t } = useTranslation();
  const { isLogin, user } = useUserState();
  const userDispatch = useUserDispatch();

  let location = useLocation();

  useEffect(() => {
    // 동일한 페이지 호출시 새로고침
    if (currentPath === location.pathname) window.location.reload();

    currentPath = location.pathname;
  }, [location]);

  useLayoutEffect(() => {
    console.log('useLayoutEffect');
    // 사용자 언어지정
    let language = localStorage.getItem('language');
    if (language) i18n.changeLanguage(language);

    userDispatch({ type: 'LOGIN-CHECK' });
  }, []);

  useEffect(() => {
    console.log('useEffect');
  }, []);

  return (
    <>
      <ContextProvider>
        <Routes>
          <Route path="/" element={<MainGuest isLogin={isLogin} user={user} t={t} />} />
          <Route path="/main" element={<MainMember isLogin={isLogin} user={user} t={t} />} />
          <Route path="/privacy" element={<Privacy isLogin={isLogin} user={user} t={t} />} />
          <Route path="/terms" element={<Terms isLogin={isLogin} user={user} t={t} />} />
          <Route path="/appLanding" element={<AppLanding isLogin={isLogin} user={user} t={t} />} />

          <Route path="/member/login" element={<Login t={t} />} />
          <Route path="/member/join" element={<Join t={t} />} />
          <Route path="/member/joinSuccess" element={<JoinSuccess t={t} />} />
          <Route
            path="/member/passwordChange"
            element={<PasswordChange isLogin={isLogin} user={user} t={t} />}
          />

          <Route path="/mypage" element={<MypageProfile isLogin={isLogin} user={user} t={t} />} />
          <Route path="/mypage/noti" element={<MypageNoti isLogin={isLogin} user={user} t={t} />} />
          <Route
            path="/mypage/coupon"
            element={<MypageCoupon isLogin={isLogin} user={user} t={t} />}
          />
          <Route
            path="/mypage/purchase"
            element={<MypagePurchase isLogin={isLogin} user={user} t={t} />}
          />
          <Route
            path="/mypage/account"
            element={<MypageAccount isLogin={isLogin} user={user} t={t} />}
          />
          <Route
            path="/mypage/passwordChange"
            element={<MypagePasswordChange isLogin={isLogin} user={user} t={t} />}
          />

          <Route path="/speak" element={<SpeakMain isLogin={isLogin} user={user} t={t} />} />
          <Route
            path="/speak/unit/:courseId"
            element={<SpeakUnit isLogin={isLogin} user={user} t={t} />}
          />
          <Route
            path="/speak/unitProblem/:courseId/:classId/:unitId/:tabType"
            element={<SpeakUnitProblem isLogin={isLogin} user={user} t={t} />}
          />
          <Route
            path="/speak/unitQuiz/:courseId/:classId/:unitId"
            element={<SpeakUnitQuiz isLogin={isLogin} user={user} t={t} />}
          />

          <Route path="/topik" element={<TopikMain isLogin={isLogin} user={user} t={t} />} />
          <Route
            path="/topik/history"
            element={<TopikHistory isLogin={isLogin} user={user} t={t} />}
          />
          <Route
            path="/topik/view/:rwdKind"
            element={<TopikView2 isLogin={isLogin} user={user} t={t} />}
          />
          <Route
            path="/topik/result/:topikId"
            element={<TopikResult isLogin={isLogin} user={user} t={t} />}
          />

          <Route path="/report" element={<ReportMain isLogin={isLogin} user={user} t={t} />} />

          <Route path="/sample/loginGoogle" element={<LoginGoogle />} />
          <Route path="/sample/loginApple" element={<LoginApple />} />
          <Route path="/sample/loginFacebook" element={<LoginFacebook />} />
          <Route path="/sample/testAudioRecord" element={<TestAudioRecord />} />

          <Route path="*" element={<NotFoundPage t={t} />} />
        </Routes>
      </ContextProvider>
    </>
  );
}

export default App;
