import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
  render() {
    return (
      <>
        <footer className="footer">
          <div className="inner">
            <div className="top">
              <div>
                <Link to="/">Hancom Genie K</Link>
                <a href="https://www.hancomgroup.com/en/main#thirdPage" target="_blank">
                  HANCOM GROUP
                </a>
              </div>
              <div>
                <a
                  href="https://www.youtube.com/playlist?list=PLrlym21EbRhc0vwR9eRbbWP62im6pY_-u"
                  className="sns youtube"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Youtube
                </a>
              </div>
            </div>
            <div className="bottom">
              <div>
                <Link to="/privacy">Privacy Policy</Link>
                <Link to="/terms">Terms of Service</Link>
                {/* <Link to="#">Cookies Settings</Link> */}
              </div>
              <p className="copyright">2022 Hancom. All rights reserved.</p>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
