import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import ReactPlayer from 'react-player/file';
import AudioAnalyser from '../../../library/AudioRecorder';

import { useUserState, useUserDispatch } from '../../../context/UserContext';

import * as Api from '../../../library/Api';

import Lottie from 'react-lottie-player';
import lottieSpinner from '../../../assets/spinner.json';
import SpeakIconStart100 from './SpeakIconStart100';

const SpeakUnitProblemStep2Modal = ({
  t,
  user,
  classStepList,
  onSetClassStepList,
  onPrev,
  onNext,
  onModalHidden,
  isPrev,
  isNext,
  modalData,
  modalIndex,
}) => {
  const localStorage = window.localStorage;

  const { courseId, classId, unitId } = useParams();

  // State 1 문장을 들어보세요.
  // State 2 마이크를 클릭하고 문장을 읽어보세요.
  // State 3 발화가 끝나면 버튼을 눌러주세요.
  // State 4 발음을 분석중입니다.
  // State 5 발음 분석 성공
  // State -1 다시 시도해 주세요.
  // State -2 발음 분석 실패
  // State 100 완료
  const [speakState, setSpeakState] = useState(0);

  //const [isAnalysisIng, setIsAnalysisIng] = useState(false); // 평가중
  //const [isAnalysisError, setIsAnalysisError] = useState(false); // 오류

  const [isAudioPlay, setIsAudioPlay] = useState(false);
  //const [isAudioPlayCheck, setIsAudioPlayCheck] = useState(false);
  const [isMicRecPlay, setIsMicRecPlay] = useState(false);
  const [isMicRec, setIsMicRec] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const reactPlayerRef = useRef();
  const reactMicRecPlayerRef = useRef();

  const [stat, setStat] = useState();
  const [audiotype, setAudiotype] = useState('audio/wav');
  const [audiosrc, setAudiosrc] = useState();

  const [pronScore, setPronScore] = useState();

  const randTexts = [
    [
      t('common.Random_reaction1'),
      t('common.Random_reaction2'),
      t('common.Random_reaction3'),
      t('common.Random_reaction4'),
      t('common.Random_reaction5'),
    ],
    [
      t('common.Random_reaction6'),
      t('common.Random_reaction7'),
      t('common.Random_reaction8'),
      t('common.Random_reaction9'),
      t('common.Random_reaction10'),
      t('common.Random_reaction11'),
    ],
    [
      t('common.Random_reaction12'),
      t('common.Random_reaction13'),
      t('common.Random_reaction14'),
      t('common.Random_reaction15'),
      t('common.Random_reaction16'),
      t('common.Random_reaction17'),
      t('common.Random_reaction18'),
      t('common.Random_reaction19'),
    ],
    [
      t('common.Random_reaction20'),
      t('common.Random_reaction21'),
      t('common.Random_reaction22'),
      t('common.Random_reaction23'),
      t('common.Random_reaction24'),
    ],
  ];
  const [rendText, setRendText] = useState('');

  useEffect(() => {}, []);

  useEffect(() => {
    if (modalData.pronScore) {
      setSpeakState(100);
      setPronScore(modalData.pronScore);
      //setIsAudioPlayCheck(true);
    } else {
      setSpeakState(1);
      setIsAudioPlay(true);
    }
  }, [modalData]);

  useEffect(() => {
    // 점수별 메세지 가공
    if (pronScore === 0) {
      let randIndex = parseInt(Math.random() * randTexts[0].length);
      setRendText(randTexts[0][randIndex]);
    } else if (pronScore >= 1 && pronScore <= 40) {
      let randIndex = parseInt(Math.random() * randTexts[1].length);
      setRendText(randTexts[1][randIndex]);
    } else if (pronScore >= 41 && pronScore <= 70) {
      let randIndex = parseInt(Math.random() * randTexts[2].length);
      setRendText(randTexts[2][randIndex]);
    } else if (pronScore >= 71) {
      let randIndex = parseInt(Math.random() * randTexts[3].length);
      setRendText(randTexts[3][randIndex]);
    }
  }, [pronScore]);

  function controlAudio(status) {
    if (status === 'recording') setIsMicRec(true);
    else if (status === 'inactive') setIsMicRec(false);

    setStat(status);
  }
  const reset = () => {
    setSpeakState(0);
    setIsAudioPlay(false);
    //setIsAudioPlayCheck(false);
    setIsMicRec(false);
    setStat();
    setAudiotype('audio/wav');
    setAudiosrc();
    setPronScore();
  };

  const audioProps = {
    className: 'audio-analyser',
    status: stat,
    audioType: audiotype,
    // audioOptions: {sampleRate: 30000},
    audioSrc: audiosrc,
    timeslice: 1000, // timeslice（https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/start#Parameters）
    startCallback: (e) => {
      setSpeakState(3);
      //setIsAnalysisIng(false);
      //setIsAnalysisError(false);
    },
    pauseCallback: (e) => {},
    stopCallback: (e) => {
      if (isClose === true) {
        onModalHidden();
      } else {
        setSpeakState(4);
        setAudiosrc(window.URL.createObjectURL(e));
        audioUpload(e);
      }
    },
    onRecordCallback: (e) => {},
    errorCallback: (err) => {},
  };

  const audioUpload = (blob) => {
    setSpeakState(4);
    const soundFile = new File([blob], 'micRec' + new Date().getTime() + '.wav', {
      lastModified: new Date().getTime(),
      type: 'audio/wav',
    });

    (async () => {
      try {
        const uploadVoiceResponse = await Api.uploadVoice(soundFile);
        const voiceAnalysisResponse = await Api.voiceAnalysis({
          esntlId: user.esntlId,
          classStepId: modalData.classStepId,
          sentenceText: modalData.classStepTextKor,
          userId: user.esntlId,
          audioLocation: uploadVoiceResponse.data,
          dialogudId: 'role_play',
        });

        if (!voiceAnalysisResponse.data || typeof voiceAnalysisResponse.data === 'string') {
          setSpeakState(-2);
          setTimeout(() => {
            setSpeakState(2);
          }, 3000);
        } else {
          if (voiceAnalysisResponse.data.result.wid.body.PronClinic.Lesson.Item.eval_score) {
            setSpeakState(100);
            onSetClassStepList(
              classStepList.map((classStepData, classStepIndex) => {
                return classStepIndex === modalIndex
                  ? {
                      ...classStepData,
                      pronScore:
                        voiceAnalysisResponse.data.result.wid.body.PronClinic.Lesson.Item
                          .eval_score,
                    }
                  : classStepData;
              }),
            );
            setPronScore(
              voiceAnalysisResponse.data.result.wid.body.PronClinic.Lesson.Item.eval_score,
            );
          }
        }
      } catch (error) {
        setSpeakState(-1);
        setTimeout(() => {
          setSpeakState(2);
        }, 3000);
        console.log('uploadVoiceResponse error', error);
      }
    })();
  };

  return (
    <>
      <ReactPlayer
        ref={reactPlayerRef}
        className="react-player"
        url={modalData.classStepTtsUrl}
        playing={isAudioPlay}
        onEnded={() => {
          if (speakState !== 100) setSpeakState(2);
          setIsAudioPlay(false);
        }}
        onPlay={() => {
          reactPlayerRef.current.seekTo(0, 'seconds');
        }}
      />

      <ReactPlayer
        ref={reactMicRecPlayerRef}
        className="react-player"
        url={audiosrc}
        playing={isMicRecPlay}
        onEnded={() => {
          setIsMicRecPlay(false);
        }}
        onPlay={() => {
          reactMicRecPlayerRef.current.seekTo(0, 'seconds');
        }}
      />

      <AudioAnalyser {...audioProps} />

      <div className="modal speak-modal" style={{ display: 'block' }}>
        <div className="inner">
          <div className="modal-header">
            <h1>
              {speakState === 1 && t('v1.SpeakUnitModel.TitleState01')}
              {speakState === 2 && t('v1.SpeakUnitModel.TitleState02')}
              {speakState === 3 && t('v1.SpeakUnitModel.TitleState03')}
              {speakState === 4 && t('v1.SpeakUnitModel.TitleState04')}
              {speakState === 5 && t('v1.SpeakUnitModel.TitleState05')}
              {speakState === -1 && t('v1.SpeakUnitModel.TitleState06')}
              {speakState === -2 && t('v1.SpeakUnitModel.TitleState07')}
              {speakState === 100 && t('v1.SpeakUnitModel.TitleState08')}
            </h1>
            <Link
              to="#"
              className="btn-close"
              onClick={(e) => {
                e.preventDefault();
                if (isMicRec) {
                  setIsClose(true);
                  controlAudio('inactive');
                } else {
                  onModalHidden();
                }
                //onModalHidden();
              }}
            >
              {t('v1.Common.BtnClose')}
            </Link>
          </div>
          <div className="modal-body">
            <div className="learning">
              {/*
              <button type="button" className="bookmark">
                즐겨찾기
              </button>
               */}
              <p className="original">{modalData.classStepTextKor}</p>

              {user && user.viewTrans && (
                <p className="translation">
                  {t('languageCode') === 'ko' && modalData.classStepTextEn}
                  {t('languageCode') === 'en' && modalData.classStepTextEn}
                  {t('languageCode') === 'id' && modalData.classStepTextIn}
                  {t('languageCode') === 'vi' && modalData.classStepTextViet}
                </p>
              )}
              {speakState === 4 && (
                <div className="loading">
                  <div className="spinner">
                    <Lottie
                      animationData={lottieSpinner}
                      background="transparent"
                      speed={1}
                      style={{ width: '80px', height: '80px' }}
                      loop
                      play
                    />
                  </div>
                </div>
              )}
              {speakState === -2 && (
                <div
                  className="fail"
                  onClick={(e) => {
                    e.preventDefault();
                    //setIsAnalysisError(false);
                  }}
                >
                  <div className="cross"></div>
                </div>
              )}
            </div>
          </div>

          <div className="btn-option">
            <div className="bubble-wrap">
              <button
                type="button"
                className={cn('btn-voice', { active: isAudioPlay })}
                onClick={(e) => {
                  e.preventDefault();
                  setIsMicRecPlay(false);

                  if (isAudioPlay) {
                    if (speakState !== 100) setSpeakState(2);
                    setIsAudioPlay(false);
                  } else {
                    if (speakState !== 100) setSpeakState(1);
                    setIsAudioPlay(true);
                  }
                }}
                disabled={speakState !== 1 && speakState !== 2 && speakState !== 100}
                /* disabled={isAnalysisIng || isAnalysisError || isMicRec}*/
              >
                <img src="/assets/images/voice.svg" alt={t('v1.Common.BtnListenPron')} />
              </button>
              <div className="bubble bottom-bubble">
                <p>{t('Speak_Edu.Speak_Modal_Listen_Info')}</p>
              </div>
            </div>

            <button
              type="button"
              className={cn('btn-record', { active: isMicRec })}
              onClick={(e) => {
                e.preventDefault();
                setIsMicRecPlay(false);
                setIsAudioPlay(false);

                if (isMicRec) controlAudio('inactive');
                else controlAudio('recording');
              }}
              disabled={speakState !== 2 && speakState !== 3 && speakState !== 100}
              /*disabled={isAnalysisIng || isAnalysisError || !isAudioPlayCheck}*/
            >
              <img src="/assets/images/voice.svg" alt={t('v1.Common.BtnRec')} />
            </button>

            {speakState === 100 && pronScore && audiosrc && (
              <button
                type="button"
                className={cn('btn-speak', { active: isMicRecPlay })}
                onClick={(e) => {
                  e.preventDefault();
                  setIsAudioPlay(false);
                  setIsMicRecPlay(!isMicRecPlay);
                }}
              >
                <img src="/assets/images/voice.svg" alt={t('v1.Common.BtnSpeak')} />
              </button>
            )}
          </div>
          <div className="btn-area">
            <div className="comment">
              {speakState === 100 && pronScore && (
                <>
                  <div className="star">
                    <SpeakIconStart100 score={pronScore} size={40} />
                  </div>
                  <p>{rendText}</p>
                </>
              )}
            </div>
            <div className="right">
              {isPrev && (
                <button
                  className="btn btn-prev"
                  onClick={(e) => {
                    e.preventDefault();
                    reset();
                    onPrev();
                  }}
                  disabled={isAudioPlay || isMicRec || isMicRecPlay || speakState === 4}
                >
                  {t('common.btn_before')}
                </button>
              )}
              {isNext && (
                <button
                  className="btn btn-next"
                  onClick={(e) => {
                    e.preventDefault();
                    reset();
                    onNext();
                  }}
                  disabled={isAudioPlay || isMicRec || isMicRecPlay || speakState === 4}
                >
                  {t('common.btn_next')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpeakUnitProblemStep2Modal;
