import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';
import detector from 'i18next-browser-languagedetector';

import languageKo from '../assets/i18n/ko';
import languageEn from '../assets/i18n/en';
import languageId from '../assets/i18n/id';
import languageVi from '../assets/i18n/vi';

const resources = {
  ko: {
    translation: languageKo,
  },
  en: {
    translation: languageEn,
  },
  id: {
    translation: languageId,
  },
  vi: {
    translation: languageVi,
  },
};

i18n

  .use(detector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'ko',
    keySeparator: '.', // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
