import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import cn from 'classnames';
import { Trans } from 'react-i18next';

import Lottie from 'react-lottie-player';
import lottieFace01 from '../../../assets/face01.json';
import lottieFace02 from '../../../assets/face02.json';
import lottieFace03 from '../../../assets/face03.json';
import lottieFace04 from '../../../assets/face04.json';
import lottieFace05 from '../../../assets/face05.json';
import lottieFace06 from '../../../assets/face06.json';

const TopikViewSuccessInfo = ({
  t,
  topikData,
  questionList,
  resultData,
  onReset,
  setPageType,
  listType,
  setListType,
}) => {
  useEffect(() => {
    if (topikData.rwdKind && questionList.length > 0 && resultData.rwdKind) {
      console.log('success data', topikData, questionList, resultData);

      if (resultData.topikScore > 0) donut('score01', resultData.topikScore);
      if (resultData.lcCorrectCount > 0)
        donut('score02', (resultData.lcCorrectCount / resultData.lcCount) * 100);
      if (resultData.rcCorrectCount > 0)
        donut('score03', (resultData.rcCorrectCount / resultData.rcCount) * 100);
    }
  }, [topikData, questionList, resultData]);

  const donut = (target, precent) => {
    var endPercent = precent;
    var canvas = document.getElementById(target);
    var context = canvas.getContext('2d');
    var x = canvas.width / 2;
    var y = canvas.height / 2;
    var radius = 61;
    var curPerc = 0;
    var circ = Math.PI * 2;
    var quart = Math.PI / 2;

    context.lineWidth = 6;
    context.strokeStyle = '#659fff';
    context.lineCap = 'round';

    function animate(current) {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.beginPath();
      context.arc(x, y, radius, -quart, circ * current - quart, false);
      context.stroke();
      curPerc++;
      if (curPerc < endPercent) {
        requestAnimationFrame(function () {
          animate(curPerc / 99);
        });
      }
    }
    animate();
  };

  return (
    <>
      <div className="grade-wrap">
        <div className="grade">
          <h3>{t('v1.TopikSuccess.Info_SubTitle_Result')}</h3>
          <div className="face">
            {resultData.topikLevel === 0 && (
              <Lottie animationData={lottieFace05} className="face" speed={1} play loop={false} />
            )}
            {resultData.questionLevel === 1 && resultData.topikLevel === 1 && (
              <Lottie animationData={lottieFace04} className="face" speed={1} play loop={false} />
            )}
            {resultData.questionLevel === 1 && resultData.topikLevel === 2 && (
              <Lottie animationData={lottieFace02} className="face" speed={1} play loop={false} />
            )}
            {resultData.questionLevel === 2 && resultData.topikLevel === 3 && (
              <Lottie animationData={lottieFace05} className="face" speed={1} play loop={false} />
            )}
            {resultData.questionLevel === 2 && resultData.topikLevel === 4 && (
              <Lottie animationData={lottieFace04} className="face" speed={1} play loop={false} />
            )}
            {resultData.questionLevel === 2 && resultData.topikLevel === 5 && (
              <Lottie animationData={lottieFace06} className="face" speed={1} play loop={false} />
            )}
            {resultData.questionLevel === 2 && resultData.topikLevel === 6 && (
              <Lottie animationData={lottieFace02} className="face" speed={1} play loop={false} />
            )}
          </div>
          <dl>
            {resultData.topikLevel === 0 && t('v1.Common.Unit0Level2')}
            {resultData.topikLevel > 0 && (
              <>
                <dt>TOPIK</dt>
                <dd>{t('v1.Common.UnitLevel1', { n: resultData.topikLevel })}</dd>
              </>
            )}
          </dl>
        </div>
        <div className="score">
          <h3>{t('v1.TopikSuccess.Info_SubTitle_Detail')}</h3>

          <div className="progress">
            <div>
              <div className="graph">
                <canvas id="score01" width="130" height="130"></canvas>
                <span>
                  <b>{resultData.topikScore}</b>
                </span>
              </div>
              <p>{t('v1.TopikSuccess.Info_DetailCategory1')}</p>
            </div>
            <div>
              <div className="graph">
                <canvas id="score02" width="130" height="130"></canvas>
                <span>
                  <b>{resultData.lcCorrectCount}</b>/{resultData.lcCount}
                </span>
              </div>
              <p>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setPageType('list');
                    setListType('lc');
                  }}
                >
                  {t('v1.TopikSuccess.Info_DetailCategory2')}
                </Link>
              </p>
            </div>
            <div>
              <div className="graph">
                <canvas id="score03" width="130" height="130"></canvas>
                <span>
                  <b>{resultData.rcCorrectCount}</b>/{resultData.rcCount}
                </span>
              </div>
              <p>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setPageType('list');
                    setListType('rc');
                  }}
                >
                  {t('v1.TopikSuccess.Info_DetailCategory3')}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="analysis">
        <h3>{t('v1.TopikSuccess.Info_SubTitle_Analyze')}</h3>
        <div className="content">
          <div className="grade">
            <div className="starter">
              <span className={cn({ current: resultData.topikLevel === 0 })}>
                {t('v1.Common.Unit0Level1')}
              </span>
            </div>
            <div className="beginner">
              <b>{t('v1.Common.GradeLv1')}</b>
              <span className={cn('first', { current: resultData.topikLevel === 1 })}>
                {t('v1.Common.UnitLevel1', { n: 1 })}
              </span>
              <span className={cn('second', { current: resultData.topikLevel === 2 })}>
                {t('v1.Common.UnitLevel1', { n: 2 })}
              </span>
            </div>
            <div className="medium">
              <b>{t('v1.Common.GradeLv2')}</b>
              <span className={cn('third', { current: resultData.topikLevel === 3 })}>
                {t('v1.Common.UnitLevel1', { n: 3 })}
              </span>
              <span className={cn('fourth', { current: resultData.topikLevel === 4 })}>
                {t('v1.Common.UnitLevel1', { n: 4 })}
              </span>
            </div>
            <div className="expert">
              <b>{t('v1.Common.GradeLv3')}</b>
              <span className={cn('fifth', { current: resultData.topikLevel === 5 })}>
                {t('v1.Common.UnitLevel1', { n: 5 })}
              </span>
              <span className={cn('sixth', { current: resultData.topikLevel === 6 })}>
                {t('v1.Common.UnitLevel1', { n: 6 })}
              </span>
            </div>
          </div>
          <div className="result">
            <strong style={{ whiteSpace: 'nowrap' }}>
              {resultData.topikLevel === 0 && t('v1.Common.Unit0Level2')}
              {resultData.topikLevel > 0 && (
                <>
                  TOPIK <span>{t('v1.Common.UnitLevel1', { n: resultData.topikLevel })}</span>
                </>
              )}
            </strong>
            {resultData.topikLevel === 0 && (
              <p>
                <Trans i18nKey="v1.TopikSuccess.Info_Ment7" />
              </p>
            )}

            {resultData.topikLevel === 1 && (
              <p>
                <Trans i18nKey="v1.TopikSuccess.Info_Ment1" />
              </p>
            )}
            {resultData.topikLevel === 2 && (
              <p>
                <Trans i18nKey="v1.TopikSuccess.Info_Ment2" />
              </p>
            )}
            {resultData.topikLevel === 3 && (
              <p>
                <Trans i18nKey="v1.TopikSuccess.Info_Ment3" />
              </p>
            )}
            {resultData.topikLevel === 4 && (
              <p>
                <Trans i18nKey="v1.TopikSuccess.Info_Ment4" />
              </p>
            )}
            {resultData.topikLevel === 5 && (
              <p>
                <Trans i18nKey="v1.TopikSuccess.Info_Ment5" />
              </p>
            )}
            {resultData.topikLevel === 6 && (
              <p>
                <Trans i18nKey="v1.TopikSuccess.Info_Ment6" />
              </p>
            )}
          </div>
        </div>
      </div>
      {topikData.payAt === 'N' && (
        <button
          type="button"
          className="btn-retry"
          onClick={(e) => {
            e.preventDefault();
            onReset();
          }}
        >
          {t('v1.TopikSuccess.Info_BtnQuizAgain')}
        </button>
      )}
    </>
  );
};

export default TopikViewSuccessInfo;
