import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import LanguageDetector from 'i18next-browser-languagedetector';

const AppLanding = ({ isLogin, user, t }) => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  const [isOpenModal, setIsOpenModal] = useState(true);

  useEffect(() => {
    console.log('@LanguageDetector', LanguageDetector);
  }, []);

  const openPage = () => {
    console.log('userAgent', userAgent);

    if (userAgent.indexOf('android') > -1) {
      return window.open(t('appGoogleDownloadLink'), '_blank');
    } else if (
      userAgent.indexOf('iphone') > -1 ||
      userAgent.indexOf('ipad') > -1 ||
      userAgent.indexOf('ipod') > -1
    ) {
      return window.open(t('appAppleDownloadLink'), '_blank');
    } else {
      alert('알수 없는 OS입니다.');
      return; // "해당 마켓에서 앱을 검색해주세요!" 나는 예외처리를 하였다..
    }
  };

  const closePage = () => {
    setIsOpenModal(false);
  };

  return (
    <div className="landing-wrap">
      <h1 className="logo">
        <img src="/assets/images/mobile_logo.svg" alt="Genie K" />
      </h1>
      <p className="copyright">Hancom Inc.</p>
      {isOpenModal && (
        <div className="modal-landing">
          <div className="inner">
            <p>
              <Trans i18nKey="v1.AppLanding.Modal_Title" />
            </p>
            <div className="btn-area">
              <Link
                to="#"
                className="btn-cancel"
                onClick={(e) => {
                  e.preventDefault();
                  closePage();
                }}
              >
                {t('v1.AppLanding.Modal_BtnClose')}
              </Link>{' '}
              <Link
                to="#"
                className="btn-open"
                onClick={(e) => {
                  e.preventDefault();
                  openPage();
                }}
              >
                {t('v1.AppLanding.Modal_BtnOk')}
              </Link>
            </div>
            <Link
              to="#"
              className="btn-close"
              onClick={(e) => {
                e.preventDefault();
                closePage();
              }}
            >
              {t('v1.Common.BtnClose')}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppLanding;
