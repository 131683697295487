import React from 'react';

const TextProfileKo = () => {
  return (
    <>
      <b>제1조 개인정보의 처리 목적</b>
      ① 회사는 다음의 목적을 위하여 개인정보를 수집하여 처리합니다. 처리하고 있는 개인정보는 다음
      목적 이외의 용도로는 이용되지 않으며, 이용목적이 변경되는 경우에는 개인정보 보호법 제18조에
      따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
      <br />
      <p style={{ paddingLeft: '15px' }}>1. 회원 관리</p>
      <p style={{ paddingLeft: '31px' }}>
        회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 불량 회원의 부정 이용 방지와
        비인가 사용 방지, 각종 고지·통지, 고충 처리 등을 목적으로 개인정보를 처리합니다.
      </p>
      <p style={{ paddingLeft: '15px' }}>2. 재화 또는 서비스 제공</p>
      <p style={{ paddingLeft: '31px' }}>
        서비스 제공, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 요금 결제·정산, 채권추심 등을 목적으로
        개인정보를 처리합니다.
      </p>
      <p style={{ paddingLeft: '15px', fontSize: '110%', fontWeight: 'bold' }}>
        3. 마케팅 및 광고에 활용
      </p>
      <p style={{ paddingLeft: '31px', fontSize: '110%', fontWeight: 'bold' }}>
        상품·서비스, 사은·판촉행사, 이벤트 등 광고성 정보 전달, 인구통계학적 특성에 따른 서비스 제공
        및 광고 게재, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계 활용 등을 목적으로
        개인정보를 처리합니다.
      </p>
      <p style={{ paddingLeft: '15px' }}>4. 부가서비스 제공</p>
      <p style={{ paddingLeft: '31px' }}>
        회원가입 및 개인정보 수정을 통해 회원님께서 부가서비스 이용을 신청하셨을 경우 회사는 원활한
        부가서비스 이용을 위하여 수집한 개인정보를 온라인 부가서비스 제공 업체에 제공하는 등을
        목적으로 개인정보를 처리합니다.
      </p>
      <br />
      <b>제 2조 개인정보의 처리 항목 및 보유기간</b>
      ① 원칙적으로 개인정보는 살아있는 개인에 관한 정보를 말합니다. 다만 법인·단체에 관한 정보이면서
      동시에 개인에 관한 정보인 경우, 개별 상황에 따라 개인정보로 처리될 수도 있기 때문에,
      법인·단체의 정보도 개인정보처리 항목에 포함하여 이해를 돕고자 합니다.
      <br />
      ② 회사는 법령에 따른 개인정보 보유기간 또는 이용자로부터 개인정보를 수집 시에 동의받은
      개인정보 보유기간 내에서 개인정보를 처리하고 보유합니다.
      <br />
      <table>
        <colgroup>
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
        </colgroup>
        <thead>
          <tr>
            <th>구분</th>
            <th colSpan="2">처리 항목</th>
            <th>보유 및 이용 기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan="2" style={{ textAlign: 'center' }}>
              회원가입
            </td>
            <td style={{ textAlign: 'center' }}>이메일 회원가입</td>
            <td>[선택] 프로필 정보(별명, 프로필 사진, 결제 기록)</td>
            <td rowSpan="2" style={{ fontWeight: 'bold', fontSize: '120%', textAlign: 'center' }}>
              회원 탈퇴 시까지
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: 'center' }}>
              간편 회원가입
              <br />
              (애플, 페이스북, 구글)
            </td>
            <td>[선택] 프로필 정보(별명, 프로필 사진, 결제 기록)</td>
          </tr>
        </tbody>
      </table>
      ③ 관계법령에 의거하여 회사에서 보존하는 개인정보 항목 및 기간은 다음과 같습니다.
      <br />
      <br />
      1. 전자상거래 등에서의 소비자 보호에 관한 법률
      <br />
      가. 계약 또는 청약철회 등에 관한 기록: 5년
      <br />
      나. 대금 결제 및 재화 등의 공급에 관한 기록: 5년
      <br />
      다. 소비자의 불만 또는 분쟁처리에 관한 기록: 3년
      <br />
      <br />
      2. 통신비밀보호법
      <br />
      라. 방문(접속) 기록: 3개월
      <br />
      <br />
      ④ “회사”는 개인정보보호법에 따라 12개월 이상 로그인하지 않은 경우나, 서비스 이용이 없는 경우
      해당 “회원”의 ID 및 개인정보를 휴면 ID로 별도 관리합니다. 이러한 경우 위 12개월의 기간 도래
      30일 전까지 “회사”는 “회원”의 개인정보가 분리되어 저장, 관리되고 있다는 사실과 기간 만료일 및
      해당 개인정보의 항목을 전자우편, 서면, 모사전송, 전화 또는 이와 유사한 방법 중 어느 하나의
      방법으로 “회원”에게 알려드립니다. 단, 서비스를 이용 중인 경우 기간 만료일과 상관없이 서비스
      삭제 시점에 휴면 ID로 전환됩니다. 휴면 ID로 전환된 개인정보는 4년간 보관 후 지체 없이
      파기합니다.
      <br />
      <br />
      <a href="/privacy" target="_blank">
        [개인정보 처리방침 전문 보기]
      </a>
    </>
  );
};

export default TextProfileKo;
