import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import cn from 'classnames';

import * as Api from '../../library/Api';
import * as Util from '../../library/Util';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import SpeakConfirm from './layout/SpeakConfirm';

import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import SpeakUnitProblem01 from './layout/SpeakUnitProblem01';
import SpeakUnitProblem02 from './layout/SpeakUnitProblem02';
import SpeakUnitProblem03 from './layout/SpeakUnitProblem03';
import SpeakUnitProblemStep2Modal from './layout/SpeakUnitProblemStep2Modal';
import SpeakUnitProblemStep3Modal from './layout/SpeakUnitProblemStep3Model';
import SpeakMicTest from './layout/SpeakMicTest';
import SpeakUnitProblemSuccess from './layout/SpeakUnitProblemSuccess';

const SpeakUnitProblem = ({ isLogin, user, t }) => {
  let localStorage = window.localStorage;
  const navigate = useNavigate();

  const { courseId, classId, unitId, tabType } = useParams();
  const [isMicModal, setIsMicModal] = useState(false);

  const [stepCheck, setStepCheck] = useState({ step1: false, step2: false, step3: false });

  const [courseData, setCourseData] = useState({ courseLevel: '' });
  const [courseLevel, setCourseLevel] = useState({
    isLevel1: false,
    isLevel2: false,
    isLevel3: false,
  });
  const [unitText, setUnitText] = useState({});
  const [classText, setClassText] = useState({
    classTextEn: '',
    classTextIn: '',
    classTextKor: '',
    classTextViet: '',
  });

  const [classStep, setClassStep] = useState();
  const [classStepList, setClassStepList] = useState([]);

  const [step2ModalData, setStep2ModalData] = useState({
    isShow: false,
    isPrev: false,
    isNext: false,
    modalData: {},
    modalIndex: 0,
  });

  const [step3ModalData, setStep3ModalData] = useState({
    isShow: false,
    isPrev: false,
    isNext: false,
    modalData: {},
    modalIndex: 0,
  });

  const [resultData, setResultData] = useState({});

  const timerRef = useRef(null);

  const [nextClass, setNextClass] = useState([]);

  // -1 로딩 전
  // 0 로딩 후
  // 1 진행중
  // 2 완료
  const learningTimeCheck = useRef(-1);

  useEffect(() => {
    // 화면 focus 가 아닐 경우 학습시간 중지
    const visiblityChangeListener = () => {
      if (learningTimeCheck.current === 1) {
        if (document.visibilityState === 'hidden') {
          learningTimeEnd();
        } else {
          learningTimeSetting();
        }
      }
    };

    window.addEventListener('visibilitychange', visiblityChangeListener);
    return () => window.removeEventListener('visibilitychange', visiblityChangeListener);
  }, []);

  useEffect(() => {
    if (learningTimeCheck.current === 0) {
      if (stepCheck.step1 && stepCheck.step2 && stepCheck.step3) {
        // 학습이 완료된 경우 라면 처리안함.
        learningTimeCheck.current = 2;
      } else {
        // 학습 중이라면 시간 저장
        learningTimeCheck.current = 1;
        learningTimeSetting();
      }
    } else if (learningTimeCheck.current === 1) {
      // 모든 학습이 끝날경우
      if (stepCheck.step1 && stepCheck.step2 && stepCheck.step3) {
        learningTimeCheck.current = 2;
        learningTimeEnd();
      }
    }
  }, [stepCheck]);

  useEffect(() => {
    console.log('init Load', isLogin, classId, unitId, tabType);
    if (isLogin === 0) {
      //alert('로그인 후 이용가능합니다.');
      navigate('/member/login');
    } else if (isLogin === 1) {
      reset();

      return () => {
        // 페이지 종료시
        learningTimeEnd();
      };
    }
  }, [isLogin, classId, unitId, tabType]);

  useEffect(() => {
    if (typeof classStep != undefined) stepLoad();
  }, [classStep]);

  useEffect(() => {
    console.log('load Check useEffect [ courseData.courseLevel ]', courseData.courseLevel);

    let isLevel1 = courseData.courseLevel.substr(0, 5) === 'BEGIN' ? true : false;
    let isLevel2 = courseData.courseLevel.substr(0, 5) === 'INTER' ? true : false;
    let isLevel3 = courseData.courseLevel.substr(0, 8) === 'BUSINESS' ? true : false;
    setCourseLevel({ isLevel1: isLevel1, isLevel2: isLevel2, isLevel3: isLevel3 });
  }, [courseData.courseLevel]);

  const reset = () => {
    if (isLogin === 1) {
      initLoad();
    }
  };

  const initLoad = () => {
    learningTimeCheck.current = -1;
    (async () => {
      try {
        // 코스 정보 불러오기
        const speakCourseDataResponse = await Api.speakCourseList({
          esntlId: user.esntlId,
          courseId: courseId,
        });
        if (
          speakCourseDataResponse.data.result.data &&
          speakCourseDataResponse.data.result.data.length > 0
        )
          setCourseData(speakCourseDataResponse.data.result.data[0]);

        // 수업명 불러오기
        const speakClassTextResponse = await Api.speakClassText({
          classId: classId,
        });
        if (speakClassTextResponse.data.result.data)
          setClassText(speakClassTextResponse.data.result.data);

        // Unit Text 불러오기
        const speakUnitTextResponse = await Api.speakUnitText({
          unitId: unitId,
        });
        if (speakUnitTextResponse.data.result.data)
          setUnitText(speakUnitTextResponse.data.result.data);

        changeStep(parseInt(tabType));
      } catch (error) {}
    })();
  };

  const stepLoad = () => {
    console.log('load Check useEffect stepLoad()');
    (async () => {
      try {
        // 학습 기록하기.
        Api.speakUpdateRecentUnit({
          esntlId: user.esntlId,
          courseId: courseId,
          recentUnit: unitId,
        });
      } catch (error) {}

      try {
        // Step List 불러오기
        const classStepDataRespone = await Api.speakClassStepList({
          esntlId: user.esntlId,
          classId: classId,
          classStepGubun: 'STEP' + (classStep + 1),
        });
        if (classStepDataRespone.data.result.data)
          setClassStepList(classStepDataRespone.data.result.data);
      } catch (error) {}
    })();
  };

  const nextStep = () => {
    changeStep(classStep + 1);
  };

  const changeStep = (step) => {
    console.log('load Check useEffect changeStep()', step);
    if (classStep === 0) {
      (async () => {
        try {
          console.log('classStepDataRespone');
          const classStepDataRespone = await Api.speakClassDone({
            esntlId: user.esntlId,
            classStepId: classStepList[0].classStepId,
          });
          if (classStepDataRespone.data.result.data)
            setClassStepList(classStepDataRespone.data.result.data);

          onStepCheck();
        } catch (error) {}
      })();
    } else if (step === 3) {
      (async () => {
        try {
          const speakCourseResultResponse = await Api.speakCourseResult({
            esntlId: user.esntlId,
            classId: classId,
          });
          if (speakCourseResultResponse.data.result.data)
            setResultData(speakCourseResultResponse.data.result.data);

          onStepCheck();
        } catch (error) {}

        try {
          // 다음 학습 불러오기
          const speakNextClassListResponse = await Api.speakNextClassList({
            esntlId: user.esntlId,
            classId: classId,
          });
          console.log('speakNextClassListResponse', speakNextClassListResponse);
          if (speakNextClassListResponse.data.result.data) {
            setNextClass(speakNextClassListResponse.data.result.data);
          }
        } catch (error) {}
      })();
    } else {
      onStepCheck();
    }

    setClassStep(step);
  };

  const step2ModalShow = (modalData, modalIndex) => {
    micPermissionsCheckAlert(() => {
      setStep2ModalData({
        isShow: true,
        isPrev: modalIndex > 0 ? true : false,
        isNext: modalIndex + 1 < classStepList.length ? true : false,
        modalData: modalData,
        modalIndex: modalIndex,
      });
    });
  };

  const step2ModalHidden = () => {
    setStep2ModalData({ isShow: false, modalData: {} });
  };

  const step2ModalPrevShow = () => {
    step2ModalShow(classStepList[step2ModalData.modalIndex - 1], step2ModalData.modalIndex - 1);
  };

  const step2ModalNextShow = () => {
    step2ModalShow(classStepList[step2ModalData.modalIndex + 1], step2ModalData.modalIndex + 1);
  };

  const step3ModalShow = (modalData, modalIndex) => {
    micPermissionsCheckAlert(() => {
      setStep3ModalData({
        isShow: true,
        isPrev: modalIndex > 0 ? true : false,
        isNext: modalIndex + 1 < classStepList.length ? true : false,
        modalData: modalData,
        modalIndex: modalIndex,
      });
    });
  };

  const step3ModalHidden = () => {
    setStep3ModalData({ isShow: false, modalData: {} });
  };

  const step3ModalPrevShow = () => {
    step3ModalShow(classStepList[step3ModalData.modalIndex - 1], step3ModalData.modalIndex - 1);
  };

  const step3ModalNextShow = () => {
    step3ModalShow(classStepList[step3ModalData.modalIndex + 1], step3ModalData.modalIndex + 1);
  };

  const step4Show = () => {
    changeStep(3);
  };

  const micTestShow = (isOpen) => {
    micPermissionsCheckAlert(() => {
      setIsMicModal(isOpen);
    });
  };

  const micPermissionsCheckAlert = (grantedFunc) => {
    (async () => {
      try {
        let micPermissionsState = await Util.getMicPermissions();
        console.log('Util.getMicPermissions()', micPermissionsState);
        if (micPermissionsState === 'granted') {
          grantedFunc();
        } else {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <SpeakConfirm
                  t={t}
                  content={t('v1.SpeakUnitView.Alert_TextMicPermission1')}
                  content2={t('v1.SpeakUnitView.Alert_TextMicPermission2')}
                  isCancelHideBtn={true}
                  onClose={onClose}
                  onConfirm={onClose}
                />
              );
            },
          });
        }
      } catch (error) {}
    })();
  };

  const onStepCheck = () => {
    (async () => {
      try {
        const speakCheckDoneResponse = await Api.speakCheckDone({
          esntlId: user.esntlId,
          classId: classId,
        });

        if (speakCheckDoneResponse.data.result.data) {
          setStepCheck(speakCheckDoneResponse.data.result.data);

          if (learningTimeCheck.current === -1) {
            learningTimeCheck.current = 0;
          }
        }
      } catch (error) {}
    })();
  };

  const learningTimeSetting = () => {
    let spearkLearningUid = courseId + '_' + classId + '_' + unitId;
    let storageSpearkLearningUid = localStorage.getItem('spearkLearningUid');
    let storageSpearkLearningStartTime = localStorage.getItem('spearkLearningStartTime');

    localStorage.setItem('spearkLearningUid', spearkLearningUid);
    localStorage.setItem('spearkLearningStartTime', new Date().getTime());
  };

  const learningTimeEnd = () => {
    let spearkLearningUid = courseId + '_' + classId + '_' + unitId;
    let storageSpearkLearningUid = localStorage.getItem('spearkLearningUid');
    let storageSpearkLearningStartTime = localStorage.getItem('spearkLearningStartTime');
    let learningTime = parseInt((new Date().getTime() - storageSpearkLearningStartTime) / 1000);

    if (spearkLearningUid === storageSpearkLearningUid) {
      if (learningTime > 0) {
        (async () => {
          try {
            await Api.speakSetLearningTime({
              esntlId: user.esntlId,
              classId: classId,
              studyTime: learningTime,
            });
            localStorage.removeItem('spearkLearningUid');
            localStorage.removeItem('spearkLearningStartTime');
          } catch (error) {}
        })();
      }
    }
  };

  const backPage = () => {
    if (stepCheck.step1 && stepCheck.step2 && stepCheck.step3) {
      navigate('/speak/unit/' + courseId);
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <SpeakConfirm
              t={t}
              content={t('v1.SpeakUnitView.Alert_TextStop1')}
              content2={t('v1.SpeakUnitView.Alert_TextStop2')}
              onClose={onClose}
              onConfirm={() => {
                navigate('/speak/unit/' + courseId);
              }}
            />
          );
        },
      });
    }
  };

  return (
    <>
      <div
        className={cn('wrap', {
          wrapKo: t('languageCode') === 'ko',
          wrapEn: t('languageCode') === 'en',
          wrapId: t('languageCode') === 'id',
          wrapVi: t('languageCode') === 'vi',
        })}
      >
        <Header />
        <section className="sub-contents">
          <article
            className={cn('expression-area', {
              beginner: courseLevel.isLevel1,
              medium: courseLevel.isLevel2,
              expert: courseLevel.isLevel3,
            })}
          >
            <div className="inner">
              <Link
                to="#"
                className="btn-prev"
                onClick={(e) => {
                  e.preventDefault();
                  backPage();
                }}
              >
                {t('common.btn_before')}
              </Link>
              <h2>
                {classText.classTextKor}
                {/*
                {t('languageCode') === 'ko' && classText.classTextKor}
                {t('languageCode') === 'en' && classText.classTextEn}
                {t('languageCode') === 'id' && classText.classTextIn}
                {t('languageCode') === 'vi' && classText.classTextViet}
              */}

                {/*
                {t('languageCode') === 'ko' && unitText.unitTextKor}
                {t('languageCode') === 'en' && unitText.unitTextEn}
                {t('languageCode') === 'id' && unitText.unitTextIn}
                {t('languageCode') === 'vi' && unitText.unitTextViet}
              */}
              </h2>
            </div>
          </article>
          <div className="sub-inner">
            <article
              className={cn('expression-detail', {
                'course-beginner': courseLevel.isLevel1,
                'course-medium': courseLevel.isLevel2,
                'course-expert': courseLevel.isLevel3,
              })}
            >
              <div className="step">
                <ol>
                  <li
                    className={cn({
                      active: classStep === 0,
                      complete: classStep === 3 || stepCheck.step1,
                    })}
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.preventDefault();
                      changeStep(0);
                    }}
                  >
                    <span>1.</span>&nbsp;{t('v1.SpeakUnitView.Tab1')}
                  </li>
                  <li
                    className={cn({
                      active: classStep === 1,
                      complete: classStep === 3 || stepCheck.step2,
                    })}
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.preventDefault();
                      changeStep(1);
                    }}
                  >
                    <span>2.</span>&nbsp;{t('v1.SpeakUnitView.Tab2')}
                  </li>
                  <li
                    className={cn({
                      active: classStep === 2,
                      complete: classStep === 3 || stepCheck.step3,
                    })}
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.preventDefault();
                      changeStep(2);
                    }}
                  >
                    <span>3.</span>&nbsp;{t('v1.SpeakUnitView.Tab3')}
                  </li>
                  {stepCheck.step1 && stepCheck.step2 && stepCheck.step3 && (
                    <li
                      className={cn({ active: classStep === 3 })}
                      onClick={(e) => {
                        e.preventDefault();
                        step4Show();
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {t('v1.SpeakUnitView.Tab4')}
                    </li>
                  )}
                </ol>

                {/*classStep === 3 && (
                  <Link
                    to="#"
                    className="btn-test"
                    onClick={(e) => {
                      e.preventDefault();
                      micTestShow(true);
                    }}
                  >
                    {t('common.btn_microphone')}
                  </Link>
                  )*/}
              </div>
              {classStep === 0 && (
                <SpeakUnitProblem01
                  t={t}
                  onNextStep={nextStep}
                  nowStep={classStep + 1}
                  classStepList={classStepList}
                />
              )}
              {classStep === 1 && (
                <SpeakUnitProblem02
                  t={t}
                  user={user}
                  onNextStep={nextStep}
                  nowStep={classStep + 1}
                  classStepList={classStepList}
                  onSetClassStepList={setClassStepList}
                  onModalShow={step2ModalShow}
                  onMicTestShow={micTestShow}
                />
              )}
              {classStep === 2 && (
                <SpeakUnitProblem03
                  t={t}
                  user={user}
                  onNextStep={nextStep}
                  nowStep={classStep + 1}
                  classStepList={classStepList}
                  onModalShow={step3ModalShow}
                  onMicTestShow={micTestShow}
                  onResult={step4Show}
                  stepCheck={stepCheck}
                />
              )}
              {classStep === 3 && (
                <SpeakUnitProblemSuccess
                  t={t}
                  user={user}
                  isLogin={isLogin}
                  resultData={resultData}
                />
              )}
            </article>
            {classStep === 3 && (
              <article className="more-study">
                <div className="inner">
                  <h3>{t('Speak_Edu.Next_Edu_Title')}</h3>
                  <div className="link">
                    {nextClass.map((nextClassItem, index) => {
                      if (nextClassItem.classId.substr(-4) !== 'QUIZ') {
                        return (
                          <Link
                            to={
                              '/speak/unitProblem/' +
                              nextClassItem.courseId +
                              '/' +
                              nextClassItem.classId +
                              '/' +
                              nextClassItem.unitId +
                              '/0'
                            }
                          >
                            <span>{t('Speak_Edu.Next_Edu_Category')}</span>
                            <p>
                              {t('languageCode') === 'ko' && nextClassItem.classTextKor}
                              {t('languageCode') === 'en' && nextClassItem.classTextEn}
                              {t('languageCode') === 'id' && nextClassItem.classTextIn}
                              {t('languageCode') === 'vi' && nextClassItem.classTextViet}
                            </p>
                          </Link>
                        );
                      } else {
                        return (
                          <Link
                            to={
                              '/speak/unitQuiz/' +
                              nextClassItem.courseId +
                              '/' +
                              nextClassItem.classId +
                              '/' +
                              nextClassItem.unitId
                            }
                          >
                            <span>{t('Speak_Edu.Next_Edu_Category')}</span>
                            <p style={{ fontSize: '21px' }}>{t('Speak_List.Speak_list_Quiz')}</p>
                          </Link>
                        );
                      }
                    })}
                  </div>
                </div>
              </article>
            )}
          </div>
        </section>
        {step2ModalData.isShow && (
          <SpeakUnitProblemStep2Modal
            t={t}
            user={user}
            classStepList={classStepList}
            onSetClassStepList={setClassStepList}
            onPrev={step2ModalPrevShow}
            onNext={step2ModalNextShow}
            onModalHidden={step2ModalHidden}
            isPrev={step2ModalData.isPrev}
            isNext={step2ModalData.isNext}
            modalData={step2ModalData.modalData}
            modalIndex={step2ModalData.modalIndex}
          />
        )}
        {step3ModalData.isShow && (
          <SpeakUnitProblemStep3Modal
            t={t}
            user={user}
            classStepList={classStepList}
            onSetClassStepList={setClassStepList}
            onPrev={step3ModalPrevShow}
            onNext={step3ModalNextShow}
            onModalHidden={step3ModalHidden}
            isPrev={step3ModalData.isPrev}
            isNext={step3ModalData.isNext}
            modalData={step3ModalData.modalData}
            modalIndex={step3ModalData.modalIndex}
          />
        )}
        {isMicModal && <SpeakMicTest t={t} onModalShowHidden={setIsMicModal} />}
        <Footer />
      </div>
    </>
  );
};

export default SpeakUnitProblem;
