import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import cn from 'classnames';
import ReactPlayer from 'react-player/file';
import dompurify from 'dompurify';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import TopikConfirm from './TopikConfirm';

const TopikViewQuestion = ({
  t,
  topikData,
  questionData,
  questionTimer,
  onClose,
  onNextStep,
  onSelectItem,
  isTimeOverToast,
  timeOverToastClose,
}) => {
  const navigate = useNavigate();

  const [isAudioPlay, setIsAudioPlay] = useState(false);
  const reactPlayerRef = useRef();

  const [isPlayToast, setIsPlayToast] = useState(false);
  const [isPlay, setIsPlay] = useState(false);

  useEffect(() => {
    return () => {
      setIsAudioPlay(false);
      setIsPlayToast(false);
      setIsPlay(false);
    };
  }, [questionData.data]);

  const playToastShow = () => {
    setIsPlayToast(true);
    setTimeout(() => {
      setIsPlayToast(false);
    }, 3000);
  };

  const audioPlay = (isPlay) => {
    setIsAudioPlay(isPlay);
    if (isPlay === true) {
      setIsPlay(true);
    }
  };

  const closeModal = (onCloseFunc, onConfirmFunc) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <TopikConfirm
            t={t}
            content={t('TOPIK_Edu.Alert_TOPIK_DC_Title')}
            content2={t('TOPIK_Edu.Alert_TOPIK_DC_Contents')}
            onClose={() => {
              onCloseFunc();
              onClose();
            }}
            onConfirm={() => {
              onConfirmFunc();
            }}
          />
        );
      },
    });
  };

  return (
    <>
      {questionData.data.questionAttribute === 'aud' && isAudioPlay && (
        <>
          <ReactPlayer
            ref={reactPlayerRef}
            className="react-player"
            url={questionData.data.questionAttributeFile}
            playing={isAudioPlay}
            onEnded={() => {
              audioPlay(false);
            }}
            onPlay={() => {
              reactPlayerRef.current.seekTo(0, 'seconds');
            }}
          />
        </>
      )}

      <div className="pop-wrap">
        <header className="pop-header exam-header">
          <h1 className="title">
            {t('languageCode') === 'ko' && topikData.rwdDesc}
            {t('languageCode') === 'en' && topikData.rwdDescEn}
            {t('languageCode') === 'id' && topikData.rwdDescIn}
            {t('languageCode') === 'vi' && topikData.rwdDescViet}
          </h1>
          <span className="time">
            {questionTimer.min < 10 ? `0${questionTimer.min}` : questionTimer.min}:
            {questionTimer.sec < 10 ? `0${questionTimer.sec}` : questionTimer.sec}
          </span>
          <Link
            to="#"
            className="btn-close"
            onClick={(e) => {
              e.preventDefault();
              //navigate(-1);
              closeModal(
                () => {},
                () => {
                  navigate(-1);
                },
              );
            }}
          >
            {t('v1.Common.BtnClose')}
          </Link>
        </header>
        <section className="pop-contents exam-contents">
          <div className="exam-progress">
            <div
              className="bar"
              style={{ width: (100 / questionData.totalStep) * questionData.nowStep + '%' }}
            >
              <div className="num">
                <div>
                  <b>{questionData.nowStep}</b>
                  <span>{questionData.totalStep}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="contents-inner">
            <h2 className={cn({ correct: false, incorrect: false })}>
              {questionData.nowStep}. {questionData.data.questionNm}
            </h2>
            <div className="question">
              <div>
                <div className="paragraph">
                  {questionData.data.questionAttribute === 'aud' && (
                    <>
                      <div className="bubble-wrap">
                        <button
                          type="button"
                          className={cn('btn-listen', { active: isAudioPlay })}
                          onClick={(e) => {
                            e.preventDefault();
                            if (isPlay === false) audioPlay(!isAudioPlay);
                          }}
                          disabled={!isAudioPlay && isPlay}
                        >
                          <img src="/assets/images/voice.svg" alt="듣기" />
                        </button>
                        {!isPlay && (
                          <div className={cn('bubble', 'top-bubble', { open: isPlayToast })}>
                            {!isPlay && <p>{t('TOPIK_Edu.TOPIK_Tooltips_Audio')}</p>}
                            {/*
                          {isPlay && <p>오디오 재생은 1회만 가능합니다.</p>}
                        */}
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  {questionData.data.questionAttribute === 'txt' && (
                    <>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: dompurify.sanitize(questionData.data.questionAttributeText),
                        }}
                      ></p>
                    </>
                  )}

                  {questionData.data.questionAttribute === 'img' && (
                    <>
                      <img
                        src={questionData.data.questionAttributeFile}
                        alt=""
                        style={{ width: '100%' }}
                      />
                    </>
                  )}

                  {/*
                  // API 구분으로 분리할수 없음.
                  <p className="txt-big">
                    아버지께서 미국으로 여행을 <span className="bracket-left">(</span>
                    <span className="bracket-right">.)</span>
                  </p>
                    */}
                </div>
                {questionData.data.questionAttributeTextAdd && (
                  <>
                    <div className="sample">
                      <strong>&lt;보기&gt;</strong>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: dompurify.sanitize(questionData.data.questionAttributeTextAdd),
                        }}
                      ></p>
                    </div>
                  </>
                )}
              </div>
              <div className="answer">
                <ul
                  className={cn('list', {
                    'txt-list': questionData.data.exampleAttribute === 'txt',
                    'img-list': questionData.data.exampleAttribute === 'img',
                  })}
                >
                  {questionData.data.exampleList.map((exampleItem, exampleKey) => {
                    return (
                      <li
                        className={cn({
                          selected: exampleItem.exampleSequence === questionData.data.selectedItem,
                        })}
                        key={'exampleList' + exampleItem.exampleSequence}
                      >
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            // 듣기시 플레이 안했을경우.
                            if (questionData.data.questionType === 'LC') {
                              if (!isPlay) {
                                playToastShow();
                                return false;
                              }
                            }

                            onSelectItem(questionData.data.questionId, exampleItem.exampleSequence);
                          }}
                        >
                          {questionData.data.exampleAttribute === 'txt' && exampleItem.exampleText}
                          {questionData.data.exampleAttribute === 'img' && (
                            <img src={exampleItem.exampleFile} alt="" style={{ height: 'auto' }} />
                          )}
                        </button>
                      </li>
                    );
                  })}
                </ul>

                {/*
                {questionData.data.selectedItem && questionData.data.questionExplanation && (
                  <>
                    <div className="resolve">
                      <strong>&lt;해설&gt;</strong>
                      <p
                        dangerouslySetInnerHTML={{ __html: dompurify.sanitize(questionData.data.questionExplanation) }}
                      ></p>
                    </div>
                  </>
                )}
                */}
              </div>
            </div>
          </div>
          <div className="exam-btn-area">
            <div className="left">
              {/*
              <a href="#" className="btn-default btn-prev">
                이전
              </a>
              */}
            </div>
            <div className="right">
              <button
                to="#"
                className="btn-default btn-next"
                disabled={!questionData.data.selectedItem}
                onClick={(e) => {
                  e.preventDefault();
                  if (questionData.data.selectedItem) {
                    onNextStep();
                  }
                }}
              >
                {t('common.btn_next')}
              </button>
            </div>
          </div>

          {isTimeOverToast && (
            <div className="toast">
              <div className="inner">
                <p>
                  {t('TOPIK_Edu.TOPIK_Tooltips_Quick')}
                  <br />
                  {t('TOPIK_Edu.TOPIK_Tooltips_TimeOver')}
                </p>
                <button
                  type="button"
                  className="btn-close"
                  onClick={(e) => {
                    e.preventDefault();
                    timeOverToastClose();
                  }}
                >
                  {t('v1.Common.BtnClose')}
                </button>
              </div>
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default TopikViewQuestion;
