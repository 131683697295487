import React from 'react';

export const getPublicDir = (path) => {
  return process.env.PUBLIC_URL + path;
};

// 마이크 권한 여부 불러오기.
export const getMicPermissions = async () => {
  if (navigator.permissions === undefined) {
    navigator.permissions = {};
  }

  if (navigator.permissions.query === undefined) {
    navigator.permissions.query = () => {
      return {
        state: 'permissions-query-not-supported',
      };
    };
  }

  const result = await navigator.permissions.query({ name: 'microphone' });
  return result.state;
};
