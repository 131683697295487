import React from 'react';
import dompurify from 'dompurify';

const TopikConfirm = ({ t, content, content2, onClose, onConfirm, isCancelHideBtn }) => {
  return (
    <div className="modal info-modal" style={{ display: 'block' }}>
      <div className="inner">
        <div className="modal-header">
          {isCancelHideBtn !== true && (
            <button
              href="#"
              className="btn-close"
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            >
              닫기
            </button>
          )}
        </div>
        <div className="modal-body">
          <div className="text-area">
            <p
              className="em"
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(content),
              }}
            ></p>
            {content2 && (
              <p
                className="em"
                style={{ fontSize: '18px' }}
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(content2),
                }}
              ></p>
            )}
          </div>
          <div className="btn-area">
            {isCancelHideBtn !== true && (
              <>
                <button
                  className="btn-cancel"
                  onClick={(e) => {
                    e.preventDefault();
                    onClose();
                  }}
                >
                  {t('common.btn_Cancellation')}
                </button>
                &nbsp;&nbsp;
              </>
            )}
            <button
              className="btn-confirm"
              onClick={(e) => {
                e.preventDefault();
                onConfirm();
                onClose();
              }}
            >
              {t('common.btn_Check')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopikConfirm;
