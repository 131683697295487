import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useUserDispatch } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import * as Api from '../../library/Api';
import cn from 'classnames';

import { useForm } from 'react-hook-form';

import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import MypageLeftNav from './layout/MypageLeftNav';

const MypageProfile = ({ isLogin, user, t }) => {
  const navigate = useNavigate();
  const userDispatch = useUserDispatch();

  const profileInputFile = useRef();
  const [profileImageUrl, setProfileImageUrl] = useState();
  const [isProfileDelete, setIsProfileDelete] = useState(false);
  const [isKorNickMakeBox, setIsKorNickMakeBox] = useState(false);

  const [typoProfileList, setTypoProfileList] = useState([]);

  const {
    register,
    setError,
    watch,
    handleSubmit,
    getValues,
    setValue,
    formState: { isSubmitting, isDirty, errors },
  } = useForm();
  const avatar = watch('image');
  const { ref, ...rest } = register('image');

  useEffect(() => {
    if (isLogin === 1) {
      setProfileImageUrl(user.imageUrl);
      setValue('nick', user.name);
      (async () => {
        try {
          const typoProfileListResponse = await Api.typoProfileList();
          if (typoProfileListResponse.data.result.data) {
            setTypoProfileList(
              typoProfileListResponse.data.result.data.map((item, index) => {
                return { ...item, isSelect: false };
              }),
            );
          }
        } catch (error) {}
      })();
    } else if (isLogin === 0) {
      //alert('로그인 후 이용가능합니다.');
      navigate('/member/login');
    }
  }, [isLogin]);

  useEffect(() => {
    if (avatar && avatar.length > 0) {
      const file = avatar[0];
      setProfileImageUrl(URL.createObjectURL(file));
      setIsProfileDelete(false);
    }
  }, [avatar]);

  const onTypeProfileSelect = (index) => {
    profileInputFile.current.value = null;
    setProfileImageUrl(typoProfileList[index].imageUrl);
    setValue(
      'nick',
      typoProfileList[index].typoNm === 'DEFAULT'
        ? user.id.split('@')[0]
        : typoProfileList[index].typoNm,
    );
    setIsProfileDelete(false);

    setTypoProfileList(
      typoProfileList.map((typeItem, typeIndex) => {
        return typeIndex === index
          ? { ...typeItem, isSelect: true }
          : { ...typeItem, isSelect: false };
      }),
    );
  };

  const onSubmit = (data) => {
    console.log(data);

    (async () => {
      try {
        let fileUrl = '';
        if (data.image.length > 0) {
          const uploadProfilePicResponse = await Api.uploadProfilePic(data.image[0]);
          if (uploadProfilePicResponse.data.result.data) {
            fileUrl = uploadProfilePicResponse.data.result.data;
          }
        } else if (isProfileDelete === false && profileImageUrl !== '') {
          fileUrl = profileImageUrl;
        } else if (isProfileDelete === false) {
          fileUrl = user.imageUrl;
        }

        const updateProfileResponse = await Api.updateProfile({
          id: user.id, // 필수
          imageUrl: fileUrl,
          name: data.nick,
        });

        console.log('assd', {
          id: user.id, // 필수
          imageUrl: fileUrl,
          name: data.nick,
        });

        userDispatch({
          type: 'LOGIN',
          user: {
            ...user,
            imageUrl: fileUrl,
            name: data.nick,
          },
        });

        alert(t('v1.MypageProfile.Success'));
      } catch (error) {
        alert(t('v1.MypageProfile.Error01'));
      }
    })();

    return false;
  };

  return (
    <>
      {isLogin === 1 && (
        <div
          className={cn('wrap', {
            wrapKo: t('languageCode') === 'ko',
            wrapEn: t('languageCode') === 'en',
            wrapId: t('languageCode') === 'id',
            wrapVi: t('languageCode') === 'vi',
          })}
        >
          <Header />

          <section className="mypage-wrap">
            <h2 className="blind">{t('v1.Mypage.Title')}</h2>
            <div className="inner">
              <MypageLeftNav isLogin={isLogin} user={user} t={t} />
              <article className="mypage-content profile-setting">
                <h3 className="mypage-title">{t('v1.MypageProfile.Title')}</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="profile">
                    {/* <strong>{t('MyPage.MyPage_Profile')}</strong> */}
                    <div>
                      <div className="pic">
                        {profileImageUrl && (
                          <img src={profileImageUrl} alt="" style={{ verticalAlign: 'middle' }} />
                        )}
                        {!profileImageUrl && <img src="/assets/images/my.svg" alt="" />}
                      </div>
                      <button
                        type="button"
                        className="btn-pic"
                        onClick={(e) => {
                          e.preventDefault();
                          profileInputFile.current.click();
                        }}
                      >
                        {t('v1.MypageProfile.BtnImageChange')}
                      </button>
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        accept="image/*"
                        {...rest}
                        ref={(e) => {
                          ref(e);
                          profileInputFile.current = e;
                        }}
                      />
                    </div>
                    <button
                      type="button"
                      className="btn-del"
                      onClick={(e) => {
                        e.preventDefault();
                        profileInputFile.current.value = null;
                        setProfileImageUrl(undefined);
                        setIsProfileDelete(true);
                      }}
                    >
                      {t('v1.MypageProfile.BtnImageDelete')}
                    </button>
                  </div>
                  <div className="form">
                    <div className="row">
                      <label htmlFor="porfile" className="sbj">
                        {t('v1.MypageProfile.Label_Id')}
                      </label>
                      <input
                        type="text"
                        id="porfile"
                        value={isLogin === 1 && (user.id || '')}
                        readOnly
                        disabled={true}
                        className={cn('profile_id', {
                          google: isLogin === 1 && user.mberType === 'GOOGLE',
                          facebook: isLogin === 1 && user.mberType === 'FACEBOOK',
                          apple: isLogin === 1 && user.mberType === 'APPLE',
                        })}
                        style={{ backgroundColor: '#eee' }}
                      />
                    </div>
                    <div className="row">
                      <label htmlFor="nick" className="sbj">
                        {t('v1.MypageProfile.Label_Nick')}
                      </label>
                      <input
                        type="text"
                        id="nick"
                        placeholder={t('v1.MypageProfile.Placeholder_Nick')}
                        {...register('nick')}
                      />
                    </div>
                    <div className="row">
                      <label className="sbj">{t('v1.MypageProfile.Label_HanNick')}</label>
                      <button
                        type="button"
                        className={cn('btn-make', { open: !isKorNickMakeBox })}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsKorNickMakeBox(!isKorNickMakeBox);
                        }}
                      >
                        {t('v1.MypageProfile.BtnHanNickOpen')}
                      </button>
                      <div
                        className="nick-list"
                        style={{ display: isKorNickMakeBox ? 'block' : 'none' }}
                      >
                        {typoProfileList.map((typeProfileItem, typeProfileIndex) => {
                          return (
                            <button
                              key={'typeProfile' + typeProfileIndex}
                              type="button"
                              className={cn('btn-nick', { active: typeProfileItem.isSelect })}
                              onClick={(e) => {
                                e.preventDefault();
                                onTypeProfileSelect(typeProfileIndex);
                              }}
                            >
                              <img
                                src={typeProfileItem.imageUrl}
                                style={{ width: '100%', height: '100%' }}
                              />
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    <div className="btn-area">
                      <div className="right">
                        <button type="submit" className="btn-save">
                          {t('v1.MypageProfile.BtnSave')}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </article>
            </div>
          </section>
          <Footer />
        </div>
      )}
    </>
  );
};

export default MypageProfile;
