import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { useUserState, useUserDispatch } from '../context/UserContext';
import { useTranslation } from 'react-i18next';

import * as Api from '../library/Api';
import cn from 'classnames';
import { confirmAlert } from 'react-confirm-alert'; // Import

import ReactPlayer from 'react-player/file';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Navigation, Pagination } from 'swiper';
import '../assets/swiper-bundle.min.css';

import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import TopikConfirm from './topik/layout/TopikConfirm';
import TopikPayInfo from './topik/layout/TopikPayInfo';

const MainMember = ({ isLogin, user, t }) => {
  const navigate = useNavigate();

  const [isVisualPattern1Class, setIsVisualPattern1Class] = useState(false);
  const [isVisualPattern2Class, setIsVisualPattern2Class] = useState(false);
  const [isVisualPattern3Class, setIsVisualPattern3Class] = useState(false);
  const [isVisualPattern4Class, setIsVisualPattern4Class] = useState(false);

  const userDispatch = useUserDispatch();

  const swiperPrevRef = useRef(null);
  const swiperNextRef = useRef(null);
  const swiperPaginationRef = useRef(null);

  const nowDate = new Date();
  const nowMonth = nowDate.toLocaleString('en-US', { month: 'short' }).toUpperCase();
  const nowDay = nowDate.getDate();

  const [todayList, setTodayList] = useState([]);
  const [todayPlayList, setTodayPlayList] = useState([]);

  const [topikList, setTopikList] = useState([]);
  const [topikFinishList, setTopikFinishList] = useState([]);
  const [isTopikPayInfo, setIsTopikPayInfo] = useState(false);

  const [recentLearning, setRecentLearning] = useState([]);
  const [learningRecommend, setLearningRecommend] = useState([]);

  const [reactPlay, setReactPlay] = useState({ is: false, url: '' });
  const reactPlayerRef = useRef();

  useEffect(() => {
    if (isLogin === 0) {
      //alert('로그인 후 이용가능합니다.');
      navigate('/member/login');
    }
  }, [isLogin]);

  useEffect(() => {
    if (isLogin === 1) {
      console.log('main Log');
      setTimeout(() => {
        setIsVisualPattern1Class(true);
      }, 150);
      setTimeout(() => {
        setIsVisualPattern2Class(true);
      }, 300);
      setTimeout(() => {
        setIsVisualPattern3Class(true);
      }, 450);
      setTimeout(() => {
        setIsVisualPattern4Class(true);
      }, 600);
    }

    if (isLogin === 1) {
      (async () => {
        try {
          const todayListResponse = await Api.studyhomeTodayList(user.esntlId);
          if (todayListResponse.data.result.data) {
            setTodayList(todayListResponse.data.result.data);
            setTodayPlayList(
              todayListResponse.data.result.data.map((todayItem, todayIndex) => {
                return {
                  todayId: todayItem.todayId,
                  isTextTts: false,
                  textTtsUrl: todayItem.todayTextTtsUrl,
                  isExampleTts: false,
                  exampleTtsUrl: todayItem.todayExampleTtsUrl,
                };
              }),
            );
          }
        } catch (error) {}

        try {
          const topikListResponse = await Api.topikList({ esntlId: user.esntlId });
          if (topikListResponse.data.result.data.reward) {
            let tmpTopikOriList = topikListResponse.data.result.data.reward;
            let tmpTopikList = [];
            for (let i = 0; i < tmpTopikOriList.length; i++) {
              if (tmpTopikOriList[i].payYn === 'F' || tmpTopikOriList[i].payYn === 'Y') {
                tmpTopikList.push(tmpTopikOriList[i]);
                if (tmpTopikList.length >= 3) break;
              }
            }
            setTopikList(tmpTopikList);
          }

          const topikRecordListResponse = await Api.topikRecordList({ esntlId: user.esntlId });
          console.log('topikRecordListResponse', topikRecordListResponse);
          if (topikRecordListResponse.data.result.data.complete)
            setTopikFinishList(topikRecordListResponse.data.result.data.complete);
        } catch (error) {}

        try {
          const learningRecommendResponse = await Api.studyhomeLearningRecommend(user.esntlId);
          if (learningRecommendResponse.data.result.data)
            setLearningRecommend(learningRecommendResponse.data.result.data);
        } catch (error) {}

        try {
          const recentLearningResponse = await Api.studyhomeRecentLearning(user.esntlId);
          //const recentLearningResponse = await Api.studyhomeRecentLearning('USRCNFRM_00000000818');
          if (recentLearningResponse.data.result.data)
            setRecentLearning(recentLearningResponse.data.result.data);
        } catch (error) {}
      })();
    }
  }, [isLogin]);

  useEffect(() => {
    for (let i = 0; i < recentLearning.length; i++) {
      donut('latest0' + i, recentLearning[i].progress);
    }
  }, [recentLearning]);

  const onTodayTextTts = (index) => {
    setTodayPlayList(
      todayPlayList.map((todayItem, todayIndex) => {
        if (todayIndex === index)
          setReactPlay({ is: !todayItem.isTextTts, url: todayItem.textTtsUrl });

        return todayIndex === index
          ? { ...todayItem, isTextTts: !todayItem.isTextTts, isExampleTts: false }
          : { ...todayItem, isTextTts: false, isExampleTts: false };
      }),
    );
  };

  const onTodayExampleTts = (index) => {
    setTodayPlayList(
      todayPlayList.map((todayItem, todayIndex) => {
        if (todayIndex === index) {
          setReactPlay({ is: !todayItem.isExampleTts, url: todayItem.exampleTtsUrl });

          (async () => {
            console.log('todayItemtodayItem, ', todayItem);
            Api.studyhomeInsertToday(user.esntlId, todayItem.todayId);
          })();
        }

        return todayIndex === index
          ? { ...todayItem, isExampleTts: !todayItem.isExampleTts, isTextTts: false }
          : { ...todayItem, isExampleTts: false, isTextTts: false };
      }),
    );
  };

  const onReactPlayerEnd = () => {
    setReactPlay({ is: false, url: '' });
    setTodayPlayList(
      todayPlayList.map((todayItem, todayIndex) => {
        return { ...todayItem, isExampleTts: false, isTextTts: false };
      }),
    );
  };

  const modalTopikPayInfoOpen = () => {
    setIsTopikPayInfo(true);
    document.body.style.overflow = 'hidden';
  };

  const modalTopikPayInfoClose = () => {
    setIsTopikPayInfo(false);
    document.body.style.overflow = 'unset';
  };

  const donut = (target, precent) => {
    var endPercent = precent;
    var canvas = document.getElementById(target);
    var context = canvas.getContext('2d');
    var x = canvas.width / 2;
    var y = canvas.height / 2;
    var radius = 63;
    var curPerc = 0;
    var circ = Math.PI * 2;
    var quart = Math.PI / 2;

    context.lineWidth = 14;
    context.strokeStyle = '#fff';
    context.lineCap = 'round';

    function animate(current) {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.beginPath();
      context.arc(x, y, radius, -quart, circ * current - quart, false);
      context.stroke();
      curPerc++;
      if (curPerc < endPercent) {
        requestAnimationFrame(function () {
          animate(curPerc / 99);
        });
      }
    }
    animate();
  };

  return (
    <>
      <ReactPlayer
        ref={reactPlayerRef}
        className="react-player"
        url={reactPlay.url}
        playing={reactPlay.is}
        onEnded={() => {
          onReactPlayerEnd();
          setReactPlay({ is: false, url: '' });
        }}
        onPlay={() => {
          reactPlayerRef.current.seekTo(0, 'seconds');
        }}
      />
      {isLogin === 1 && (
        <div
          className={cn('wrap', {
            wrapKo: t('languageCode') === 'ko',
            wrapEn: t('languageCode') === 'en',
            wrapId: t('languageCode') === 'id',
            wrapVi: t('languageCode') === 'vi',
          })}
        >
          <Header />
          <section className="main-contents">
            <article className="key-visual" style={{ position: 'relative' }}>
              <div className="inner">
                <div className="txt-area">
                  <Trans i18nKey="Learning_home.Learning_Visual">
                    <h2 />
                    <p />
                  </Trans>
                </div>
                <div className="icon"></div>
              </div>
              <div className="obj">
                <div className={cn('pattern01', { move: isVisualPattern1Class })}></div>
                <div className={cn('pattern02', { move: isVisualPattern2Class })}></div>
                <div className={cn('pattern03', { move: isVisualPattern3Class })}></div>
                <div className={cn('pattern04', { move: isVisualPattern4Class })}></div>
              </div>
            </article>

            <article className="main-today">
              <div className="date">
                <span className="month">{nowMonth}</span>
                <span className="day">{nowDay}</span>
              </div>
              <h2 className="article-title">
                <Trans i18nKey="Learning_home.Learning_Today_Title" />
              </h2>
              <div className="today-slide">
                <Swiper
                  className="swiper-wrapper"
                  modules={[Navigation]}
                  loop="true"
                  touchRatio="0"
                  navigation={{
                    nextEl: swiperNextRef.current,
                    prevEl: swiperPrevRef.current,
                  }}
                >
                  {todayList.map((todayItem, todayIndex) => {
                    return (
                      <SwiperSlide className="con swiper-slide" key={'today' + todayIndex}>
                        {todayItem.todayImgUrl && (
                          <div className="img">
                            <img
                              src={todayItem.todayImgUrl}
                              alt={t('Learning_home.Learning_Today_Title')}
                            />
                          </div>
                        )}
                        {todayItem.todayEmoji && (
                          <div className="img" style={{ fontSize: '100px' }}>
                            {todayItem.todayEmoji}
                          </div>
                        )}
                        <div className="txt swiper-slide">
                          <div className="today">
                            <strong>
                              {todayItem.todayTextKor}{' '}
                              <button
                                className={cn('btn-listen', {
                                  active: todayPlayList[todayIndex].isTextTts,
                                })}
                                onClick={(e) => {
                                  e.preventDefault();
                                  onTodayTextTts(todayIndex);
                                }}
                              >
                                <img
                                  src="/assets/images/voice.svg"
                                  alt="발음듣기"
                                  style={{ width: '35%' }}
                                />
                              </button>
                            </strong>
                            <p className="translation">
                              {t('languageCode') === 'ko' && todayItem.todayTextEn}
                              {t('languageCode') === 'en' && todayItem.todayTextEn}
                              {t('languageCode') === 'id' && todayItem.todayTextIn}
                              {t('languageCode') === 'vi' && todayItem.todayTextViet}
                            </p>
                          </div>
                          <div className="detail">
                            <p className="original">
                              <span>{todayItem.todayExample}</span>
                              <button
                                className={cn('btn-listen', {
                                  active: todayPlayList[todayIndex].isExampleTts,
                                })}
                                onClick={(e) => {
                                  e.preventDefault();
                                  onTodayExampleTts(todayIndex);
                                }}
                              >
                                <img
                                  src="/assets/images/voice.svg"
                                  alt="발음듣기"
                                  style={{ width: '35%' }}
                                />
                              </button>
                            </p>
                            <p className="translation">
                              {t('languageCode') === 'ko' && todayItem.todayExampleEn}
                              {t('languageCode') === 'en' && todayItem.todayExampleEn}
                              {t('languageCode') === 'id' && todayItem.todayExampleIn}
                              {t('languageCode') === 'vi' && todayItem.todayExampleViet}
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <div className="slide-btn">
                  <button className="btn-prev" ref={swiperPrevRef}>
                    {t('common.btn_before')}
                  </button>
                  <button className="btn-next" ref={swiperNextRef}>
                    {t('common.btn_next')}
                  </button>
                </div>
              </div>
            </article>
            <article className="main-recommend">
              <h2 className="article-title">
                <Trans i18nKey="Learning_home.Learning_Promotion_Title" />
              </h2>
              <div className="item-wrap">
                {learningRecommend.map((item, index) => {
                  const isLevel1 = item.courseLevel.substr(0, 5) === 'BEGIN' ? true : false;
                  const isLevel2 = item.courseLevel.substr(0, 5) === 'INTER' ? true : false;
                  const isLevel3 = item.courseLevel.substr(0, 8) === 'BUSINESS' ? true : false;
                  return (
                    <div
                      className="item"
                      key={'mainRecommend' + index}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(
                          '/speak/unitProblem/' +
                            item.courseId +
                            '/' +
                            item.classId +
                            '/' +
                            item.unitId +
                            '/0',
                        );
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <div className="tag">
                        {isLevel1 && (
                          <span className="beginner">{t('common.Learning_Grade_Lv1')}</span>
                        )}
                        {isLevel2 && (
                          <span className="medium">{t('common.Learning_Grade_Lv2')}</span>
                        )}
                        {isLevel3 && (
                          <span className="expert">{t('common.Learning_Grade_Lv3')}</span>
                        )}{' '}
                        {/*
                      <span className="sbj">
                        {t('Learning_home.Learning_Promotion_Separation2')}
                      </span>{' '}
                       */}
                        <span>{t('Learning_home.Learning_Promotion_Separation1')}</span>
                      </div>
                      <div className="txt">
                        <p className="original">{item.classTextKor}</p>
                        {user && user.viewTrans && (
                          <p className="translation">
                            {t('languageCode') === 'ko' && item.classTextEn}
                            {t('languageCode') === 'en' && item.classTextEn}
                            {t('languageCode') === 'id' && item.classTextIn}
                            {t('languageCode') === 'vi' && item.classTextViet}
                          </p>
                        )}
                      </div>
                      <div className="user">
                        {item.profileImages.map((profileUrl, index2) => {
                          return (
                            <span className="pic" key={'profile-' + index + '-' + index2}>
                              <img src={profileUrl} alt="" />
                            </span>
                          );
                        })}
                        <span className="status">{t('Learning_home.Learning_Promotion_ETC')}</span>
                      </div>
                    </div>
                  );
                })}
                {learningRecommend.length < 3 && (
                  <div className="item none">추천학습 없습니다.</div>
                )}
                {learningRecommend.length < 2 && (
                  <div className="item none">추천학습 없습니다.</div>
                )}
                {learningRecommend.length < 1 && (
                  <div className="item none">추천학습 없습니다.</div>
                )}
              </div>
            </article>

            <article className="main-latest">
              <div className="inner">
                <h2 className="article-title">{t('Learning_home.Learning_Recent_Title')}</h2>
                <div className="item-wrap">
                  {recentLearning.map((recentLearningItem, recentLearningIndex) => {
                    const isLevel1 =
                      recentLearningItem.courseLevel.substr(0, 5) === 'BEGIN' ? true : false;
                    const isLevel2 =
                      recentLearningItem.courseLevel.substr(0, 5) === 'INTER' ? true : false;
                    const isLevel3 =
                      recentLearningItem.courseLevel.substr(0, 8) === 'BUSINESS' ? true : false;

                    let tCode = '';
                    switch (recentLearningItem.courseLevel) {
                      case 'BEGIN_L1':
                        tCode = 'common.Learning_Grade_Lv1-1';
                        break;
                      case 'BEGIN_L2':
                        tCode = 'common.Learning_Grade_Lv1-2';
                        break;
                      case 'BEGIN_L3':
                        tCode = 'common.Learning_Grade_Lv1-3';
                        break;
                      case 'INTER_L1':
                        tCode = 'common.Learning_Grade_Lv1-4';
                        break;
                      case 'INTER_L2':
                        tCode = 'common.Learning_Grade_Lv1-5';
                        break;
                      case 'BUSINESS_L1':
                        tCode = 'common.Learning_Grade_Lv2-1';
                        break;
                      case 'BUSINESS_L2':
                        tCode = 'common.Learning_Grade_Lv2-2';
                        break;
                      case 'BUSINESS_L3':
                        tCode = 'common.Learning_Grade_Lv3-1';
                        break;
                      case 'BUSINESS_L4':
                        tCode = 'common.Learning_Grade_Lv3-2';
                        break;
                      default:
                        break;
                    }

                    //donut('latest0' + recentLearningIndex, recentLearningItem.progress);
                    //donut('latest02', 50);
                    //donut('latest03', 50);
                    return (
                      <>
                        <div
                          className={cn('item', {
                            beginner: isLevel1,
                            medium: isLevel2,
                            expert: isLevel3,
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate('/speak/unit/' + recentLearningItem.courseId);
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <div className="cate">
                            {/* <span>{recentLearningItem.kind}</span> */}
                            <span>{t('Learning_home.Learning_Promotion_Separation1')}</span>
                          </div>
                          <h3>
                            {t('languageCode') === 'ko' && recentLearningItem.courseTextKor}
                            {t('languageCode') === 'en' && recentLearningItem.courseTextEn}
                            {t('languageCode') === 'id' && recentLearningItem.courseTextIn}
                            {t('languageCode') === 'vi' && recentLearningItem.courseTextViet}
                          </h3>
                          <div className="progress">
                            <div className="graph">
                              <canvas
                                id={'latest0' + recentLearningIndex}
                                width="140"
                                height="140"
                              ></canvas>
                            </div>
                            <p className="percentage">
                              {recentLearningItem.progress}
                              <span>%</span>
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  {recentLearning.length !== 0 && recentLearning.length < 3 && (
                    <div className="item none" style={{ width: '30%' }}>
                      <p>{t('Learning_home.Learning_Topik_Description')}</p>
                    </div>
                  )}
                  {recentLearning.length !== 0 && recentLearning.length < 2 && (
                    <div className="item none" style={{ width: '30%' }}>
                      <p>{t('Learning_home.Learning_Topik_Description')}</p>
                    </div>
                  )}

                  {recentLearning.length < 1 && (
                    <div className="item none">
                      <p>{t('Learning_home.Learning_Topik_Description')}</p>
                    </div>
                  )}
                </div>
              </div>
            </article>
            <article className="main-topik">
              <h2 className="article-title">{t('Learning_home.Learning_Topik_Title')}</h2>
              <Link to="/topik" className="btn-all">
                {t('Learning_home.Learning_Topik_More')}
              </Link>
              {topikList.map((topikItem, topikKey) => {
                return (
                  <div
                    className={cn('type', {
                      one: topikItem.questionLevel === 1,
                      two: topikItem.questionLevel === 2,
                    })}
                    key={'maintopikList' + topikItem.rwdKind}
                  >
                    <h3>
                      {topikItem.questionLevel === 1 && 'TOPIK l'}
                      {topikItem.questionLevel === 2 && 'TOPIK ll'}
                    </h3>
                    <div className="info">
                      <Link
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();

                          if (topikItem.useYn !== 'Y') {
                            navigate('/topik/view/' + topikItem.rwdKind);
                          } else {
                            if (topikItem.payYn === 'Y' || topikItem.payYn === 'F') {
                              confirmAlert({
                                customUI: ({ onClose }) => {
                                  return (
                                    <TopikConfirm
                                      t={t}
                                      content={
                                        t('TOPIK.Alert_TOPIK_Re_Title') +
                                        '<br />' +
                                        t('TOPIK.Alert_TOPIK_Re_info')
                                      }
                                      onClose={onClose}
                                      onConfirm={() => {
                                        navigate('/topik/view/' + topikItem.rwdKind);
                                      }}
                                    />
                                  );
                                },
                              });
                            } else {
                              modalTopikPayInfoOpen();
                            }
                          }
                        }}
                      >
                        <div className="title">
                          <span className="sbj">
                            {t('languageCode') === 'ko' && topikItem.rwdDesc}
                            {t('languageCode') === 'en' && topikItem.rwdDescEn}
                            {t('languageCode') === 'id' && topikItem.rwdDescIn}
                            {t('languageCode') === 'vi' && topikItem.rwdDescViet}
                          </span>
                          <span
                            className={cn({
                              free: topikItem.payAt === 'N',
                              payment: topikItem.payAt === 'Y',
                            })}
                          >
                            {topikItem.payAt === 'N' && t('Learning_home.Learning_Topik_Badge1')}
                            {topikItem.payAt === 'Y' && t('Learning_home.Learning_Topik_Badge2')}
                          </span>
                          {topikItem.viewYn === 'N' && <span className="new">N</span>}
                        </div>
                        <div className="time">
                          <Trans
                            i18nKey="Learning_home.Learning_Topik_Time"
                            values={{ n: topikItem.topikTime }}
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </article>
            {isTopikPayInfo && <TopikPayInfo t={t} onClose={modalTopikPayInfoClose} />}
          </section>
          <Footer />
        </div>
      )}
    </>
  );
};

export default MainMember;
