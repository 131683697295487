import React, { useState, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import cn from 'classnames';

const SpeakUnitProblem02 = ({
  t,
  user,
  onNextStep,
  nowStep,
  classStepList,
  onModalShow,
  onMicTestShow,
}) => {
  return (
    <>
      <p className="noti">{t('Speak_Edu.Speak_Edu_Sentence_Info')}</p>
      <div className="detail-content">
        <div className="sentence-list">
          {classStepList.map((classStepData, classStepIndex) => {
            //class =item active,item complete
            return (
              <div
                key={'classStep' + classStepIndex}
                className={cn('item', { complete: classStepData.pronScore })}
                style={{ cursor: 'pointer' }}
                onMouseEnter={(e) => {
                  e.currentTarget.classList.add('active');
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.classList.remove('active');
                }}
                onClick={(e) => {
                  e.preventDefault();
                  onModalShow(classStepData, classStepIndex);
                }}
              >
                <span className="check"></span>
                <p className="original">{classStepData.classStepTextKor}</p>
                {user && user.viewTrans && (
                  <p className="translation">
                    {t('languageCode') === 'ko' && classStepData.classStepTextEn}
                    {t('languageCode') === 'en' && classStepData.classStepTextEn}
                    {t('languageCode') === 'id' && classStepData.classStepTextIn}
                    {t('languageCode') === 'vi' && classStepData.classStepTextViet}
                  </p>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="btn-area">
        <div className="left">
          <Link
            to="#"
            className="btn-test"
            onClick={(e) => {
              e.preventDefault();
              onMicTestShow(true);
            }}
          >
            {t('common.btn_microphone')}
          </Link>
        </div>
        <div className="right">
          <Link
            to="#"
            className="btn-default btn-next"
            onClick={(e) => {
              e.preventDefault();
              onNextStep();
            }}
          >
            {t('common.btn_next')}
          </Link>
        </div>
      </div>
    </>
  );
};

export default SpeakUnitProblem02;
