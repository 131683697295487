import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import cn from 'classnames';

import * as Api from '../../library/Api';

import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import moment from 'moment/moment';
import SpeakIconStart3 from './layout/SpeakIconStart3';

const SpeakUnit = ({ isLogin, user, t }) => {
  const navigate = useNavigate();
  const { courseId } = useParams();

  const [courseData, setCourseData] = useState({ courseLevel: '', progress: 0 });
  const [isLevel1, setIsLevel1] = useState(false);
  const [isLevel2, setIsLevel2] = useState(false);
  const [isLevel3, setIsLevel3] = useState(false);
  const [unitList, setUnitList] = useState([]);

  const [openIndex, setOpenIndex] = useState(-1);
  const [lastUnit, setLastUnit] = useState(0);
  const unitListsRef = useRef([]);

  useEffect(() => {
    if (isLogin === 0) {
      //alert('로그인 후 이용가능합니다.');
      navigate('/member/login');
    } else if (isLogin === 1) {
      (async () => {
        try {
          const speakCourseDataResponse = await Api.speakCourseList({
            esntlId: user.esntlId,
            courseId: courseId,
          });
          if (
            speakCourseDataResponse.data.result.data &&
            speakCourseDataResponse.data.result.data.length > 0
          )
            setCourseData(speakCourseDataResponse.data.result.data[0]);
        } catch (error) {}

        try {
          const speakUnitListResponse = await Api.speakUnitList({
            esntlId: user.esntlId,
            courseId: courseId,
          });
          if (speakUnitListResponse.data.result.data) {
            let tmpData = speakUnitListResponse.data.result.data;
            for (let i = 0; i < tmpData.length; i++) {
              tmpData[i].totalProgress = 0;
              for (let j = 0; j < tmpData[i].classList.length; j++) {
                tmpData[i].totalProgress += tmpData[i].classList[j].progress;
              }
            }
            setUnitList(tmpData);
          }
        } catch (error) {}

        try {
          const speakRecentUnitResponse = await Api.speakRecentUnit({
            esntlId: user.esntlId,
            courseId: courseId,
          });
          if (
            speakRecentUnitResponse &&
            speakRecentUnitResponse.data &&
            speakRecentUnitResponse.data.result &&
            speakRecentUnitResponse.data.result.data &&
            speakRecentUnitResponse.data.result.data.recentUnit
          ) {
            setLastUnit(speakRecentUnitResponse.data.result.data.recentUnit);
          } else {
            setOpenIndex(0);
          }
        } catch (error) {}
      })();
    }

    return () => {};
  }, [isLogin]);

  useEffect(() => {
    if (lastUnit) {
      console.log('lastUnit', lastUnit);
      // unitListsRef.current[unitIndex]
      // audioPlayRef.current[isReactAudioPlay.index].scrollIntoView({ block: 'center' });
      for (let i = 0; i < unitList.length; i++) {
        if (unitList[i].unitId === lastUnit) {
          setOpenIndex(i);
          if (i > 0) {
            unitListsRef.current[i - 1].scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
        }
      }
    }
  }, [lastUnit]);

  useEffect(() => {
    setIsLevel1(courseData.courseLevel.substr(0, 5) === 'BEGIN' ? true : false);
    setIsLevel2(courseData.courseLevel.substr(0, 5) === 'INTER' ? true : false);
    setIsLevel3(courseData.courseLevel.substr(0, 8) === 'BUSINESS' ? true : false);

    if (courseData.progress) donut('course-chart', courseData.progress);
    else donut('course-chart', 0);
  }, [courseData]);

  const donut = (target, precent) => {
    var canvas = document.getElementById(target);
    var context = canvas.getContext('2d');
    var radius = 110;
    var curPerc = 0;
    var endPercent = precent;
    var start = 0.92 * Math.PI;
    var end = 1.75 * Math.PI;
    var end2 = (endPercent * 0.0083 + 0.92) * Math.PI;

    context.lineWidth = 14;
    context.lineCap = 'round';

    //context.strokeStyle = 'rgba(255, 255, 255, .3)';
    context.strokeStyle = 'rgba(255, 255, 255, .2)';
    context.beginPath();
    context.arc(120, 120, radius, start, end, false);
    context.stroke();

    // context.strokeStyle = '#fff';
    // context.beginPath();
    // context.arc(120, 120, radius, start, end2, false);
    // context.stroke();

    function animate() {
      //context.strokeStyle = '#cf3';
      context.strokeStyle = '#fff';
      context.beginPath();
      context.arc(120, 120, radius, start, (curPerc * 0.0083 + 0.92) * Math.PI, false);
      context.stroke();

      curPerc++;
      if (curPerc < endPercent) {
        requestAnimationFrame(function () {
          animate(curPerc / 99);
        });
      }
    }
    animate();
  };

  return (
    <>
      <div
        className={cn('wrap', {
          wrapKo: t('languageCode') === 'ko',
          wrapEn: t('languageCode') === 'en',
          wrapId: t('languageCode') === 'id',
          wrapVi: t('languageCode') === 'vi',
        })}
      >
        <Header />
        <section className="sub-contents">
          <article
            className={cn('sub-visual', {
              'speak-beginner1-visual': courseData.courseLevel === 'BEGIN_L1',
              'speak-beginner2-visual': courseData.courseLevel === 'BEGIN_L2',
              'speak-medium1-visual': courseData.courseLevel === 'INTER_L1',
              'speak-medium2-visual': courseData.courseLevel === 'INTER_L2',
              'speak-expert-visual': isLevel3,
            })}
          >
            <div className="inner">
              <div className="txt-area">
                <h2>
                  {isLevel1 && (
                    <Trans i18nKey="Speak_List.Speak_Top_Course_Lv1">
                      <b />
                    </Trans>
                  )}
                  {isLevel2 && (
                    <Trans i18nKey="Speak_List.Speak_Top_Course_Lv2">
                      <b />
                    </Trans>
                  )}
                  {isLevel3 && (
                    <Trans i18nKey="Speak_List.Speak_Top_Course_Lv3">
                      <b />
                    </Trans>
                  )}
                </h2>
              </div>
              <div className="status">
                <canvas width="240" height="240" id="course-chart"></canvas>
                <div className="num">
                  <b style={{ color: '#fff' }}>{courseData.finishCnt}</b>
                  <span>/{courseData.totalCnt}</span>
                </div>
              </div>
            </div>
          </article>

          <div className="sub-inner">
            <article
              className={cn('course-wrap', {
                'course-beginner': isLevel1,
                'course-medium': isLevel2,
                'course-expert': isLevel3,
              })}
            >
              <h3 className="page-title">
                {t('languageCode') === 'ko' && courseData.courseTextKor}
                {t('languageCode') === 'en' && courseData.courseTextEn}
                {t('languageCode') === 'id' && courseData.courseTextIn}
                {t('languageCode') === 'vi' && courseData.courseTextViet}
              </h3>
              <div className="expression-list">
                {unitList.map((unitItem, unitIndex) => {
                  return (
                    <div
                      className={cn('expression', { open: unitIndex === openIndex })}
                      ref={(el) => (unitListsRef.current[unitIndex] = el)}
                      key={'unit' + unitIndex}
                    >
                      <div
                        className="expression-title"
                        onClick={(e) => {
                          e.currentTarget.parentElement.classList.toggle('open');
                        }}
                      >
                        <h4>
                          {/* 1 단원. / Unit 1. */}
                          <b>{t('common.Unit_Number', { n: unitIndex + 1 })}</b>
                          <span>
                            {unitItem.unitTextKor}
                            {t('languageCode') === 'en' && <span>{unitItem.unitTextEn}</span>}
                            {t('languageCode') === 'id' && <span>{unitItem.unitTextIn}</span>}
                            {t('languageCode') === 'vi' && <span>{unitItem.unitTextViet}</span>}
                          </span>
                        </h4>
                        {unitItem.totalProgress > 0 && (
                          <span
                            className={cn('status', {
                              ing: unitItem.totalProgress < 300,
                              complete: unitItem.totalProgress >= 300,
                            })}
                          >
                            {unitItem.totalProgress < 300 && t('Speak_List.Speak_list_Ing')}
                            {unitItem.totalProgress >= 300 && t('Speak_List.Speak_list_done')}
                          </span>
                        )}
                      </div>
                      <div className="expression-content">
                        {unitItem.classList.map((classItem, classIndex) => {
                          if (classItem.classGubun === 'QUIZ') {
                            return (
                              <div
                                className="expression-item"
                                style={{ cursor: 'pointer' }}
                                key={'class-' + unitIndex + '-' + classIndex}
                                onMouseEnter={(e) => {
                                  e.currentTarget.classList.add('active');
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.classList.remove('active');
                                }}
                                onClick={() => {
                                  navigate(
                                    '/speak/unitQuiz/' +
                                      courseId +
                                      '/' +
                                      classItem.classId +
                                      '/' +
                                      classItem.unitId,
                                  );
                                }}
                              >
                                <p>{t('Speak_List.Speak_list_Quiz')}</p>
                                <div className="progress single">
                                  <dl>
                                    <dd>
                                      <div className="bar">
                                        <div style={{ width: classItem.progress + '%' }}></div>
                                      </div>
                                      <span
                                        className={cn('num', {
                                          ing: classItem.progress < 100,
                                          complete: classItem.progress >= 100,
                                        })}
                                      >
                                        {classItem.progress}%
                                      </span>
                                    </dd>
                                  </dl>
                                </div>
                                <div className="util">
                                  <div className="answer">
                                    <span className="correct">
                                      <i>맞음</i>
                                      {typeof classItem.quizScore !== 'undefined' &&
                                        classItem.quizScore}
                                      {typeof classItem.quizScore === 'undefined' && '-'}
                                    </span>
                                    <span className="incorrect">
                                      <i>틀림</i>
                                      {typeof classItem.quizScore !== 'undefined' &&
                                        5 - classItem.quizScore}
                                      {typeof classItem.quizScore === 'undefined' && '-'}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                className="expression-item"
                                key={'class-' + unitIndex + '-' + classIndex}
                                onMouseEnter={(e) => {
                                  e.currentTarget.classList.add('active');
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.classList.remove('active');
                                }}
                              >
                                <p
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate(
                                      '/speak/unitProblem/' +
                                        courseId +
                                        '/' +
                                        classItem.classId +
                                        '/' +
                                        classItem.unitId +
                                        '/0',
                                    );
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {classItem.classTextKor}
                                </p>
                                <div className="progress">
                                  <Link
                                    to={
                                      '/speak/unitProblem/' +
                                      courseId +
                                      '/' +
                                      classItem.classId +
                                      '/' +
                                      classItem.unitId +
                                      '/0'
                                    }
                                  >
                                    <dl>
                                      <dt>{t('Speak_List.Speak_Common_Expression')}</dt>
                                      <dd>
                                        <div className="bar">
                                          <div
                                            style={{
                                              width: classItem.step1 + '%',
                                            }}
                                          ></div>
                                        </div>
                                        <span
                                          className={cn('num', {
                                            ing: classItem.step1 > 0,
                                            complete: classItem.step1 >= 100,
                                          })}
                                        >
                                          {classItem.step1 < 100 && classItem.step1 + '%'}
                                          {classItem.step1 >= 100 &&
                                            t('Speak_List.Speak_list_done')}
                                        </span>
                                      </dd>
                                    </dl>
                                  </Link>
                                  <Link
                                    to={
                                      '/speak/unitProblem/' +
                                      courseId +
                                      '/' +
                                      classItem.classId +
                                      '/' +
                                      classItem.unitId +
                                      '/1'
                                    }
                                  >
                                    <dl>
                                      <dt>{t('Speak_List.Speak_Common_Sentence')}</dt>
                                      <dd>
                                        <div className="bar">
                                          <div
                                            style={{
                                              width: classItem.step2 + '%',
                                            }}
                                          ></div>
                                        </div>
                                        <span
                                          className={cn('num', {
                                            ing: classItem.step2 > 0,
                                            complete: classItem.step2 >= 100,
                                          })}
                                        >
                                          {classItem.step2 < 100 && classItem.step2 + '%'}
                                          {classItem.step2 >= 100 &&
                                            t('Speak_List.Speak_list_done')}
                                        </span>
                                      </dd>
                                    </dl>
                                  </Link>
                                  <Link
                                    to={
                                      '/speak/unitProblem/' +
                                      courseId +
                                      '/' +
                                      classItem.classId +
                                      '/' +
                                      classItem.unitId +
                                      '/2'
                                    }
                                  >
                                    <dl>
                                      <dt>{t('Speak_List.Speak_Common_Talk')}</dt>
                                      <dd>
                                        <div className="bar">
                                          <div
                                            style={{
                                              width: classItem.step3 + '%',
                                            }}
                                          ></div>
                                        </div>
                                        <span
                                          className={cn('num', {
                                            ing: classItem.step3 > 0,
                                            complete: classItem.step3 >= 100,
                                          })}
                                        >
                                          {classItem.step3 < 100 && classItem.step3 + '%'}
                                          {classItem.step3 >= 100 &&
                                            t('Speak_List.Speak_list_done')}
                                        </span>
                                      </dd>
                                    </dl>
                                  </Link>
                                </div>
                                <div className="util">
                                  <div className="star">
                                    <SpeakIconStart3 score={classItem.pronTotal} size={25} />
                                  </div>
                                  <div className="time">
                                    {moment.utc(classItem.totalTime * 1000).format('HH:mm:ss')}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </article>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default SpeakUnit;
