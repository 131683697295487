import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import ReactPlayer from 'react-player/file';
import { Trans } from 'react-i18next';

const SpeakUnitQuiz02 = ({
  t,
  onNextStep,
  onStepChange,
  onAnswerAdd,
  nowStep,
  totalStep,
  quizData,
  answerData,
}) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [isAnswer, setIsAnswer] = useState(false);
  const [isAudioPlay, setIsAudioPlay] = useState(false);
  const reactPlayerRef = useRef();

  const [selectQuiz, setSelectQuiz] = useState('');
  const [selectQuizList, setSelectQuizList] = useState([]);

  const [isPlayToast, setIsPlayToast] = useState(false);
  const [isPlay, setIsPlay] = useState(false);

  useEffect(() => {
    console.log('SpeakUnitQuiz02', 'quizData reload');
    if (!answerData.isSuccess) {
      setIsSubmit(false);
      setIsAnswer(false);
      setIsAudioPlay(false);
      setSelectQuiz('');
      setSelectQuizList([]);

      setIsPlayToast(false);
      setIsPlay(false);
    }
  }, [quizData]);

  useEffect(() => {
    console.log('SpeakUnitQuiz02', 'nswerData.isSuccess reload');
    if (answerData.isSuccess) {
      setIsSubmit(true);
      setIsAnswer(answerData.selectItems[nowStep].isCorrect);
      setSelectQuiz(answerData.selectItems[nowStep].answerCheck);
      setSelectQuizList(answerData.selectItems[nowStep].item);
      setIsPlay(true);
    }
  }, [answerData.isSuccess, nowStep]);

  const playToastShow = () => {
    setIsPlayToast(true);
    setTimeout(() => {
      setIsPlayToast(false);
    }, 3000);
  };

  const drop = (event) => {
    if (event.target.classList.contains('drag-content')) {
      if (event.target.classList.contains('answer-word')) {
        const exampleText = event.dataTransfer.getData('exampleText');
        const exampleIndex = event.dataTransfer.getData('exampleIndex');

        setSelectQuiz(selectQuiz === '' ? exampleText : selectQuiz + ' ' + exampleText);
        setSelectQuizList([...selectQuizList, quizData.exampleList[exampleIndex]]);
      }
    }
  };

  const selectQuizSubmit = () => {
    if (selectQuiz !== '') {
      if (!isSubmit) {
        setIsSubmit(true);
        if (selectQuiz === quizData.exampleAnswer) {
          setIsAnswer(true);
          onAnswerAdd(nowStep, true, selectQuizList);
        } else {
          onAnswerAdd(nowStep, false, selectQuizList);
        }
      } else {
        onNextStep();
      }
    }
  };

  return (
    <>
      <ReactPlayer
        ref={reactPlayerRef}
        className="react-player"
        url={quizData.questionAttributeFile}
        playing={isAudioPlay}
        onEnded={() => {
          setIsAudioPlay(false);
        }}
        onPlay={() => {
          reactPlayerRef.current.seekTo(0, 'seconds');
        }}
      />
      <div className="detail-content">
        <div className="title-area">
          <h3 className="em">{quizData.questionNm}</h3>
          {t('languageCode') !== 'ko' && <p>{t('v1.SpeakQuiz.Text02')}</p>}
        </div>
        <div className="btn-area bubble-wrap" style={{ display: 'block' }}>
          <button
            type="button"
            className={cn('btn-listen', { active: isAudioPlay })}
            onClick={(e) => {
              e.preventDefault();
              setIsAudioPlay(!isAudioPlay);
              setIsPlay(true);
            }}
          >
            <img src="/assets/images/voice.svg" alt="듣기" />
          </button>
          {!isPlay && (
            <div
              className={cn('bubble', 'top-bubble', { open: isPlayToast })}
              style={{ top: '-15px' }}
            >
              {!isPlay && <p>{t('TOPIK_Edu.TOPIK_Tooltips_Audio')}</p>}
            </div>
          )}
        </div>
        <div
          className={cn('word-wrap', {
            incorrect: isSubmit && !isAnswer,
          })}
        >
          {!isSubmit && (
            <button
              type="button"
              className="btn-retry"
              onClick={(e) => {
                e.preventDefault();
                setSelectQuiz('');
                setSelectQuizList([]);
              }}
            >
              {t('common.Quiz_Again')}
            </button>
          )}
          <div className="word-content">
            {isSubmit && !isAnswer && (
              <div
                className="answer-word"
                style={{
                  position: 'absolute',
                  width: '100%',
                  zIndex: '999',
                  backgroundColor: 'rgba(245, 123, 123, 0.7)',
                  color: '#fff',
                  textAlign: 'center',
                }}
              >
                <div className="txt" style={{ width: '100%', textAlign: 'center' }}>
                  {quizData.exampleAnswer}
                </div>
              </div>
            )}

            <div
              className="answer-word drag-content incor"
              onDrop={(e) => {
                drop(e);
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
            >
              {Array.isArray(selectQuizList) &&
                selectQuizList.map((exampleItem, exampleIndex) => {
                  return (
                    <span
                      className="word"
                      draggable="true"
                      key={'wordExampleItem' + exampleIndex}
                      onClick={(e) => {
                        if (!isSubmit) {
                          setSelectQuizList(
                            selectQuizList.filter(
                              (selectQuiz, selectQuizIndex) => selectQuizIndex !== exampleIndex,
                            ),
                          );
                          let selectQuizText = '';
                          for (let i = 0; i < selectQuizList.length; i++) {
                            if (i === exampleIndex) continue;
                            if (selectQuizText !== '') selectQuizText += ' ';
                            selectQuizText += selectQuizList[i].exampleText;
                          }
                          setSelectQuiz(selectQuizText);
                        }
                      }}
                      onDragStart={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {exampleItem.exampleText}
                    </span>
                  );
                })}
            </div>

            <div
              className="word-list drag-content"
              onDrop={(e) => {
                //drop(e);
                e.preventDefault();
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
            >
              {quizData.exampleList &&
                quizData.exampleList.map((exampleItem, exampleIndex) => {
                  let draggable = true;
                  for (let i = 0; i < selectQuizList.length; i++) {
                    if (selectQuizList[i].exampleId === exampleItem.exampleId) {
                      draggable = false;
                    }
                  }
                  return (
                    <span
                      className={cn('word', { empty: !draggable })}
                      id={'word0' + (exampleIndex + 1)}
                      draggable={draggable}
                      onClick={(e) => {
                        if (!isSubmit) {
                          if (!isPlay) {
                            playToastShow();
                          } else if (draggable) {
                            setSelectQuiz(
                              selectQuiz === ''
                                ? exampleItem.exampleText
                                : selectQuiz + ' ' + exampleItem.exampleText,
                            );
                            setSelectQuizList([...selectQuizList, exampleItem]);
                          }
                        }
                      }}
                      onDragStart={(e) => {
                        e.preventDefault();
                      }}
                      /*
                      onDragStart={(e) => {
                        e.dataTransfer.setData('exampleText', exampleItem.exampleText);
                        e.dataTransfer.setData('exampleIndex', exampleIndex);
                      }}
                      */
                      key={'exampleItem' + exampleIndex}
                    >
                      {exampleItem.exampleText}
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        {isSubmit && (
          <div className={cn('result', { correct: isAnswer }, { incorrect: !isAnswer })}>
            <div className="result-correct">
              <span>{t('Speak_Edu.Answer')}</span>
              <Trans i18nKey="common.Quiz_Ans_Number" values={{ n: answerData.correctCount }} />
            </div>
            <div className="result-incorrect">
              <span>{t('Speak_Edu.Wrong')}</span>
              {answerData.incorrectCount}개
            </div>
            <p className="result-txt">{isAnswer ? t('common.Quiz_Ans') : t('common.Quiz_Wrong')}</p>
          </div>
        )}
      </div>
      {!answerData.isSuccess && (
        <div className="btn-area">
          <div className="right">
            <Link
              to="#"
              className={cn('btn-default', { 'btn-next': isSubmit, 'btn-confirm': !isSubmit })}
              onClick={(e) => {
                e.preventDefault();
                selectQuizSubmit();
              }}
            >
              {isSubmit ? t('common.btn_next') : t('common.btn_Check')}
            </Link>
          </div>
        </div>
      )}
      {answerData.isSuccess && (
        <div className="btn-area">
          <div className="left">
            <Link
              to="#"
              className={'btn-default'}
              onClick={(e) => {
                e.preventDefault();
                onStepChange(totalStep);
              }}
            >
              퀴즈 결과
            </Link>
          </div>
          <div className="right">
            {nowStep > 0 && (
              <Link
                to="#"
                className={'btn-default btn-prev'}
                onClick={(e) => {
                  e.preventDefault();
                  onStepChange(nowStep - 1);
                }}
              >
                {t('common.btn_before')}
              </Link>
            )}
            &nbsp;
            <Link
              to="#"
              className={'btn-default btn-next'}
              onClick={(e) => {
                e.preventDefault();
                onStepChange(nowStep + 1);
              }}
            >
              {t('common.btn_next')}
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default SpeakUnitQuiz02;
