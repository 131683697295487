import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import cn from 'classnames';

import Footer from '../../layouts/Footer';
import Header from '../../layouts/Header';

const JoinSuccess = ({ t }) => {
  return (
    <div
      className={cn('wrap', {
        wrapKo: t('languageCode') === 'ko',
        wrapEn: t('languageCode') === 'en',
        wrapId: t('languageCode') === 'id',
        wrapVi: t('languageCode') === 'vi',
      })}
    >
      <Header />
      <section className="join-wrap">
        <h2 className="blind">회원가입 완료</h2>
        <div className="join-complete">
          <p>
            <Trans i18nKey="Member.Member_Signup_Complete_Ment" />
          </p>
          <Link to="/member/login">{t('Member.Member_Signup_Complete_btn')}</Link>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default JoinSuccess;
