import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import cn from 'classnames';
import TopikViewSuccessInfo from './TopikViewSuccessInfo';
import TopikViewSuccessList from './TopikViewSuccessList';
import TopikViewSuccessView from './TopikViewSuccessView';

const TopikViewSuccess = ({ t, topikData, questionList, resultData, onClose, onReset }) => {
  const [pageType, setPageType] = useState('info'); // info, list
  const [questionView, setQuestionView] = useState({
    is: false,
    totalStep: 0,
    nowStep: 0,
    list: [],
    data: {},
  });

  const [listType, setListType] = useState(''); // 공백, lc, rc

  const onQuestionViewOpen = (questionData) => {
    setQuestionView({
      is: true,
      totalStep: questionData.totalStep,
      nowStep: questionData.nowStep,
      list: questionData.list,
      data: questionData.data,
    });
  };

  const onQuestionViewClose = () => {
    setQuestionView({
      is: false,
      totalStep: 0,
      nowStep: 0,
      data: {},
    });
  };

  const onQuestionViewPrev = () => {
    if (questionView.nowStep > 1) {
      setQuestionView({
        ...questionView,
        nowStep: questionView.nowStep - 1,
        data: questionView.list[questionView.nowStep - 2],
      });
    }
  };

  const onQuestionViewNext = () => {
    if (questionView.totalStep > questionView.nowStep) {
      setQuestionView({
        ...questionView,
        nowStep: questionView.nowStep + 1,
        data: questionView.list[questionView.nowStep],
      });
    }
  };

  return (
    <>
      {questionView.is === true && (
        <TopikViewSuccessView
          t={t}
          topikData={topikData}
          questionData={questionView}
          onQuestionViewClose={onQuestionViewClose}
          onQuestionViewPrev={onQuestionViewPrev}
          onQuestionViewNext={onQuestionViewNext}
        />
      )}
      {questionView.is === false && (
        <div className="pop-wrap">
          <header className="pop-header exam-header">
            <h1 className="title">
              {t('languageCode') === 'ko' && topikData.rwdDesc}
              {t('languageCode') === 'en' && topikData.rwdDescEn}
              {t('languageCode') === 'id' && topikData.rwdDescIn}
              {t('languageCode') === 'vi' && topikData.rwdDescViet}
            </h1>
            <Link
              to="#"
              className="btn-close"
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            >
              {t('v1.Common.BtnClose')}
            </Link>
          </header>
          <section className="pop-contents result-contents">
            <div className="contents-inner">
              <div>
                <div className="title-tab">
                  <h2
                    className={cn('page-title', { active: pageType === 'info' })}
                    onClick={(e) => {
                      e.preventDefault();
                      setPageType('info');
                    }}
                  >
                    {t('v1.TopikSuccess.TabMenu1')}
                  </h2>
                  <h2
                    className={cn('page-title', { active: pageType === 'list' })}
                    onClick={(e) => {
                      e.preventDefault();
                      setPageType('list');
                    }}
                  >
                    {t('v1.TopikSuccess.TabMenu2')}
                  </h2>
                </div>
                {pageType === 'info' && (
                  <TopikViewSuccessInfo
                    t={t}
                    topikData={topikData}
                    questionList={questionList}
                    resultData={resultData}
                    onReset={onReset}
                    setPageType={setPageType}
                    listType={listType}
                    setListType={setListType}
                  />
                )}

                {pageType === 'list' && (
                  <TopikViewSuccessList
                    t={t}
                    resultData={resultData}
                    onQuestionViewOpen={onQuestionViewOpen}
                    setPageType={setPageType}
                    listType={listType}
                    setListType={setListType}
                  />
                )}
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default TopikViewSuccess;
