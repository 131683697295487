import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';
import moment from 'moment';

import * as Api from '../../library/Api';

import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import TopikView from './TopikView';
import TopikViewSuccess from './layout/TopikViewSuccess';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Legend);

const TopikHistory = ({ isLogin, user, t }) => {
  const navigate = useNavigate();

  const [isVisualCircle1Class, setIsVisualCircle1Class] = useState(false);
  const [isVisualCircle2Class, setIsVisualCircle2Class] = useState(false);
  const [isVisualCircle3Class, setIsVisualCircle3Class] = useState(false);
  const [isVisualCircle4Class, setIsVisualCircle4Class] = useState(false);
  const [isVisualCircle5Class, setIsVisualCircle5Class] = useState(false);
  const [isVisualCircle6Class, setIsVisualCircle6Class] = useState(false);

  const chartParentDivRef = useRef();

  const [listType, setListType] = useState('month');
  const [listNumber, setListNumber] = useState(3);
  const [isListLoad, setIsListLoad] = useState(false);

  const [totalData, setTotalData] = useState({
    level: 0,
    lcScore: 0,
    rcScore: 0,
  });
  const [completeList, setCompleteList] = useState([]);
  const completeListRef = useRef([]);
  const [topikViewData, setTopikViewData] = useState({
    is: false,
    topikData: {},
    questionList: {},
    resultData: {},
  });

  const chartRef = useRef(null);
  const chartDefaultTooltipIndex = useRef(-1);
  const [chartOptions, setChartOptions] = useState({
    responsive: true,
    maintainAspectRatio: false,
    events: ['click'],
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: false,
      },
      showAllTooltips: true,
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem) => {
            let reValue = [chartSubjectData.current[tooltipItem.dataIndex]];
            reValue.push(t('v1.Common.UnitLevel1', { n: tooltipItem.formattedValue }));
            return reValue;
          },
        },
      },
    },
    scales: {
      y: {
        max: 6,
        min: -0.07,
        ticks: {
          stepSize: 1,
          callback: function (value, index, ticks) {
            if (value <= 0) return t('v1.TopikHistory.Chart_Level0');
            else return t('v1.Common.UnitLevel1', { n: value });
          },
        },
      },
    },
    onClick: (evt, element) => {
      chartRef.current.update();
      if (element.length > 0) {
        let tmpCompleteList = [...completeListRef.current];
        tmpCompleteList = tmpCompleteList.map((item) => {
          return { ...item, isOpen: false };
        });
        if (tmpCompleteList[completeListRef.current.length - 1 - element[0].index]) {
          tmpCompleteList[completeListRef.current.length - 1 - element[0].index].isOpen = true;
          setCompleteList(tmpCompleteList);
        }
      }
    },
  });
  const chartSubjectData = useRef([]);
  const [chartDataLength, setChartDataLength] = useState(0);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: t('v1.TopikHistory.Chart_Label'),
        data: [],
        borderColor: 'rgb(144, 87, 221)',
        backgroundColor: 'rgba(144, 87, 221, 1)',

        hoverBackgroundColor: 'rgba(255, 61, 98, 1)',
        hoverBorderColor: 'rgba(255, 61, 98, 1)',
        hoverBorderWidth: 5,
      },
    ],
  });

  useEffect(() => {
    if (isLogin === 0) {
      //alert('로그인 후 이용가능합니다.');
      navigate('/member/login');
    }
  }, [isLogin]);

  useEffect(() => {
    console.log('completeList', completeList);
  }, [completeList]);

  useEffect(() => {
    setChartData({
      ...chartData,
      datasets: [
        {
          ...chartData.datasets[0],
          label: t('v1.TopikHistory.Chart_Label'),
        },
      ],
    });
  }, [t]);

  useEffect(() => {
    if (isLogin === 1) {
      load('month', 3);
    }

    return () => {};
  }, [isLogin, t]);

  const load = (type, number) => {
    setListType(type);
    setListNumber(number);

    setTimeout(() => {
      setIsVisualCircle1Class(true);
    }, 150);
    setTimeout(() => {
      setIsVisualCircle2Class(true);
    }, 300);
    setTimeout(() => {
      setIsVisualCircle3Class(true);
    }, 450);
    setTimeout(() => {
      setIsVisualCircle4Class(true);
    }, 600);
    setTimeout(() => {
      setIsVisualCircle5Class(true);
    }, 750);
    setTimeout(() => {
      setIsVisualCircle6Class(true);
    }, 900);

    chartDefaultTooltipIndex.current = -1;
    (async () => {
      try {
        setIsListLoad(true);
        console.log('@@@@ 1');
        const topikMyRecordResponse = await Api.topikMyRecord({ esntlId: user.esntlId });
        if (topikMyRecordResponse.data.result.data) {
          setTotalData({
            ...totalData,
            level: topikMyRecordResponse.data.result.data.level,
            lcScore: Math.floor(topikMyRecordResponse.data.result.data.record[0].lcScore * 2),
            rcScore: Math.floor(topikMyRecordResponse.data.result.data.record[0].rcScore * 2),
          });
        }

        console.log('@@@@ 2');
        /*
        const topikRecordListResponse = await Api.topikRecordList({ esntlId: user.esntlId });
        console.log('topikRecordListResponse', topikRecordListResponse);
        if (topikRecordListResponse.data.result.data.complete) {
          setCompleteList(topikRecordListResponse.data.result.data.complete);
        }
        */

        let param = {
          esntlId: user.esntlId,
        };
        if (type === 'month') {
          param.filterMonth = number;
        } else if (type === 'week') {
          param.filterWeek = number;
        }

        console.log('@@@@ 3');
        const topikRecordChartResponse = await Api.topikRecordChart(param);

        if (topikRecordChartResponse.data.result.data.chart.length > 0) {
          // topikRecordList 대체 chart 랑 동일한 데이터로 노출

          let tmpCompleteList = topikRecordChartResponse.data.result.data.chart
            .reverse()
            .map((item) => {
              return item;
            });
          tmpCompleteList[0].isOpen = true;
          setCompleteList(tmpCompleteList);
          completeListRef.current = tmpCompleteList;

          let tmpChartLables = [];
          let tmpChartDatas = [];
          let tmpChartSubjects = [];
          let tmpChartOriData = topikRecordChartResponse.data.result.data.chart.reverse();

          chartDefaultTooltipIndex.current = tmpChartOriData.length - 1;

          for (let i = 0; i < tmpChartOriData.length; i++) {
            tmpChartDatas.push(tmpChartOriData[i].topikLevel);
            if (t('languageCode') === 'ko')
              tmpChartLables.push(moment(tmpChartOriData[i].finishDt).format('MM. DD.'));
            else tmpChartLables.push(moment(tmpChartOriData[i].finishDt).format('MMM. DD'));

            if (t('languageCode') === 'ko') tmpChartSubjects.push(tmpChartOriData[i].rwdDesc);
            if (t('languageCode') === 'en') tmpChartSubjects.push(tmpChartOriData[i].rwdDescEn);
            if (t('languageCode') === 'vi') tmpChartSubjects.push(tmpChartOriData[i].rwdDescViet);
          }

          chartSubjectData.current = tmpChartSubjects;
          setChartData({
            ...chartData,
            labels: [...tmpChartLables],
            datasets: [
              {
                ...chartData.datasets[0],
                label: t('v1.TopikHistory.Chart_Label'),
                data: [...tmpChartDatas],
              },
            ],
          });

          setChartDataLength(tmpChartDatas.length);
          setIsListLoad(false);
        } else {
          setChartData({
            ...chartData,
            labels: [],
            datasets: [
              {
                ...chartData.datasets[0],
                label: t('v1.TopikHistory.Chart_Label'),
                data: [],
              },
            ],
          });
          setChartDataLength(0);
          setIsListLoad(false);
        }

        chartRef.current.tooltip.setActiveElements([], { x: 0, y: 0 });
        chartRef.current.update();
      } catch (error) {
        console.log('error', error);
      }
    })();

    setTimeout(() => {
      if (chartParentDivRef.current) {
        console.log('chartParentDivRef.current.scrollLeft', chartParentDivRef.current.scrollLeft);
        let scrollLeft =
          chartParentDivRef.current.scrollWidth - chartParentDivRef.current.clientWidth;
        chartParentDivRef.current.scrollLeft = scrollLeft;
      }
      triggerTooltip(chartRef.current);
    }, 300);
  };

  const triggerTooltip = (chart) => {
    const tooltip = chart?.tooltip;
    if (!tooltip) {
      return;
    }

    console.log('chartDefaultTooltipIndex', chartDefaultTooltipIndex.current);
    if (chartDefaultTooltipIndex.current === -1) {
      tooltip.setActiveElements([], { x: 0, y: 0 });
      return;
    }

    tooltip.setActiveElements([
      {
        datasetIndex: 0,
        index: chartDefaultTooltipIndex.current,
      },
    ]);

    chart.update();
  };

  const onTopikViewSuccessReset = () => {};
  const onTopikViewSuccessOpen = (completeItem) => {
    (async () => {
      try {
        const topikTopikResponse = await Api.topikTopik({
          questionRound: completeItem.rwdKind,
        });
        const topikResultResponse = await Api.topikResult({
          esntlId: user.esntlId,
          topikId: completeItem.topikId,
        });
        if (topikTopikResponse.data.result.data && topikResultResponse.data.result.data) {
          setTopikViewData({
            ...topikViewData,
            is: true,
            topikData: completeItem,
            questionList: topikTopikResponse.data.result.data,
            resultData: topikResultResponse.data.result.data,
          });
        }
      } catch (error) {}
    })();
  };
  const onTopikViewSuccessClose = () => {
    setTopikViewData({ ...topikViewData, is: false });
  };
  return (
    <>
      {topikViewData.is && (
        <div
          className={cn('wrap', {
            wrapKo: t('languageCode') === 'ko',
            wrapEn: t('languageCode') === 'en',
            wrapId: t('languageCode') === 'id',
            wrapVi: t('languageCode') === 'vi',
          })}
          style={{ overflow: 'auto' }}
        >
          <TopikViewSuccess
            t={t}
            topikData={topikViewData.topikData}
            questionList={topikViewData.questionList}
            resultData={topikViewData.resultData}
            onClose={onTopikViewSuccessClose}
            onReset={onTopikViewSuccessReset}
          />
        </div>
      )}
      {!topikViewData.is && (
        <div
          className={cn('wrap', {
            wrapKo: t('languageCode') === 'ko',
            wrapEn: t('languageCode') === 'en',
            wrapId: t('languageCode') === 'id',
            wrapVi: t('languageCode') === 'vi',
          })}
        >
          <Header />
          <section className="sub-contents">
            <article className="visual topik-visual">
              <div className="inner">
                <div className="txt-area">
                  <h2>
                    {/*<span>모의고사</span> TOPIK*/}
                    {t('v1.Topik.Title')}
                  </h2>
                </div>
                <div className="status">
                  <dl className="latest">
                    <dt>{t('v1.Topik.TopInfo1')}</dt>
                    <dd>
                      {t('languageCode') === 'ko' && (
                        <Trans i18nKey="v1.Common.UnitLevel2" values={{ n: totalData.level }}>
                          <span />
                        </Trans>
                      )}
                      {t('languageCode') !== 'ko' && <>{totalData.level}</>}
                    </dd>
                  </dl>
                  <dl className="listen">
                    <dt>{t('v1.Topik.TopInfo2')}</dt>
                    <dd>
                      {t('languageCode') === 'ko' && (
                        <Trans i18nKey="v1.Common.UnitPoint2" values={{ n: totalData.lcScore }}>
                          <span />
                        </Trans>
                      )}
                      {t('languageCode') !== 'ko' && <>{totalData.lcScore}</>}
                    </dd>
                  </dl>
                  <dl className="read">
                    <dt>{t('v1.Topik.TopInfo3')}</dt>
                    <dd>
                      {t('languageCode') === 'ko' && (
                        <Trans i18nKey="v1.Common.UnitPoint2" values={{ n: totalData.rcScore }}>
                          <span />
                        </Trans>
                      )}
                      {t('languageCode') !== 'ko' && <>{totalData.rcScore}</>}
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="obj">
                <div className={cn('circle01', { move: isVisualCircle1Class })}></div>
                <div className={cn('circle02', { move: isVisualCircle2Class })}></div>
                <div className={cn('circle03', { move: isVisualCircle3Class })}></div>
                <div className={cn('circle04', { move: isVisualCircle4Class })}></div>
                <div className={cn('circle05', { move: isVisualCircle5Class })}></div>
                <div className={cn('circle06', { move: isVisualCircle6Class })}></div>
              </div>
            </article>
            <div className="sub-inner">
              <article className="topik-wrap">
                <div className="title-tab">
                  <h3
                    className="page-title"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/topik');
                    }}
                  >
                    {t('v1.Topik.TabMenu1')}
                  </h3>
                  <h3 className="page-title active">{t('v1.Topik.TabMenu2')}</h3>
                </div>

                <div className="tab-area">
                  <ul className="course-type">
                    <li>
                      <Link
                        to="#"
                        className={cn({ active: listType === 'week' && listNumber === 1 })}
                        onClick={(e) => {
                          e.preventDefault();
                          if (isListLoad === false) {
                            load('week', 1);
                          }
                        }}
                      >
                        {t('v1.TopikHistory.Tab1')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className={cn({ active: listType === 'month' && listNumber === 1 })}
                        onClick={(e) => {
                          e.preventDefault();
                          if (isListLoad === false) {
                            load('month', 1);
                          }
                        }}
                      >
                        {t('v1.TopikHistory.Tab2')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className={cn({ active: listType === 'month' && listNumber === 3 })}
                        onClick={(e) => {
                          e.preventDefault();
                          if (isListLoad === false) {
                            load('month', 3);
                          }
                        }}
                      >
                        {t('v1.TopikHistory.Tab3')}
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="history-chart" style={{ padding: '30px' }}>
                  <div
                    style={{
                      overflowX: chartDataLength > 15 ? 'scroll' : 'visible',
                      width: '100%',
                      height: '100%',
                    }}
                    ref={chartParentDivRef}
                  >
                    <div
                      style={{
                        minWidth: '1140px',
                        width: chartDataLength * 74 + 53 + 'px',
                        height: '100%',
                      }}
                    >
                      <Line
                        ref={chartRef}
                        options={chartOptions}
                        data={chartData}
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>
                  </div>
                </div>
                <div className="history">
                  {completeList.map((completeItem, completeIndex) => {
                    return (
                      <div
                        key={'completeList' + completeIndex}
                        className={cn('item', { open: completeItem.isOpen })}
                        onClick={(e) => {
                          e.preventDefault();
                          let tmpCompleteList = [...completeList];
                          tmpCompleteList[completeIndex].isOpen =
                            !tmpCompleteList[completeIndex].isOpen;
                          setCompleteList(tmpCompleteList);
                        }}
                      >
                        <div className="history-title">
                          <h4
                            onClick={(e) => {
                              e.preventDefault();
                              onTopikViewSuccessOpen(completeItem);
                            }}
                          >
                            {t('languageCode') === 'ko' && completeItem.rwdDesc}
                            {t('languageCode') === 'en' && completeItem.rwdDescEn}
                            {t('languageCode') === 'id' && completeItem.rwdDescIn}
                            {t('languageCode') === 'vi' && completeItem.rwdDescViet}
                          </h4>
                          <span className="tag">
                            <Trans
                              i18nKey="v1.TopikHistory.List_Badge"
                              values={{ n: completeItem.topikLevel }}
                            >
                              <b />
                            </Trans>
                          </span>
                        </div>
                        <div className="toggle-area">
                          <span className="date">
                            {moment(completeItem.finishDt).format('YYYY. MM. DD.')}
                          </span>
                        </div>
                        <div className="content">
                          <ul>
                            <li>
                              <span className="sbj">{t('v1.TopikHistory.List_SubItem1')}</span>
                              <span className="score">
                                <Trans
                                  i18nKey="v1.Common.UnitPoint3"
                                  values={{ n: completeItem.topikScore }}
                                >
                                  <b />
                                </Trans>
                              </span>
                            </li>
                            <li>
                              <span className="sbj">{t('v1.TopikHistory.List_SubItem2')}</span>
                              <span className="score">
                                <Trans
                                  i18nKey="v1.Common.UnitPoint3"
                                  values={{ n: completeItem.lcScore * 2 }}
                                >
                                  <b />
                                </Trans>
                              </span>
                            </li>
                            <li>
                              <span className="sbj">{t('v1.TopikHistory.List_SubItem3')}</span>
                              <span className="score">
                                <Trans
                                  i18nKey="v1.Common.UnitPoint3"
                                  values={{ n: completeItem.rcScore * 2 }}
                                >
                                  <b />
                                </Trans>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/*
              <a href="#" className="btn-more">
                더보기<span></span>
              </a>
                */}
              </article>
            </div>
          </section>
          <Footer />
        </div>
      )}
    </>
  );
};

export default TopikHistory;
