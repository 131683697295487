import React, { useState } from 'react';
import cn from 'classnames';

import Footer from '../../layouts/Footer';
import Header from '../../layouts/Header';
import { Link } from 'react-router-dom';

const Privacy = ({ isLogin, user, t }) => {
  const [buttonType, setButtonType] = useState('ko');
  return (
    <>
      <div
        className={cn('wrap', {
          wrapKo: t('languageCode') === 'ko',
          wrapEn: t('languageCode') === 'en',
          wrapId: t('languageCode') === 'id',
          wrapVi: t('languageCode') === 'vi',
        })}
      >
        <Header />

        <section className="sub-contents">
          <div className="sub-inner">
            <article className="privacy-wrap">
              <div className="title-area">
                <h3 className={cn('page-title', 'kor', { active: buttonType === 'ko' })}>
                  Hancom Genie K (한컴 지니케이) 개인정보처리방침
                </h3>
                <h3 className={cn('page-title', 'eng', { active: buttonType === 'en' })}>
                  Hancom Genie K Privacy Policy
                </h3>
                <div className="lang">
                  <button
                    data-lang="kor"
                    className={cn({ active: buttonType === 'ko' })}
                    onClick={(e) => {
                      e.preventDefault();
                      setButtonType('ko');
                    }}
                  >
                    KOR
                  </button>
                  <button
                    data-lang="eng"
                    className={cn({ active: buttonType === 'en' })}
                    onClick={(e) => {
                      e.preventDefault();
                      setButtonType('en');
                    }}
                  >
                    ENG
                  </button>
                </div>
              </div>
              <div className="privacy-content">
                <div id="kor" className={cn({ active: buttonType === 'ko' })}>
                  <div>
                    <ul>
                      <li>
                        ㈜한글과컴퓨터 (이하 '회사')는 이용자의 개인정보 보호 및 권익을 보호하고
                        개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같이
                        개인정보 처리방침을 정하여 운영하고 있습니다.
                      </li>
                      <li>
                        본 개인정보 처리방침은 회사에서 제공하는 Hancom Genie K (한컴 지니케이)
                        서비스 (이하 ‘서비스’)에 적용됩니다.
                      </li>
                    </ul>
                  </div>

                  <h4>제 1조 개인정보의 처리 목적</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회사는 다음의 목적을 위하여 개인정보를 수집하여 처리합니다. 처리하고 있는
                        개인정보는 다음 목적 이외의 용도로는 이용되지 않으며, 이용목적이 변경되는
                        경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를
                        이행할 예정입니다.
                        <ul className="one-step">
                          <li>
                            1. 회원 관리회원제 서비스 제공에 따른 본인 식별·인증, 회원자격
                            유지·관리, 불량 회원의 부정 이용 방지와 비인가 사용 방지, 각종
                            고지·통지, 고충 처리 등을 목적으로 개인정보를 처리합니다.
                          </li>
                          <li>
                            2. 재화 또는 서비스 제공서비스 제공, 콘텐츠 제공, 맞춤 서비스 제공,
                            본인인증, 요금 결제·정산, 채권추심 등을 목적으로 개인정보를 처리합니다.
                          </li>
                          <li>
                            3. 마케팅 및 광고에 활용상품·서비스, 사은·판촉행사, 이벤트 등 광고성
                            정보 전달, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도
                            파악 또는 회원의 서비스 이용에 대한 통계 활용 등을 목적으로 개인정보를
                            처리합니다.
                          </li>
                          <li>
                            4. 부가서비스 제공회원가입 및 개인정보 수정을 통해 회원님께서 부가서비스
                            이용을 신청하셨을 경우 회사는 원활한 부가서비스 이용을 위하여 수집한
                            개인정보를 온라인 부가서비스 제공 업체에 제공하는 등을 목적으로
                            개인정보를 처리합니다.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>

                  <h4>제 2조 개인정보의 처리 항목 및 보유기간</h4>
                  <div>
                    <ul>
                      <li>
                        ① 원칙적으로 개인정보는 살아있는 개인에 관한 정보를 말합니다. 다만
                        법인·단체에 관한 정보이면서 동시에 개인에 관한 정보인 경우, 개별 상황에 따라
                        개인정보로 처리될 수도 있기 때문에, 법인·단체의 정보도 개인정보처리 항목에
                        포함하여 이해를 돕고자 합니다.
                      </li>
                      <li>
                        ② 회사는 법령에 따른 개인정보 보유기간 또는 이용자로부터 개인정보를 수집
                        시에 동의받은 개인정보 보유기간 내에서 개인정보를 처리하고 보유합니다.
                      </li>
                      <li>
                        <table>
                          <colgroup>
                            <col style={{ width: '15%' }} />
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '30%' }} />
                            <col style={{ width: '25%' }} />
                          </colgroup>
                          <thead>
                            <tr>
                              <th colSpan="2">구분</th>
                              <th colSpan="2">처리 항목</th>
                              <th>보유 및 이용 기간</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td colSpan="2" rowSpan="4">
                                회원가입
                              </td>
                              <td rowSpan="2">이메일 회원가입</td>
                              <td>[필수] 이메일 주소 (로그인 ID), 비밀번호</td>
                              <td rowSpan="4">회원 탈퇴 시까지</td>
                            </tr>
                            <tr>
                              <td>[선택] 프로필 정보(별명, 프로필 사진, 결제 기록)</td>
                            </tr>
                            <tr>
                              <td rowSpan="2">간편 회원가입 (애플, 페이스북, 구글)</td>
                              <td>[필수] 간편가입 계정, 이메일 주소</td>
                            </tr>
                            <tr>
                              <td>[선택] 프로필 정보(별명, 프로필 사진, 결제 기록)</td>
                            </tr>
                            <tr>
                              <td colSpan="2">유료 서비스 구매(인앱(in-app) 결제)</td>
                              <td colSpan="2">영수증 번호</td>
                              <td>법정 의무 보유기간까지</td>
                            </tr>
                            <tr>
                              <td colSpan="2">
                                서비스 이용 시 <br />
                                수집될 수 있는 정보
                              </td>
                              <td colSpan="2">
                                서비스 이용 및 중지 기록, 접속 로그, 쿠키, 접속 IP 정보, 단말기 정보
                              </td>
                              <td>법정 의무 보유기간까지</td>
                            </tr>
                            <tr>
                              <td rowSpan="4">회원 상담∙불만 접수, 처리결과 통보</td>
                              <td>이메일 접수</td>
                              <td colSpan="2">이메일 주소</td>
                              <td rowSpan="4">법정 의무 보유기간까지</td>
                            </tr>
                            <tr>
                              <td>팩스 접수</td>
                              <td colSpan="2">팩스 번호</td>
                            </tr>
                            <tr>
                              <td>전화 접수</td>
                              <td colSpan="2">휴대 전화번호</td>
                            </tr>
                            <tr>
                              <td>우편 접수</td>
                              <td colSpan="2">주소</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                      <li>
                        ③ 관계법령에 의거하여 회사에서 보존하는 개인정보 항목 및 기간은 다음과
                        같습니다.
                        <ul className="one-step">
                          <li>
                            1. 전자상거래 등에서의 소비자 보호에 관한 법률
                            <ul className="two-step">
                              <li>가. 계약 또는 청약철회 등에 관한 기록: 5년</li>
                              <li>나. 대금 결제 및 재화 등의 공급에 관한 기록: 5년</li>
                              <li>다. 소비자의 불만 또는 분쟁처리에 관한 기록: 3년</li>
                            </ul>
                          </li>
                          <li>
                            2. 통신비밀보호법
                            <ul className="two-step">
                              <li>가. 방문(접속) 기록: 3개월</li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li>
                        ④ “회사”는 개인정보보호법에 따라 12개월 이상 로그인하지 않은 경우나, 서비스
                        이용이 없는 경우 해당 “회원”의 ID 및 개인정보를 휴면 ID로 별도 관리합니다.
                        이러한 경우 위 12개월의 기간 도래 30일 전까지 “회사”는 “회원”의 개인정보가
                        분리되어 저장, 관리되고 있다는 사실과 기간 만료일 및 해당 개인정보의 항목을
                        전자우편, 서면, 모사전송, 전화 또는 이와 유사한 방법 중 어느 하나의 방법으로
                        “회원”에게 알려드립니다. 단, 서비스를 이용 중인 경우 기간 만료일과 상관없이
                        서비스 삭제 시점에 휴면 ID로 전환됩니다. 휴면 ID로 전환된 개인정보는 4년간
                        보관 후 지체 없이 파기합니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 3조 개인정보의 파기</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회사는 원칙적으로 개인정보 보유기간의 경과, 개인정보 수집 및 이용목적이
                        달성된 후에는 해당 정보를 지체 없이 파기합니다. 파기절차 및 방법은 다음과
                        같습니다.
                        <ul className="one-step">
                          <li>
                            1. 파기절차
                            <ul className="two-step">
                              <li>
                                가. 회사가 제공하는 서비스를 받는 동안 회원님의 개인정보는 회사가
                                지속적으로 보유하 며 회원님께 회사의 서비스를 제공하기 위하여
                                이용합니다.
                              </li>
                              <li>
                                나. 회원님께서 가입 해지를 요청한 경우 및 회원자격을 상실한 경우
                                개인정보 중에 해지 관련 통계 관리를 위한 최소한의 정보만을 남기고
                                회원 개인을 식별할 수 있는 정보들은 즉시 삭제되며, 어떤 이유나
                                방법으로도 재생되거나 이용할 수 없도록 처리됩니다.
                              </li>
                              <li>
                                다. 유료 서비스 구매 회원의 경우에는 최소한의 개인정보를 남겨둬 제품
                                이용 관련 고객지원 서비스 제공에 문제가 발생하지 않도록 처리됩니다.
                              </li>
                              <li>
                                라. 개인정보의 수집 목적 또는 제공받은 목적이 달성된 때에도 회사는
                                회원님의 개인정보를 지체 없이 파기합니다.
                              </li>
                              <li>
                                마. 수집 목적 또는 제공받은 목적이 달성된 경우에도 상법 등 법령의
                                규정에 의하여 보존할 필요성이 있는 경우에 한하여 회원님의 개인정보를
                                보유할 수 있습니다. 이 경우 해당 개인정보는 보관의 목적으로만
                                관리합니다.
                              </li>
                            </ul>
                          </li>
                          <li>
                            2. 파기 방법
                            <ul className="two-step">
                              <li>
                                가. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
                                파기합니다.
                              </li>
                              <li>
                                나. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는
                                기술적 방법을 사용하여 삭제합니다.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <h4>제 4조 개인정보의 제 3자 제공</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만,
                        아래의 경우에는 예외로 합니다.
                        <ul className="one-step">
                          <li>1. 이용자가 사전에 동의한 경우</li>
                          <li>
                            2. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
                            따라 수사기관의 요구가 있는 경우
                          </li>
                        </ul>
                      </li>
                      <li>
                        ② 회사는 보다 나은 서비스 제공을 위해 회사는 다른 업체와 제휴를 맺어
                        개인정보를 제휴사에 제공할 수 있습니다. 진행 시에는 이용약관 또는 개인정보
                        처리방침을 통해 회원에게 공지하며 약관에 의거한 동의 절차에 따라
                        동의/비동의를 간주합니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 5조 이용자 및 법정대리인의 권리·의무와 그 행사방법</h4>
                  <div>
                    <ul>
                      <li>
                        ① 이용자는 회사에 대하여 언제든지 다음 각 호의 개인정보 보호 관련 권리를
                        행사할 수 있으며, 만 14세 미만 아동의 법정대리인은 그 아동의 개인정보에
                        대하여 열람 등 요구를 할 수 있습니다.
                        <ul className="one-step">
                          <li>- 개인정보 열람 요구</li>
                          <li>- 개인정보에 오류 등이 있을 경우 정정 요구</li>
                          <li>- 개인정보 삭제 요구</li>
                          <li>- 개인정보 처리정지 요구</li>
                        </ul>
                      </li>
                      <li>
                        ② 홈페이지, 애플리케이션을 통한 방법
                        <ul className="one-step">
                          <li>
                            1. 회원은 홈페이지 또는 애플리케이션을 통해 언제든지 본인의 개인정보를
                            열람, 수정 그리고 삭제 (탈퇴) 할 수 있습니다. 개인정보의 열람 및 수정은
                            로그인 후 프로필 메뉴를 통하여 회원님께서 원하시는 내용대로 변경하실 수
                            있습니다. 또한, 회원님께서 더 이상 회사의 서비스를 이용하실 의사가 없을
                            경우, 프로필 메뉴" 내의 "회원 탈퇴"에서 서비스 이용 해지를 신청할 수
                            있습니다.
                          </li>
                        </ul>
                      </li>
                      <li>
                        ③ 이메일, 서면, 전화를 통한 방법
                        <ul className="one-step">
                          <li>
                            1. 홈페이지 또는 애플리케이션을 통해 개인정보에 대한 열람, 정정, 삭제,
                            처리정지 요청을 할 수 없는 경우, 서면, 전화, 전자우편, 모사전송(FAX)
                            등을 통하여 할 수 있으며, 회사는 이에 대해 지체 없이 조치합니다.
                          </li>
                        </ul>
                      </li>
                      <li>
                        ④ 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실
                        수 있으며, 회사는 권리 행사 요청을 받는 경우 회원 본인이거나 정당한
                        대리인인지 확인하기 위하여 신분증, 주민등록등본, 가족관계 증명서 등을 추가로
                        요청할 수 있습니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 6조 개인정보 자동수집 장치의 설치/운영 및 거부에 관한 사항</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회사는 서비스 이용 시 기기 식별 번호(디바이스 아이디 또는 IMEI)를 자동으로
                        수집합니다. 이용자가 기기 식별 번호 자동 수집에 거부하는 경우 서비스를
                        이용할 수 없습니다.
                      </li>
                      <li>
                        ② 회사는 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 이용정보를 저장하고
                        수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
                      </li>
                      <li>
                        ③ 쿠키는 사이트 등을 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터
                        브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에
                        저장되기도 합니다.
                        <ul className="one-step">
                          <li>
                            1. 쿠키의 사용목적: 이용자가 방문한 각 서비스와 사이트 등에 대한 방문 및
                            이용 형태, 인기 검색어, 보안접속 여부 등을 파악하여 이용자에게 최적화된
                            정보 제공을 위해 사용됩니다.
                          </li>
                          <li>
                            2. 쿠키의 설치∙운영 및 거부
                            <ul className="two-step">
                              <li>
                                가. Internet Explorer : 웹 브라우저 상단의 도구 → 인터넷 옵션 →
                                개인정보 → 고급 설정을 통해 쿠키 저장을 거부할 수 있습니다
                              </li>
                              <li>
                                나. Chrome : 웹 브라우저 우측의 설정 메뉴 → 화면 하단의 고급 설정
                                표시 → 개인정보의 콘텐츠 설정 버튼 → 쿠키 저장을 거부할 수 있습니다.
                              </li>
                            </ul>
                          </li>
                          <li>
                            3. 이용자가 쿠키 저장을 거부할 경우 회사가 제공하는 서비스 이용이 일부
                            제한됩니다.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <h4>제 7조 개인정보의 안전성 확보 조치</h4>
                  <div>
                    <ul>
                      <li>
                        ① 관리적 조치 : 개인정보 내부관리 계획 수립 · 시행, 개인정보 처리 직원
                        최소화, 정기적 직원 교육 등
                      </li>
                      <li>
                        ② 기술적 조치 : 개인정보 처리시스템 등의 접근 권한 관리, 접근통제시스템
                        설치, 고유식별 정보 및 비밀번호 등의 암호화, 보안 프로그램 설치 및 주기적인
                        취약점 업데이트 및 점검, 접속기록의 보관
                      </li>
                      <li>
                        ③ 물리적 조치 : 전산실(데이터 센터), 자료 보관실 등의 접근통제 및 잠금장치
                        사용
                      </li>
                    </ul>
                  </div>
                  <h4>제 8조 개인정보 보호책임자</h4>
                  <div>
                    <ul>
                      <li>
                        ① 회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여
                        아래와 같이 개인정보 보호 책임자를 지정하고 있습니다.
                      </li>
                      <li>
                        <table>
                          <colgroup>
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>구분</th>
                              <th>직책</th>
                              <th>성명</th>
                              <th>연락처</th>
                            </tr>
                            <tr>
                              <td>개인정보 보호책임자</td>
                              <td>전략기획본부장</td>
                              <td>김병기</td>
                              <td>e-Mail: privacy.geniek@hancom.com</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                      <li>
                        ② 이용자께서는 회사의 모든 서비스를 이용하시면서 발생한 모든 개인정보 보호
                        관련 문의, 불만 처리, 피해 구제 등에 관한 사항을 개인정보 보호책임자 및 담당
                        부서로 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및
                        처리해드릴 것입니다.
                      </li>
                    </ul>
                  </div>
                  <h4>제 9조 개인정보 열람청구</h4>
                  <div>
                    <ul>
                      <li>
                        ① 이용자는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의
                        부서에 할 수 있습니다. 회사는 이용자의 개인정보 열람청구가 신속하게
                        처리되도록 노력하겠습니다.
                      </li>
                      <li>
                        <table>
                          <colgroup>
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>구분</th>
                              <th>부서명</th>
                              <th>담당자</th>
                              <th>연락처</th>
                            </tr>
                            <tr>
                              <td>개인정보 열람청구 및 접수 처리부서</td>
                              <td>교육서비스팀한국어교육사업실</td>
                              <td>박근형</td>
                              <td>e-Mail: privacy.geniek@hancom.com</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                    </ul>
                  </div>
                  <h4>제 10조 권익 침해 구제방법</h4>
                  <div>
                    <ul>
                      <li>
                        ① 이용자는 아래의 기관에 개인정보 침해에 대한 피해 구제, 상담 등을 문의하실
                        수 있습니다.
                      </li>
                      <li>
                        ② 아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만
                        처리, 피해 구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면
                        문의하여 주시기 바랍니다.
                        <ul className="one-step">
                          <li>
                            1. 개인정보 침해신고센터 (
                            <a href="http://privacy.kisa.or.kr" target="_blank">
                              http://privacy.kisa.or.kr
                            </a>{' '}
                            / 국번 없이 118)
                          </li>
                          <li>
                            2. 개인정보 분쟁조정위원회 (
                            <a href="http://www.kopico.go.kr" target="_blank">
                              http://www.kopico.go.kr
                            </a>{' '}
                            / 1833-6972)
                          </li>
                          <li>
                            3. 대검찰청 사이버수사과 (
                            <a href="http://www.spo.go.kr" target="_blank">
                              http://www.spo.go.kr
                            </a>{' '}
                            / 국번 없이 1301)
                          </li>
                          <li>
                            4. 경찰청 사이버안전국 (
                            <a href="http://cyberbureau.police.go.kr" target="_blank">
                              http://cyberbureau.police.go.kr
                            </a>{' '}
                            / 국번 없이 182)
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <h4>제 11조 개인정보 처리방침 변경</h4>
                  <div>
                    <ul>
                      <li>① 현 개인정보 처리방침은 2022. 8. 10. 부터 적용됩니다.</li>
                      <li>
                        ② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.
                        <br />
                        <a href="http://www.kolaroid.co/policy" target="_blank">
                          {' '}
                          ~ 2022. 8. 9. 적용
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div id="eng" className={cn({ active: buttonType === 'en' })}>
                  <div>
                    <ul>
                      <li>
                        Hancom Inc. (hereafter “the company”) establishes and implements its privacy
                        policy to protect the user’s personal information and their rights and
                        benefits and to resolve any user issues smoothly.
                      </li>
                      <li>
                        This privacy policy applies to the Hancom Genie K service (hereafter “the
                        service”) which is offered by the company.
                      </li>
                    </ul>
                  </div>

                  <h4>Article 1 Purpose of processing personal information</h4>
                  <div>
                    <ul>
                      <li>
                        ① The company collects and processes personal information for the following
                        purposes. Personal information the company processes will not be used for
                        the purposes other than the followings, and necessary procedures including
                        obtaining separate consent, according to Article 18 of the Personal
                        Information Protection Act, will be followed if there is any change to the
                        purposes.
                        <ul className="one-step">
                          <li>
                            1. Member management
                            <br />
                            Personal information will be processed for the following purposes:
                            identity verification for exclusive membership service, maintenance and
                            management of membership qualification, prevention of misuse by
                            delinquent members, prevention of unauthorized use, various notices and
                            announcements, resolving requests and other complaints, etc.
                          </li>
                          <li>
                            2. Provision of currency or Service
                            <br />
                            Personal information will be processed for the following purposes:
                            provision of services, contents, and customized services, user
                            verification, bill payment, bill settlement, payment collection, etc.
                          </li>
                          <li>
                            3. Use in the marketing and advertisements
                            <br />
                            Personal information will be processed for the following purposes:
                            provision of advertisement information including products, services,
                            rewards, promotions, and events, provision of services and advertisement
                            posting per demographic factors, assessment of access frequency, use of
                            statistics regarding member’s service use, etc.
                          </li>
                          <li>
                            4. Provision of additional services
                            <br />
                            When a member requests an optional service after member sign-up and
                            modification of personal information, personal information will be
                            processed to provide on-line optional service provider with collected
                            personal information to offer optional services smoothly.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>

                  <h4>Article 2 Items of personal information processed and retention period</h4>
                  <div>
                    <ul>
                      <li>
                        ① By default, personal information refers to the information of a person who
                        is alive. Yet, when the information is regarding both corporate or
                        organization and a person, it can be processed as personal information in a
                        certain situation. Therefore, the information of corporate or organization
                        is included in the items of personal information processed to make it easy
                        to understand.
                      </li>
                      <li>
                        ② The company processes and retains personal information of its users
                        according to the relevant laws or within the retention period agreed by
                        users when the personal information was collected.
                      </li>
                      <li>
                        <table>
                          <colgroup>
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '20%' }} />
                          </colgroup>
                          <thead>
                            <tr>
                              <th colSpan="2">Category</th>
                              <th colSpan="2">Processed items</th>
                              <th>Period of retention and use</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td colSpan="2" rowSpan="4">
                                Member sign up
                              </td>
                              <td rowSpan="2">E-mail member sign up</td>
                              <td>[Required] E-mail address (Login ID), Password</td>
                              <td rowSpan="4">Until membership withdrawal</td>
                            </tr>
                            <tr>
                              <td>
                                [Optional] Profile information (nickname, profile photo, payment
                                records)
                              </td>
                            </tr>
                            <tr>
                              <td rowSpan="2">Simple member sign up (Apple, Facebook, Google)</td>
                              <td>[Required] Simple sign up account, e-mail address</td>
                            </tr>
                            <tr>
                              <td>
                                [Optional] Profile information (nickname, profile photo, payment
                                records)
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2">Paid service purchase(In-app payment)</td>
                              <td colSpan="2">Receipt number</td>
                              <td>Until legally mandatory retention period</td>
                            </tr>
                            <tr>
                              <td colSpan="2">
                                When you use the service, the following information can be collected
                              </td>
                              <td colSpan="2">
                                Service usage and stoppage record, access logs, cookies, IP
                                connection information, device information
                              </td>
                              <td>Until legally mandatory retention period</td>
                            </tr>
                            <tr>
                              <td rowSpan="4">
                                Member consultation &amp; complaint report, process result
                                notification
                              </td>
                              <td>E-mail report</td>
                              <td colSpan="2">E-mail address</td>
                              <td rowSpan="4">Until legally mandatory retention period</td>
                            </tr>
                            <tr>
                              <td>Fax report</td>
                              <td colSpan="2">Fax number</td>
                            </tr>
                            <tr>
                              <td>Phone report</td>
                              <td colSpan="2">Cell phone number</td>
                            </tr>
                            <tr>
                              <td>Mail report</td>
                              <td colSpan="2">Address</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                      <li>
                        ③ Personal information items the company retains according to the relevant
                        laws and its retention period is as follows.
                        <ul className="one-step">
                          <li>
                            1. The Act on Consumer Protection in Electronic Commerce, etc.
                            <ul className="two-step">
                              <li>
                                A. Records of agreement or subscription cancellations: Five years
                              </li>
                              <li>B. Records on payments and provision of currency: Five years</li>
                              <li>
                                C. Records on consumer complaints or dispute processing: Three years
                              </li>
                            </ul>
                          </li>
                          <li>
                            2. Protection of Communications Secrets Act
                            <ul className="two-step">
                              <li>A. Visit (access) records: Three months</li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li>
                        ④ “The company”, according to the Personal Information Protection Act, will
                        make the ID and personal information of a “member” as dormant if there is no
                        login record or use of services for 12 months or longer. In this case,
                        before 30 days prior to the 12 months period, the “company” will contact a
                        “member” through e-mail, document, fax, phone, or a similar method to notify
                        that which personal information items of a “member” will be separately
                        stored and managed and its expiration date. However, if the member is using
                        a service, regardless of the expiration date, the ID will become dormant
                        once the service is removed. The personal information of a dormant ID will
                        be stored for four years and discarded without delay after four years.
                      </li>
                    </ul>
                  </div>
                  <h4>Article 3 Disposal of Personal Information</h4>
                  <div>
                    <ul>
                      <li>
                        ① In general, the company discards the personal information once the
                        personal information retention period expires, or once the purpose of
                        collecting and using personal information is achieved. Disposal procedure
                        and method are as follows.
                        <ul className="one-step">
                          <li>
                            1. Disposal Procedure
                            <ul className="two-step">
                              <li>
                                A. While a member is using the services by the company, the company
                                retains personal information of the member and it is used to offer
                                the company’s services to the member.
                              </li>
                              <li>
                                B. If a member requests membership withdrawal or if a member loses
                                its member qualification, all personal information which can be used
                                to identify a member will be deleted except minimum information, for
                                the purpose of withdrawal statistics management, and it will be
                                processed in such a way that it will never be restored or used for
                                any reasons or methods.
                              </li>
                              <li>
                                C. For members who are using paid services, minimum personal
                                information will be saved to make sure that customer support service
                                will be processed smoothly in case of a problem with the product.
                              </li>
                              <li>
                                D. The company will discard member’s personal information
                                immediately once the purpose of collecting and providing personal
                                information is achieved.
                              </li>
                              <li>
                                E. The company may retain member’s personal information if there is
                                a reason to do so according to the relevant laws including
                                commercial law even if the purpose of collecting and providing
                                personal information is achieved. In this case, the personal
                                information is managed only for the purpose of storage.
                              </li>
                            </ul>
                          </li>
                          <li>
                            2. Disposal Method
                            <ul className="two-step">
                              <li>
                                A. Personal information printed on papers shall be discarded by a
                                shredder or by incineration.
                              </li>
                              <li>
                                B. Personal information stored electronically shall be deleted using
                                a technical method in which the records cannot be regenerated.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <h4>Article 4 Provision of Personal Information to Third Party</h4>
                  <div>
                    <ul>
                      <li>
                        ① In general, the company does not provide personal information of members
                        to external entities. However, the following are exceptions.
                        <ul className="one-step">
                          <li>1. When the company has a prior consent from a member</li>
                          <li>
                            2. When it is required by relevant laws, or when it is requested by law
                            enforcement authorities according to the procedure and method by the
                            regulations
                          </li>
                        </ul>
                      </li>
                      <li>
                        ② The company may form a partnership with another company and provide
                        personal information to the partner company in order to provide better
                        service. Upon sharing information, the terms of use and privacy policy will
                        be announced to members and consent/non-consent shall be determined
                        according to the consent procedure as in the terms of use.
                      </li>
                    </ul>
                  </div>
                  <h4>
                    Article 5 Rights and Duties of User and its Legal Representative and How to
                    Exercise it
                  </h4>
                  <div>
                    <ul>
                      <li>
                        ① Members may exercise their rights on personal information protection to
                        the company, and the legal representatives for children under 14 years old
                        may exercise their rights, including viewing the personal information of the
                        child.
                        <ul className="one-step">
                          <li>- Request to view personal information</li>
                          <li>- Request to modify personal information when there is an error</li>
                          <li>- Request to discard personal information</li>
                          <li>- Request to suspend processing personal information</li>
                        </ul>
                      </li>
                      <li>
                        ② How to request from the home page/through application
                        <ul className="one-step">
                          <li>
                            1. Members can browse, edit and delete (withdraw) their personal
                            information from the home page or through the application whenever they
                            want. To browse or edit personal information, log in first to access
                            Profile menu to change the details as you wish. Also, when you no longer
                            wish to use the company’s services, you can access Profile menu to
                            select “DELETE” to no longer use the services.
                          </li>
                        </ul>
                      </li>
                      <li>
                        ③ How to request by using e-mail, writing, or phone
                        <ul className="one-step">
                          <li>
                            1. If you cannot request to browse, edit, delete, or suspend processing
                            of personal information from the home page or through the application,
                            you can still use phone, e-mail, fax, or a written request to do so, and
                            the company will immediately process the request.
                          </li>
                        </ul>
                      </li>
                      <li>
                        ④ A legal representative or someone with delegation may exercise the
                        member’s right, and the company may request ID verification including
                        resident registration certificate or family census to verify if the person
                        has the legitimate right.
                      </li>
                    </ul>
                  </div>
                  <h4>
                    Article 6 Matters Regarding the Installation/Operation and Refusal of Automatic
                    Personal Information Collection Devices
                  </h4>
                  <div>
                    <ul>
                      <li>
                        ① The company automatically collects a device ID number (device ID or IMEI)
                        when a member uses the service. If the user refuses the automatic collection
                        of device ID number, the service will not be available.
                      </li>
                      <li>
                        ② The company uses cookies which save and regularly recall user information
                        in order to provide personalized and customized service for users.
                      </li>
                      <li>
                        ③ Cookies are very small information sent by the server which are used to
                        operate the website in the user’s browser and which can be saved on the
                        user’s computer hard disk.
                        <ul className="one-step">
                          <li>
                            1. Purpose of Using Cookies Cookies are used to provide optimized
                            information to users by assessing visit/usage patterns, popular search
                            words, secure access, etc. for the services and websites visited by
                            users.
                          </li>
                          <li>
                            2. Installation/Operation and Refusal of Cookies
                            <ul className="two-step">
                              <li>
                                A. Internet Explorer: You can select Tools (on the top of web
                                browser) → Internet Options → Privacy → Advanced to refuse saving
                                cookies.
                              </li>
                              <li>
                                B. Chrome: You can block cookies from More (on the right of the web
                                browser) → Settings (on the bottom of the screen) → Privacy and
                                security → Cookies.
                              </li>
                            </ul>
                          </li>
                          <li>
                            3. If a user blocks cookies, some services by the company may not be
                            available.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <h4>Article 7 Safety Measures for Personal Information</h4>
                  <div>
                    <ul>
                      <li>
                        ① Management measures: Establish and implement internal privacy policy,
                        minimize the number of staff handling personal information, regular training
                        for staff
                      </li>
                      <li>
                        ② Technical measures: Manage access privilege to personal information
                        processing system, install access control system, encrypt unique
                        identification information and password, install security programs and
                        regular security updates, retain access logs
                      </li>
                      <li>
                        ③ Physical measures: Access control and use of locking device to data
                        processing center (data center) and storage center
                      </li>
                    </ul>
                  </div>
                  <h4>Article 8 Personal information protection officer</h4>
                  <div>
                    <ul>
                      <li>
                        ① The company has a designated personal information protection officer to
                        protect personal information of customers and to resolve any complaints
                        related to personal information.
                      </li>
                      <li>
                        <table>
                          <colgroup>
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>Category</th>
                              <th>Title</th>
                              <th>Name</th>
                              <th>Contact number</th>
                            </tr>
                            <tr>
                              <td>Personal information protection officer</td>
                              <td>Head of strategical planning headquarters</td>
                              <td>Byoungki Kim</td>
                              <td>e-Mail: privacy.geniek@hancom.com</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                      <li>
                        ② You may seek or report any inquiries, civil complaints, damage relief,
                        etc. which are related to personal information protection that may occur
                        while using the company’s services to the personal information protection
                        officer or the department in charge. The company will answer and respond to
                        your inquiry without any delay.
                      </li>
                    </ul>
                  </div>
                  <h4>Article 9 Request to Browse Personal Information</h4>
                  <div>
                    <ul>
                      <li>
                        ① Users may request to browse their personal information, according to
                        Article 35 of Personal Information Protection Act, to the below department
                        in charge. The company will make every efforts to process your request to
                        browse personal information immediately.
                      </li>
                      <li>
                        <table>
                          <colgroup>
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>Category</th>
                              <th>Department name</th>
                              <th>Staff in charge</th>
                              <th>Contact number</th>
                            </tr>
                            <tr>
                              <td>
                                Department in charge to handle request to browse personal
                                information
                              </td>
                              <td>Korean Language Education Business Office</td>
                              <td>Keun Hyung Park</td>
                              <td>e-Mail: privacy.geniek@hancom.com</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                    </ul>
                  </div>
                  <h4>Article 10 How to seek relief for breach of rights</h4>
                  <div>
                    <ul>
                      <li>
                        ① Users may contact the agencies below to seek damage relief, consultation,
                        etc. regarding breach of their personal information.
                      </li>
                      <li>
                        ② The below agencies are not related to the company; you can contact them
                        when you are not satisfied with the company’s own resolution to your
                        inquiries or requests, or when you need additional help.
                        <ul className="one-step">
                          <li>
                            1. Personal Information Infringement Report Center
                            (http://privacy.kisa.or.kr / Press 118)
                          </li>
                          <li>
                            2. Personal Information Dispute Mediation Committee
                            (http://www.kopico.go.kr / Phone: 1833-6972)
                          </li>
                          <li>
                            3. Cyber Investigation Division of the Supreme Prosecutors’ Office
                            (http://www.spo.go.kr / Press 1301)
                          </li>
                          <li>
                            4. Cyber Security Bureau of the National Police Agency
                            (http://cyberbureau.police.go.kr / Press 182)
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <h4>Article 11 Amendment of Privacy Policy</h4>
                  <div>
                    <ul>
                      <li>
                        ① The current version of privacy policy is effective as of August 10, 2022.
                      </li>
                      <li>
                        ② The previous version of privacy policy can be found at the below.
                        <br />
                        <a href="http://www.kolaroid.co/policy" target="_blank">
                          ~ August 9, 2022 Apply (Click)
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default Privacy;
