import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import cn from 'classnames';

import * as Api from '../../../library/Api';

import ReactPlayer from 'react-player/file';
import AudioAnalyser from '../../../library/AudioRecorder';
import SpeakIconStart100 from './SpeakIconStart100';

const SpeakUnitProblem03 = ({
  t,
  user,
  onNextStep,
  nowStep,
  classStepList,
  onModalShow,
  onMicTestShow,
  onResult,
  stepCheck,
}) => {
  const { courseId, classId, unitId } = useParams();

  const [isAudioPlayAll, setIsAudioPlayAll] = useState(false);
  const [isAudioPlayList, setIsAudioPlayList] = useState([]);
  const audioPlayRef = useRef([]);

  const [isReactAudioPlay, setIsReactAudioPlay] = useState({ is: false, url: '', index: 0 });
  const reactPlayerRef = useRef();

  const [talkFirstName, setTalkFirstName] = useState('');

  useEffect(() => {});

  useEffect(() => {
    if (classStepList.length > 0) {
      setTalkFirstName(classStepList[0].speaker);
    }
    setIsAudioPlayList(
      classStepList.map((classStepItem, classStepIndex) => {
        return { audioUrl: classStepItem.classStepTtsUrl, isAudioPlay: false, isMicRec: false };
      }),
    );
  }, [classStepList]);

  useEffect(() => {
    if (isAudioPlayAll === true) {
      audioPlayRef.current[isReactAudioPlay.index].scrollIntoView({ block: 'center' });
    }
  }, [isReactAudioPlay]);

  const onAudioPlayAll = () => {
    if (!isAudioPlayAll === true) {
      onAudioPlay(0);
    } else {
      onAudioPlay(isReactAudioPlay.index);
      setIsAudioPlayAll(false);
    }

    setIsAudioPlayAll(!isAudioPlayAll);
  };

  const onAudioPlayNext = () => {
    if (isAudioPlayAll === true && isReactAudioPlay.index + 1 < isAudioPlayList.length) {
      onAudioPlay(isReactAudioPlay.index + 1);
    } else if (isAudioPlayAll === true) {
      onAudioPlay(isReactAudioPlay.index);
      setIsAudioPlayAll(false);
    } else {
      onAudioPlay(isReactAudioPlay.index);
    }
  };

  const onAudioPlay = (index) => {
    setIsAudioPlayList(
      isAudioPlayList.map((listItem, listIndex) => {
        if (listIndex === index) {
          if (!listItem.isAudioPlay === false) {
            setIsAudioPlayAll(false);
          }
          setIsReactAudioPlay({
            is: !listItem.isAudioPlay,
            url: listItem.audioUrl,
            index: listIndex,
          });
        }

        return listIndex === index
          ? { ...listItem, isAudioPlay: !listItem.isAudioPlay }
          : { ...listItem, isAudioPlay: false };
      }),
    );
  };

  const onMicRec = (index) => {
    setIsAudioPlayList(
      isAudioPlayList.map((listItem, listIndex) => {
        return listIndex === index
          ? { ...listItem, isMicRec: !listItem.isMicRec }
          : { ...listItem, isMicRec: false };
      }),
    );
  };

  return (
    <>
      <ReactPlayer
        ref={reactPlayerRef}
        className="react-player"
        url={isReactAudioPlay.url}
        playing={isReactAudioPlay.is}
        onEnded={() => {
          onAudioPlayNext();
        }}
        onPlay={() => {
          reactPlayerRef.current.seekTo(0, 'seconds');
        }}
      />

      <p className="noti">{t('Speak_Edu.Speak_Edu_Talk_Info1')}</p>
      <div className="detail-content">
        <div className="conversation-wrap">
          <div className="title-area">
            <h3>{t('Speak_Edu.Speak_Edu_Talk_Title')}</h3>
            <button
              type="button"
              className={cn('btn-listen', { active: isAudioPlayAll })}
              onClick={(e) => {
                e.preventDefault();
                onAudioPlayAll();
              }}
            >
              <img src="/assets/images/voice.svg" alt="듣기" />
            </button>
          </div>
          <div className="list">
            {classStepList.map((classStepData, classStepIndex) => {
              return (
                <div
                  className={cn('talk', {
                    partner: talkFirstName === classStepData.speaker,
                    own: talkFirstName !== classStepData.speaker,
                  })}
                  ref={(el) => (audioPlayRef.current[classStepIndex] = el)}
                  key={'classStep' + classStepIndex}
                >
                  <div className="user">
                    <div className="pic">
                      <img src={classStepData.imgUrl} alt="" />
                    </div>
                    <span className="name">{classStepData.speaker}</span>
                  </div>
                  <div
                    className={cn('txt', {
                      active:
                        isAudioPlayList[classStepIndex] &&
                        isAudioPlayList[classStepIndex].isAudioPlay,
                    })}
                  >
                    {/*
                    <button type="button" className="bookmark">
                      북마크 등록됨
                    </button>
                  */}
                    <p className="em">{classStepData.classStepTextKor}</p>
                    {user && user.viewTrans && (
                      <p className="normal">
                        {t('languageCode') === 'ko' && classStepData.classStepTextEn}
                        {t('languageCode') === 'en' && classStepData.classStepTextEn}
                        {t('languageCode') === 'id' && classStepData.classStepTextIn}
                        {t('languageCode') === 'vi' && classStepData.classStepTextViet}
                      </p>
                    )}
                  </div>
                  <div className="util" style={{ position: 'static' }}>
                    <div className="btn-area">
                      <button
                        type="button"
                        className={cn('btn-listen', {
                          active:
                            isAudioPlayList[classStepIndex] &&
                            isAudioPlayList[classStepIndex].isAudioPlay,
                        })}
                        onClick={(e) => {
                          e.preventDefault();
                          onAudioPlay(classStepIndex);
                        }}
                      >
                        <img src="/assets/images/voice.svg" alt="발음듣기" />
                      </button>

                      <button
                        type="button"
                        className={cn('btn-speak', {
                          active:
                            isAudioPlayList[classStepIndex] &&
                            isAudioPlayList[classStepIndex].isMicRec,
                        })}
                        onClick={(e) => {
                          e.preventDefault();
                          //onMicRec(classStepIndex);
                          onModalShow(classStepData, classStepIndex);
                        }}
                      >
                        <img src="/assets/images/voice.svg" alt="말하기" />
                      </button>
                    </div>
                    {classStepData.pronScore && (
                      <div className="star-area">
                        <SpeakIconStart100 score={classStepData.pronScore} size={20} />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="btn-area">
        <div className="left">
          <Link
            to="#"
            className="btn-test"
            onClick={(e) => {
              e.preventDefault();
              onMicTestShow(true);
            }}
          >
            {t('common.btn_microphone')}
          </Link>
        </div>
        <div className="right">
          <span className="info">{t('Speak_Edu.Speak_Edu_Talk_Info2')}</span>
          <Link
            to="#"
            className="btn-default btn-result"
            disabled={!(stepCheck.step1 && stepCheck.step2 && stepCheck.step3)}
            onClick={(e) => {
              e.preventDefault();
              if (stepCheck.step1 && stepCheck.step2 && stepCheck.step3) onResult();
            }}
          >
            {t('common.btn_ViewResults')}
          </Link>
        </div>
      </div>
    </>
  );
};

export default SpeakUnitProblem03;
