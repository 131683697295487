import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import * as Api from '../../library/Api';
import cn from 'classnames';
import Footer from '../../layouts/Footer';
import Header from '../../layouts/Header';
import JoinStep1 from './layout/JoinStep1';
import JoinStep2 from './layout/JoinStep2';
import JoinStep3 from './layout/JoinStep3';
import JoinStep4 from './layout/JoinStep4';

const Join = ({ t }) => {
  const navigate = useNavigate();

  const [formStep, setFormStep] = useState(1);
  const formMethods = useForm({
    mode: 'all',
    defaultValues: {
      isTerms1: false,
      isTerms2: false,
      isTerms3: false,
      isTerms4: false,
      isTerms5: false,
      email: '',
      emailNumber: '',
      emailToken: '',
      password: '',
      password_re: '',
    },
  });

  const completeFormStep = async () => {
    console.log('completeFormStep');
    if (formStep === 1) {
      if (
        !formMethods.getValues('isTerms1') ||
        !formMethods.getValues('isTerms2') ||
        !formMethods.getValues('isTerms3')
      ) {
        alert(t('v1.MemberJoin.Step1_Error01'));
        return false;
      }
    }

    //------------------------------------

    if (formStep === 2) {
      console.log('email error1');
      if (!formMethods.getValues('email')) {
        console.log('email error2');
        formMethods.setError('email', { message: t('v1.MemberJoin.Step2_Error01') });
        return false;
      }

      if (formMethods.formState.errors.email) {
        return false;
      }

      // 중복계정 체크
      try {
        const emailCheckResponse = await Api.checkDuplicateId(formMethods.getValues('email'));
        console.log('emailCheckResponse', emailCheckResponse);
        if (emailCheckResponse.data.result.code === '1') {
          formMethods.setError('email', { message: t('v1.MemberJoin.Step2_Error03') });
          return false;
        }

        const sendAuthMailResponse = await Api.sendAuthMail({
          id: formMethods.getValues('email'),
          lang: t('languageApiCode'),
        });
        console.log('sendAuthMailResponse', sendAuthMailResponse);
        formMethods.setValue('emailToken', sendAuthMailResponse.data.result.data);
      } catch (apiError) {
        formMethods.setError('email', { message: apiError.message });
        return false;
      }
    }

    //------------------------------------

    // 인증번호 확인
    if (formStep === 3) {
      try {
        const checkAuthNumResponse = await Api.checkAuthNum(
          formMethods.getValues('emailToken'),
          formMethods.getValues('emailNumber'),
        );
        console.log('checkAuthNumResponse', checkAuthNumResponse);

        if (Number(checkAuthNumResponse.data.result.code) === 1) {
          alert(t('v1.MemberJoin.Step3_Error02'));
          return false;
        } else if (Number(checkAuthNumResponse.data.result.code) === 2) {
          alert(t('v1.MemberJoin.Step3_Error03'));
          return false;
        }
      } catch (apiError) {
        alert(t('v1.MemberJoin.Step3_Error04'));
        return false;
      }
    }

    //------------------------------------

    // 비밀번호 확인
    if (formStep === 4) {
      if (!formMethods.getValues('password')) {
        formMethods.setError('password', { message: t('v1.MemberJoin.Step4_Error01') });
        return false;
      }

      if (formMethods.getValues('password') !== formMethods.getValues('password_re')) {
        formMethods.setError('password', { message: t('v1.MemberJoin.Step4_Error02') });
        return false;
      }

      if (formMethods.getValues('password').length < 8) {
        formMethods.setError('password', { message: t('v1.MemberJoin.Step4_Error03') });
        return false;
      }

      const passwordRegex = /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#&$%^*+=-_~./]).{8,}$/;
      if (passwordRegex.test(formMethods.getValues('password')) === false) {
        formMethods.setError('password', {
          message: t('v1.MemberJoin.Step4_Error05'),
        });
        return false;
      }
    }

    //------------------------------------

    if (formStep < 4) setFormStep((nowValue) => nowValue + 1);
    else {
      submitForm();
    }
    return false;
  };

  const reEmailSend = async () => {
    try {
      const sendAuthMailResponse = await Api.sendAuthMail({
        id: formMethods.getValues('email'),
        lang: t('languageApiCode'),
      });
      formMethods.setValue('emailToken', sendAuthMailResponse.data.result.data);

      alert(t('v1.MemberJoin.Step3_AlertReSend'));
    } catch (apiError) {
      alert(t('v1.MemberJoin.Step3_Error05'));
      return false;
    }
  };

  const submitForm = async () => {
    console.log('submitForm', formMethods.getValues());
    // 중복계정 체크
    try {
      const signupResponse = await Api.signupNormal({
        ...formMethods.getValues(),
        lang: t('languageApiCode'),
      });
      console.log('signupResponse', signupResponse);
      navigate('/member/joinSuccess');
    } catch (apiError) {
      console.log('signup Error', apiError);
      formMethods.setError('success', { message: apiError.message });
      return false;
    }
  };

  return (
    <>
      <div
        className={cn('wrap', {
          wrapKo: t('languageCode') === 'ko',
          wrapEn: t('languageCode') === 'en',
          wrapId: t('languageCode') === 'id',
          wrapVi: t('languageCode') === 'vi',
        })}
      >
        <Header />
        <section className="join-wrap">
          <h2>{t('v1.MemberJoin.Title')}</h2>
          <ol className="join-step">
            <li className={formStep === 1 ? 'current' : formStep > 1 ? 'past' : undefined}>
              <span className="num">1</span>
              <span className="txt">{t('v1.MemberJoin.TextStep1')}</span>
            </li>
            <li className={formStep === 2 ? 'current' : formStep > 2 ? 'past' : undefined}>
              <span className="num">2</span>
              <span className="txt">{t('v1.MemberJoin.TextStep2')}</span>
            </li>
            <li className={formStep === 3 ? 'current' : formStep > 3 ? 'past' : undefined}>
              <span className="num">3</span>
              <span className="txt">{t('v1.MemberJoin.TextStep3')}</span>
            </li>
            <li className={formStep === 4 ? 'current' : formStep > 4 ? 'past' : undefined}>
              <span className="num">4</span>
              <span className="txt">{t('v1.MemberJoin.TextStep4')}</span>
            </li>
          </ol>
          <div className="inner">
            <article className="join-form">
              <div className="form-section mail">
                <FormProvider {...formMethods}>
                  <form>
                    {formStep === 1 && <JoinStep1 t={t} completeFormStep={completeFormStep} />}
                    {formStep === 2 && <JoinStep2 t={t} completeFormStep={completeFormStep} />}
                    {formStep === 3 && (
                      <JoinStep3
                        t={t}
                        completeFormStep={completeFormStep}
                        onReEmailSend={reEmailSend}
                      />
                    )}
                    {formStep === 4 && <JoinStep4 t={t} completeFormStep={completeFormStep} />}
                  </form>
                </FormProvider>
              </div>
            </article>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Join;
