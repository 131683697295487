import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import ReactPlayer from 'react-player/file';

const SpeakUnitQuiz03 = ({
  t,
  onNextStep,
  onStepChange,
  onAnswerAdd,
  nowStep,
  totalStep,
  quizData,
  answerData,
}) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [isAnswer, setIsAnswer] = useState(false);
  const [isAudioPlay, setIsAudioPlay] = useState(false);
  const reactPlayerRef = useRef();

  const [selectQuiz, setSelectQuiz] = useState({});
  const [selectQuizIndex, setSelectQuizIndex] = useState(-1);

  const [isPlayToast, setIsPlayToast] = useState(false);
  const [isPlay, setIsPlay] = useState(false);

  useEffect(() => {
    console.log('SpeakUnitQuiz03', 'quizData reload');
    if (!answerData.isSuccess) {
      setIsSubmit(false);
      setIsAnswer(false);
      setIsAudioPlay(false);
      setSelectQuiz({});
      setSelectQuizIndex(-1);

      setIsPlayToast(false);
      setIsPlay(false);
    }
  }, [quizData]);

  useEffect(() => {
    console.log('SpeakUnitQuiz03', 'nswerData.isSuccess reload');
    if (answerData.isSuccess) {
      setIsSubmit(true);
      setIsAnswer(answerData.selectItems[nowStep].isCorrect);
      setSelectQuiz(answerData.selectItems[nowStep].item);
      setSelectQuizIndex(Number(answerData.selectItems[nowStep].answerCheck));
      setIsPlay(true);
    }
  }, [answerData.isSuccess, nowStep]);

  const playToastShow = () => {
    setIsPlayToast(true);
    setTimeout(() => {
      setIsPlayToast(false);
    }, 3000);
  };

  const selectQuizSubmit = () => {
    if (selectQuizIndex !== -1) {
      if (!isSubmit) {
        setIsSubmit(true);
        if (selectQuizIndex === Number(quizData.exampleAnswer)) {
          setIsAnswer(true);
          onAnswerAdd(nowStep, true, selectQuiz);
        } else {
          onAnswerAdd(nowStep, false, selectQuiz);
        }
      } else {
        onNextStep();
      }
    }
  };
  return (
    <>
      <ReactPlayer
        ref={reactPlayerRef}
        className="react-player"
        url={quizData.questionAttributeFile}
        playing={isAudioPlay}
        onEnded={() => {
          setIsAudioPlay(false);
        }}
        onPlay={() => {
          reactPlayerRef.current.seekTo(0, 'seconds');
        }}
      />
      <div className="detail-content">
        <div className="title-area">
          <h3 className="em">{quizData.questionNm}</h3>
          {t('languageCode') !== 'ko' && <p>{t('v1.SpeakQuiz.Text03')}</p>}
        </div>
        <div className="btn-area bubble-wrap" style={{ display: 'block' }}>
          <button
            type="button"
            className={cn('btn-listen', { active: isAudioPlay })}
            onClick={(e) => {
              e.preventDefault();
              setIsAudioPlay(!isAudioPlay);
              setIsPlay(true);
            }}
          >
            <img src="/assets/images/voice.svg" alt="듣기" />
          </button>
          {!isPlay && (
            <div
              className={cn('bubble', 'top-bubble', { open: isPlayToast })}
              style={{ top: '-15px' }}
            >
              {!isPlay && <p>{t('TOPIK_Edu.TOPIK_Tooltips_Audio')}</p>}
            </div>
          )}
        </div>
        <div className="quiz-list">
          <ul>
            {quizData.exampleList &&
              quizData.exampleList.map((exampleItem, exampleIndex) => {
                return (
                  <li key={'exampleItem' + exampleIndex}>
                    {/* button active,correct, incorrect  */}
                    <button
                      type="button"
                      className={cn({
                        active: !isSubmit && selectQuizIndex === exampleIndex,
                        correct: isSubmit && Number(quizData.exampleAnswer) === exampleIndex,
                        incorrect:
                          isSubmit &&
                          selectQuizIndex === exampleIndex &&
                          Number(quizData.exampleAnswer) !== exampleIndex,
                      })}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!isSubmit) {
                          if (!isPlay) {
                            playToastShow();
                          } else {
                            setSelectQuizIndex(exampleIndex);
                            setSelectQuiz(exampleItem);
                          }
                        }
                      }}
                    >
                      <span className="num">{exampleIndex + 1}</span>
                      <span className="txt">{exampleItem.exampleText}</span>
                    </button>
                  </li>
                );
              })}
          </ul>
        </div>
        {isSubmit && (
          <div className={cn('result', { correct: isAnswer }, { incorrect: !isAnswer })}>
            <div className="result-correct">
              <span>{t('Speak_Edu.Answer')}</span>
              {answerData.correctCount}개
            </div>
            <div className="result-incorrect">
              <span>{t('Speak_Edu.Wrong')}</span>
              {answerData.incorrectCount}개
            </div>
            <p className="result-txt">{isAnswer ? t('common.Quiz_Ans') : t('common.Quiz_Wrong')}</p>
          </div>
        )}
      </div>
      {!answerData.isSuccess && (
        <div className="btn-area">
          <div className="right">
            <Link
              to="#"
              className={cn('btn-default', { 'btn-next': isSubmit, 'btn-confirm': !isSubmit })}
              onClick={(e) => {
                e.preventDefault();
                selectQuizSubmit();
              }}
            >
              {isSubmit ? t('common.btn_next') : t('common.btn_Check')}
            </Link>
          </div>
        </div>
      )}
      {answerData.isSuccess && (
        <div className="btn-area">
          <div className="left">
            <Link
              to="#"
              className={'btn-default'}
              onClick={(e) => {
                e.preventDefault();
                onStepChange(totalStep);
              }}
            >
              퀴즈 결과
            </Link>
          </div>
          <div className="right">
            {nowStep > 0 && (
              <Link
                to="#"
                className={'btn-default btn-prev'}
                onClick={(e) => {
                  e.preventDefault();
                  onStepChange(nowStep - 1);
                }}
              >
                {t('common.btn_before')}
              </Link>
            )}
            &nbsp;
            <Link
              to="#"
              className={'btn-default btn-next'}
              onClick={(e) => {
                e.preventDefault();
                onStepChange(nowStep + 1);
              }}
            >
              {t('common.btn_next')}
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default SpeakUnitQuiz03;
