import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import * as Api from '../../../library/Api';
import cn from 'classnames';

import { useUserDispatch } from '../../../context/UserContext';
import { Trans } from 'react-i18next';

const MypageSignOut = ({ isLogin, user, t, onClose }) => {
  const navigate = useNavigate();
  const userDispatch = useUserDispatch();

  const [signOutStep, setSignOutStep] = useState(1);
  const [password, setPassword] = useState('');
  const passwordRef = useRef();

  useEffect(() => {
    setSignOutStep(1);
  }, []);

  const onModalClose = () => {
    setSignOutStep(1);
    setPassword('');
    onClose();
  };

  const onStep1Submit = () => {
    if (passwordRef.current.value !== t('v1.MypageAccount.DeleteStep1_InputCheck')) {
      alert(t('v1.MypageAccount.DeleteStep1_Error01'));
      return false;
    }

    setPassword(passwordRef.current.value);
    setSignOutStep(2);
  };

  const onStep2Submit = () => {
    (async () => {
      try {
        const secessionResponse = await Api.secession(user.id);

        console.log('secessionResponse', secessionResponse);

        if (secessionResponse.data.result && secessionResponse.data.result.code === 'OK') {
          userDispatch({ type: 'LOGOUT' });
          navigate('/');
        } else if (secessionResponse.data.result && secessionResponse.data.result.message) {
          alert(secessionResponse.data.result.message);
        } else {
          alert(t('v1.MypageAccount.DeleteStep1_Error02'));
        }
      } catch (error) {
        alert(t('v1.MypageAccount.DeleteStep1_Error03'));
      }
    })();
  };

  return (
    <>
      {signOutStep === 1 && (
        <div className="modal info-modal" style={{ display: 'block' }}>
          <div className="inner">
            <div className="modal-header">
              <Link
                to="#"
                className="btn-close"
                onClick={(e) => {
                  e.preventDefault();
                  onModalClose();
                }}
              >
                {t('v1.Common.BtnClose')}
              </Link>
            </div>
            <div className="modal-body">
              <div className="fail-pw">
                <strong>{t('v1.MypageAccount.DeleteStep1_Title')}</strong>
                <p>
                  <Trans i18nKey="v1.MypageAccount.DeleteStep1_Text" />
                </p>
              </div>
              <div className="break-form">
                <form name="">
                  <fieldset>
                    <legend>{t('v1.MypageAccount.DeleteStep1_InputLabel')}</legend>
                    <div className="row">
                      <label for="txt">{t('v1.MypageAccount.DeleteStep1_InputLabel')}</label>
                      <input
                        type="text"
                        id="txt"
                        ref={passwordRef}
                        placeholder={t('v1.MypageAccount.DeleteStep1_InputCheck')}
                      />
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
            <div className="btn-area">
              <Link
                to="#"
                className="btn-default"
                onClick={(e) => {
                  e.preventDefault();
                  onStep1Submit();
                }}
              >
                {t('v1.MypageAccount.DeleteStep1_Btn')}
              </Link>
            </div>
          </div>
        </div>
      )}
      {signOutStep === 2 && (
        <div className="modal info-modal" style={{ display: 'block' }}>
          <div className="inner">
            <div className="modal-header">
              <Link
                to="#"
                className="btn-close"
                onClick={(e) => {
                  e.preventDefault();
                  onModalClose();
                }}
              >
                {t('v1.Common.BtnClose')}
              </Link>
            </div>
            <div className="modal-body">
              <p className="caution">
                <Trans i18nKey="v1.MypageAccount.DeleteStep2_Text" />
              </p>
            </div>
            <div className="btn-area">
              <Link
                to="#"
                className="btn-cancel"
                onClick={(e) => {
                  e.preventDefault();
                  onModalClose();
                }}
              >
                {t('v1.MypageAccount.DeleteStep2_BtnCancel')}
              </Link>
              <Link
                to="#"
                className="btn-default"
                onClick={(e) => {
                  e.preventDefault();
                  onStep2Submit();
                }}
              >
                {t('v1.MypageAccount.DeleteStep2_BtnOk')}
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MypageSignOut;
