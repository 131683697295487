import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const TopikPayInfo = ({ t, onClose }) => {
  return (
    <>
      <div className="modal topik-modal" style={{ display: 'block' }}>
        <div className="inner">
          <div className="modal-header">
            <h1 className="no-ico">{t('v1.TopikPayInfo.Title')}</h1>
            <Link
              to="#"
              className="btn-close"
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            >
              {t('v1.Common.BtnClose')}
            </Link>
          </div>
          <div className="modal-body">
            <div className="modal-visual">
              <p>
                <Trans i18nKey="v1.TopikPayInfo.VisualTitle" />
              </p>
            </div>
            <div className="price">
              <dl>
                <dt>{t('v1.TopikPayInfo.Info2Subject')}</dt>
                <dd>{t('v1.TopikPayInfo.Info2Content')}</dd>
              </dl>
              {/*
              <dl>
                <dt>
                  3회분 <span>(세트)</span>
                </dt>
                <dd>
                  <del>30$</del> 25$
                </dd>
              </dl>
            */}
            </div>
            <div className="component">
              <strong>{t('v1.TopikPayInfo.Info3Subject')}</strong>
              <ul>
                <li>{t('v1.TopikPayInfo.Info3Content1')}</li>
                <li>{t('v1.TopikPayInfo.Info3Content2')}</li>
                <li>{t('v1.TopikPayInfo.Info3Content3')}</li>
              </ul>
            </div>
            <div className="component-detail">
              <h2>{t('v1.TopikPayInfo.Info4Subject')}</h2>
              <p className="disc">
                <Trans i18nKey="v1.TopikPayInfo.Info4Content" />
              </p>
              <table className="tbl-component">
                <caption>{t('v1.TopikPayInfo.Info4Subject')}</caption>
                <colgroup>
                  <col style={{ width: '30%' }} />
                  <col style={{ width: '40%' }} />
                  <col style={{ width: '30%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">{t('v1.TopikPayInfo.Info4TableTh1')}</th>
                    <th scope="col">{t('v1.TopikPayInfo.Info4TableTh2')}</th>
                    <th scope="col">{t('v1.TopikPayInfo.Info4TableTh3')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Trans i18nKey="v1.TopikPayInfo.Info4TableTd1-1">
                        <span className="beginner" />
                      </Trans>
                    </td>
                    <td>
                      <Trans i18nKey="v1.TopikPayInfo.Info4TableTd1-2" />
                    </td>
                    <td>{t('v1.TopikPayInfo.Info4TableTd1-3')}</td>
                  </tr>
                  <tr>
                    <td>
                      <Trans i18nKey="v1.TopikPayInfo.Info4TableTd2-1">
                        <span className="medium" />
                      </Trans>
                    </td>
                    <td>
                      <Trans i18nKey="v1.TopikPayInfo.Info4TableTd2-2" />
                    </td>
                    <td>{t('v1.TopikPayInfo.Info4TableTd2-3')}</td>
                  </tr>
                </tbody>
              </table>
              <p className="info">{t('v1.TopikPayInfo.Info4TableCaption')}</p>
            </div>
            <div className="component-detail">
              <h2>{t('v1.TopikPayInfo.Info5Subject')}</h2>
              <p className="disc">{t('v1.TopikPayInfo.Info5Content')}</p>
            </div>
            <div className="caution">
              <h2>{t('v1.TopikPayInfo.Info6Subject')}</h2>

              <h3>{t('v1.TopikPayInfo.Info6-1Subject')}</h3>
              <ul>
                <li>{t('v1.TopikPayInfo.Info6-1Content')}</li>
              </ul>

              <h3>{t('v1.TopikPayInfo.Info6-2Subject')}</h3>
              <ul>
                <li>{t('v1.TopikPayInfo.Info6-2Content')}</li>
              </ul>

              <h3>{t('v1.TopikPayInfo.Info6-3Subject')}</h3>
              <ul>
                <li>{t('v1.TopikPayInfo.Info6-3Content1')}</li>
                <li>{t('v1.TopikPayInfo.Info6-3Content2')}</li>
                <li>{t('v1.TopikPayInfo.Info6-3Content3')}</li>
                <li>{t('v1.TopikPayInfo.Info6-3Content4')}</li>
                <li>{t('v1.TopikPayInfo.Info6-3Content5')}</li>
              </ul>

              <h3>{t('v1.TopikPayInfo.Info6-4Subject')}</h3>
              <ul>
                <li>{t('v1.TopikPayInfo.Info6-4Content1')}</li>
                <li>{t('v1.TopikPayInfo.Info6-4Content2')}</li>
                <li>{t('v1.TopikPayInfo.Info6-4Content3')}</li>
                <li>{t('v1.TopikPayInfo.Info6-4Content4')}</li>
              </ul>

              <h3>{t('v1.TopikPayInfo.Info6-5Subject')}</h3>
              <ul>
                <li>{t('v1.TopikPayInfo.Info6-5Content1')}</li>
                <li>{t('v1.TopikPayInfo.Info6-5Content2')}</li>
                <li>{t('v1.TopikPayInfo.Info6-5Content3')}</li>
              </ul>

              <h3>{t('v1.TopikPayInfo.Info6-6Subject')}</h3>
              <ul>
                <li>{t('v1.TopikPayInfo.Info6-6Content')}</li>
              </ul>

              <p className="em">{t('v1.TopikPayInfo.InfoFooter')}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopikPayInfo;
