import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { useUserState, useUserDispatch } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import * as Api from '../../library/Api';
import cn from 'classnames';

import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import MypageLeftNav from './layout/MypageLeftNav';

const MypageCoupon = ({ isLogin, user, t }) => {
  const [listType, setListType] = useState(1);
  const [couponList, setCouponList] = useState([]);

  const addCouponNumberRef = useRef();

  useEffect(() => {
    if (user) {
      initLoad();
    }
  }, [user]);

  const initLoad = () => {
    (async () => {
      try {
        const mypageCouponListResponse = await Api.mypageCouponList(user.esntlId);

        if (mypageCouponListResponse.data.result.data) {
          setCouponList(
            mypageCouponListResponse.data.result.data.map((item, index) => {
              return {
                ...item,
              };
            }),
          );
        }
      } catch (error) {}
    })();
  };

  const onAddCoupon = (e) => {
    e.preventDefault();
    if (addCouponNumberRef.current.value === '') {
      alert('쿠폰번호를 입력해주세요.');
      addCouponNumberRef.current.focus();
      return false;
    }

    (async () => {
      try {
        const mypageCouponListResponse = await Api.mypageAddCoupon(
          user.esntlId,
          addCouponNumberRef.current.value,
        );
        if (mypageCouponListResponse.data.result.data) {
          switch (Number(mypageCouponListResponse.data.result.data)) {
            case 1:
              alert('쿠폰이 등록 되었습니다.');
              initLoad();
              break;
            case 2:
              alert('올바른 쿠폰코드가 아닙니다.');
              break;
            case 3:
              alert('만료된 쿠폰 입니다.');
              break;
            case 4:
              alert('이미 등록 된 쿠폰입니다.');
              break;
            default:
              alert('쿠폰등록 중 오류가 발생하였습니다. 다시 시도 해주시기 바랍니다.');
          }
        }
      } catch (error) {
        alert('쿠폰등록 중 오류가 발생하였습니다. 다시 시도 해주시기 바랍니다.');
      }
    })();
  };

  return (
    <>
      <div
        className={cn('wrap', {
          wrapKo: t('languageCode') === 'ko',
          wrapEn: t('languageCode') === 'en',
          wrapId: t('languageCode') === 'id',
          wrapVi: t('languageCode') === 'vi',
        })}
      >
        <Header />

        <section className="mypage-wrap">
          <h2 className="blind">마이페이지</h2>
          <div className="inner">
            <MypageLeftNav isLogin={isLogin} user={user} t={t} />
            <article className="mypage-content notice-content">
              <h3 className="mypage-title">{t('MyPage.MyPage_Coupon_Title')}</h3>
              <div className="regi-coupon">
                <input
                  type="text"
                  placeholder={t('MyPage.MyPage_Coupon_input')}
                  ref={addCouponNumberRef}
                />
                <button type="submit" className="btn-regi" onClick={onAddCoupon}>
                  {t('MyPage.MyPage_Coupon_Reg_Btn')}
                </button>
              </div>
              <div className="sorting">
                <Link
                  to="#"
                  className={cn({ active: listType === 1 })}
                  onClick={(e) => {
                    e.preventDefault();
                    setListType(1);
                  }}
                >
                  {t('MyPage.MyPage_Coupon_Tab_Total_Btn')}({couponList.length})
                </Link>{' '}
                <Link
                  to="#"
                  className={cn({ active: listType === 2 })}
                  onClick={(e) => {
                    e.preventDefault();
                    setListType(2);
                  }}
                >
                  {t('MyPage.MyPage_Coupon_Use1', {
                    n: couponList.filter((item) => {
                      return item.cpUsed === 'N';
                    }).length,
                  })}
                </Link>
              </div>
              <div className="list">
                {couponList.filter((item) => {
                  if (listType === 1) return true;
                  else if (listType === 2) return item.cpUsed === 'N';
                }).length === 0 && (
                  <div className="none">
                    <p className="coupon">{t('MyPage.MyPage_NoCoupon')}</p>
                  </div>
                )}
                {couponList
                  .filter((item) => {
                    if (listType === 1) return true;
                    else if (listType === 2) return item.cpUsed === 'N';
                  })
                  .map((item, index) => {
                    return (
                      <>
                        <div className="item" key={'couponItem' + item.cpCode}>
                          <strong className="sbj">{item.cpName}</strong>
                          <div className="txt">{item.cpDetail}</div>
                          <div className="date">
                            사용기간 : {item.cpFrstDate} ~ {item.cpLastDate}
                          </div>
                          <span
                            className={cn('status', {
                              able: item.cpUsed === 'N',
                              disable: item.cpUsed === 'Y',
                            })}
                          >
                            {item.cpUsed === 'Y'
                              ? t('MyPage.MyPage_Coupon_Complete')
                              : t('MyPage.MyPage_Coupon_Use2')}
                          </span>
                        </div>
                      </>
                    );
                  })}
              </div>
            </article>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default MypageCoupon;
