import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import cn from 'classnames';
import ReactPlayer from 'react-player/file';
import dompurify from 'dompurify';

const TopikViewSuccessView = ({
  t,
  topikData,
  questionData,
  onQuestionViewClose,
  onQuestionViewPrev,
  onQuestionViewNext,
}) => {
  const [isAudioPlay, setIsAudioPlay] = useState(false);
  const reactPlayerRef = useRef();

  const audioPlay = (isPlay) => {
    setIsAudioPlay(isPlay);
  };

  useEffect(() => {
    setIsAudioPlay(false);
  }, [questionData]);

  return (
    <>
      {questionData.data.questionAttribute === 'aud' && isAudioPlay && (
        <>
          <ReactPlayer
            ref={reactPlayerRef}
            className="react-player"
            url={questionData.data.questionAttributeFile}
            playing={isAudioPlay}
            onEnded={() => {
              audioPlay(false);
            }}
            onPlay={() => {
              reactPlayerRef.current.seekTo(0, 'seconds');
            }}
          />
        </>
      )}
      <div className="pop-wrap">
        <header className="pop-header exam-header">
          <h1 className="title">
            {t('languageCode') === 'ko' && topikData.rwdDesc}
            {t('languageCode') === 'en' && topikData.rwdDescEn}
            {t('languageCode') === 'id' && topikData.rwdDescIn}
            {t('languageCode') === 'vi' && topikData.rwdDescViet}
          </h1>
          <Link
            to="#"
            className="btn-close"
            onClick={(e) => {
              e.preventDefault();
              onQuestionViewClose();
            }}
          >
            {t('v1.Common.BtnClose')}
          </Link>
        </header>
        <section className="pop-contents exam-contents">
          <div className="exam-progress">
            <div
              className="bar"
              style={{ width: (100 / questionData.totalStep) * questionData.nowStep + '%' }}
            >
              <div className="num">
                <div>
                  <b>{questionData.nowStep}</b>
                  <span>{questionData.totalStep}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="contents-inner">
            <h2
              className={cn({
                correct: questionData.data.exampleAnswer === questionData.data.selectedItem,
                incorrect: questionData.data.exampleAnswer !== questionData.data.selectedItem,
              })}
            >
              {questionData.nowStep}. {questionData.data.questionNm}
            </h2>
            <div className="question">
              <div>
                <div className="paragraph">
                  {questionData.data.questionAttribute === 'aud' && (
                    <>
                      <div className="bubble-wrap">
                        <button
                          type="button"
                          className={cn('btn-listen', { active: isAudioPlay })}
                          onClick={(e) => {
                            e.preventDefault();
                            audioPlay(!isAudioPlay);
                          }}
                        >
                          <img src="/assets/images/voice.svg" alt="듣기" />
                        </button>
                      </div>
                    </>
                  )}

                  {questionData.data.questionAttribute === 'txt' && (
                    <>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: dompurify.sanitize(questionData.data.questionAttributeText),
                        }}
                      ></p>
                    </>
                  )}

                  {questionData.data.questionAttribute === 'img' && (
                    <>
                      <img
                        src={questionData.data.questionAttributeFile}
                        alt=""
                        style={{ width: '100%' }}
                      />
                    </>
                  )}
                </div>
                {questionData.data.questionAttributeTextAdd && (
                  <>
                    <div className="sample">
                      <strong>&lt;보기&gt;</strong>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: dompurify.sanitize(questionData.data.questionAttributeTextAdd),
                        }}
                      ></p>
                    </div>
                  </>
                )}

                {questionData.data.questionType === 'LC' && (
                  <>
                    <div className="ex">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: dompurify.sanitize(questionData.data.questionScript),
                        }}
                      ></p>
                    </div>
                  </>
                )}
              </div>
              <div className="answer">
                <ul
                  className={cn('list', 'result', {
                    'txt-list': questionData.data.exampleAttribute === 'txt',
                    'img-list': questionData.data.exampleAttribute === 'img',
                  })}
                >
                  {questionData.data.exampleList.map((exampleItem, exampleKey) => {
                    return (
                      <li
                        className={cn({
                          correct: exampleItem.exampleSequence === questionData.data.exampleAnswer,
                          incorrect:
                            exampleItem.exampleSequence === questionData.data.selectedItem &&
                            questionData.data.exampleAnswer !== exampleItem.exampleSequence,
                        })}
                        key={'exampleList' + exampleItem.exampleSequence}
                      >
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {questionData.data.exampleAttribute === 'txt' && exampleItem.exampleText}
                          {questionData.data.exampleAttribute === 'img' && (
                            <img src={exampleItem.exampleFile} alt="" />
                          )}
                        </button>
                      </li>
                    );
                  })}
                </ul>

                {questionData.data.questionExplanation && (
                  <div className="resolve">
                    <strong>&lt;해설&gt;</strong>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: dompurify.sanitize(questionData.data.questionExplanation),
                      }}
                    ></p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="exam-btn-area">
            <div className="left">
              <Link
                to="#"
                className="btn-default btn-prev"
                disabled={questionData.nowStep === 1}
                onClick={(e) => {
                  e.preventDefault();
                  if (questionData.nowStep > 1) {
                    onQuestionViewPrev();
                  }
                }}
              >
                {t('v1.Common.BtnPrev')}
              </Link>
            </div>
            <div className="right">
              <Link
                to="#"
                className="btn-default btn-next"
                disabled={questionData.totalStep <= questionData.nowStep}
                onClick={(e) => {
                  e.preventDefault();
                  if (questionData.totalStep > questionData.nowStep) {
                    onQuestionViewNext();
                  }
                }}
              >
                {t('v1.Common.BtnNext')}
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default TopikViewSuccessView;
