import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import * as Api from '../../../library/Api';
import TextTermsKo from './TextTermsKo';
import TextTermsEn from './TextTermsEn';
import TextPrivacyKo from './TextPrivacyKo';
import TextPrivacyEn from './TextPrivacyEn';
import TextProfileKo from './TextProfileKo';
import TextProfileEn from './TextProfileEn';

const SocialJoinStep1 = ({ t, socialData }) => {
  const navigate = useNavigate();

  const [formStep, setFormStep] = useState(1);
  const formMethods = useForm({
    mode: 'all',
    defaultValues: {
      isTerms1: false,
      isTerms2: false,
      isTerms3: false,
      isTerms4: false,
      isTerms5: false,
    },
  });

  const [allCheck, setAllCheck] = useState(false);

  const submitForm = async () => {
    if (
      !formMethods.getValues('isTerms1') ||
      !formMethods.getValues('isTerms2') ||
      !formMethods.getValues('isTerms3')
    ) {
      alert(t('v1.MemberJoin.Sns_Error01'));
      return false;
    }

    // 중복계정 체크
    try {
      const signupResponse = await Api.signupSocial({
        email: socialData.user.email,
        password: 'dummy',
        socialType: socialData.type, // GOOGLE / APPLE / FACEBOOK
        socialId: socialData.socialId, // Social ID
        isTerms3: formMethods.getValues('isTerms3'),
        isTerms5: formMethods.getValues('isTerms5'),
        lang: t('languageApiCode'),
      });
      console.log('signupResponse', signupResponse);
      navigate('/member/joinSuccess');
    } catch (apiError) {
      console.log('signup Error', apiError);
      formMethods.setError('success', { message: apiError.message });
      return false;
    }
  };

  return (
    <>
      <section className="join-wrap">
        <h2>{t('v1.MemberJoin.Sns_Title')}</h2>
        <div className="inner">
          <article className="join-form">
            <FormProvider {...formMethods}>
              <form>
                <fieldset>
                  <legend>{t('v1.MemberJoin.Sns_Title')}</legend>
                  {/*
                <div className="row required">
                  <label for="required">
                    <span>(필수)</span> 추가정보 입력
                  </label>
                  <input type="text" name="" id="required" placeholder="이메일" />
                </div>
                */}
                  <div className="row all">
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        name="allCheck"
                        id="allCheck"
                        checked={allCheck}
                        onChange={(e) => {
                          setAllCheck(e.target.checked);
                          formMethods.setValue('isTerms1', e.target.checked);
                          formMethods.setValue('isTerms2', e.target.checked);
                          formMethods.setValue('isTerms3', e.target.checked);
                          formMethods.setValue('isTerms4', e.target.checked);
                          formMethods.setValue('isTerms5', e.target.checked);
                        }}
                      />
                      <label htmlFor="allCheck">{t('v1.MemberJoin.Sns_LabelAllCheck')}</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        name="isTerms1"
                        id="isTerms1"
                        {...formMethods.register('isTerms1', { required: true })}
                      />
                      <label htmlFor="isTerms1">{t('v1.MemberJoin.Sns_LabelTerms1')}</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        name="isTerms2"
                        id="isTerms2"
                        {...formMethods.register('isTerms2', { required: true })}
                      />
                      <label htmlFor="isTerms2">{t('v1.MemberJoin.Sns_LabelTerms2')}</label>
                    </div>
                    <div className="clause">
                      <div className="inner">
                        {t('languageCode') === 'ko' && <TextTermsKo />}
                        {t('languageCode') !== 'ko' && <TextTermsEn />}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        name="isTerms3"
                        id="isTerms3"
                        {...formMethods.register('isTerms3', { required: true })}
                      />
                      <label htmlFor="isTerms3">{t('v1.MemberJoin.Sns_LabelTerms3')}</label>
                    </div>
                    <div className="clause">
                      <div className="inner">
                        {t('languageCode') === 'ko' && <TextPrivacyKo />}
                        {t('languageCode') !== 'ko' && <TextPrivacyEn />}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        name="isTerms4"
                        id="isTerms4"
                        {...formMethods.register('isTerms4')}
                      />
                      <label htmlFor="isTerms4">{t('v1.MemberJoin.Sns_LabelTerms4')}</label>
                    </div>
                    <div className="clause">
                      <div className="inner">
                        {t('languageCode') === 'ko' && <TextProfileKo />}
                        {t('languageCode') !== 'ko' && <TextProfileEn />}
                      </div>
                    </div>
                  </div>
                  {/*
                  <div className="row">
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        name="isTerms5"
                        id="isTerms5"
                        {...formMethods.register('isTerms5')}
                      />
                      <label htmlFor="isTerms5">{t('v1.MemberJoin.Sns_LabelTerms5')}</label>
                    </div>
                  </div>
                      */}
                  <p className="clause-info">* {t('v1.MemberJoin.Sns_Text1')}</p>

                  <div className="btn-area">
                    <button
                      type="submit"
                      className="btn-cancel"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate('/');
                      }}
                    >
                      {t('v1.MemberJoin.Sns_BtnCancel')}
                    </button>
                    <button
                      type="submit"
                      className="btn-login"
                      onClick={(e) => {
                        e.preventDefault();
                        submitForm();
                      }}
                    >
                      {t('v1.MemberJoin.Sns_BtnSubmit')}
                    </button>
                  </div>
                </fieldset>
              </form>
            </FormProvider>
          </article>
        </div>
      </section>
    </>
  );
};

export default SocialJoinStep1;
