import React, { useEffect, useState, useRef } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import * as Api from '../library/Api';

import { useUserState, useUserDispatch } from '../context/UserContext';
import { isMobile } from 'react-device-detect';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLogin, user } = useUserState();
  const userDispatch = useUserDispatch();

  const { t, i18n } = useTranslation();
  const [languageNavClass, setLanguageNavClass] = useState('');
  const utilNavBtnRef = useRef();
  const utilNavRef = useRef();
  const utilNavIsOpenRef = useRef(false);
  const [utilNavClass, setUtilNavClass] = useState('');

  const [ScrollY, setScrollY] = useState(0); // window 의 pageYOffset값을 저장
  const [ScrollActive, setScrollActive] = useState(false);

  const profileImageInput = useRef();

  useEffect(() => {
    if (isMobile) {
      navigate('/appLanding');
    }

    window.addEventListener('click', handleCloseProfileModal);
    return () => {
      window.addEventListener('click', handleCloseProfileModal);
    };
  }, []);

  useEffect(() => {}, [isLogin]);

  const handleScroll = () => {
    if (window.pageYOffset > 50) {
      setScrollY(window.pageYOffset);
      setScrollActive(true);
    } else {
      setScrollY(window.pageYOffset);
      setScrollActive(false);
    }
  };

  useEffect(() => {
    function scrollListener() {
      window.addEventListener('scroll', handleScroll);
    } //  window 에서 스크롤을 감시 시작
    scrollListener(); // window 에서 스크롤을 감시
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }; //  window 에서 스크롤을 감시를 종료
  });

  const handleCloseProfileModal = (e) => {
    if (
      utilNavIsOpenRef.current === true &&
      !utilNavRef.current.contains(e.target) &&
      !utilNavBtnRef.current.contains(e.target)
    ) {
      utilNavIsOpenRef.current = false;
      setUtilNavClass('');
    }

    /*
    console.log('utilNavClassutilNavClass');
    console.log('@1', utilNavClass, '@', utilNavIsOpenRef.current);
    console.log('@2', utilNavRef, utilNavRef.current.contains(e.target));
    console.log('@3', utilNavBtnRef, utilNavBtnRef.current.contains(e.target));
    console.log('@4', e, e.target);
    */

    //if (!utilNavRef.current.contains(e.target)) setUtilNavClass('');
  };

  const getNavLinkClass = (path, addClass = '') => {
    //console.log('location.pathname', location.pathname.substr(0, path.length), path);
    if (path === '/') return location.pathname === path ? addClass + ' active' : addClass;
    else return location.pathname.substr(0, path.length) === path ? addClass + ' active' : addClass;
  };

  const utilNavToggle = () => {
    console.log('utilNavToggle');
    utilNavIsOpenRef.current = utilNavClass === '' ? true : false;
    setUtilNavClass(utilNavClass === '' ? 'show' : '');
  };

  const languageNavToggle = () => {
    setLanguageNavClass(languageNavClass === '' ? 'show' : '');
  };

  const changeLanguage = (e, language) => {
    e.preventDefault();
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
    setLanguageNavClass('');
  };

  const changeProfile = (e) => {
    const fileUploaded = e.target.files[0];
    (async () => {
      try {
        const uploadProfilePicResponse = await Api.uploadProfilePic(fileUploaded);
        if (uploadProfilePicResponse.data.result.data) {
          console.log('uploadProfilePic', uploadProfilePicResponse.data);
          const profileUrl = uploadProfilePicResponse.data.result.data;
          const updateProfileResponse = await Api.updateProfile({
            id: user.id, // 필수
            imageUrl: profileUrl,
            name: user.name,
          });

          userDispatch({
            type: 'LOGIN',
            user: {
              ...user,
              imageUrl: profileUrl,
            },
          });
        }
      } catch (error) {}
    })();
  };

  return (
    <header
      className={cn('header', {
        'main-header': location.pathname === '/',
        'sub-header': location.pathname !== '/',
        fixed: ScrollActive,
      })}
    >
      <div className="inner">
        <h1
          className="logo"
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.preventDefault();
            navigate('/');
          }}
        >
          Genie K
        </h1>
        <nav className={'main-nav'}>
          <ul>
            <li className={getNavLinkClass('/main', 'home')}>
              <NavLink to="/main">Home</NavLink>
            </li>
            <li className={getNavLinkClass('/speak', 'speak')}>
              <NavLink to="/speak">Speak</NavLink>
            </li>
            <li className={getNavLinkClass('/topik', 'topik')}>
              <NavLink to="/topik">TOPIK</NavLink>
            </li>
            <li className={getNavLinkClass('/report', 'report')}>
              <NavLink to="/report">Report</NavLink>
            </li>
          </ul>
        </nav>
        <div
          className={cn('util', { login: isLogin === 1 })}
          style={{ position: 'absolute', top: '20px', right: '90px' }}
        >
          <button type="button" className="btn-user" onClick={utilNavToggle} ref={utilNavBtnRef}>
            {isLogin === 1 && user.imageUrl && user.imageUrl !== '' && (
              <img src={user.imageUrl} alt="" />
            )}
            {(isLogin === 0 || !user.imageUrl || user.imageUrl === '') && (
              <img src="/assets/images/my.svg" alt="" />
            )}
          </button>
          <div className={cn('util-nav', utilNavClass)} ref={utilNavRef}>
            <div className="user-info">
              <div className="profile">
                <div className="pic">
                  {isLogin === 1 && user.imageUrl && user.imageUrl !== '' && (
                    <img src={user.imageUrl} alt="" style={{ width: '100%' }} />
                  )}
                  {(isLogin === 0 || !user.imageUrl || user.imageUrl === '') && (
                    <img src="/assets/images/none_porfile.png" alt="" />
                  )}
                </div>
                {isLogin === 1 && user.mberType === 'GOOGLE' && (
                  <span className="account google">Google</span>
                )}
                {isLogin === 1 && user.mberType === 'FACEBOOK' && (
                  <span className="account facebook">Facebook</span>
                )}
                {isLogin === 1 && user.mberType === 'APPLE' && (
                  <span className="account apple">Apple</span>
                )}
              </div>
              <div className="nick">
                {isLogin === 1 && (
                  <>
                    <button
                      type="button"
                      className="btn-pic"
                      onClick={(e) => {
                        e.preventDefault();
                        profileImageInput.current.click();
                      }}
                    >
                      <span>{t('v1.CommonHeader.BtnProfileImageChange')}</span>
                    </button>
                    <input
                      type="file"
                      accept="image/*"
                      ref={profileImageInput}
                      style={{ display: 'none' }}
                      onChange={changeProfile}
                    />
                    <p className="email">{user.id}</p>
                  </>
                )}
              </div>
              <div className="language">
                <button type="button" className="btn-lang" onClick={languageNavToggle}>
                  {t('language')}
                </button>
                <ul className={cn('language-nav', languageNavClass)}>
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        changeLanguage(e, 'ko-KR');
                      }}
                    >
                      {t('v1.CommonHeader.SelectLanguageKo')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        changeLanguage(e, 'en-US');
                      }}
                    >
                      {t('v1.CommonHeader.SelectLanguageEn')}
                    </Link>
                  </li>
                  {/*
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        changeLanguage(e, 'id-ID');
                      }}
                    >
                      태국어
                    </Link>
                  </li>
                    */}

                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        changeLanguage(e, 'vi-VN');
                      }}
                    >
                      {t('v1.CommonHeader.SelectLanguageVi')}
                    </Link>
                  </li>
                </ul>
              </div>
              <Link
                to="/mypage"
                className="btn-manage"
                disabled={!isLogin}
                onClick={(e) => {
                  if (!isLogin) e.preventDefault();
                }}
              >
                {t('v1.CommonHeader.BtnMypage')}
              </Link>
            </div>
            {isLogin === 1 && (
              <Link
                to="#"
                className="btn-log"
                onClick={() => {
                  userDispatch({ type: 'LOGOUT' });
                  navigate('/');
                }}
              >
                {t('ETC.MyPage_SignOut')}
              </Link>
            )}
            {isLogin !== 1 && (
              <Link to="/member/login" className="btn-log">
                {t('Member.Member_Login')}
              </Link>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
