import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';

const JoinStep3 = ({ t, completeFormStep, onReEmailSend }) => {
  const {
    register,
    formState: { isValid, errors },
    getValues,
  } = useFormContext();

  return (
    <>
      <fieldset>
        <legend>{t('Member.Member_Step3')}</legend>
        <h3>{t('Member.Member_Step3')}</h3>
        <div className="mail-confirm">
          <Trans i18nKey="Member.Member_Code_Ment" values={{ n: getValues('email') }}>
            <strong />
            <p />
          </Trans>
        </div>
        <div className="auth">
          <div className="num">
            <input
              type="text"
              placeholder={t('v1.MemberJoin.Step3_PlaceholderNumber')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  completeFormStep();
                }
              }}
              {...register('emailNumber')}
            />
            <button type="button" className="btn-confirm" onClick={completeFormStep}>
              {t('v1.MemberJoin.Step3_BtnOk')}
            </button>
          </div>
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              onReEmailSend();
            }}
          >
            {t('v1.MemberJoin.Step3_BtnReSend')}
          </Link>
        </div>
        <p className="clause-info">
          * {t('v1.MemberJoin.Step3_Text1')}
          <br />*{' '}
          <Trans i18nKey="v1.MemberJoin.Step3_Text2">
            <a href="mailto:support.geniek@hancom.com" />
          </Trans>
        </p>
      </fieldset>
    </>
  );
};

export default JoinStep3;
