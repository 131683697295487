import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';
import moment from 'moment';

import * as Api from '../../library/Api';

import TopikViewSuccess from './layout/TopikViewSuccess';

const TopikResult = ({ isLogin, user, t }) => {
  const { topikId } = useParams();

  const navigate = useNavigate();

  const [rwdKind, setRwdKind] = useState({});

  const [topikData, setTopikData] = useState({});
  const [questionList, setQuestionList] = useState([]);
  const [resultData, setResultData] = useState({});

  useEffect(() => {
    if (isLogin === 0) {
      //alert('로그인 후 이용가능합니다.');
      navigate('/member/login');
    } else {
      onTopikViewSuccessOpen();
    }
  }, [isLogin]);

  const onTopikViewSuccessReset = () => {
    navigate('/topik/view/' + rwdKind);
  };
  const onTopikViewSuccessOpen = () => {
    (async () => {
      try {
        const topikResultResponse = await Api.topikResult({
          esntlId: user.esntlId,
          topikId: topikId,
        });

        let rwdKind = null;
        if (topikResultResponse.data.result.data) {
          setQuestionList(topikResultResponse.data.result.data);
          setRwdKind(topikResultResponse.data.result.data.rwdKind);

          rwdKind = topikResultResponse.data.result.data.rwdKind;
          const topikTopikResponse = await Api.topikTopik({
            questionRound: rwdKind,
          });

          if (topikTopikResponse.data.result.data) {
            setResultData(topikResultResponse.data.result.data);
          }
        }

        // API 단일 정보 추출이 없어 LIST 호출
        const topikListResponse = await Api.topikList({ esntlId: user.esntlId });
        if (topikListResponse.data.result.data.reward) {
          for (let i = 0; i < topikListResponse.data.result.data.reward.length; i++) {
            if (topikListResponse.data.result.data.reward[i].rwdKind === rwdKind) {
              setTopikData(topikListResponse.data.result.data.reward[i]);
            }
          }
        }
      } catch (error) {}
    })();
  };
  const onTopikViewSuccessClose = () => {
    navigate('/topik');
  };
  return (
    <>
      <div
        className={cn('wrap', {
          wrapKo: t('languageCode') === 'ko',
          wrapEn: t('languageCode') === 'en',
          wrapId: t('languageCode') === 'id',
          wrapVi: t('languageCode') === 'vi',
        })}
        style={{ overflow: 'auto' }}
      >
        <TopikViewSuccess
          t={t}
          topikData={topikData}
          questionList={questionList}
          resultData={resultData}
          onClose={onTopikViewSuccessClose}
          onReset={onTopikViewSuccessReset}
        />
      </div>
    </>
  );
};

export default TopikResult;
