import React, { useState, createContext } from 'react';

export const ContextStore = createContext();

const ContextProvider = ({ children }) => {
  const setLoggedUser = (data) => {
    setState((prevState) => ({
      ...prevState,
      loggedUser: data,
    }));
  };

  const setLoggedIn = () => {
    setState((prevState) => ({
      ...prevState,
      loggedIn: !prevState.loggedIn,
    }));
  };

  const initialState = {
    loggedUser: {
      id: '',
      username: '',
      email: '',
      first_name: '',
    },
    loggedIn: false,
    setLoggedUser,
    setLoggedIn,
  };

  const [state, setState] = useState(initialState);

  return <ContextStore.Provider value={state}>{children}</ContextStore.Provider>;
};

export default ContextProvider;
