import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import cn from 'classnames';
import * as Api from '../../../library/Api';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import TopikConfirm from './TopikConfirm';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import SwiperCore, { EffectFade, Navigation, Pagination } from 'swiper';
import '../../../assets/swiper-bundle.min.css';
import ReactPlayer from 'react-player';

const TopikViewInfo = ({
  t,
  infoType,
  topikData,
  totalMinute,
  totalCount,
  totalLCCount,
  totalRCCount,
  onClose,
  onStart,
}) => {
  //const { rwdKind } = useParams();

  const swiperPaginationRef = useRef(null);
  const [swiper, setSwiper] = useState(null);
  const [isLastSwiper, setIsLastSwiper] = useState(false);
  const [swiperIndex, setSwiperIndex] = useState(0);

  const [isAudioPlay, setIsAudioPlay] = useState(false);
  const reactPlayerRef = useRef();

  useEffect(() => {
    console.log('topikData', topikData);
  }, []);

  return (
    <>
      <ReactPlayer
        ref={reactPlayerRef}
        className="react-player"
        url={
          'https://s3.ap-northeast-2.amazonaws.com/res.kolaroid.co/uploaded/topik/AIKORAPP-612.mp3'
        }
        playing={isAudioPlay}
        onEnded={() => {
          setIsAudioPlay(false);
        }}
        onPlay={() => {
          reactPlayerRef.current.seekTo(0, 'seconds');
        }}
      />

      <div className="pop-wrap">
        <header className="pop-header guide-header">
          <h1 className="title">
            {t('languageCode') === 'ko' && topikData.rwdDesc}
            {t('languageCode') === 'en' && topikData.rwdDescEn}
            {t('languageCode') === 'id' && topikData.rwdDescIn}
            {t('languageCode') === 'vi' && topikData.rwdDescViet}
          </h1>
          <Link
            to="#"
            className="btn-close"
            onClick={(e) => {
              e.preventDefault();
              if (infoType === 1) onClose();
              else {
                let onCloseModal = onClose;
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <TopikConfirm
                        t={t}
                        content={
                          t('TOPIK_Edu.Alert_TOPIK_DC_Title') +
                          '<br />' +
                          t('TOPIK_Edu.Alert_TOPIK_DC_Contents')
                        }
                        onClose={onClose}
                        onConfirm={() => {
                          onCloseModal();
                        }}
                      />
                    );
                  },
                });
              }
            }}
          >
            닫기
          </Link>
        </header>
        <section className="pop-contents guide-contents">
          <div className="contents-inner">
            <div className="exam-slide">
              <Swiper
                className="swiper-wrapper"
                modules={[Navigation, Pagination, EffectFade]}
                effect="fade"
                pagination={{ clickable: true, el: swiperPaginationRef.current }}
                onBeforeInit={(swiper) => {
                  swiper.params.pagination.el = swiperPaginationRef.current;
                }}
                onSwiper={setSwiper}
                onReachEnd={() => {
                  setIsLastSwiper(true);
                }}
                onSlideChangeTransitionStart={(swiper) => {
                  setSwiperIndex(swiper.activeIndex);
                }}
              >
                {infoType === 1 && (
                  <>
                    <SwiperSlide className="item swiper-slide">
                      <h2>
                        <Trans i18nKey="TOPIK_Edu.Listen_Prepare1-1" values={{ n: totalMinute }} />
                      </h2>
                      <p className="disc">{t('TOPIK_Edu.Listen_Prepare1-2')}</p>
                      <dl className="caution">
                        <Trans i18nKey="TOPIK_Edu.Listen_Prepare1-3">
                          <dt />
                          <dd />
                        </Trans>
                      </dl>
                    </SwiperSlide>
                    <SwiperSlide className="item swiper-slide">
                      <h2>{t('TOPIK_Edu.Listen_Prepare2-1')}</h2>
                      <div className="disc">
                        <ul>
                          <li>
                            <Trans
                              i18nKey="TOPIK_Edu.Listen_Prepare2-2"
                              values={{ n: totalLCCount }}
                            />
                          </li>
                          <li>{t('TOPIK_Edu.Listen_Prepare2-3')}</li>
                          <li>{t('TOPIK_Edu.Listen_Prepare2-4')}</li>
                        </ul>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="item swiper-slide">
                      <h2>{t('TOPIK_Edu.Listen_Prepare3-1')}</h2>
                      <button
                        className={cn('btn-listen', { active: isAudioPlay })}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsAudioPlay(!isAudioPlay);
                        }}
                      >
                        <img src="/assets/images/voice.svg" alt="발음듣기" />
                      </button>
                      <div className="disc">
                        <ul>
                          <li>{t('TOPIK_Edu.Listen_Prepare3-2')}</li>
                          <li>{t('TOPIK_Edu.Listen_Prepare3-3')}</li>
                        </ul>
                      </div>
                    </SwiperSlide>
                  </>
                )}
                {infoType === 2 && (
                  <>
                    <SwiperSlide className="item item4 swiper-slide">
                      <h2>{t('TOPIK_Edu.Read_Prepare1-1')}</h2>
                      <div className="disc">
                        <ul>
                          <li>
                            <Trans
                              i18nKey="TOPIK_Edu.Read_Prepare1-2"
                              values={{ n: totalRCCount }}
                            />
                          </li>
                          <li>{t('TOPIK_Edu.Read_Prepare1-3')}</li>
                        </ul>
                      </div>
                    </SwiperSlide>
                  </>
                )}
              </Swiper>
              <div className="pagination" ref={swiperPaginationRef}></div>
            </div>
          </div>
          <div className="pop-util">
            <div className="left">
              {infoType === 1 && swiperIndex < 2 && (
                <Link
                  to="#"
                  className="btn-skip"
                  onClick={(e) => {
                    e.preventDefault();
                    onStart();
                  }}
                >
                  {t('common.btn_Skip')}
                </Link>
              )}
            </div>
            <div className="right">
              <Link
                to="#"
                className="btn-default btn-next"
                onClick={(e) => {
                  e.preventDefault();
                  if (!isLastSwiper) swiper.slideNext();
                  else onStart();
                }}
              >
                {infoType === 1 && swiperIndex >= 2 && t('common.btn_Start')}
                {infoType === 1 && swiperIndex < 2 && t('common.btn_Next')}
                {infoType === 2 && t('common.btn_Start')}
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default TopikViewInfo;
