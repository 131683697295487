import axios from 'axios';
import React from 'react';

const apiDomain = process.env.REACT_APP_API_URL;
const apiUrl = {
  uploadVoice: 'uploadVoice', // 음성 업로드
  voiceAnalysis: 'voiceAnalysis', // 음성평가
  mypage: {
    uploadProfilePic: 'uploadProfilePic', //프로필 사진 업로드
    updateProfile: 'updateProfile', // 내정보 업데이트
    updateViewTrans: 'updateViewTrans', // 번역보기 업데이트
    typoProfileList: 'typoProfileList', // 타이포 프로필 불러오기
    notiList: 'notiList', // 알림 리스트
    notiViewUpd: 'notiViewUpd', // 알림 읽음표시
    couponList: 'couponList', // 쿠폰 목록
    addCoupon: 'addCoupon', // 쿠폰 등록
    paymentList: '/payment/paymentList', // 구매내역
  },
  memeber: {
    login: 'login',
    checkPrePw: 'checkPrePw', // 현재 비밀번호 체크
    changePw: 'myinfo/changePw', // 비밀번호 변경
    checkDuplicateId: 'checkDuplicateId', // 이메일 중복체크
    checkDuplicateSocialId: 'checkDuplicateSocialId', // FACEBOOK 용 소셜계정 중복체크
    sendAuthMail: 'sendAuthMail', // 인증 메일 발송 ( 오류 )
    checkAuthNum: 'checkAuthNum', // 인증번호 확인
    signup: 'signup', // 회원가입
    checkDuplicateIdReset: 'checkDuplicateIdReset', // 패스워드 변경시 ID 체크
    resetPassword: 'resetPassword', // 패스워드 변경
    secession: 'secession', // 회원탈퇴
  },
  studyhome: {
    todayList: 'studyhome/todayList', // 학습홈 - 오늘의 표현
    insertToday: 'studyhome/insertToday', // 학습홈 - 오늘의 표현 학습기록 저장
    recentLearning: 'studyhome/recentLearning', // 최근학습
    learningRecommend: 'studyhome/learningRecommend', // 추천학습
  },
  speak: {
    courseList: 'speak/courseList', // 서브홈 - 하단 코스학습 리스트
    unitList: 'speak/unitList', // S_0201_코스학습 목록 상단
    updateRecentUnit: 'speak/updateRecentUnit', // 마지막 학습 단원 기록하기
    recentUnit: 'speak/recentUnit', // 마지막 학습 기록 불러오기
    classStepList: 'speak/classStepList', // S_0211, S_0221, S_0231
    studyHistory: 'speak/studyHistory', // 서브홈 - 상단 학습 히스토리
    courseResult: 'speak/courseResult', // 학습결과페이지
    unitQuiz: 'speak/unitQuiz', // 말하기 학습 퀴즈
    unitQuizResult: 'speak/unitQuizResult', // 퀴즈결과저장
    unitQuizReview: 'speak/unitQuizReview', // 퀴즈 결과 다시보기
    classDone: 'speak/classDone', // 코스학습1단계 다음처리
    checkDone: 'speak/checkDone', // 코스학습1,2,3 단계  완료체크
    setLearningTime: 'speak/setLearningTime', // 학습시간기록
    unitText: 'speak/unitText', // 단원 이름 불러오기
    classText: 'speak/classText', // 수업명 불러오기
    lastClickCourse: 'speak/lastClickCourse', // 마지막 클릭 코스 불러오기
    nextClassList: 'speak/nextClassList', // 다음 학습 불러오기(PC용)
  },
  topik: {
    topik: 'topik', // 시험문제 가져오기
    list: 'topik/list', // 서브홈, 모의고사 리스트
    myRecord: 'topik/myRecord', // 내 성적
    recordList: 'topik/recordList', // 기록, 모의고사 회차명 표시
    recordChart: 'topik/recordChart', // 점수 추이
    updateRewardView: 'topik/updateRewardView', // 시험 시작 후 사용여부 변경
    start: 'topik/start', // 토픽 시작
    stop: 'topik/stop', // 토픽 중지
    finish: 'topik/finish', // 토픽 끝
    result: 'topik/result', // 토픽 결과
  },
  mynote: {
    review: 'mynote/review', // AI 총평
    report: 'mynote/report', // 학습리포트
    cnt: 'mynote/cnt', // 내 학습량
  },
};

const instanceAxios = axios.create({
  baseURL: apiDomain,
  timeout: 30000,
});

export const login = (param) => {
  return postAxios(apiUrl.memeber.login, param);
};

export const checkPrePw = (param) => {
  return postAxios(apiUrl.memeber.checkPrePw, param);
};

export const changePw = (param) => {
  return postAxios(apiUrl.memeber.changePw, param);
};

export const checkDuplicateId = (id) => {
  return postAxios(apiUrl.memeber.checkDuplicateId, { id: id });
};

export const checkDuplicateSocialId = (id) => {
  return postAxios(apiUrl.memeber.checkDuplicateSocialId, { mberType: 'FACEBOOK', socialId: id });
};

export const sendAuthMail = (param) => {
  return postAxios(apiUrl.memeber.sendAuthMail, param);
};

export const checkAuthNum = (tokenValue, numberValue) => {
  return postAxios(apiUrl.memeber.checkAuthNum, { token: tokenValue, number: numberValue });
};

export const signupNormal = (param) => {
  return postAxios(apiUrl.memeber.signup, {
    id: param.email,
    password: param.password,
    personalYn: param.isTerms3 ? 'Y' : 'N',
    marketingYn: param.isTerms5 ? 'Y' : 'N',
    lang: param.lang,
  });
};

export const signupSocial = (param) => {
  return postAxios(apiUrl.memeber.signup, {
    id: param.email,
    password: param.password,
    mberType: param.socialType, // GOOGLE / APPLE / FACEBOOK
    socialId: param.socialId, // Social ID
    personalYn: param.isTerms3 ? 'Y' : 'N',
    marketingYn: param.isTerms5 ? 'Y' : 'N',
    lang: param.lang,
  });
};

export const checkDuplicateIdReset = (idValue) => {
  return postAxios(apiUrl.memeber.checkDuplicateIdReset, {
    id: idValue,
  });
};

export const resetPassword = (tokenValue, passwordValue) => {
  return postAxios(apiUrl.memeber.resetPassword, {
    token: tokenValue,
    password: passwordValue,
  });
};

export const secession = (idValue) => {
  return postAxios(apiUrl.memeber.secession, {
    id: idValue,
  });
};

//----------------------------------------------------------

export const uploadVoice = (file) => {
  let formData = new FormData();
  formData.append('ionicfile', file);

  return multiPostAxios(apiUrl.uploadVoice, formData);
};

export const voiceAnalysis = (param) => {
  return postAxios(apiUrl.voiceAnalysis, param);
};

//----------------------------------------------------------

export const uploadProfilePic = (file) => {
  let formData = new FormData();
  formData.append('file', file);

  return multiPostAxios(apiUrl.mypage.uploadProfilePic, formData);
};
export const updateProfile = (param) => {
  return postAxios(apiUrl.mypage.updateProfile, param);
};
export const updateViewTrans = (param) => {
  return postAxios(apiUrl.mypage.updateViewTrans, param);
};
export const typoProfileList = () => {
  return postAxios(apiUrl.mypage.typoProfileList, {});
};

export const mypageNotiList = (esntlIdValue) => {
  return postAxios(apiUrl.mypage.notiList, { esntlId: esntlIdValue });
};

export const mypageNotiViewUpd = (esntlIdValue, notiNoValue) => {
  return postAxios(apiUrl.mypage.notiViewUpd, { esntlId: esntlIdValue, msgNo: notiNoValue });
};

export const mypageCouponList = (esntlIdValue) => {
  return postAxios(apiUrl.mypage.couponList, { esntlId: esntlIdValue });
};

export const mypageAddCoupon = (esntlIdValue, cpCodeValue) => {
  return postAxios(apiUrl.mypage.addCoupon, {
    esntlId: esntlIdValue,
    cpCode: cpCodeValue,
  });
};

export const mypagePaymentList = (esntlIdValue) => {
  return postAxios(apiUrl.mypage.paymentList, {
    esntlId: esntlIdValue,
    productKind: 'ticket',
  });
};
//----------------------------------------------------------

export const studyhomeTodayList = (esntlIdValue) => {
  return postAxios(apiUrl.studyhome.todayList, {
    esntlId: esntlIdValue,
  });
};
export const studyhomeInsertToday = (esntlIdValue, todayIdValue) => {
  return postAxios(apiUrl.studyhome.insertToday, {
    esntlId: esntlIdValue,
    todayId: todayIdValue,
  });
};
export const studyhomeRecentLearning = (esntlIdValue) => {
  return postAxios(apiUrl.studyhome.recentLearning, {
    esntlId: esntlIdValue,
  });
};
export const studyhomeLearningRecommend = (esntlIdValue) => {
  return postAxios(apiUrl.studyhome.learningRecommend, {
    esntlId: esntlIdValue,
  });
};

//----------------------------------------------------------

// 서브홈 - 하단 코스학습 리스트
export const speakCourseList = (param) => {
  return postAxios(apiUrl.speak.courseList, param);
};

// S_0201_코스학습 목록 상단
export const speakUnitList = (param) => {
  return postAxios(apiUrl.speak.unitList, param);
};

// 마지막 학습 단원 기록하기
export const speakUpdateRecentUnit = (param) => {
  return postAxios(apiUrl.speak.updateRecentUnit, param);
};

// 마지막 학습 기록 불러오기
export const speakRecentUnit = (param) => {
  return postAxios(apiUrl.speak.recentUnit, param);
};

// S_0211, S_0221, S_0231
export const speakClassStepList = (param) => {
  return postAxios(apiUrl.speak.classStepList, param);
};
// 서브홈 - 상단 학습 히스토리
export const speakStudyHistory = (param) => {
  return postAxios(apiUrl.speak.studyHistory, param);
};
// 학습결과페이지
export const speakCourseResult = (param) => {
  return postAxios(apiUrl.speak.courseResult, param);
};
// 말하기 학습 퀴즈
export const speakUnitQuiz = (param) => {
  return postAxios(apiUrl.speak.unitQuiz, param);
};
// 퀴즈결과저장
export const speakUnitQuizResult = (param) => {
  return postAxios(apiUrl.speak.unitQuizResult, param);
};
// 퀴즈 결과 다시보기
export const speakUnitQuizReview = (param) => {
  return postAxios(apiUrl.speak.unitQuizReview, param);
};
// 코스학습1단계 다음처리
export const speakClassDone = (param) => {
  return postAxios(apiUrl.speak.classDone, param);
};
// 코스학습1,2,3 단계  완료체크
export const speakCheckDone = (param) => {
  return postAxios(apiUrl.speak.checkDone, param);
};
// 학습시간기록
export const speakSetLearningTime = (param) => {
  return postAxios(apiUrl.speak.setLearningTime, param);
};
// 단원 이름 불러오기
export const speakUnitText = (param) => {
  return postAxios(apiUrl.speak.unitText, param);
};

// 수업명 불러오기
export const speakClassText = (param) => {
  return postAxios(apiUrl.speak.classText, param);
};

// 마지막 클릭 코스 불러오기
export const speakLastClickCourse = (param) => {
  return postAxios(apiUrl.speak.lastClickCourse, param);
};

// 다음 수업 (PC용)
export const speakNextClassList = (param) => {
  return postAxios(apiUrl.speak.nextClassList, param);
};

//----------------------------------------------------------

export const topikTopik = (param) => {
  console.log('##topikTopik', param);
  return postAxios(apiUrl.topik.topik, param);
};

export const topikList = (param) => {
  return postAxios(apiUrl.topik.list, param);
};

export const topikMyRecord = (param) => {
  return postAxios(apiUrl.topik.myRecord, param);
};

export const topikRecordList = (param) => {
  return postAxios(apiUrl.topik.recordList, param);
};

export const topikRecordChart = (param) => {
  return postAxios(apiUrl.topik.recordChart, param);
};

export const topikUpdateRewardView = (param) => {
  return postAxios(apiUrl.topik.updateRewardView, param);
};

export const topikStart = (param) => {
  return postAxios(apiUrl.topik.start, param);
};

export const topikStop = (param) => {
  return postAxios(apiUrl.topik.stop, param);
};

export const topikFinish = (param) => {
  return postAxios(apiUrl.topik.finish, param);
};

export const topikResult = (param) => {
  return postAxios(apiUrl.topik.result, param);
};

//----------------------------------------------------------

export const mynoteReview = (param) => {
  return postAxios(apiUrl.mynote.review, param);
};

export const mynoteReport = (param) => {
  return postAxios(apiUrl.mynote.report, param);
};

export const mynoteCnt = (param) => {
  return postAxios(apiUrl.mynote.cnt, param);
};

//----------------------------------------------------------

export const getAxios = (url) => {
  return instanceAxios({
    method: 'GET',
    url: url,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const postAxios = (url, data) => {
  return instanceAxios({
    method: 'POST',
    url: url,
    data: data,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const multiPostAxios = (url, formData) => {
  return instanceAxios({
    method: 'POST',
    url: url,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
