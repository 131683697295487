import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import cn from 'classnames';
import dompurify from 'dompurify';

const TopikViewSuccessList = ({
  t,
  resultData,
  onQuestionViewOpen,
  setPageType,
  listType,
  setListType,
}) => {
  const [isIncorrect, setIsIncorrect] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    if (resultData.topikText) {
      setList(JSON.parse(resultData.topikText));
    }
  }, [resultData]);
  return (
    <>
      <div className="tab-area">
        <ul className="course-type">
          <li>
            <Link
              to="#"
              className={cn({ active: listType === '' })}
              onClick={(e) => {
                e.preventDefault();
                setListType('');
              }}
            >
              {t('v1.TopikSuccess.List_Tab1')}
            </Link>
          </li>
          <li>
            <Link
              to="#"
              className={cn({ active: listType === 'lc' })}
              onClick={(e) => {
                e.preventDefault();
                setListType('lc');
              }}
            >
              {t('v1.TopikSuccess.List_Tab2')}
            </Link>
          </li>
          <li>
            <Link
              to="#"
              className={cn({ active: listType === 'rc' })}
              onClick={(e) => {
                e.preventDefault();
                setListType('rc');
              }}
            >
              {t('v1.TopikSuccess.List_Tab3')}
            </Link>
          </li>
        </ul>
        <div className="checkbox">
          <input
            type="checkbox"
            id="view"
            onChange={(e) => {
              setIsIncorrect(e.target.checked);
            }}
          />
          <label htmlFor="view">{t('v1.TopikSuccess.List_CheckIncorrect')}</label>
        </div>
      </div>
      <div className="answer-wrap">
        {list.map((item, index) => {
          if (
            listType === '' ||
            (listType === 'lc' && item.questionType === 'LC') ||
            (listType === 'rc' && item.questionType === 'RC')
          ) {
            if (
              isIncorrect === false ||
              (isIncorrect === true && item.exampleAnswer !== item.selectedItem)
            ) {
              return (
                <div
                  className="item"
                  key={'list' + item.questionId}
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault();
                    onQuestionViewOpen({
                      totalStep: list.length,
                      nowStep: index + 1,
                      list: list,
                      data: item,
                    });
                  }}
                >
                  <span className="num">{index + 1}.</span>
                  <div className="sentence">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: dompurify.sanitize(
                          item.questionType === 'LC'
                            ? item.questionScript
                            : item.questionAttributeText,
                        ),
                      }}
                    ></p>
                  </div>
                  <span
                    className={cn('answer-result', {
                      correct: item.exampleAnswer === item.selectedItem,
                      incorrect: item.exampleAnswer !== item.selectedItem,
                    })}
                  >
                    {item.exampleAnswer === item.selectedItem
                      ? t('v1.TopikSuccess.List_Answer')
                      : t('v1.TopikSuccess.List_Wrong')}
                  </span>
                </div>
              );
            }
          }
        })}
      </div>
    </>
  );
};

export default TopikViewSuccessList;
