import React from 'react';

const TextTermsKo = () => {
  return (
    <>
      <b>제 1 조 (목적)</b>
      본 약관은 회원(본 약관을 동의한 자를 말합니다. 이하 ‘회원’이라고 합니다.)이 주식회사
      한글과컴퓨터(이하 ‘회사’라 합니다)이 제공하는 Hancom Genie K (한컴 지니케이) 서비스 (이하
      ‘서비스’라 합니다)를 이용함에 있어 회사와 회원의 권리, 의미 및 책임사항을 규정함을 목적으로
      합니다.
      <br />
      <br />
      <b>제 2 조 (정의)</b>
      ① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.1. ‘서비스’라 함은 접속 가능한 단말기의
      종류와는 상관없이 이용 가능한 회사가 제공하는 온라인상의 인터넷 서비스를 의미합니다.
      <br />
      2. ‘회원’이라 함은 회사의 서비스에 접속하여 이 약관에 따라 회사와 이용계약을 체결하고 회사가
      제공하는 서비스를 이용하는 고객을 말합니다.
      <br />
      3. ‘비회원’이라 함은 회원으로 가입하지 않고 회사가 제공하는 서비스를 이용하는 자를 말합니다.
      <br />
      4. ‘이용자’란 서비스에 접속하여 이 약관에 따라 회사가 제공하는 정보 및 기타 서비스를 제공받는
      회원 및 비회원을 말합니다.
      <br />
      5. ‘콘텐츠’라 함은 회원이 서비스를 이용함에 있어 서비스상에 게시한 대화 시나리오 및 Text,
      음성, 이미지 형태의 디지털 콘텐츠를 의미하며, 정보통신망이용촉진 및 정보보호 등에 관한 법률
      제2조 제1항 제1호의 규정에 의한 정보통신망에서 사용되는 부호.문자.음성.음향.이미지.영상 등으로
      표현된 자료 또는 정보를 말합니다.
      <br />
      6. ‘유료서비스’라 함은 회사가 유료로 제공하는 각종 온라인디지털콘텐츠(각종 정보 콘텐츠, 유료
      콘텐츠) 및 제반 서비스를 의미합니다.
      <br />
      7. ‘정기구독‘라 함은 ‘회원’이 ‘유료서비스’를 이용하기 위해 미리 등록한 결제수단을 통하여 월/년
      단위로 이용 요금이 정기적으로 결제되고, 이용기간이 자동 갱신되는 ‘유료서비스’ 이용방식을
      말합니다. ‘정기구독’을 해지하면 ‘결제’가 이루어진 ‘유료서비스’의 남은 이용기간 동안은
      ‘유료서비스’를 정상 이용할 수 있고, 다음 이용기간부터 자동 결제가 이루어지지 않습니다.
      <br />
      <br />
      <b>제 3 조 (약관의 명시와 개정)</b>
      ① 회사는 이 약관의 내용과 상호 및 대표자의 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수
      있는 곳의 주소를 포함), 전화번호, 전자우편주소, 사업자 등록번호, 통신판매업신고번호,
      개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 서비스 웹사이트에 게시합니다.
      <br />
      회사는 약관의규제에관한법률, 정보통신망이용촉진 및 정보보호등에관한법률(이하 ‘정보통신망법’)
      등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
      <br />
      ② 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에
      따라 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 다만, 이용자의 권리와
      의무에 중대한 영향을 미치는 사항에 대하여는 최소한 30일 이상의 사전 유예기간을 두고
      공지합니다.
      <br />
      ③ 회사가 전항에 따라 공지된 적용일자 이후에 이용자가 거부 표시 없이 회사의 서비스를 계속
      이용하는 경우에는 개정된 약관에 동의하는 것으로 봅니다.
      <br />
      ④ 회원이 개정약관의 적용에 동의하지 않는 경우 회원은 이용계약을 해지할 수 있습니다.
      <br />
      ⑤ 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 관계법령 또는 상관례에 따릅니다.
      <br />
      <br />
      <b>제 4 조 (이용계약 체결)</b>
      ① 이용계약은 회원이 되고자 하는 자(이하 가입신청자)가 약관의 내용에 대하여 동의를 한 다음 계정
      인증을 함으로써 체결됩니다.
      <br />
      ② 회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 회사는 다음
      각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지 할 수 있습니다.
      <br />
      1. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 회사의 회원
      재가입 승낙을 얻은 경우에는 예외로 함<br />
      2. 실명이 아니거나 타인의 명의를 이용한 경우
      <br />
      3. 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않는 경우
      <br />
      4. 14세 미만 아동이 법정대리인의 동의를 얻지 아니한 경우
      <br />
      5. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는
      경우
      <br />
      ③ 제4조 제2항 제1호에 따른 신청에 있어 회사”는 회원의 종류에 따라 전문기관을 통한 실명확인 및
      본인인증을 요청할 수 있습니다.
      <br />
      ④ 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을
      유보할 수 있습니다.
      <br />
      ⑤ 제4조 제2항 제2호과 제4호에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 회사는
      원칙적으로 이를 가입 신청자에게 알리도록 합니다.
      <br />
      이용계약의 성립 시기는 사용자가 계정인증을 진행하여 회원가입의 절차가 완료된 시점으로 합니다.
      <br />
      ⑥ 회사는 회원에 대해 회사정책에 따라 등급별로 구분하고 이용시간, 이용횟수, 이용방식, 이용범위.
      서비스 메뉴 등을 세분화하여 이용에 차등을 둘 수 있습니다.
      <br />
      ⑦ 회사는 회원에 대하여 영화 및 비디오물의진흥에관한법률 및 청소년보호법 등에 따른 등급 및 연령
      준수를 위해 이용제한이나 등급별 제한을 할 수 있습니다.
      <br />
      <br />
      <b>제 5 조 (회원정보의 변경)</b>
      ① 회원은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다.
      <br />
      ② 회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 해야 합니다.
      <br />
      ③ 제5조 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.
      <br />
      <br />
      <b>제 6 조 (개인정보보호 의무)</b>
      ① 회사는 정보통신망법 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해
      노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 회사의 개인정보취급방침이 적용됩니다.
      다만, 회사의 공식 사이트 이외의 링크된 사이트에서는 회사의 개인정보 취급방침이 적용되지
      않습니다.
      <br />
      <br />
      <b>제 7 조 (회원의 아이디 및 비밀번호의 관리에 대한 의무)</b>
      ① 회원의 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는
      안 됩니다.
      <br />
      ② 회사는 회원의 아이디가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 회사
      및 회사의 운영자로 오인한 우려가 있는 경우, 해당 아이디의 이용을 제한할 수 있습니다.
      <br />
      ③ 회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시
      회사에 통지하고 회사의 안내에 따라야 합니다.
      <br />
      ④ 제7조 제3항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의
      안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.
      <br />
      <br />
      <b>제 8 조 (회원에 대한 통지)</b>
      ① 회사가 회원에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스 내 전자우편주소,
      전자쪽지 등으로 할 수 있습니다.
      <br />
      ② 회사는 회원 전체에 대한 통지의 경우 7일 이상 회사의 서비스에 게시함으로써 제1항의 통지에
      갈음할 수 있습니다.
      <br />
      <br />
      <b>제 9 조 (회사의 의무)</b>
      ① 회사는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고
      안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.
      <br />
      ② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해
      보안시스템을 갖추어야 하며 개인정보 취급방침을 공시하고 준수합니다.
      <br />
      ③ 회사는 서비스 이용과 관련하여 발생하는 이용자의 불만 또는 피해구제요청을 적절하게 처리할 수
      있도록 필요한 인력 및 시스템을 구비합니다.
      <br />
      ④ 회사는 서비스 이용과 관련하여 회원으로 부터 제기된 의견이나 불만이 정당하다고 인정할
      경우에는 이를 처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 전자우편 등을
      통하여 회원에게 처리 과정 및 결과를 전달합니다.
      <br />
      <br />
      <b>제 10 조 (회원의 의무)</b>
      회원은 다음 행위를 하여서는 안 됩니다.
      <br />
      1. 신청 또는 변경 시 허위내용의 등록
      <br />
      2. 타인의 정보도용
      <br />
      3. 회사가 게시한 정보의 변경
      <br />
      4. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
      <br />
      5. 회사 및 기타 제3자의 저작권 등 지적재산권에 대한 침해
      <br />
      6. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
      <br />
      7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 서비스에 공개 또는
      게시하는 행위
      <br />
      8. 회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위
      <br />
      9. 기타 불법적이거나 부당한 행위
      <br />
      <br />
      <b>제 11 조 (서비스의 제공 등)</b>
      ① 회사는 회원에게 아래와 같은 서비스를 제공합니다.
      <br />
      1. 학습 시나리오 콘텐츠 제공
      <br />
      2. 사니리오별 퀴즈 문항 및 단어, 문형 제공
      <br />
      3. 학습 결과 데시보드를 통한 통계 제공
      <br />
      4. 이메일, 문자, 메시지 등을 활용한 정보 알림
      <br />
      5. 기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 회원에게 제공하는 일체의
      서비스
      <br />
      ② 서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.
      <br />
      ③ 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가
      있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 회사는 제9조 [회원에 대한
      통지]에 정한 방법으로 회원에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가
      있는 경우 사후에 통지할 수 있습니다.
      <br />
      ④ 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은
      서비스제공화면에 공지한 바에 따릅니다.
      <br />
      <br />
      <b>제 12 조 (주문 및 결제)</b>
      ① 회원은 회사가 제공하는 다음 각 호 또는 이와 유사한 절차에 의하여 콘텐츠 구매를 신청 합니다.
      <br />
      1. 콘텐츠의 검색, 목록 열람 및 구매 대상 선택
      <br />
      2. 콘텐츠 상세정보 확인
      <br />
      3. 결제금액 확인, 결제수단 및 선택 결제하기 (환불규정 안내)
      <br />
      4. 결제금액 재확인 및 결제
      <br />
      ② 회원은 회사에서 정하고 있는 방법으로 유료서비스의 결제가 가능합니다. 단, 미성년 회원의
      결제는 원칙적으로 법정대리인의 명의 또는 동의 하에 이루어져야 하고, 법정대리인은 본인 동의
      없이 체결된 자녀(미성년자)의 계약을 취소할 수 있습니다. 미성년자가 다른 성년자 등의
      주민등록번호를 이용하여 회원가입을 하거나 성년자 등의 결제정보를 동의 없이 이용하는 등 회사로
      하여금 성년자인 것으로 믿게 한 경우에는 법정대리인의 동의가 없더라도 계약을 취소하지 못합니다.
      <br />
      ③ 1인당 1회 한정으로 제공되는 혜택의 경우, 서로 다른 ID에서 혜택 수령을 시도하더라도 결제수단
      및 기타 개인식별정보를 이용하여 동일인으로 간주하고 혜택 제공을 차단할 수 있습니다.
      <br />
      ④ 앱 마켓 인앱 결제의 경우 각 앱 마켓 서비스 이용 약관 정책을 따릅니다.
      <br />
      <br />
      <b>제 13 조 (환불규정)</b>① 구매를 철회하거나 취소하고 제품을 반품하거나 환불받을 권리에 관한
      자세한 정보는 각 앱 마켓 서비스 이용 약관 정책을 따릅니다. 각 앱 마켓 환불 정책 또는 제공자의
      환불 정책에 명시적으로 규정된 경우를 제외하고 모든 구매는 취소할 수 없으며 반품, 교환 또는
      환불이 허용되지 않습니다. <br />
      ② 앱 마켓의 인앱 결제 수단 및 해지/환불 이슈 등은 각 앱 마켓 고객센터에 문의하시기 바랍니다.
      단, 다음 각 호에 해당하는 경우에는 이용자가 환불을 요청할 수 없습니다.
      <br />
      1. 구매 콘텐츠 열람 시<br />
      2. 서비스 업데이트를 통한 문제 해결이 가능함에도 회원의 의사로 이를 거부하여 서비스를 이용하지
      못하는 경우
      <br />
      3. 회원의 실수로 해당 서비스를 이용하지 못하는 경우
      <br />
      4. 회원이 유료서비스를 선물 받거나, 프로모션 등을 통해 무료/무상으로 취득하는 등 회원이 직접
      비용을 지불하지 아니하고 이용하는 유료서비스의 경우
      <br />
      ③ ‘유료서비스’를 유지하고자 하는 ‘유료회원’은 이용요금의 체납 또는 결제수단의 연체가 발생하지
      않도록 사전에 조치하여야 합니다.
      <br />
      ④ ‘회원’이 전항을 위반하여 ‘유료서비스’의 신청 또는 동의에 따라 월 정기결제 중인
      ‘유료서비스’를 이용하는 중에 ‘유료서비스’의 이용요금을 체납하는 경우 연체가 발생한 날 자동으로
      이용권 해지가 될 수 있습니다.
      <br />
      ⑤ ‘유료서비스’를 이용 중인 ‘회원’이 회원가입 계약을 해지하는 경우 해당 ‘유료서비스’는 즉시
      해지되며, 환불할 경우, '회사'는 '회원’의 서비스 이용한 일 수, 이용내역, 서비스 또는 상품을
      통하여 얻은 이익에 해당하는 금액, 할인 받은 금액 등을 차감 후 환불합니다. 다만, ‘회사’는 이
      경우 ‘유료서비스’를 이용한 ‘회원’의 정보와 이용 내용은 관련 법령이 정하는 경우를 제외하고는 이
      약관에 따라 처리합니다.
      <br />
      <br />
      <b>제 14 조 (서비스의 변경)</b>
      ① 회사는 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경할 수
      있습니다. (유료 서비스 포함)
      <br />
      ② 서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의
      내용 및 제공일자 등은 그 변경 전에 해당 서비스 초기화면에 게시하여야 합니다.
      <br />
      ③ 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단,
      변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지
      않습니다.
      <br />
      <br />
      <b>제 15 조 (정보의 제공 및 광고의 게재)</b>
      ① 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의
      방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 관련법에 따른 거래관련 정보 및 고객문의
      등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.
      <br />
      ② 제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 회원의 사전 동의를
      받아서 전송합니다. 다만, 회원의 거래관련 정보 및 고객문의 등에 대한 회신에 있어서는
      제외됩니다.
      <br />
      ③ 회사는 서비스의 운영과 관련하여 서비스 화면, 홈페이지, 전자우편 등에 광고를 게재할 수
      있습니다. 광고가 게재된 전자우편을 수신한 회원은 수신거절을 회사에게 할 수 있습니다.
      <br />
      이용자(회원, 비회원 포함)는 회사가 제공하는 서비스와 관련하여 게시물 또는 기타 정보를 변경,
      수정, 제한하는 등의 조치를 취하지 않습니다.
      <br />
      <br />
      <b>제 16 조 (콘텐츠의 저작권)</b>
      ① 회사가 서비스를 통하여 제공하는 콘텐츠의 저작권 및 기타 지적재산권은 회사에 있습니다.
      <br />
      ② 이용자는 서비스를 이용함으로써 얻는 정보 중 회사에게 지적재산권이 귀속된 정보를 회사의 사전
      승낙없이 복제, 판매, 출판, 송신, 배포, 방송 기타 방법에 의하여 영리, 비영리 목적으로
      이용하거나 제3자에게 이용하게 하여서는 안됩니다.
      <br />
      <br />
      <b>제 17 조 (저작권의 관리)</b>
      ① 회사와 업무협의를 통하여 업로드한 관계사의 콘텐츠가 정보통신망법 및 저작권법 등 관련법에
      위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 콘텐츠의 게시중단 및
      삭제 등을 요청할 수 있으며, 회사는 관련법에 따라 해당 콘텐츠를 임의로 삭제할 수 있으며 해당
      게시물에 대한 법적 문제가 종결된 후 이를 근거로 회사에 신청이 있는 경우에만 상기 임시 삭제된
      게시물을 다시 게재할 수 있습니다.
      <br />
      ② 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타
      회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 콘텐츠에 대해 임시 조치 등을 취할
      수 있습니다.
      <br />
      <br />
      <b>제 18 조 (권리의 귀속)</b>
      ① 서비스에 대한 저작권 및 지적재산권은 회사에 귀속됩니다.
      <br />
      ② 회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 계정, 아이디, 콘텐츠 등을
      이용할 수 있는 이용권만을 부여하며, 회원은 이를 양도, 판매, 담보 제공 등의 처분행위를 할 수
      없습니다.
      <br />
      <br />
      <b>제 19 조 (계약해제, 해지 등)</b>
      ① 회원은 언제든지 서비스 내에 메뉴를 통하여 이용계약 해지 신청을 할 수 있으며, 회사는 관련법
      등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.
      <br />
      ② 회원이 계약을 해지할 경우, 관련법 및 개인정보취급방침에 따라 회사가 회원정보를 보유하는
      경우를 제외하고는 해지 즉시 회원의 모든 데이터는 소멸됩니다.
      <br />
      <br />
      <b>제 20 조 (이용제한 등)</b>
      ① 회사는 회원이 이 약관의 위반하의무를 거나 서비스의 정상적인 운영을 방해한 경우, 경고,
      일시정지, 영구이용정지 등으로 서비스 이용을 단계적으로 제한할 수 있습니다.
      <br />
      ② 회사는 전항에도 불구하고, 주민등록법을 위반한 명의도용 및 결제도용, 저작권법 및
      컴퓨터프로그램보호법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망법을 위반한 불법통신
      및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시
      영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지 시 서비스 이용을 통해 획득한 모든
      혜택도 함께 소멸되며, 회사”는 이에 대해 별도로 보상하지 않습니다.
      <br />
      ③ 회사는 회원이 계속해서 3개월 이상 로그인하지 않은 경우, 회원정보의 보호 및 운영의 효율성을
      위해 이용을 제한할 수 있습니다.
      <br />
      본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는 회사는 제8조[회원에 대한
      통지]에 따라 통지합니다.
      <br />
      ④ 회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이
      때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.
      <br />
      <br />
      <b>제 21 조 (책임제한)</b>
      ① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스
      제공에 관한 책임이 면제됩니다.
      <br />
      ② 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.
      <br />
      회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는
      책임을 지지 않습니다.
      <br />
      ③ 회사는 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이
      면제됩니다.
      <br />
      ④ 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지
      않습니다.
      <br />
      <br />
      <b>제 22 조 (준거법 및 재판관할)</b>
      ① 회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.
      <br />
      ② 회사와 회원간 발생한 분쟁에 관한 소송은 서울중앙지방법원을 전속관할로 합니다.
      <br />
      ③ 해외에 주소나 거소가 있는 회원의 경우 회사와 회원간 발생한 분쟁에 관한 소송은 대한민국
      서울중앙지방법원을 관할법원으로 합니다.
      <br />
      <br />
      <b>[부칙]</b>
      제 1 조 (시행일)
      <br />본 약관은 2021년 06월 16일자부터 시행하며, 종전의 약관을 대체합니다.
    </>
  );
};

export default TextTermsKo;
