import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import ReactPlayer from 'react-player/file';

const SpeakMicTest = ({ t, onModalShowHidden }) => {
  const localStorage = window.localStorage;

  // 0 마이크 버튼을 클릭하여 테스트하세요.
  // 1 마이크에 대고 말한 후, 중단 버튼을 클릭하세요.
  // 2 재생되는 소리가 들리시나요?
  //   ※ 들리지 않으면 다른 마이크를 선택해주세요.
  const [state, setState] = useState(0);

  const refSelectMic = useRef(null);
  const [micList, setMicList] = useState([]);

  const [soundLevel, setSoundLevel] = useState(0);
  const soundLevelInterval = useRef(null);

  const [analyser, setAnalyser] = useState();
  const [stream, setStream] = useState();
  const [source, setSource] = useState();
  const [mediaRecorder, setMediaRecorder] = useState();

  const [audio, setAudio] = useState();
  const [audioUrl, setAudioUrl] = useState();
  const [audioAnalyser, setAudioAnalyser] = useState();
  const [audioSource, setAudioSource] = useState();

  useEffect(() => {
    //localStorage.removeItem('selectMicId');
    _updateMicList();

    // 장치가 변경됐을경우.
    navigator.mediaDevices.ondevicechange = function (event) {
      setState(0);
      _updateMicList();
    };

    return () => {
      navigator.mediaDevices.ondevicechange = function (event) {};
    };
  }, []);

  useEffect(() => {
    if (state === 1) {
      _micRecord();
    } else {
      _micRecordStop();
    }

    if (state === 2) {
      _micRecordPlay();
    }
  }, [state]);

  useEffect(() => {
    if (source && analyser) {
      _soundLevelAnimation(analyser);
    }
    return () => {
      _soundLevelAnimationClear();
    };
  }, [source, analyser]);

  useEffect(() => {
    if (audioSource && audioAnalyser) {
      _soundLevelAnimation(audioAnalyser);
    }
    return () => {
      _soundLevelAnimationClear();
    };
  }, [audioSource, audioAnalyser]);

  // 음성 파동 애니메이션
  const _soundLevelAnimation = (analyser) => {
    _soundLevelAnimationClear();
    soundLevel();
    function soundLevel() {
      analyser.fftSize = 32;
      var bufferLengthAlt = analyser.frequencyBinCount;
      var dataArrayAlt = new Uint8Array(bufferLengthAlt);

      analyser.getByteFrequencyData(dataArrayAlt);
      let soundLevelSum =
        dataArrayAlt[0] + dataArrayAlt[1] + dataArrayAlt[2] + dataArrayAlt[3] + dataArrayAlt[4];
      setSoundLevel(Math.floor((soundLevelSum / 1275) * 10));

      soundLevelInterval.current = requestAnimationFrame(soundLevel);
    }
  };

  // 음성 파동 애니메이션 제거
  const _soundLevelAnimationClear = () => {
    setSoundLevel(0);
    if (soundLevelInterval.current) window.cancelAnimationFrame(soundLevelInterval.current);
  };

  useEffect(() => {
    if (audioUrl) {
      _audioPlay();
    }

    return () => {
      _audioStop();
    };
  }, [audioUrl]);

  //----------------------------------------

  const onMicClick = () => {
    if (state === 0 || state === 2) {
      onMicRecord();
    } else {
      onMicRecordPlay();
    }
  };

  // 녹음 시작하기.
  const onMicRecord = () => {
    setState(1);
  };

  // 녹음 중지 재생하기.
  const onMicRecordPlay = () => {
    setState(2);
  };

  // 마이크 변경
  const onMicSelectChange = () => {
    setState(0);
    _micRecordStop();
    if (refSelectMic.current.value !== '') {
      localStorage.setItem('selectMicId', refSelectMic.current.value);
    } else {
      localStorage.removeItem('selectMicId');
    }
  };

  //----------------------------------------

  const _getUserMediaParams = (audio) => {
    return {
      audio: {
        deviceId: audio ? { exact: audio } : undefined,
      },
    };
  };

  const _micRecord = async () => {
    _audioStop();
    _micRecordStop();

    const tmpAudioCtx = new (window.AudioContext || window.webkitAudioContext)();
    const tmpAnalyser = tmpAudioCtx.createAnalyser();
    setAnalyser(tmpAnalyser);

    let micDeviceId = localStorage.getItem('selectMicId');
    const userMediaParam = _getUserMediaParams(micDeviceId);
    let tmpStream;
    try {
      tmpStream = await navigator.mediaDevices.getUserMedia(userMediaParam);
    } catch (error) {
      tmpStream = await navigator.mediaDevices.getUserMedia({ audio: true });
    }
    setStream(tmpStream);

    // 녹음 기능 시작
    const tmpMediaRecorder = new MediaRecorder(tmpStream);
    tmpMediaRecorder.start();
    setMediaRecorder(tmpMediaRecorder);

    // 내 컴퓨터의 마이크나 다른 소스를 통해 발생한 오디오 스트림의 정보를 보여준
    const tmpSource = tmpAudioCtx.createMediaStreamSource(tmpStream);
    setSource(tmpSource);
    tmpSource.connect(tmpAnalyser);
    //tmpAnalyser.connect(tmpAudioCtx.destination);

    tmpAnalyser.onaudioprocess = function (e) {
      // 3분(180초) 지나면 자동으로 음성 저장 및 녹음 중지
      if (e.playbackTime > 180) {
        _micRecordStop();
      } else {
        onMicRecordPlay();
      }
    };
  };

  const _micRecordStop = () => {
    if (mediaRecorder) {
      mediaRecorder.ondataavailable = function (e) {
        setAudioUrl(URL.createObjectURL(e.data));
      };
      mediaRecorder.stop();
      setMediaRecorder(undefined);
    }

    if (stream) stream.getAudioTracks().forEach((vidTrack) => vidTrack.stop());
    if (analyser) analyser.disconnect();

    if (analyser) analyser.disconnect();
    if (source) source.disconnect();
    setAnalyser(undefined);
    setSource(undefined);

    if (stream) {
      const tmpAudioCtx = new (window.AudioContext || window.webkitAudioContext)();
      tmpAudioCtx.createMediaStreamSource(stream).disconnect();
    }

    if (soundLevelInterval.current) window.cancelAnimationFrame(soundLevelInterval.current);
    setSoundLevel(0);
  };

  const _micRecordPlay = () => {
    // 마이크 음성 재생
    const tmpAudio = new Audio();
    tmpAudio.srcObject = stream;
    tmpAudio.onloadedmetadata = (event) => {
      tmpAudio.play();
    };
  };

  const _audioPlay = () => {
    const tmpAudio = new Audio(audioUrl);
    tmpAudio.play();

    tmpAudio.addEventListener('ended', () => _audioStop());

    const tmpAudioCtx = new AudioContext();
    const tmpAudioSource = tmpAudioCtx.createMediaElementSource(tmpAudio);
    const tmpAudioAnalayzer = tmpAudioCtx.createAnalyser();
    tmpAudioSource.connect(tmpAudioAnalayzer);
    tmpAudioSource.connect(tmpAudioCtx.destination);

    setAudio(tmpAudio);
    setAudioSource(tmpAudioSource);
    setAudioAnalyser(tmpAudioAnalayzer);
  };

  const _audioStop = () => {
    if (audio) {
      audio.pause();
      audio.removeEventListener('ended', () => _audioStop());
      setAudio(undefined);
    }

    if (audioAnalyser) audioAnalyser.disconnect();
    if (audioSource) audioSource.disconnect();
    setAudioAnalyser(undefined);
    setAudioSource(undefined);
  };

  // 화면에 마이크 기기 정보를 업데이트 한다.
  const _updateMicList = async () => {
    // 마이크 기기 정보
    const devices = await navigator.mediaDevices.enumerateDevices();
    const mics = devices.filter((device) => device.kind === 'audioinput');

    setMicList(mics);

    const selectMicId = localStorage.getItem('selectMicId');

    refSelectMic.current.options.length = 0;

    if (mics.length === 0) {
      const option = document.createElement('option');
      option.value = '';
      option.selected = true;
      option.text = t('v1.SpeakMicTest.TextNotMic');

      refSelectMic.current.appendChild(option);
    } else if (mics.length > 0) {
      mics.forEach((micData) => {
        const option = document.createElement('option');

        if (micData.deviceId) {
          option.value = micData.deviceId;
          option.selected = selectMicId === micData.deviceId ? true : false;
          option.text = micData.label || `mic ${refSelectMic.current.length + 1}`;

          refSelectMic.current.appendChild(option);
        }
      });
    }
  };

  return (
    <div className="modal" style={{ display: 'block' }}>
      <div className="inner">
        <div className="modal-header">
          <h1 className="no-ico">{t('v1.SpeakMicTest.Title')}</h1>
          <Link
            to="#"
            className="btn-close"
            onClick={(e) => {
              e.preventDefault();
              _micRecordStop();
              onModalShowHidden();
            }}
          >
            {t('v1.Common.BtnClose')}
          </Link>
        </div>
        <div className="modal-body">
          <div className="mic-test">
            <div className="txt-area">
              <p className="em">
                {state === 0 && t('v1.SpeakMicTest.Title1')}
                {state === 1 && t('v1.SpeakMicTest.Title2')}
                {state === 2 && t('v1.SpeakMicTest.Title3')}
              </p>
              {state === 2 && <p className="normal">{t('v1.SpeakMicTest.SubTitle3-1')}</p>}
            </div>
            <div className="mic-area">
              <div className="mic-select">
                <button
                  type="button"
                  className={cn('btn-mic', { 'btn-record': state === 1 })}
                  onClick={(e) => {
                    e.preventDefault();
                    onMicClick();
                  }}
                >
                  Mic
                </button>
                <label htmlFor="select" className="sbj">
                  {t('Speak_Edu.Microphone_Test_Select1')}
                </label>
                <select
                  id="select"
                  ref={refSelectMic}
                  disabled={state === 1 ? true : false}
                  onChange={(e) => {
                    onMicSelectChange();
                  }}
                >
                  <option></option>
                </select>
              </div>
              <div className="level">
                <span className="sbj">{t('Speak_Edu.Microphone_Test_Lv')}</span>
                <div className="bar">
                  <span className={cn({ active: soundLevel > 0 })}>1 Level</span>
                  <span className={cn({ active: soundLevel > 1 })}>2 Level</span>
                  <span className={cn({ active: soundLevel > 2 })}>3 Level</span>
                  <span className={cn({ active: soundLevel > 3 })}>4 Level</span>
                  <span className={cn({ active: soundLevel > 4 })}>5 Level</span>
                  <span className={cn({ active: soundLevel > 5 })}>6 Level</span>
                  <span className={cn({ active: soundLevel > 6 })}>7 Level</span>
                  <span className={cn({ active: soundLevel > 7 })}>8 Level</span>
                  <span className={cn({ active: soundLevel > 8 })}>9 Level</span>
                  <span className={cn({ active: soundLevel > 9 })}>10 Level</span>
                  <span className={cn({ active: soundLevel > 10 })}>11 Level</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-area">
          <Link
            to="#"
            className="btn-default"
            onClick={(e) => {
              e.preventDefault();
              _micRecordStop();
              onModalShowHidden();
            }}
          >
            {t('Speak_Edu.btn_Edu_Start')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SpeakMicTest;
