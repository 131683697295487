import React, { useRef } from 'react';
import useScript from '../../library/useScript';
import jwt_decode from 'jwt-decode';

const LoginGoogle = () => {
  const onGoogleSignIn = (resp) => {
    console.log('onGoogleSignInㅁㄴㅇ', resp);
    console.log('token Decode', jwt_decode(resp.credential));
  };
  const text = '로그인';
  const googleSignInButton = useRef(null);

  useScript('https://accounts.google.com/gsi/client', () => {
    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: onGoogleSignIn,
    });
    window.google.accounts.id.renderButton(
      googleSignInButton.current,
      { type: 'icon', theme: 'none', size: 'large', shape: 'circle', width: 500, height: 500 }, // customization attributes
    );
  });
  return <div className="test" ref={googleSignInButton}></div>;
};

export default LoginGoogle;
