import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useUserState, useUserDispatch } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import * as Api from '../../library/Api';
import cn from 'classnames';

import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import MypageLeftNav from './layout/MypageLeftNav';
import MypageSignOut from './layout/MypageSignOut';
import { use } from 'i18next';

const MypageAccount = ({ isLogin, user, t }) => {
  const navigate = useNavigate();
  const userDispatch = useUserDispatch();
  const viewTransCheck = useRef();

  const [isSignOutProcess, setIsSignOutProcess] = useState(false);

  useEffect(() => {
    if (isLogin === 1) {
      if (viewTransCheck.current) viewTransCheck.current.checked = user.viewTrans;
    } else if (isLogin === 0) {
      //alert('로그인 후 이용가능합니다.');
      navigate('/member/login');
    }
  }, [isLogin]);

  const onSignOutClose = () => {
    setIsSignOutProcess(false);
  };

  const onSignOutOpen = () => {
    setIsSignOutProcess(true);
  };

  return (
    <>
      {isLogin === 1 && (
        <div
          className={cn('wrap', {
            wrapKo: t('languageCode') === 'ko',
            wrapEn: t('languageCode') === 'en',
            wrapId: t('languageCode') === 'id',
            wrapVi: t('languageCode') === 'vi',
          })}
        >
          <Header />

          <section className="mypage-wrap">
            <h2 className="blind">{t('v1.Mypage.Title')}</h2>
            <div className="inner">
              <MypageLeftNav isLogin={isLogin} user={user} t={t} />
              <article className="mypage-content">
                <h3 className="mypage-title">{t('v1.MypageAccount.Title')}</h3>
                <div className="manage-wrap">
                  <div className="row">
                    <span className="sbj">{t('v1.MypageAccount.BtnLanguage')}</span>
                    <div className="toggle">
                      <input
                        type="checkbox"
                        name="view"
                        id="view"
                        ref={viewTransCheck}
                        onClick={(e) => {
                          //e.preventDefault();
                          (async () => {
                            try {
                              Api.updateViewTrans({
                                id: user.id, // 필수
                                viewTrans: viewTransCheck.current.checked,
                              });

                              userDispatch({
                                type: 'LOGIN',
                                user: {
                                  ...user,
                                  viewTrans: viewTransCheck.current.checked,
                                },
                              });
                            } catch (error) {}
                          })();
                        }}
                      />
                      <label htmlFor="view">{t('v1.MypageAccount.BtnLanguage')}</label>
                    </div>
                  </div>
                  {!user.mberType && (
                    <div className="row">
                      <Link to="/mypage/passwordChange">
                        {t('v1.MypageAccount.BtnPasswordChange')}
                      </Link>
                    </div>
                  )}
                  <div className="row">
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        onSignOutOpen();
                      }}
                    >
                      {t('v1.MypageAccount.BtnDelete')}
                    </Link>
                  </div>
                </div>
              </article>
            </div>

            {isSignOutProcess && (
              <MypageSignOut isLogin={isLogin} user={user} t={t} onClose={onSignOutClose} />
            )}
          </section>
          <Footer />
        </div>
      )}
    </>
  );
};

export default MypageAccount;
