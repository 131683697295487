import React from 'react';

const TextTermsEn = () => {
  return (
    <>
      <b>Article 1 (Purpose)</b>
      The purpose of the terms of use is to stipulate the rights, meaning, and obligations of the
      member (the person who agreed to the terms of use; hereafter "the member") and Hancom Co.,
      Ltd. (hereafter "the company") with respect to using the Hancom Genie K service (hereafter
      "the service") offered by the company.
      <b>Article 2 (Definition)</b>
      ① The definition of terms used in the terms of use is as follows.
      <br />
      1. "Service" refers to the online internet services provided by the company which can be used
      regardless of the types of devices accessible.
      <br />
      2. "Member" refers to customers who access the company's service to sign a user agreement with
      the company in accordance with the terms of use and use the services provided by the company.
      <br />
      3. "Non-member" refers to those who use the service provided by the company without
      registering as a member.
      <br />
      4. "User" refers to a member or non-member who accesses the service and receives information
      or other services provided by the company in accordance with the terms of use.
      <br />
      5. "Content" refers to digital content in the form of conversation scenarios, texts, voice, or
      images that are presented in the service while the member uses the service, and refers to
      materials or information expressed as symbols, text, voice, sound, images, videos, etc. which
      are used in the information and communications network in accordance with Article 2 Paragraph
      1 Subparagraph 1 of the Act on the Promotion of Information and Communications Network
      Utilization and Information Protection, Etc.
      <br />
      6. "Paid Service" refers to various online digital content (including information content, and
      paid content) and related services provided by the company at a charge.
      <br />
      7. "Regular Subscription" refers to a method of using "paid services" for which a subscription
      period is renewed automatically and subscription fees are paid regularly every month/year
      through the pre-registered payment method for a "member" to use the "paid services". If a
      "regular subscription" is canceled, "paid services" can be used normally for the remaining
      period of the "paid services" for which payment is completed, and automatic payments will not
      be charged for the next usage period.
      <br />
      <br />
      <b>Article 3 (Announcement and Amendment of Terms of Use)</b>
      ① The company posts the content of the terms of use, company name, representative's name,
      business address (including the address where consumer complaints are handled), phone number,
      email address, business registration number, online marketing business report number, personal
      information manager, etc. on the service website so that it is easily accessible to users.
      <br />
      The company may make amendments to the terms of use within a scope which does not violate any
      relevant laws including the Act on the Regulation of Terms and Conditions and the Act on the
      Promotion of Information and Communications Network Utilization and Information Protection,
      Etc. (hereafter "the Information and Communications Network Act").
      <br />
      ② If the company amends the terms of use, it will announce the date of effect and reason for
      the amendments along with the current terms of use from 7 days before the date of effect to
      the day before the date of effect, in accordance with the method in Paragraph 1. However,
      matters which may have a significant impact on the rights and obligations of users will be
      announced at least 30 days in advance.
      <br />
      ③ After the date of effect announced by the company in accordance with the previous paragraph,
      it is deemed that a user agrees to the amended terms of use when the user continues to use the
      company's service without any expression of refusal.
      <br />
      ④ Members may cancel the user agreement if they do not consent to the application of the
      amended terms.
      <br />
      ⑤ Matters or interpretations which are not stipulated in the terms of use shall be determined
      in accordance with relevant laws or common commercial practices.
      <br />
      <br />
      <b>Article 4 (Signing the User Agreement)</b>
      ① The user agreement is completed when a person who wishes to become a member (hereafter
      "applicant") agrees to the content of the terms of use and verifies their account.
      <br />
      ② By default, the company shall approve an applicant's application to use the service.
      However, the company may not approve or cancel the user agreement in the future for the
      applications in the following subparagraphs.
      <br />
      1. If the applicant has previously lost their membership in the past in accordance with the
      terms of use, but an exception may be made upon the company's approval for them to re-apply
      for membership.
      <br />
      2. If the applicant does not use a real name or uses someone else's name
      <br />
      3. If the applicant uses false information or does not enter required information asked by the
      company.
      <br />
      4. If a minor under 14 years of age does not have a consent from their legal representative
      <br />
      5. If approval cannot be given due to user liability or if the applicant violates general
      regulations
      <br />
      ③ With respect to the applications in accordance with Article 4 Paragraph 2 Subparagraph 1,
      "the company" may request real name verification and identity verification through a
      specialized institution according to member type.
      <br />
      ④ The company may withhold approval if there are not enough service-related facilities or if
      there are technical or operational issues.
      <br />
      ⑤ If the applicant is not approved or if approval is withheld due to Article 4 Paragraph 2
      Subparagraph 2 or 4, the company shall notify the applicant.
      <br />
      The user agreement shall be considered concluded when the user verifies their account and
      completes the membership registration process.
      <br />
      ⑥ The company shall categorize members into different levels according to the company's
      policies, and differentiate the services by sub-dividing the usage time, frequency of use,
      usage method, usage range, service menu, and more.
      <br />
      ⑦ The company may restrict usage or implement restrictions by user level in order to comply
      with class or age regulations in accordance with the Promotion of The Motion Pictures and
      Video Products Act and the Juvenile Protection Act.
      <br />
      <br />
      <b>Article 5 (Member Information Change)</b>
      ① Members may view and revise their personal information at any time through the personal
      information management screen.
      <br />
      ② Members can make revisions online if the information they entered when signing up has
      changed.
      <br />
      ③ The company shall not be held responsible for any disadvantages which occur due to members'
      failure to notify the company of the changes specified in Article 5 Paragraph 2.
      <br />
      <br />
      <b>Article 6 (Personal Information Protection Obligations)</b>
      ① The company will make the effort to protect member's personal information as stipulated in
      the Information and Communications Network Act and other relevant laws. Relevant laws and the
      company's privacy policy apply to the protection and use of personal information. However,
      except the company's official website itself, the company's privacy policy does not apply to
      the websites linked.
      <br />
      <br />
      <b>Article 7 (Obligation to Member ID and Password Management)</b>
      ① Members shall be responsible for managing their ID and password, and they shall not allow
      any third party to use their ID and password.
      <br />
      ② The company may prohibit use of an ID in question if a member ID is suspicious of personal
      information exposure, if it may be antisocial or deviating from social norms, or if it could
      be misinterpreted as the company or company's administrator.
      <br />
      ③ If a member becomes aware that their ID or password has been stolen or that a third party is
      using this information, the member shall immediately notify the company and follow the
      company's instructions.
      <br />
      ④ If, in cases stipulated in Article 7 Paragraph 3, members fail to notify the company of
      these facts or if they fail to follow the company's instructions after notifying the company,
      the company shall not be held responsible for any disadvantages which may occur as a result of
      member negligence.
      <br />
      <br />
      <b>Article 8 (Notifications to Members)</b>
      ① If the company sends a notification to members, it will be sent via email, electronic
      message, etc. within the service unless otherwise specified in the terms of use.
      <br />
      ② If the notification is for all members, the company may substitute the notification from
      Paragraph 1 with an announcement displayed on the company's service for 7 days or longer.
      <br />
      <br />
      <b>Article 9 (The Company's Obligations)</b>
      ① The company shall not engage in activities prohibited by relevant laws and terms of use nor
      deviate from social norms, and shall make the effort to provide continuous and stable service.
      <br />
      ② The company shall secure a security system to protect personal information (including credit
      information) so that members can safely use the service, and announce and comply with the
      privacy policy.
      <br />
      ③ The company shall prepare staff and systems required to appropriately process user
      complaints or damage relief requests which may occur with respect to use of service.
      <br />
      ④ If the company finds that a comment or complaint raised by a member regarding service use is
      legitimate, the company must take appropriate actions to resolve it. The company will deliver
      the processing status and results to a member via email, etc. regarding a comment or complaint
      that member has raised.
      <br />
      <br />
      <b>Article 10 (Member Obligations)</b>
      Members are prohibited from the following activities.
      <br />
      1. Registering false information when applying or making changes
      <br />
      2. Using another person's information
      <br />
      3. Changing information posted by the company
      <br />
      4. Transmitting or posting information (computer programs, etc.) not allowed by the company
      <br />
      5. Violating the company's or a third party's copyright or intellectual property rights
      <br />
      6. Damaging the company's or a third party's reputation or sabotaging an operation
      <br />
      7. Disclosing or posting obscenities, violent messages, images, sounds, and other information
      against public norms
      <br />
      8. Using the service for commercial purposes without the company's consent
      <br />
      9. Other illegal or inappropriate activities
      <br />
      <br />
      <b>Article 11 (Service Provision, Etc.)</b>
      ① The company will provide the following services to its members.
      <br />
      1. Lesson scenario content
      <br />
      2. Quiz questions, words, and sentence structures for each scenario
      <br />
      3. Statistics through the lesson result dashboard
      <br />
      4. Notifications of important information via email, text message, direct message, etc.
      <br />
      5. All services provided for members through partnerships with other companies or additional
      services developed by other companies
      <br />
      ② By default, the service will be provided for 24 hours a day, all year around.
      <br />
      ③ The company may temporarily suspend services if information and communication facilities,
      including computers, require maintenance or replacement, if there are any errors,
      communication interruptions, or other significant operational reasons. In this case, the
      company shall notify members through the methods stipulated in Article 9 [Notifications to the
      Member]. However, if there is an unavoidable reason which does not allow the company to
      announce it in advance, the company may notify members afterwards.
      <br />
      ④ The company may carry out routine maintenance if it is necessary to provide the service
      properly, and the routine maintenance schedule will be announced on the service screen.
      <br />
      <br />
      <b>Article 12 (Orders and Payments)</b>
      ① Members can request to purchase content provided by the company according to the following
      subparagraphs or another similar procedure.
      <br />
      1. Search for content, view lists, and select items to purchase
      <br />
      2. Check content details
      <br />
      3. Check payment amounts, select payment methods, and make payments (refund policy
      information)
      <br />
      4. Re-confirm payment amounts and make payments
      <br />
      ② Members can pay for paid services through the methods specified by the company. However,
      payments by minor members can be only made under the name or consent of their legal
      representatives, and the legal representatives may cancel their child's (minor's) purchase
      agreement made without their consent. Purchase agreements cannot be canceled if a minor signs
      up as a member by using an adult's resident registration number or uses an adult's payment
      information without their consent, or otherwise leads the company to believe that the minor is
      an adult, even when there is no consent from their legal representative.
      <br />
      ③ For benefits only given 1 time per person, the payment method and other personal identifying
      information can be used to identify them as the same person and block the benefits when
      someone uses two different IDs to claim benefits.
      <br />
      ④ In-app payments in the app market shall be subject to follow the terms of use for the
      respective app market service.
      <br />
      <br />
      <b>Article 13 (Refund Policy)</b>
      ① Details regarding the right to withdraw or cancel purchases or return products for refund
      shall be subject to change according to the terms of use for the respective app market
      service. Except the cases stipulated in the respective app market's refund policy or
      provider's refund policy, no purchases can be canceled and there will be no returns,
      exchanges, or refunds. <br />
      ② For in-app payment methods or cancellation/refund issues for app markets, please contact a
      respective app market's customer service center. However, users cannot request a refund in the
      event of the following cases.
      <br />
      1. If they have already viewed the purchased content
      <br />
      2. If the service cannot be used because it hasn't been updated if the member has refused
      service updates
      <br />
      3. If the service cannot be used due to a member's mistakes
      <br />
      4. If the paid service is used without direct payment from the member; a paid service given by
      others as a gift or a bonus service from promotional events
      <br />
      ③ A "paid member" who wishes to maintain "paid service" must act in advance to ensure that
      there are no unpaid service fees and their payment method is not overdue.
      <br />
      ④ When payment for the paid service fee is not made while using the paid service with monthly
      subscription upon paid service agreement, in violation of the previous article, the
      subscription may be canceled automatically on the first day the payment is overdue.
      <br />
      ⑤ If a "member" using "paid service" cancels their membership agreement, the "paid service"
      will be immediately canceled. If a refund is requested, the "company" will process a refund
      after deducting a certain amount obtained by assessing the number of days the service was
      used, the details of the service used, any amount benefited from the service or product, and
      any discounted amount. Yet, the "company" will process the information and usage record of the
      "member" who used the "paid service" in accordance with the terms of use, unless it is
      stipulated otherwise in relevant laws.
      <br />
      <br />
      <b>Article 14 (Changes to the Service)</b>
      ① The company may make changes to all or a portion of the services it offers according to
      operational or technical needs. (including paid services)
      <br />
      ② If there are changes to the service content, usage method, or usage time, the company will
      post reasons for the change, details of the changed service, date of offer, and more, in
      advance on the initial screen for the relevant service.
      <br />
      ③ The company may revise, suspend, or change all or a portion of the services it offers for
      free according to the company's policy or operational needs, and it will not compensate
      members for the changes unless stipulated otherwise in relevant laws.
      <br />
      <br />
      <b>Article 15 (Provision of Information and Posting Advertisements)</b>
      ① The company may provide various information deemed as necessary for service use to its
      members by posting an announcement, sending an email, etc. However, members may refuse to
      receive emails at any time, except transaction-related information in accordance with relevant
      laws and responses to customer inquiries.
      <br />
      ② To send the information specified in Paragraph 1 via telephone or fax, the company will get
      members' prior consent for transmission. However, information related to the member's
      transactions or responses to customer inquiries will be exempt from this consent.
      <br />
      ③ The company may post advertisements on the service screen, website, email, etc. for its
      service operation. Members may refuse to receive emails containing advertisements from the
      company.
      <br />
      Users (including members and non-members) may not take any actions to change, revise, or
      restrict service-related postings or other information created by the company.
      <br />
      <br />
      <b>Article 16 (Content Copyrights)</b>
      ① The company owns the copyrights and intellectual property rights to the content offered
      through the service.
      <br />
      ② Users may not use information obtained while using the service that is under the company's
      intellectual property rights for commercial or non-commercial purposes or use the information
      of a third party by copying, selling, publishing, transmitting, distributing, broadcasting, or
      other methods without the company's prior consent.
      <br />
      <br />
      <b>Article 17 (Copyright Management)</b>
      ① When an affiliate's content uploaded through business negotiation with the company contains
      information which violates the Information and Communications Network Act, the Copyright Act,
      or other relevant laws, the copyright owner may request the posted content be suspended or
      deleted according to the procedures in relevant laws. The company may delete the content at
      its discretion in accordance with relevant laws, and the posting temporarily deleted can be
      posted only if the legal issues regarding this post are resolved and a posting request is made
      to the company.
      <br />
      ② Even without a request by the copyright owner, as in the previous paragraph, the company may
      take any temporary actions on the content in question according to relevant laws when there is
      a reason to believe that there is a breach of rights or when it is against the company's
      policy or relevant laws.
      <br />
      <br />
      <b>Article 18 (Ownership of Rights)</b>
      ① The company shall own the copyrights and intellectual property rights regarding the service.
      <br />
      ② The company shall only grant its users the right to use their account, ID, content, etc.
      according to the terms and conditions for the service established by the company, and members
      may not transfer, sell, or offer their right to use the service as collateral or partake in
      any other acts of disposition.
      <br />
      <br />
      <b>Article 19 (Agreement Cancellation and Termination)</b>
      ① Members may request to terminate the user agreement through the menu in the service at any
      time. The company must immediately process their request in accordance with relevant laws.
      <br />
      ② If a member terminates the agreement, all of the member's data shall immediately be deleted,
      except in cases where the company retains member information in accordance with relevant laws
      and the privacy policy.
      <br />
      <br />
      <b>Article 20 (Usage Restrictions, Etc.)</b>
      ① If a member violates their obligations in the terms of use or disrupts normal operation of
      service, the company may restrict the user from using the service in several phases, starting
      from a warning or temporary suspension and escalating to a permanent ban or more.
      <br />
      ② Despite the previous paragraph, the company may immediately impose a permanent ban if a
      member violates relevant laws, such as using another person's name or using another person's
      payment method, which violates the Resident Registration Act; offering illegal programs, which
      violates the Copyright Act and the Computer Programs Protection Act; disrupting normal
      operations; conducting illegal communication, which violates the Information and
      Communications Network Act; hacking and distributing malicious programs; overriding their
      access rights; etc. If a permanent ban is imposed in accordance with this paragraph, all
      benefits obtained from the service shall be terminated and "the company" shall not provide any
      compensation for this forfeiture.
      <br />
      ③ If a member does not log in for 3 months or longer, the company may restrict the use of the
      service in order to protect member information and to increase operational efficiency.
      <br />
      The company shall notify its members in accordance with Article 8 [Notifications to the
      Member] when use of service is restricted or if their user agreement has been terminated in
      accordance with this paragraph.
      <br />
      ④ A member may file an objection to the service restriction based on this paragraph according
      to the procedures established by the company. If the company finds that the member's objection
      is legitimate, the company will immediately allow the member to use the service.
      <br />
      <br />
      <b>Article 21 (Limit on Liabilities)</b>
      ① If the company is unable to offer its service due to a natural disaster or equivalent force
      majeure, it will be exempt from its obligations to provide regular services.
      <br />
      ② The company shall not be held responsible when a member cannot use the service properly due
      to that member's fault.
      <br />
      The company shall not be held responsible for the accuracy and reliability of the information,
      materials, or content posted to offer its service to members.
      <br />
      ③ The company shall be exempt from responsibility when a transaction among members or among
      members and third parties occurs within the service.
      <br />
      ④ The company shall not be held responsible for the use of services offered for free unless
      otherwise stipulated in relevant laws.
      <br />
      <br />
      <b>Article 22 (Governing Laws and Court of Jurisdiction)</b>
      ① The laws of the Republic of Korea shall be the governing law for lawsuits filed between the
      company and members.
      <br />
      ② The Seoul Central District Court shall be the court of jurisdiction over lawsuits regarding
      disputes between the company and members.
      <br />
      ③ If a member resides overseas or their address is overseas, the Seoul Central District Court
      of the Republic of Korea shall be the court of jurisdiction over lawsuits regarding disputes
      between the company and the member.
      <br />
      <br />
      <b>[Supplementary Provisions]</b>
      Article 1 (Date of Effect)
      <br />
      These terms of use shall be in effect starting from 16/06/2021 and shall replace the previous
      terms of use.
    </>
  );
};

export default TextTermsEn;
