import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import cn from 'classnames';

import * as Api from '../../library/Api';

import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';

const SpeakMain = ({ isLogin, user, t }) => {
  const navigate = useNavigate();

  const [isVisualCircle1Class, setIsVisualCircle1Class] = useState(false);
  const [isVisualCircle2Class, setIsVisualCircle2Class] = useState(false);
  const [isVisualCircle3Class, setIsVisualCircle3Class] = useState(false);
  const [isVisualCircle4Class, setIsVisualCircle4Class] = useState(false);
  const [isVisualCircle5Class, setIsVisualCircle5Class] = useState(false);
  const [isVisualCircle6Class, setIsVisualCircle6Class] = useState(false);

  const [listType, setListType] = useState('');
  const [courseList, setCourseList] = useState([]);
  const [studyHistoryData, setStudyHistoryData] = useState({
    pronunciation: 0,
    sentence: 0,
    class: 0,
  });

  useEffect(() => {
    if (isLogin === 0) {
      //alert('로그인 후 이용가능합니다.');
      navigate('/member/login');
    }
  }, [isLogin]);

  useEffect(() => {
    if (isLogin === 1) {
      setTimeout(() => {
        setIsVisualCircle1Class(true);
      }, 150);
      setTimeout(() => {
        setIsVisualCircle2Class(true);
      }, 300);
      setTimeout(() => {
        setIsVisualCircle3Class(true);
      }, 450);
      setTimeout(() => {
        setIsVisualCircle4Class(true);
      }, 600);
      setTimeout(() => {
        setIsVisualCircle5Class(true);
      }, 750);
      setTimeout(() => {
        setIsVisualCircle6Class(true);
      }, 900);

      (async () => {
        try {
          const speakCourseListResponse = await Api.speakCourseList({
            esntlId: user.esntlId,
          });
          if (speakCourseListResponse.data.result.data)
            setCourseList(speakCourseListResponse.data.result.data);

          const speakStudyHistoryResponse = await Api.speakStudyHistory({
            esntlId: user.esntlId,
          });
          if (speakStudyHistoryResponse.data.result.data)
            setStudyHistoryData(speakStudyHistoryResponse.data.result.data);
        } catch (error) {}
      })();
    }

    return () => {};
  }, [isLogin]);

  return (
    <>
      {isLogin === 1 && (
        <div
          className={cn('wrap', {
            wrapKo: t('languageCode') === 'ko',
            wrapEn: t('languageCode') === 'en',
            wrapId: t('languageCode') === 'id',
            wrapVi: t('languageCode') === 'vi',
          })}
        >
          <Header />
          <section className="sub-contents">
            <article className="visual speak-visual">
              <div className="inner">
                <div className="txt-area">
                  <h2>
                    {/* <span>{t('Speak_home.Speak_Visual')}</span> Speak */}
                    {t('v1.Speak.Title')}
                  </h2>
                </div>
                <div className="status">
                  <dl className="pronunciation">
                    <dt>{t('Speak_home.Speak_Visual_Total_Score1')}</dt>
                    <dd>
                      {t('languageCode') === 'ko' && (
                        <Trans
                          i18nKey="v1.Common.UnitPoint2"
                          values={{ n: studyHistoryData.pronunciation }}
                        >
                          <span />
                        </Trans>
                      )}
                      {t('languageCode') !== 'ko' && <>{studyHistoryData.pronunciation}</>}
                    </dd>
                  </dl>
                  <dl className="sentence">
                    <dt>{t('Speak_home.Speak_Visual_Total_Score2')}</dt>
                    <dd>
                      {t('languageCode') === 'ko' && (
                        <Trans
                          i18nKey="v1.Common.UnitNumber2"
                          values={{ n: studyHistoryData.sentence }}
                        >
                          <span />
                        </Trans>
                      )}
                      {t('languageCode') !== 'ko' && <>{studyHistoryData.sentence}</>}
                    </dd>
                  </dl>
                  <dl className="course">
                    <dt>{t('Speak_home.Speak_Visual_Total_Score3')}</dt>
                    <dd>
                      {t('languageCode') === 'ko' && (
                        <Trans
                          i18nKey="v1.Common.UnitNumber2"
                          values={{ n: studyHistoryData.class }}
                        >
                          <span />
                        </Trans>
                      )}
                      {t('languageCode') !== 'ko' && <>{studyHistoryData.class}</>}
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="obj">
                <div className={cn('circle01', { move: isVisualCircle1Class })}></div>
                <div className={cn('circle02', { move: isVisualCircle2Class })}></div>
                <div className={cn('circle03', { move: isVisualCircle3Class })}></div>
                <div className={cn('circle04', { move: isVisualCircle4Class })}></div>
                <div className={cn('circle05', { move: isVisualCircle5Class })}></div>
                <div className={cn('circle06', { move: isVisualCircle6Class })}></div>
              </div>
            </article>
            <div className="sub-inner">
              <article className="course-wrap">
                <h3 className="page-title">{t('Speak_home.Speak_Home_Title_Course')}</h3>
                <ul className="course-type">
                  <li>
                    <Link
                      to="#"
                      className={cn({ active: listType === '' })}
                      onClick={(e) => {
                        e.preventDefault();
                        setListType('');
                      }}
                    >
                      {t('Speak_home.Speak_Category_All')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className={cn({ active: listType === '초급' })}
                      onClick={(e) => {
                        e.preventDefault();
                        setListType('초급');
                      }}
                    >
                      {t('common.Learning_Grade_Lv1')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className={cn({ active: listType === '중급' })}
                      onClick={(e) => {
                        e.preventDefault();
                        setListType('중급');
                      }}
                    >
                      {t('common.Learning_Grade_Lv2')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className={cn({ active: listType === '고급' })}
                      onClick={(e) => {
                        e.preventDefault();
                        setListType('고급');
                      }}
                    >
                      {t('common.Learning_Grade_Lv3')}
                    </Link>
                  </li>
                </ul>
                <div className="course-list">
                  {courseList.map((courseItem, courseIndex) => {
                    const isLevel1 = courseItem.courseLevel.substr(0, 5) === 'BEGIN' ? true : false;
                    const isLevel2 = courseItem.courseLevel.substr(0, 5) === 'INTER' ? true : false;
                    const isLevel3 =
                      courseItem.courseLevel.substr(0, 8) === 'BUSINESS' ? true : false;

                    if (listType === '초급' && !isLevel1) return '';
                    if (listType === '중급' && !isLevel2) return '';
                    if (listType === '고급' && !isLevel3) return '';

                    return (
                      <div
                        className={cn('course', {
                          beginner: isLevel1,
                          beginner1: courseItem.courseLevel === 'BEGIN_L1',
                          beginner2: courseItem.courseLevel === 'BEGIN_L2',
                          medium: isLevel2,
                          medium1: courseItem.courseLevel === 'INTER_L1',
                          medium2: courseItem.courseLevel === 'INTER_L2',
                          expert: isLevel3,
                        })}
                        style={{ cursor: 'pointer' }}
                        key={'course' + courseIndex}
                      >
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate('/speak/unit/' + courseItem.courseId);
                          }}
                        >
                          <div className="tag">
                            <span className="grade">
                              {isLevel1 && t('common.Learning_Grade_Lv1')}
                              {isLevel2 && t('common.Learning_Grade_Lv2')}
                              {isLevel3 && t('common.Learning_Grade_Lv3')}
                            </span>{' '}
                            <span className="cate">
                              {(courseItem.courseLevel === 'BEGIN_L1' ||
                                courseItem.courseLevel === 'BEGIN_L2') &&
                                t('v1.Common.DifficultyEasy')}
                              {(courseItem.courseLevel === 'INTER_L1' ||
                                courseItem.courseLevel === 'INTER_L2') &&
                                t('v1.Common.DifficultyMiddle')}
                              {courseItem.courseLevel === 'BUSINESS_L1' &&
                                t('v1.Common.DifficultyHard')}
                            </span>
                          </div>
                          <h4>
                            {t('languageCode') === 'ko' && courseItem.courseTextKor}
                            {t('languageCode') === 'en' && courseItem.courseTextEn}
                            {t('languageCode') === 'id' && courseItem.courseTextIn}
                            {t('languageCode') === 'vi' && courseItem.courseTextViet}
                          </h4>
                          <div className="ing">
                            <div className="num">
                              <span className="current">{courseItem.finishCnt}</span>
                              <span>/ {courseItem.totalCnt}</span>
                            </div>
                            <div className="bar">
                              <div
                                style={{
                                  width:
                                    courseItem.progress === 0 ? '0%' : courseItem.progress + '%',
                                }}
                              ></div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </article>
            </div>
          </section>
          <Footer />
        </div>
      )}
    </>
  );
};

export default SpeakMain;
