import React, { useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import ReactPlayer from 'react-player/file';

const SpeakUnitProblem01 = ({ t, onNextStep, nowStep, classStepList }) => {
  const [isAudioPlay, setIsAudioPlay] = useState(false);
  const reactPlayerRef = useRef();

  if (classStepList && classStepList.length > 0) {
    return (
      <>
        <ReactPlayer
          ref={reactPlayerRef}
          className="react-player"
          url={classStepList[0].classStepTtsUrl}
          playing={isAudioPlay}
          onEnded={() => {
            setIsAudioPlay(false);
          }}
          onPlay={() => {
            reactPlayerRef.current.seekTo(0, 'seconds');
          }}
        />
        <p className="noti">{t('Speak_Edu.Speak_Edu_Expressions_Info')}</p>
        <div className="detail-content">
          <div className="sentence">
            <p style={{ width: '100%', textAlign: 'center', margin: '0' }}>
              <b>{classStepList[0].classExplain}</b>
              {t('languageCode') !== 'ko' && (
                <>
                  <br />
                  <span style={{ fontSize: '22px', margin: '0' }}>
                    {t('languageCode') === 'en' && classStepList[0].classExplainEn}
                    {t('languageCode') === 'id' && classStepList[0].classExplainIn}
                    {t('languageCode') === 'vi' && classStepList[0].classExplainViet}
                  </span>
                </>
              )}
            </p>

            {classStepList[0].classStepTtsUrl && (
              <button
                className={cn('btn-listen', { active: isAudioPlay })}
                onClick={(e) => {
                  e.preventDefault();
                  setIsAudioPlay(!isAudioPlay);
                }}
              >
                <img src="/assets/images/voice.svg" alt="발음듣기" />
              </button>
            )}
          </div>
          <div className="guide-wrap">
            <div className="inner">
              <dl>
                <dt>{t('Speak_Edu.Speak_Edu_Expressions_Title1')}</dt>
                <dd>
                  <ul>
                    {t('languageCode') === 'ko' && <li>{classStepList[0].classExplainWhen}</li>}
                    {t('languageCode') === 'en' && <li>{classStepList[0].classExplainWhenEn}</li>}
                    {t('languageCode') === 'id' && <li>{classStepList[0].classExplainWhenIn}</li>}
                    {t('languageCode') === 'vi' && <li>{classStepList[0].classExplainWhenViet}</li>}
                  </ul>
                </dd>
              </dl>
              <dl>
                <dt>{t('Speak_Edu.Speak_Edu_Expressions_Title2')}</dt>
                <dd>
                  <ul>
                    {(() => {
                      let tmpText = '';
                      if (t('languageCode') === 'ko') tmpText = classStepList[0].classExplainHow;
                      if (t('languageCode') === 'en') tmpText = classStepList[0].classExplainHowEn;
                      if (t('languageCode') === 'id') tmpText = classStepList[0].classExplainHowIn;
                      if (t('languageCode') === 'vi')
                        tmpText = classStepList[0].classExplainHowViet;

                      return tmpText.split('\n').map((item, index) => {
                        return <li key={'unitProblem12-' + index}>{item}</li>;
                      });
                    })()}
                  </ul>
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="btn-area">
          <div className="right">
            <Link
              to="#"
              className="btn-default btn-next"
              onClick={(e) => {
                e.preventDefault();
                onNextStep();
              }}
            >
              {t('common.btn_next')}
            </Link>
          </div>
        </div>
      </>
    );
  }
};

export default SpeakUnitProblem01;
