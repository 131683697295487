import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import * as Api from '../../../library/Api';
import moment from 'moment';

import Lottie from 'react-lottie-player';
import lottieFace01 from '../../../assets/face01.json';
import lottieFace02 from '../../../assets/face02.json';
import lottieFace03 from '../../../assets/face03.json';
import lottieFace04 from '../../../assets/face04.json';
import lottieFace05 from '../../../assets/face05.json';
import lottieFullBlue from '../../../assets/full_blue.json';
import lottieFullGreen from '../../../assets/full_green.json';
import lottieFullPink from '../../../assets/full_pink.json';
import SpeakIconStart3 from './SpeakIconStart3';

const SpeakUnitProblemSuccess = ({ t, user, isLogin, resultData }) => {
  const [pronTotalPer, setPronTotalPer] = useState(-1);
  const [correctIcon, setCorrectIcon] = useState(false);

  const [randIndex, setRandIndex] = useState([-1, -1]);
  const randTexts = [
    [
      t('common.Random_reaction1'),
      t('common.Random_reaction2'),
      t('common.Random_reaction3'),
      t('common.Random_reaction4'),
      t('common.Random_reaction5'),
    ],
    [
      t('common.Random_reaction6'),
      t('common.Random_reaction7'),
      t('common.Random_reaction8'),
      t('common.Random_reaction9'),
      t('common.Random_reaction10'),
      t('common.Random_reaction11'),
    ],
    [
      t('common.Random_reaction12'),
      t('common.Random_reaction13'),
      t('common.Random_reaction14'),
      t('common.Random_reaction15'),
      t('common.Random_reaction16'),
      t('common.Random_reaction17'),
      t('common.Random_reaction18'),
      t('common.Random_reaction19'),
    ],
    [
      t('common.Random_reaction20'),
      t('common.Random_reaction21'),
      t('common.Random_reaction22'),
      t('common.Random_reaction23'),
      t('common.Random_reaction24'),
    ],
  ];
  const [rendText, setRendText] = useState('');

  useEffect(() => {
    if (resultData.pronTotal > 0) {
      // 점수별 메세지 가공
      if (resultData.pronTotal >= 0 && resultData.pronTotal <= 0.5) {
        let randIndex = parseInt(Math.random() * randTexts[0].length);
        setRandIndex([0, randIndex]);
      } else if (resultData.pronTotal > 0.5 && resultData.pronTotal <= 1.5) {
        let randIndex = parseInt(Math.random() * randTexts[1].length);
        setRandIndex([1, randIndex]);
      } else if (resultData.pronTotal > 1.5 && resultData.pronTotal <= 2.5) {
        let randIndex = parseInt(Math.random() * randTexts[2].length);
        setRandIndex([2, randIndex]);
      } else if (resultData.pronTotal > 2.5) {
        let randIndex = parseInt(Math.random() * randTexts[3].length);
        setRandIndex([3, randIndex]);
      }
      setPronTotalPer(parseInt((resultData.pronTotal / 3) * 100));
    }
  }, [resultData.pronTotal]);

  useEffect(() => {
    if (randIndex.length === 2 && randIndex[0] !== -1 && randIndex[1] !== -1)
      setRendText(randTexts[randIndex[0]][randIndex[1]]);
  }, [randIndex, t]);

  useEffect(() => {
    console.log('pronTotalPer', resultData.pronTotal, pronTotalPer);
    if (pronTotalPer >= 0) {
      donut('ring', pronTotalPer);

      if (pronTotalPer <= 10) setCorrectIcon(lottieFace05);
      else if (pronTotalPer <= 40) setCorrectIcon(lottieFace04);
      else if (pronTotalPer <= 80) setCorrectIcon(lottieFace03);
      else setCorrectIcon(lottieFace02);
    }
    // lottieFace05
    // lottieFace04
    // lottieFace03
    // lottieFace02
  }, [pronTotalPer]);

  const donut = (target, precent) => {
    var endPercent = precent;
    var canvas = document.getElementById(target);
    var context = canvas.getContext('2d');
    var x = canvas.width / 2;
    var y = canvas.height / 2;
    var radius = 81;
    var curPerc = 0;
    var counterClockwise = false;
    var circ = Math.PI * 2;
    var quart = Math.PI / 2;

    context.lineWidth = 12;
    context.strokeStyle = '#f4fbf7';
    context.lineCap = 'round';

    function animate(current) {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.beginPath();
      context.arc(x, y, radius, -quart, circ * current - quart, false);
      context.stroke();
      curPerc += 1;
      if (curPerc < endPercent) {
        requestAnimationFrame(function () {
          animate(curPerc / 99);
        });
      }
    }
    animate();
  };

  return (
    <>
      <p className="noti">{t('Speak_Edu.Result_info')}</p>
      <div className="detail-content">
        <div className="learning-result">
          <div className="total">
            <div className="result-face">
              <div className="ring">
                <canvas id="ring" width="175" height="175"></canvas>
              </div>
              <div className="face-area">
                {correctIcon && (
                  <Lottie
                    animationData={correctIcon}
                    className="face"
                    speed={1}
                    play
                    loop={false}
                  />
                )}
              </div>
            </div>
            <p className="good">&quot;{rendText}&quot;</p>
          </div>
          <div className="result-detail">
            <div className="row">
              <strong>{t('Speak_Edu.Result_Category1')}</strong>
              <div className="star total-star">
                <SpeakIconStart3 score={resultData.pronTotal} size={44} />
              </div>
            </div>
            <div className="row direction-column">
              <strong>{t('Speak_Edu.Result_Category2')}</strong>
              <div className="score">
                <div>
                  <div className="star zero">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="38"
                      height="39"
                      viewBox="0 0 71 72"
                    >
                      <g id="normal_star" transform="translate(-960 -2345)">
                        <rect
                          width="38"
                          height="39"
                          transform="translate(960 2345)"
                          fill="rgba(255,255,255,0)"
                        />
                        <path
                          d="M-1353.128,579.714l-11.343-6.808-11.343,6.808a2,2,0,0,1-2.978-2.164l2.962-12.856-9.993-8.7a2,2,0,0,1,1.138-3.5l13.685-1.2,4.66-10.963a2,2,0,0,1,3.681,0l4.673,11,13.309,1.17a2,2,0,0,1,1.138,3.5l-9.642,8.39,3.032,13.162a2.005,2.005,0,0,1-1.951,2.455A1.983,1.983,0,0,1-1353.128,579.714Z"
                          transform="translate(2360.181 1821.44)"
                          fill="#b1b1b1"
                        />
                      </g>
                    </svg>
                  </div>
                  <span className="point">
                    {t('v1.Common.UnitPoint4', { n: 0 })}~{t('v1.Common.UnitPoint4', { n: 0.5 })}
                  </span>
                  <p>
                    <Trans i18nKey="v1.Common.UnitNumber2" values={{ n: resultData.pronL1 }}>
                      <span />
                    </Trans>
                  </p>
                </div>
                <div>
                  <div className="star one">
                    <div>
                      <Lottie
                        animationData={lottieFullBlue}
                        className="face"
                        speed={1}
                        play
                        loop={false}
                        background="transparent"
                        style={{ width: '38px', height: '39px' }}
                      />
                    </div>
                  </div>
                  <span className="point">
                    {t('v1.Common.UnitPoint4', { n: 1 })}~{t('v1.Common.UnitPoint4', { n: 1.5 })}
                  </span>
                  <p>
                    <Trans i18nKey="v1.Common.UnitNumber2" values={{ n: resultData.pronL2 }}>
                      <span />
                    </Trans>
                  </p>
                </div>
                <div>
                  <div className="star two">
                    <div>
                      <Lottie
                        animationData={lottieFullBlue}
                        className="face"
                        speed={1}
                        play
                        loop={false}
                        background="transparent"
                        style={{ width: '33px', height: '34px' }}
                      />
                    </div>
                    <div>
                      <Lottie
                        animationData={lottieFullGreen}
                        className="face"
                        speed={1}
                        play
                        loop={false}
                        background="transparent"
                        style={{ width: '33px', height: '34px' }}
                      />
                    </div>
                  </div>
                  <span className="point">
                    {t('v1.Common.UnitPoint4', { n: 2 })}~{t('v1.Common.UnitPoint4', { n: 2.5 })}
                  </span>
                  <p>
                    <Trans i18nKey="v1.Common.UnitNumber2" values={{ n: resultData.pronL3 }}>
                      <span />
                    </Trans>
                  </p>
                </div>
                <div>
                  <div className="star three">
                    <div>
                      <Lottie
                        animationData={lottieFullBlue}
                        className="face"
                        speed={1}
                        play
                        loop={false}
                        background="transparent"
                        style={{ width: '30px', height: '31px' }}
                      />
                    </div>
                    <div>
                      <Lottie
                        animationData={lottieFullGreen}
                        className="face"
                        speed={1}
                        play
                        loop={false}
                        background="transparent"
                        style={{ width: '30px', height: '31px' }}
                      />
                    </div>
                    <div>
                      <Lottie
                        animationData={lottieFullPink}
                        className="face"
                        speed={1}
                        play
                        loop={false}
                        background="transparent"
                        style={{ width: '30px', height: '31px' }}
                      />
                    </div>
                  </div>
                  <span className="point">{t('v1.Common.UnitPoint4', { n: 3 })}</span>
                  <p>
                    <Trans i18nKey="v1.Common.UnitNumber2" values={{ n: resultData.pronL4 }}>
                      <span />
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <strong>{t('Speak_Edu.Result_Category3')}</strong>
              <div className="time">
                {(() => {
                  if (resultData.totalTime >= 3600)
                    return moment.utc(resultData.totalTime * 1000).format('HH:mm:ss');
                  else return moment.utc(resultData.totalTime * 1000).format('mm:ss');
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpeakUnitProblemSuccess;
