import React from 'react';

const TextProfileEn = () => {
  return (
    <>
      <b>Article 1 Purpose of processing personal information</b>
      ① The company collects and processes personal information for the following purposes. Personal
      information the company processes will not be used for the purposes other than the followings,
      and necessary procedures including obtaining separate consent, according to Article 18 of the
      Personal Information Protection Act, will be followed if there is any change to the purposes.
      <br />
      <p style={{ paddingLeft: '15px' }}>1. Member management</p>
      <p style={{ paddingLeft: '31px' }}>
        Personal information will be processed for the following purposes: identity verification for
        exclusive membership service, maintenance and management of membership qualification,
        prevention of misuse by delinquent members, prevention of unauthorized use, various notices
        and announcements, resolving requests and other complaints, etc.
      </p>
      <p style={{ paddingLeft: '15px' }}>2. Provision of currency or Service</p>
      <p style={{ paddingLeft: '31px' }}>
        Personal information will be processed for the following purposes: provision of services,
        contents, and customized services, user verification, bill payment, bill settlement, payment
        collection, etc.
      </p>
      <p style={{ paddingLeft: '15px', fontSize: '110%', fontWeight: 'bold' }}>
        3. Use in the marketing and advertisements
      </p>
      <p style={{ paddingLeft: '31px', fontSize: '110%', fontWeight: 'bold' }}>
        Personal information will be processed for the following purposes: provision of
        advertisement information including products, services, rewards, promotions, and events,
        provision of services and advertisement posting per demographic factors, assessment of
        access frequency, use of statistics regarding member’s service use, etc.
      </p>
      <p style={{ paddingLeft: '15px' }}>4. Provision of additional services</p>
      <p style={{ paddingLeft: '31px' }}>
        When a member requests an optional service after member sign-up and modification of personal
        information, personal information will be processed to provide on-line optional service
        provider with collected personal information to offer optional services smoothly.
      </p>
      <br />
      <b>Article 2 Items of personal information processed and retention period</b>
      ① By default, personal information refers to the information of a person who is alive. Yet,
      when the information is regarding both corporate or organization and a person, it can be
      processed as personal information in a certain situation. Therefore, the information of
      corporate or organization is included in the items of personal information processed to make
      it easy to understand.
      <br />
      ② The company processes and retains personal information of its users according to the
      relevant laws or within the retention period agreed by users when the personal information was
      collected.
      <br />
      <table>
        <colgroup>
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
        </colgroup>
        <thead>
          <tr>
            <th>Category</th>
            <th colSpan="2">Processed items</th>
            <th>Period of retention and use</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan="2" style={{ textAlign: 'center' }}>
              Member sign up
            </td>
            <td style={{ textAlign: 'center' }}>E-mail member sign up</td>
            <td>[Optional] Profile information (nickname, profile photo, payment records)</td>
            <td rowSpan="2" style={{ fontWeight: 'bold', fontSize: '120%', textAlign: 'center' }}>
              Until membership withdrawal
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: 'center' }}>Simple member sign up (Apple, Facebook, Google)</td>
            <td>[Optional] Profile information (nickname, profile photo, payment records)</td>
          </tr>
        </tbody>
      </table>
      ③ Personal information items the company retains according to the relevant laws and its
      retention period is as follows.
      <br />
      <br />
      1. The Act on Consumer Protection in Electronic Commerce, etc.
      <br />
      A. Records of agreement or subscription cancellations: Five years
      <br />
      B. Records on payments and provision of currency: Five years
      <br />
      C. Records on consumer complaints or dispute processing: Three years
      <br />
      <br />
      2. Protection of Communications Secrets Act
      <br />
      A. Visit (access) records: Three months
      <br />
      <br />
      ④ “The company”, according to the Personal Information Protection Act, will make the ID and
      personal information of a “member” as dormant if there is no login record or use of services
      for 12 months or longer. In this case, before 30 days prior to the 12 months period, the
      “company” will contact a “member” through e-mail, document, fax, phone, or a similar method to
      notify that which personal information items of a “member” will be separately stored and
      managed and its expiration date. However, if the member is using a service, regardless of the
      expiration date, the ID will become dormant once the service is removed. The personal
      information of a dormant ID will be stored for four years and discarded without delay after
      four years.
      <br />
      <br />
      <a href="/privacy" target="_blank">
        [View full text of Privacy Policy]
      </a>
    </>
  );
};

export default TextProfileEn;
