import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

const JoinStep4 = ({ t, completeFormStep }) => {
  const [isPasswordView1, setIsPasswordView1] = useState(false);
  const [isPasswordView2, setIsPasswordView2] = useState(false);
  const {
    register,
    formState: { isValid, errors },
    getValues,
  } = useFormContext();

  return (
    <>
      <fieldset>
        <legend>{t('Member.Member_Step4')}</legend>
        <h3>{t('v1.MemberJoin.Step4_Title')}</h3>
        <div className="input pw">
          <input
            type={isPasswordView1 ? 'text' : 'password'}
            placeholder={t('Member.Member_Password_Title')}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                completeFormStep();
              }
            }}
            {...register('password', { required: t('v1.MemberJoin.Step4_Error01') })}
          />
          <button
            type="button"
            className="btn"
            onClick={() => {
              setIsPasswordView1(!isPasswordView1);
            }}
          >
            {t('v1.MemberJoin.Step4_BtnViewPassword')}
          </button>
        </div>
        <ul className="guide">
          <li>-{t('v1.MemberJoin.Step4_Text1')}</li>
          <li>-{t('v1.MemberJoin.Step4_Text2')}</li>
        </ul>
        <div className="input pw">
          <input
            type={isPasswordView2 ? 'text' : 'password'}
            placeholder={t('Member.Member_Password_Ment')}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                completeFormStep();
              }
            }}
            {...register('password_re', { required: t('v1.MemberJoin.Step4_Error01') })}
          />
          <button
            type="button"
            className="btn"
            onClick={() => {
              setIsPasswordView2(!isPasswordView2);
            }}
          >
            {t('v1.MemberJoin.Step4_BtnViewPassword')}
          </button>
        </div>
        {errors.password && <p className="alert">! {errors.password.message}</p>}
        {errors.success && <p className="alert">! {errors.success.message}</p>}
        <div className="btn-area">
          <button type="button" className="btn-login btn-full" onClick={completeFormStep}>
            {t('v1.MemberJoin.Step4_BtnJoin')}
          </button>
        </div>
      </fieldset>
    </>
  );
};

export default JoinStep4;
