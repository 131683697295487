import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import * as Api from '../../library/Api';
import Footer from '../../layouts/Footer';
import Header from '../../layouts/Header';
import { ContextStore } from '../../ContextProvider';
import { useUserDispatch } from '../../context/UserContext';

import { signInWithPopup, FacebookAuthProvider } from 'firebase/auth';
import {
  firebaseGoogleProvider,
  firebaseFacebookProvider,
  firebaseAppleProvider,
  firebaseAuth,
} from '../../Firebase';
import SocialJoinStep1 from './layout/SocialJoinStep1';

const Login = ({ t }) => {
  const navigate = useNavigate();
  const userDispatch = useUserDispatch();

  const [isPasswordView, setIsPasswordView] = useState(false);
  const { loggedUser, loggedIn, setLoggedUser, setLoggedIn } = useContext(ContextStore);

  const [socialData, setSocialData] = useState({
    is: false,
    type: '',
    user: {},
  });

  const {
    register,
    setError,
    watch,
    handleSubmit,
    getValues,
    setValue,
    formState: { isSubmitting, isDirty, errors },
  } = useForm({ mode: 'onBlur' });

  const onSubmit = async (data) => {
    if (getValues('password').length < 8) {
      setError('password', {
        message: t('v1.MemberLogin.ErrorPassword02'),
      });
      return false;
    }

    try {
      const loginCheckResponse = await Api.login({
        id: getValues('id'),
        password: getValues('password'),
      });

      // 로그인 처리
      userDispatch({
        type: 'LOGIN',
        user: loginCheckResponse.data.result.data,
      });

      navigate('/main');
    } catch (loginCheckError) {
      console.log('loginCheckError', loginCheckError);

      userDispatch({ type: 'LOGOUT' });

      if (loginCheckError.response.data.result.code === '1') {
        setError('password', {
          message: t('v1.MemberLogin.ErrorPassword03', {
            n: loginCheckError.response.data.result.data + 1,
          }),
          message2: t('v1.MemberLogin.ErrorPassword04'),
        });
      } else if (loginCheckError.response.data.result.code === '2') {
        setError('id', { message: t('v1.MemberLogin.ErrorId03') });
      } else if (loginCheckError.response.data.result.code === '3') {
        setError('password', { message: t('v1.MemberLogin.ErrorPassword05') });
      } else {
        setError('id', { message: 'Error-Network' });
      }

      //alert('로그인이 실패하였습니다. 아이디/비밀번호를 다시 한번 확인해주세요.');
      //throw loginCheckError;
    }

    return false;
  };

  const onLoginIdCheck = async () => {
    try {
      const checkDuplicateIdResponse = await Api.checkDuplicateId(getValues('id'));
      if (checkDuplicateIdResponse.data.result.code === '1') {
        if (checkDuplicateIdResponse.data.result.data === 'GOOGLE') {
          setError('id', { message: t('ETC.Signup_Already_GG') });
        } else if (checkDuplicateIdResponse.data.result.data === 'APPLE') {
          setError('id', { message: t('ETC.Signup_Already_AP') });
        } else if (checkDuplicateIdResponse.data.result.data === 'FACEBOOK') {
          setError('id', { message: t('ETC.Signup_Already_FB') });
        }
      }
    } catch (loginCheckError) {}
  };

  useEffect(() => {
    console.log('check');
  }, [watch]);

  const handleSnsLogin = (type) => {
    let tmpProvider = firebaseGoogleProvider;
    if (type === 'APPLE') {
      tmpProvider = firebaseAppleProvider;
    } else if (type === 'FACEBOOK') {
      tmpProvider = firebaseFacebookProvider;
    }

    signInWithPopup(firebaseAuth, tmpProvider)
      .then((result) => {
        console.log('handleSnsLogin', type, result);

        let tmpUserData = {
          displayName: result.user.displayName
            ? result.user.displayName
            : result.user.providerData[0].displayName,
          email: result.user.email ? result.user.email : result.user.providerData[0].email,
          socialId: result.user.providerData[0].uid
            ? result.user.providerData[0].uid
            : result.user.uid,
        };

        snsLoginCheck(type, tmpUserData);
      })
      .catch((error) => {
        console.log('handleGoogleLoginError', error);
      });
  };

  const snsLoginCheck = (type, userData) => {
    (async () => {
      try {
        let isJoinCheck = false;
        let isError = false;

        // 중복 체크
        if (type === 'FACEBOOK') {
          const checkDuplicateSocialIdResponse = await Api.checkDuplicateSocialId(
            userData.socialId,
          );
          if (checkDuplicateSocialIdResponse.data.result.data === 0) {
            isJoinCheck = true;
          }
        }

        const checkDuplicateIdResponse = await Api.checkDuplicateId(userData.email);
        if (checkDuplicateIdResponse.data.result.code === '0') {
          isJoinCheck = true;
        } else {
          if (type !== checkDuplicateIdResponse.data.result.data) {
            isError = true;
            if (checkDuplicateIdResponse.data.result.data === 'EMAIL') {
              alert('이미 이메일 로 가입된 이메일입니다.\n이메일로 로그인해 주세요.');
            } else {
              alert(
                '이미 ' +
                  checkDuplicateIdResponse.data.result.data +
                  ' 로 간편 가입된 이메일입니다.\n' +
                  checkDuplicateIdResponse.data.result.data +
                  ' 로 로그인해 주세요.',
              );
            }
          }
        }

        // 신규 소셜가입
        if (!isError) {
          if (isJoinCheck === true) {
            setSocialData({
              is: true,
              type: type,
              socialId: userData.socialId,
              user: userData,
            });
          } else {
            // SNS 로그인 처리
            snsLoginSubmit(type, userData);
          }
        }
      } catch (loginCheckError) {
        alert('아이디 중복 체크 중 오류가 발생하였습니다. 다시 시도하시기 바랍니다.');
      }
    })();
  };

  const snsLoginSubmit = (type, userData) => {
    (async () => {
      try {
        let tmpLoginData = {
          id: userData.email,
          password: 'dummy',
          mberType: type,
        };

        if (type === 'FACEBOOK') {
          tmpLoginData.socialId = userData.socialId;
        }

        const loginCheckResponse = await Api.login(tmpLoginData);

        // 로그인 처리
        userDispatch({
          type: 'LOGIN',
          user: loginCheckResponse.data.result.data,
        });

        navigate('/main');
      } catch (loginCheckError) {
        console.log('loginCheckError', loginCheckError);

        userDispatch({ type: 'LOGOUT' });

        //alert('로그인이 실패하였습니다. 아이디/비밀번호를 다시 한번 확인해주세요.');
        setError('id', {
          message: '로그인이 실패하였습니다. 아이디/비밀번호를 다시 한번 확인해주세요.',
        });

        //throw loginCheckError;
      }
    })();
  };

  return (
    <div
      className={cn('wrap', {
        wrapKo: t('languageCode') === 'ko',
        wrapEn: t('languageCode') === 'en',
        wrapId: t('languageCode') === 'id',
        wrapVi: t('languageCode') === 'vi',
      })}
    >
      <Header />
      {!socialData.is && (
        <section className="login-wrap">
          <h2>{t('v1.MemberLogin.Title')}</h2>
          <div className="inner">
            <article className="login-form">
              <section className="form-section">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <fieldset>
                    <legend>{t('v1.MemberLogin.Title')}</legend>
                    <div className="input id">
                      <input
                        type="text"
                        placeholder={t('v1.MemberLogin.PlaceholderId')}
                        {...register('id', {
                          required: t('v1.MemberLogin.ErrorId01'),
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: t('v1.MemberLogin.ErrorId02'),
                          },
                          onBlur: (e) => {
                            onLoginIdCheck();
                          },
                        })}
                      />
                      <button
                        type="button"
                        className="btn"
                        onClick={() => {
                          setValue('id', '');
                        }}
                      >
                        {t('v1.MemberLogin.BtnIdDelete')}
                      </button>
                      {errors.id && <p className="alert">! {errors.id.message}</p>}
                      {errors.login && <p className="alert">! {errors.login.message}</p>}
                    </div>
                    <div className="input pw">
                      <input
                        type={isPasswordView ? 'text' : 'password'}
                        placeholder={t('v1.MemberLogin.PlaceholderPassword')}
                        {...register('password', { required: t('v1.MemberLogin.ErrorPassword01') })}
                      />
                      <button
                        type="button"
                        className={cn('btn', { secret: isPasswordView })}
                        onClick={() => {
                          setIsPasswordView(!isPasswordView);
                        }}
                      >
                        {t('v1.MemberLogin.BtnPasswordView')}
                      </button>
                      {errors.password && <p className="alert">! {errors.password.message}</p>}
                      {errors.password && errors.password.message2 && (
                        <p className="alert">! {errors.password.message2}</p>
                      )}
                    </div>
                    <div className="util">
                      <div className="checkbox">
                        {/*
                      <input type="checkbox" id="auto" {...register('auto')} />
                      <label htmlFor="auto">{t('Member.Member_Auto_Login')}</label>
                      */}
                      </div>

                      <Link to="/member/passwordChange">
                        {t('v1.MemberLogin.BtnPasswordReset')}
                      </Link>
                    </div>

                    <button type="submit" className="btn-login-full">
                      {t('v1.MemberLogin.BtnLogin')}
                    </button>
                  </fieldset>
                </form>
              </section>
              <div className="sns-login">
                <Link
                  to="#"
                  className="google"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSnsLogin('GOOGLE');
                  }}
                >
                  <i></i>
                  {t('v1.MemberLogin.BtnSnsGoogle')}
                </Link>
                <Link
                  to="#"
                  className="apple"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSnsLogin('APPLE');
                  }}
                >
                  <i></i>
                  {t('v1.MemberLogin.BtnSnsApple')}
                </Link>
                <Link
                  to="#"
                  className="facebook"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSnsLogin('FACEBOOK');
                  }}
                >
                  <i></i>
                  {t('v1.MemberLogin.BtnSnsFacebook')}
                </Link>
              </div>
              <div className="join">
                <p>{t('v1.MemberLogin.TextJoin')}</p>
                <Link to="/member/join">
                  <i></i>
                  {t('v1.MemberLogin.BtnJoin')}
                </Link>
              </div>
            </article>
          </div>
        </section>
      )}
      {socialData.is && <SocialJoinStep1 t={t} socialData={socialData} />}
      <Footer />
    </div>
  );
};

export default Login;
